import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Coupon_card from "../../Components/Coupon_card/coupon_card";
import coupon_frame from "../../Assets/Images/icons/coupon_frame.png"
import axiosInstance from "../../Services/axios";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { FaIndianRupeeSign } from "react-icons/fa6";

export default function Coupon_card_list() {
    const navigate = useNavigate();
    const valueParam = useParams();
    const [coupon_list, setCoupon_list] = useState([])
    const location = useLocation();
    const { cart_id } = location.state || {}
    console.log("cart_id----", cart_id);
    const [coupon, setCoupon] = useState()
    const [cartId, setCartId] = useState("");
    const [pageLoaded, setPageLoaded] = useState(false)
    const [couponRawdata, setCouponRawdata] = useState([])


    useEffect(() => {
        vendor_coupons_list()
    }, [])
    const vendor_coupons_list = () => {
        try {

            let reqData = {
                vendor_id: valueParam.vendor_id,
                cart_id: cart_id
            };

            axiosInstance.post("/user_products/vendor_coupons_list", reqData)
                .then((res) => {
                    let data = res.data;

                    console.log('vendor_coupons_list', data);
                    setCoupon_list(data?.vendor_coupon_list)
                    setCouponRawdata(data?.vendor_coupon_list)
                    setPageLoaded(true)
                })
                .catch((err) => {
                    if (err.response.status == 400 || err.response.status == 500) {
                        toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                    }
                });
        } catch (error) {
            toast(" please content pingle are Try again later", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
        }
    };

    const apply_coupon = () => {
        try {

            let reqData = {
                cart_id: cart_id,
                vendor_id: valueParam.vendor_id,
                coupons_id: coupon.coupons_id,
                coupon_code: coupon.coupon_code
            }
            axiosInstance.post("/user_products/apply_coupon", reqData)
                .then((res) => {
                    let data = res.data;

                    navigate(-1)


                })
                .catch((err) => {
                    if (err.response.status == 400 || err.response.status == 500) {
                        toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                    }
                });
        }
        catch {

        }
    }
    const remove_coupon = () => {
        try {

            let reqData = {
                cart_id: cart_id,
                coupons_id: coupon.coupons_id,
            }
            axiosInstance.post("/user/products/remove_coupon", reqData)
                .then((res) => {
                    let data = res.data;
                    vendor_coupons_list()
                })
                .catch((err) => {
                    if (err.response.status == 400 || err.response.status == 500) {
                        toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                    }
                });
        }
        catch {

        }
    }

    const [searchTerm, setSearchTerm] = useState("");

    const filteredCoupons = coupon_list.filter((coupon) =>
        coupon.coupons_name.toLowerCase().includes(searchTerm.toLowerCase())
    );



    return (
        <>
            {pageLoaded ?
                <div className=" ">
                    <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white  " >
                        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(-1)} />
                            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                                Coupons
                            </div>
                        </div>
                    </div>



                    <div className="p-3">
                        <h5 className="fs-14 ff-poppins-semiBold">Enter Coupon Code</h5>
                        <div className="d-flex align-items-center justify-contet-between coupon_search p-2 col-12 mb-3">
                            <input
                                type="search"
                                className="coupon_search_box w-100 border border-0 ff-poppins-semiBold secondary-text-1 fs-14"
                                placeholder="Enter Coupon Code"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <span className="primary-text ff-poppins-semiBold fs-12">Apply</span>
                        </div>
                        {/* Coupon List */}
                        {filteredCoupons.length > 0 ? (
                            filteredCoupons.map((item, index) => (
                                <div key={index} className="mt-2 mb-2">
                                    <div className={`p-1 rounded-4 coupon_card d-flex ${item.available_status == 0 ? "opacity-40" : ""}`}>
                                        <img src={coupon_frame} className="coupon_frame" />
                                        <div className="col-10 ms-1">
                                            <div className="d-flex justify-content-between mt-2">
                                                <div>
                                                    <h5 className="p-0 m-0 fs-15 ff-poppins-semiBold">{item.coupons_name} </h5>
                                                    <h6 className="p-0 m-0 fs-14 ff-poppins-semiBold primary-text">
                                                        {item.discount} {item.discount_type == 1 ?  <FaIndianRupeeSign className="me-1 fs-12" /> : "%"} Discount
                                                    </h6>
                                                </div>
                                                {item.applied_status == 1 ? (
                                                    <div className="ff-poppins-semiBold fs-14 primary-text mt-2" onClick={() => { remove_coupon(); setCoupon(item); }}>
                                                        Remove
                                                    </div>
                                                ) : (
                                                    <button className="apply_btn ff-poppins-semiBold fs-12 mt-2" onClick={() => { apply_coupon(); setCoupon(item); }}>
                                                        Apply
                                                    </button>
                                                )}
                                            </div>
                                            <div className="border_Bootam_Line mt-2"></div>
                                            <div>
                                                <p className="fs-13 ff-poppins-regular primary-text p-0 m-0 mt-2 ">Required purchase value to redeem - {item.min_pur_amount}</p>
                                                <p className="fs-10 ff-poppins-regular  m-0 p-0 mt-2">
                                                    {item.coupons_description.length > 100 ? `${item.coupons_description.slice(0, 80)}...` : item.coupons_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="fs-14 text-center mt-2">No matching coupons found</p>
                        )}
                    </div>

                </div >
                :
                <div className=" ReactLoading">
                    <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                </div>
            }
        </>
    )
}