import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_navbars.svg';
import service_alert from '../../Assets/Images/icons/service_alert.png';
import service_missed from '../../Assets/Images/icons/service_missed.png';
import reschedule from '../../Assets/Images/icons/reschedule.png';
import { getChatList } from '../../Services/api';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import billing from "../../Assets/Images/billing.png"
import booking from "../../Assets/Images/booking.png"
import referral from "../../Assets/Images/referral.png"
import loyalty_redeemed from "../../Assets/Images/loyalty_redeemed.png"
import loyalty_reward from "../../Assets/Images/loyalty_reward.png"
import service_started from "../../Assets/Images/service_started.png"
import service_completed from "../../Assets/Images/service_completed.png"
import DoorstepNotificationIcon from "../../Assets/Images/DoorstepNotificationIcon.png"
import product_order from "../../Assets/Images/product_order.png"
import order_cancelled from "../../Assets/Images/order_cancelled.png"
import order_ready from "../../Assets/Images/product_order.png"
import payment_request from "../../Assets/Images/payment_request.png"
import membership_purchased from "../../Assets/Images/membership_purchased.png"
import axiosInstance from '../../Services/axios';


const NotificationList = () => {
    const location = useLocation()
    const { UserId } = location.state || {};

    const navigate = useNavigate();
    const [notification_details, setNotification_details] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getNotificaionData()
    }, [UserId])

    const getNotificaionData = () => {
        setIsLoading(true);
        let data = {
            user_id: UserId,
        };
        axiosInstance
            .post("user/user_notification_details", data)
            .then((res) => {
                setNotification_details(res.data.notification_results.sort().reverse());
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    // navigate(`/ProductPass/${product.order_id}`) 
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Notification</div>
                </div>
            </div>

            <div className='messengerList scroller-style-y' >
                {
                    !isLoading ?


                        notification_details.length > 0 ?

                            notification_details?.map((item, index) => {
                                return (
                                    <div className='noticationItem m-2 rounded bg-white pt-2 pb-2'
                                        onClick={() => {
                                            // Handle onPress logic based on data.no_role
                                            if (item.no_role === "referral") {
                                                navigate("/PinglePoints", { userId: item?.no_user_id });
                                            } else if (
                                                item.no_role === "loyalty_redeemed" ||
                                                item.no_role === "loyalty_reward"
                                            ) {
                                                navigate("/PinglePoints", { userId: item?.no_user_id });
                                            } else if (
                                                item?.no_role === "billing" ||
                                                item?.no_role === "reschedule" ||
                                                item?.no_role === "booking" ||
                                                item?.no_role === "service_started" ||
                                                item?.no_role === "service_completed" ||
                                                item?.no_role === "missed" ||
                                                item?.no_role === "remainder"
                                            ) {
                                                navigate(`/details/${item.no_trans_id}`)
                                            } else if (item.no_role == "doorstep_request_cancel") {

                                                navigate(`/ReviewAndPay/${item.no_vendor_id}`, {
                                                    User_id: item?.no_user_id,
                                                    Vendor_id: item?.no_vendor_id,
                                                    Cart_App_id: item?.no_trans_id,
                                                    Unconfirmed: true
                                                })

                                            } else if (item.no_role == "order_cancelled" || item.no_role == "product_order" || item.no_role == "order_ready") {
                                                // navigate('/ProductPass', { state: { orderid: item?.no_trans_id } })
                                                navigate(`/ProductPass/${item?.no_trans_id}`) 
                                            }
                                        }}>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-2 d-flex justify-content-between align-items-center'>
                                                    <img src={
                                                        item?.no_role === "billing" ? billing :
                                                            item?.no_role === "reschedule" ? reschedule :
                                                                item?.no_role === "loyalty_reward" ? loyalty_reward :
                                                                    item?.no_role === "booking" ? booking :
                                                                        item?.no_role === "referral" ? referral :
                                                                            item?.no_role === "loyalty_redeemed" ? loyalty_redeemed :
                                                                                item?.no_role === "service_started" ? service_started :
                                                                                    item?.no_role === "service_completed" ? service_completed :
                                                                                        item?.no_role === "missed" ? service_missed :
                                                                                            item?.no_role === "remainder" ? service_alert :
                                                                                                item?.no_role === "doorstep_request" ? DoorstepNotificationIcon :
                                                                                                    item?.no_role === "doorstep_request_cancel" ? DoorstepNotificationIcon :
                                                                                                        item?.no_role === "doorstep_request_accept" ? DoorstepNotificationIcon :
                                                                                                            item?.no_role === "reschedule_request" ? reschedule :
                                                                                                                item?.no_role === "reschedule_request_cancel" ? service_missed :
                                                                                                                    item?.no_role === "reschedule_request_accept" ? reschedule :
                                                                                                                        item?.no_role === "booking_cancel" ? service_missed :
                                                                                                                            item?.no_role === "product_order" ? product_order :
                                                                                                                                item?.no_role === "order_cancelled" ? order_cancelled :
                                                                                                                                    item?.no_role === "order_ready" ? order_ready :
                                                                                                                                        item?.no_role === "order_delivered" ? service_completed :
                                                                                                                                            item?.no_role === "payment_request" ? payment_request :
                                                                                                                                                item?.no_role === "membership" ? membership_purchased : ""
                                                    } width={'50px'} height={'50px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                                                </div>
                                                <div className='col-10'>
                                                    <div className='ff-poppins-semiBold fs-13'>{item.no_title} </div>
                                                    <div className='ff-poppins-regular fs-12 mt-1'>{item.no_msg} </div>
                                                    <div className='ff-poppins-regular fs-12 mt-1'>{item.no_created_at} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='d-flex justify-content-center align-items-center ff-poppins-regular fs-16 h-100' >
                             <span>No Notification</span>
                            </div>

                        :
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div class="spinner-border primary-text" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }


            </div>
        </>
    )
}

export default NotificationList