import React, { useEffect, useRef, useState, useCallback } from "react";
import p_nav_previous from "../../../Assets/Images/p_nav_previous.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import OtpInput from "react-otp-input";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdHome } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { FaHourglassHalf } from "react-icons/fa";
import { RiShareFill } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import { PiPhoneCallFill } from "react-icons/pi";
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import { MdWork } from "react-icons/md";
import { BsDot } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import axiosInstance, { Razorpay_Key } from "../../../Services/axios";
import "../../../Assets/Images/STAR_OUTLINE.png";
import defaultperson from "../../../Assets/Images/defaultperson.jpg";
import { IoIosStarOutline } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import { useUser } from "../../../Components/UserContext"; // Adjust the path according to your file structure
import { getDatabase, ref, onValue } from "firebase/database";
import VerticalStepper from "../../../Components/stepper";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Offcanvas } from "bootstrap";
import Loading from "react-loading";
import AppointmentCancelled from "../../../Assets/Images/Appointment.jpg";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import SuccessLotie from "./../../../Assets/lottieFiles/success.json";
import useRazorpay from "react-razorpay";
import { CiCalendar } from "react-icons/ci";
import { BsClock } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import tick_icon from "../../../Assets/Images/icons/tick_icon.png"
import live_tracking_lens from "../../../Assets/Images/icons/live_tracking_lens.png"
import apply_coupon from "../../../Assets/Images/icons/apply_coupon.png"

import { IoMdClose } from "react-icons/io";
import bell_icon from "../../../Assets/Images/icons/bell_icon.png"
import door_icon from "../../../Assets/Images/icons/door_icon.png"
import Avoid_call from "../../../Assets/Images/icons/avoid_call_icon.png"
import gurard_icon from "../../../Assets/Images/icons/guard_icon.png"
import pet_foot from "../../../Assets/Images/icons/pet_foot_icon.png"
import { IoSend } from "react-icons/io5";
import { RiErrorWarningLine } from "react-icons/ri";
import select_circle from "../../../Assets/Images/icons/select_rectangle.png"
import unselect_circle from "../../../Assets/Images/icons/unselect_rectangle.png"

const ProductPass = () => {

  const valueParam = useParams();
  const order_id = valueParam.order_id;

  const [Razorpay] = useRazorpay();
  const currentUser = useUser(); // Access user context
  const locations = useLocation();
  const { orderid, flowtype, fromPage } = locations.state || {};


  const navigate = useNavigate();
  const paymentSuccessModalRef = useRef(null);
  const canceldisputeModalRef = useRef(null);
  const cancelRefundModalRef = useRef(null);
  const cancelConfirmModalRef = useRef(null);
  const editOrderModalRef = useRef(null);
  const instructionsNoteRef = useRef(null)
  const cancelOrderConfirmModalRef = useRef(null)
  const deliverypersonunavailableRef = useRef(null)
  const [showContentBI, setShowContentBI] = useState(false);
  const [activeButton, setActiveButton] = useState("ticket");
  const [showDetails, setShowDetails] = useState(false);
  const [showStaffDetails, setShowStaffDetails] = useState(false);
  const [product, setProduct] = useState('');
  const [location, setLocation] = useState();
  const [staff, setStaff] = useState([]);
  const [user, setUser] = useState([]);
  const [vendor, setVendor] = useState('');
  const [orderdetail, setOrderdetail] = useState(['']);
  const [gststatus, setGststatus] = useState(false);
  const [payment, setPayment] = useState([]);
  const [deliverystep, setDeliverystep] = useState([]);
  const [cancelorder, setCancelorder] = useState([]);
  const [refund, setRefund] = useState([]);
  const [refundstatus, setRefundstatus] = useState([]);
  const [ratecount, setRatecount] = useState('');
  const [feedback, setFeedback] = useState('');
  const [ratings, setRatings] = useState([false, false, false, false, false]);
  const [showContent, setShowContent] = useState(false);
  const [buyproduct, setBuyproduct] = useState([]);
  const [getproduct, setGetproduct] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [userId, setUserId] = useState();
  const [paymentOrderId, setPaymentOrderId] = useState();
  const [dispute, setDispute] = useState('')
  const [activeTab, setActiveTab] = useState("services");
  const [pilotRatecount, setPilotRatecount] = useState('');
  const [delivery_partner_feedback, setDelivery_partner_feedback] = useState('');
  const [pilotratings, setPilotRatings] = useState([false, false, false, false, false]);
  const [showaddons, setShowaddons] = useState(false);
  const [showAddonsState, setShowAddonsState] = useState({});
  const [editOrder, setEditOrder] = useState("");

  const [delivery_option, setDelivery_option] = useState(1)
  const [deliverytype, setDeliverytype] = useState();
  const [otherdeliveryoption, setOtherdeliveryoption] = useState(false);
  const [instructionsOption, setInstructionsOption] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
  const [noneExistStatus, setNoneExistStatus] = useState("")
  const [consulation, setConsulation] = useState("");
  const [selectedTexts, setSelectedTexts] = useState("")
  const [isChanged, setIsChanged] = useState(false)
  const [ticket_tab, setTicket_tab] = useState(1)
  const [ratingType, setRatingType] = useState()
  const [editproductRateing, setEditProductRateing] = useState(false)
  const [editdeliveryRateing, setEditDeliveryRateing] = useState(false)


  console.log("product---", product);

  console.log('selectedTexts', selectedTexts);

  const toggleContent = () => {
    setShowContent(!showContent);
  };
  const toggleaddon = () => {
    setShowaddons(!showaddons)
  }

  useEffect(() => {
    if (
      product?.payment_details?.balence_request?.request_status == 1 &&
      product.payment_details.payment_status == "0"
    ) {
      openBookForModal();
    } else {
      closeBookForModal();
    }
  }, [payment]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/orders/${order_id}`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        getproductDetails();
      }
    });

    return () => unsubscribe(); // Detach listener on cleanup
  }, [currentUser, order_id]);

  useEffect(() => {
    if (!currentUser) return; // Check if user is not logged in
    const database = getDatabase();
    const orderRef = ref(database, `/payments/product_order/${userId}`);
    // const orderRef = ref(database, `/payments/product_order/23U00002`);
    const unsubscribe = onValue(orderRef, (snapshot) => {
      const data = snapshot.val();
      if (data.status === 2 && data.order_id === paymentOrderId) {
        openPaymentSuccessModal();
      }

    });
    return () => unsubscribe(); // Detach listener on cleanup
  }, [currentUser, paymentOrderId]);

  const [deliveryTypeModal, setDeliveryTypeModal] = useState()

  useEffect(() => {
    if (order_id) {
      const database = getDatabase();
      const orderRef = ref(database, `/instant_delivery/${order_id}`);

      onValue(orderRef, (snapshot) => {
        if (snapshot.exists()) {
          console.log("-----------------------------------------", snapshot.val());

          if (snapshot.val() == 3) {
            setDeliveryTypeModal(true);
            opendeliveryunavailableModal()
          } else if (snapshot.val() == 2) {
            setDeliveryTypeModal(false);
            // deliveryStaffDetails();
          }
        }
      });
    }
  }, [order_id]);


  useEffect(() => {
    getproductDetails();
    localStorage.removeItem("cartID");
  }, []);

  useEffect(() => {
    if (offcanvasRef.current) {
      const bsOffcanvas = new Offcanvas(offcanvasRef.current, {
        backdrop: true,
        scroll: true,
      });

      // Optional: Setup a trigger button or similar activation method
      // Example of setting up a trigger button
      const triggerButton = document.getElementById("triggerButtonId");
      triggerButton.addEventListener("click", function () {
        bsOffcanvas.toggle();
      });
    }
  }, []);

  const handleStarClick = (index) => {
    const newRatings = [...ratings];
    for (let i = 0; i < newRatings.length; i++) {
      if (i <= index) {
        newRatings[i] = true;
      } else {
        newRatings[i] = false;
      }
    }
    setRatings(newRatings);
    setRatecount(index + 1);
  };
  const handlePiltoStarClick = (index) => {
    const pilotnewRatings = [...ratings];
    for (let i = 0; i < pilotnewRatings.length; i++) {
      if (i <= index) {
        pilotnewRatings[i] = true;
      } else {
        pilotnewRatings[i] = false;
      }
    }
    setPilotRatings(pilotnewRatings);
    setPilotRatecount(index + 1);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const toggleStaffDetails = () => {
    setShowStaffDetails(!showStaffDetails);
  };

  const [otp, setOtp] = useState("");
  const handleChangeOTP = (newOtp) => {
    const sanitizedOtp = newOtp.replace(/\D/g, "");
    setOtp(sanitizedOtp);
  };
  const toggleActive = (button) => {
    setActiveButton(button);
  };
  const toggleContentBI = () => {
    setShowContentBI(!showContentBI);
  };


  const getproductDetails = () => {
    try {
      let reqData = {
        order_id: order_id,
      };

      axiosInstance
        .post("user/products/get_order_details", reqData)
        .then((res) => {
          let data = res.data;
          console.log("data get order details ", data);

          setProduct(data);
          setEditOrder(data?.edit_order_detail)

          setLocation(data?.delevery_address);
          setStaff(data.delivery_staff_detail);
          setUser(data.user_detail);
          setVendor(data.vendor_detail);
          console.log("delivery_staff_detail", data);
          if (data.pre_order_status != 1) {
            setOrderdetail(Array.isArray(data.order_products) ? data.order_products : []);
          }
          if (data.shipping_type == 2) {
            // setTicket_tab('2')
          }


          setGststatus(data.gst_status);
          setPayment(data.payment_details);
          if (Array.isArray(data.delivery_steps_detail)) {
            setDeliverystep(data.delivery_steps_detail);
          }
          setInstructionsOption(data.order_notes.delivery_instructions)
          const deliveryInstructions = data.order_notes?.delivery_instructions || [];

          // Filter out non-string values (e.g., numbers)
          const labelsOnly = deliveryInstructions.filter((item) => typeof item === "string");

          setSelectedTexts(labelsOnly);

          setCancelorder(data.order_cancel_detail);
          setRefundstatus(data.order_cancel_detail);
          setBuyproduct(data.offer_details.buy_products);
          setGetproduct(data.offer_details.get_products);
          if (data?.edit_order_status == "1" && data?.edit_order_detail?.request_status == "1") {
            openEditorderModal()
          }

          if (data?.rating_status == "1") {
            handleStarClick(
              data?.rating_detail?.rate_count ? Number(data?.rating_detail?.rate_count) - 1 : 0
            );
            setFeedback(data?.rating_detail?.rate_description);
          }
          if (data?.pilot_rating_status == true) {
            handlePiltoStarClick(
              data?.pilot_rating_detail?.rate_count ? Number(data?.pilot_rating_detail?.rate_count) - 1 : 0
            )
            setDelivery_partner_feedback(data?.pilot_rating_detail?.pilot_feedback)
          }

        }).catch((err) => {
          // if (err.response.status == 400 || err.response.status == 500) {
          //   toast(err.response.data.message, {
          //     position: "bottom-center",
          //     autoClose: 5000,
          //     hideProgressBar: true,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",
          //     className: "text-danger toast-style-1",
          //   });
          // }


        });
    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };
  useEffect(() => {
    if (product?.edit_order_status == "1" && product?.edit_order_detail?.request_status == "1") {
      openEditorderModal()
    }
  }, [product])

  const openCancel_modal = () => {
    cancelRefundModalRef.current.classList.add("show");
    cancelRefundModalRef.current.style.display = "block";
    setIsModalOpen(true)
  }


  const getrefund = () => {
    try {
      let reqdata = {
        order_id: order_id,
      };
      axiosInstance
        .post("/user/products/get_refund_info", reqdata)
        .then((res) => {
          setRefund(res.data.refund_message);

          cancelRefundModalRef.current.classList.add("show");
          cancelRefundModalRef.current.style.display = "block";
          setIsModalOpen(true)
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const cancel_order = () => {
    try {
      let reqData = {
        order_id: order_id,
      };
      axiosInstance
        .post("/user/products/confirm_cancel_order", reqData)
        .then((res) => {
          cancelRefundModalRef.current.classList.remove("show");
          cancelRefundModalRef.current.style.display = "none";
          cancelConfirmModalRef.current.classList.add("show");
          cancelConfirmModalRef.current.style.display = "block";

        });
      setLoading(true); // Start loading
      setButtonClicked(true);

    } catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const starRating = () => {
    try {
      let reqData = {
        order_id: order_id,
        rate_count: ratecount,
        rate_description: feedback,
      };
      if (product.rating_status) {
        reqData["rate_id"] = order_id;
      }
      axiosInstance.post("user/products/submit_order_rating", reqData)
        .then((res) => {
          setEditProductRateing(false)
          toast("Rating has been submitted.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-success toast-style-1",
          });
        })
        .catch((err) => {

          let errorMessage = 'An unexpected error occurred';
          if (err.response) {

            switch (err.response.status) {
              case 400:
                errorMessage = 'Rating Count field is required';
                break;
              case 500:
                errorMessage = 'Feedback is required';
                break;
              default:
                errorMessage = ` ${err.response.data.message || 'Unknown error'}`;
                break;
            }
          } else if (err.request) {
            // The request was made but no response was received
            errorMessage = 'No response was received from the server';
          } else {
            // Something happened in setting up the request that triggered an error
            errorMessage = 'An error occurred: ' + err.message;
          }

          toast(errorMessage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-danger toast-style-1", // Use a different class or styling for error messages
          });
        });
    }
    catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }

  const delivery_Partner_rateing = () => {
    try {
      let reqData = {
        order_id: order_id,
        rate_count: pilotRatecount,
        rate_description: delivery_partner_feedback,
      };
      if (product.rating_status) {
        reqData["rate_id"] = order_id;
      }
      axiosInstance.post("user/products/submit_order_deliverer_rating", reqData)
        .then((res) => {
          setEditDeliveryRateing(false)
          toast("Rating has been submitted.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-success toast-style-1",
          });
        })
        .catch((err) => {
          let errorMessage = 'An unexpected error occurred';
          if (err.response) {

            switch (err.response.status) {
              case 400:
                errorMessage = 'Rating Count field is required';
                break;
              case 500:
                errorMessage = 'Feedback is required';
                break;
              default:
                errorMessage = ` ${err.response.data.message || 'Unknown error'}`;
                break;
            }
          } else if (err.request) {
            // The request was made but no response was received
            errorMessage = 'No response was received from the server';
          } else {
            // Something happened in setting up the request that triggered an error
            errorMessage = 'An error occurred: ' + err.message;
          }

          toast(errorMessage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "text-danger toast-style-1", // Use a different class or styling for error messages
          });
        });
    }
    catch (error) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }
  const raisedispute = () => {
    try {
      let reqData = {
        order_id: order_id,
        reason: dispute
      }

      axiosInstance.post('user/products/raise_dispute', reqData)
        .then((res) => {
          closeDisputeModal()
          getproductDetails()
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    }
    catch {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  }
  const cancelBalanceRequest = () => {
    try {
      const reqData = {
        order_id: order_id,
      };

      axiosInstance.post("user/products/reject_balance_payment_request", reqData)
        .then((res) => {

        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const closeCencalConfirmModal = () => {
    cancelConfirmModalRef.current.classList.remove("show");
    cancelConfirmModalRef.current.style.display = "none";
    setIsModalOpen(false)
  };

  const payBalance = () => {


    let userDetatil = {
      name: "",
      phone: "",
      email: "",
    };

    setPaymentOrderId(payment.balence_request?.payment_order_id);
    setUserId(user?.user_id);
    handlePayment(userDetatil, payment.balence_request.order);
  };

  const cancelEditorderRequest = () => {
    try {
      const reqData = {
        order_id: order_id,
      };

      axiosInstance.post("user/products/user_cancel_edit_order_request", reqData)
        .then((res) => {

          closeEditorderModal()
          getproductDetails()
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {

            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const acceptEditorderRequest = () => {
    try {
      const reqData = {
        order_id: order_id,
      };

      axiosInstance.post("/user/products/accept_and_edit_order", reqData)
        .then((res) => {
          let EditOrder_Detail = {
            orderid: order_id,
            shippingtype: product?.shipping_type,
            vendorId: vendor?.vendor_id
          }
          localStorage.setItem('EditOrder_Detail', JSON.stringify(EditOrder_Detail));
          navigate(`/products/${vendor?.vendor_id}`, { state: { flowtype: "EDIT_ORDER" } })
        }).catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          }
        });
    } catch (err) {
      toast("Try Again Later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      })
    }
  };

  const offcanvasRef = useRef(null);

  const handleOkClick = () => {
    setShowModal(false);
    closeCencalConfirmModal();

  };
  const copyToClipboard = () => {
    const textToCopy = vendor.vendor_share_link;
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);

    toast("Link copied!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      className: "text-dark toast-style-1",
    });

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handlePayment = useCallback(
    (userData, orderData) => {
      const createOrderAndInitializePayment = async () => {
        const options = {
          key: Razorpay_Key,
          amount: orderData.amount,
          currency: orderData.currency,
          name: "PINGLE",
          description: "Payment",
          image: "https://pingle.in/user/assets/images/LogoUser.png",
          order_id: orderData.id,
          handler: (res) => {


            if (res) {
              openPaymentSuccessModal();
              // onPayment(res); // Passing the order ID to the parent component
            }
          },
          prefill: {
            name: userData.name,
            email: userData.email,
            contact: userData.phone,
          },
          theme: {
            color: "#4E26A3",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      };

      createOrderAndInitializePayment();
    },
    [Razorpay]
  );

  const openPaymentSuccessModal = () => {
    paymentSuccessModalRef.current.classList.add("show");
    paymentSuccessModalRef.current.style.display = "block";
  };

  const handleSuccessOkClick = () => {
    paymentSuccessModalRef.current.classList.remove("show");
    paymentSuccessModalRef.current.style.display = "none";
    // navigate('/');
  };

  const openDisputeModal = () => {
    canceldisputeModalRef.current.classList.add("show");
    canceldisputeModalRef.current.style.display = "block";
  };

  const closeDisputeModal = () => {
    canceldisputeModalRef.current.classList.remove("show");
    canceldisputeModalRef.current.style.display = "none";
  };


  const opencancelOrderModal = () => {
    cancelOrderConfirmModalRef.current.classList.add("show");
    cancelOrderConfirmModalRef.current.style.display = "block";
    setCancelConfirmModal(true)
  };

  const closecancelOrderModal = () => {
    cancelOrderConfirmModalRef.current.classList.remove("show");
    cancelOrderConfirmModalRef.current.style.display = "none";
    setCancelConfirmModal(false)
  };




  const opendeliveryunavailableModal = () => {
    if (deliverypersonunavailableRef.current) {
      deliverypersonunavailableRef.current.classList.add("show");
      deliverypersonunavailableRef.current.style.display = "block";
      setIsModalOpen(true);
    }
  };

  const closedeliveryunavailableModal = () => {
    deliverypersonunavailableRef.current.classList.remove("show");
    deliverypersonunavailableRef.current.style.display = "none";
    setIsModalOpen(false);
  };

  const openEditorderModal = () => {
    editOrderModalRef.current.classList.add("show");
    editOrderModalRef.current.style.display = "block";

  };

  const closeEditorderModal = () => {
    editOrderModalRef.current.classList.remove("show");
    editOrderModalRef.current.style.display = "none";
    // navigate('/');
  };
  function openBookForModal() {
    document
      .getElementById("selectCustomerModalProductRP")
      .classList.add("show");
  }

  function closeBookForModal() {
    var modal = document.getElementById("selectCustomerModalProductRP");
    modal.classList.remove("show");
  }


  const toggleAddon = (index) => {
    setShowAddonsState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };

  const hoursFromNow = (neededHour) => {
    let date = new Date();
    date.setHours(date.getHours() + neededHour); // Adjusting the time by needed hours

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if necessary

    return `${hours}:${minutes} ${ampm}`
  };

  let dateOption = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  const handleOptionToggle = (label) => {
    setInstructionsOption((prevOptions) =>
      prevOptions.includes(label)
        ? prevOptions.filter((option) => option !== label) // Remove if already selected
        : [...prevOptions, label] // Add if not selected
    );
    setSelectedTexts((prevOptions) =>
      prevOptions.includes(label)
        ? prevOptions.filter((option) => option !== label) // Remove if already selected
        : [...prevOptions, label] // Add if not selected
    );
    setIsChanged(true)
  };

  const openinstructionsModal = () => {
    instructionsNoteRef.current.classList.add("show");
    instructionsNoteRef.current.style.display = "block";

  };

  const handleContinue = () => {
    closedeliveryunavailableModal()
    setDeliveryTypeModal(false);
    if (deliverytype == "instant") {
      ReassignOrder()
    } else if (deliverytype == "slot") {
      ChooseSlotDelivery()
    }
  };

  const ReassignOrder = () => {
    let data = {
      order_id: order_id,
    };
    console.log("reqdata", data);
    axiosInstance.post("user/products/reassign_order_confirm", data)
      .then((res) => {
         closedeliveryunavailableModal()
         setDeliveryTypeModal(false)
      })
      .catch((err) => {
        if (err.response.status == 500) {

        }
      });
  };

 

  const ChooseSlotDelivery = () => {
    let data = {
      order_id: order_id,
      order_delivery_time: "",
      order_delivery_date: "ANY",
    };
    axiosInstance
      .post("/user/products/order_delivery_time_date_update", data)
      .then((res) => {
        if (res.status == 200) {
          handleOkClick()
        }
      })
      .catch((err) => {
        if (err.response.status == 500 || err.response.status == 400) {


        }
      });
  };
  const handleSendInstructions = async () => {
    try {
      const reqData = {
        order_id: order_id,
        delivery_instructions: selectedTexts,
        additional_notes: consulation
      }

      console.log("reqData", reqData)
      const response = await axiosInstance.post("user/products/update_delivery_instructions", reqData);

      if (response.status = 200) {
        setIsChanged(false);
        console.log("Instructions updated successfully");
      } else {
        console.error("Failed to update instructions");
      }
    } catch (error) {
      console.error("Error sending instructions:", error);
    }
  };



  return (
    <div>
      <div className="min-vh-100">
        <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
          <div className="d-flex justify-content-between align-items-center ms-2 me-2">
            <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => {
              fromPage == "DashBoard" ? navigate("/dashboard") : fromPage == "ReviewPay" ? navigate("/", { state: { fromPage: "ProductPass" } }) : navigate("/", { state: { fromPage: "ProductPass" } });
            }
            } />
            <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
              Product pass
            </div>
          </div>
        </div>
        <div className="servicePassHead scroller-style-y">
          <div className="servicePassCard">
            <div className="">
              <div class="TicketPass-Div">
                {
                  product?.shipping_type == "2" ?
                    <div className="w3-bar w3-black d-flex justify-content-center w-100">
                      <div class="Switch-T-S nav flex-column nav-pills me-3" id="v-pills-tab"
                        role="tablist" aria-orientation="vertical" className="d-flex justify-content-between"  >
                        <div className="col-6" onClick={() => setTicket_tab('1')}>
                          <button class={`px-5 nav-link   w-100 ff-poppins-semiBold w3-bar-item w3-button ${ticket_tab == 1 ? " active" : ""}`}
                            id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home"
                            type="button" role="tab" aria-controls="v-pills-home" aria-selected="true"  >
                            Ticket
                          </button>
                        </div>
                        <div className="col-6" onClick={() => setTicket_tab('2')}>
                          <button class={`px-5 nav-link   w-100 ff-poppins-semiBold w3-bar-item w3-button ${ticket_tab == 2 ? " active" : ""}`}
                            style={{ paddingRight: 40, paddingLeft: 40 }} id="v-pills-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" >
                            Schedule
                          </button>
                        </div>
                      </div>
                    </div> : ""
                }

                <div class="tab-content col-12" id="v-pills-tabContent">
                  {
                    ticket_tab == 1 ?
                      <div className={`tab-pane  ${ticket_tab == 1 ? "show active" : "fade"} `} >
                        {product.order_status == 2 || product.delivery_status == 5 ? (
                          <div className="p-2">

                            {editdeliveryRateing ?

                              <div className="feedback_box p-3" >
                                <div className=" text-center">
                                  <h6 className="p-0 m-0 ff-poppins-medium primary-text" >Rate the delivery partner</h6>
                                  <p className="p-0 m-0 ff-poppins-medium fs-10" >Rate the delivery experience!</p>
                                </div>
                                <div className="feedback mt-1 mb-1">
                                  {pilotratings.map((filled, index) => (
                                    <div key={index} onClick={() => handlePiltoStarClick(index)}>
                                      {filled ? <IoMdStar color="gold" size={30} /> : <IoIosStarOutline color="gold" size={30} />}
                                    </div>
                                  ))}
                                </div>
                                <fieldset className="feedbackInput rounded-3 secondary-border" >
                                  <input className="feedbackEnter bg-white fs-12 text-darl ff-poppins-medium" value={delivery_partner_feedback} onChange={(e) => setDelivery_partner_feedback(e.target.value)} type="text" />
                                  <button className="form-submit col-2 ff-poppins-medium ouline-none primary-bg fs-10 rounded-1 text-light border-0 p-1" onClick={() => delivery_Partner_rateing()}  >
                                    POST
                                  </button>
                                </fieldset>
                              </div> :
                              <div className="rounded-3 secondary-border mt-2">
                                <div className="p-3">
                                  <h6 className="fs-14 ff-poppins-medium p-0 m-0 primary-text">Delivery Rating and Review</h6>
                                  <div className="d-flex justify-content-between mt-1">
                                    <div className="delivery_star d-flex">
                                      {pilotratings.map((filled, index) => (
                                        <div key={index} onClick={() => handleStarClick(index)} className="fs-14" >
                                          {filled ? <IoMdStar color="gold" size={25} /> : <IoIosStarOutline color="gold" size={25} />}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="rounded-2 align-self-center text-dark secondary-border text-center fs-12 ff-poppins-semiBold col-2" onClick={() => setEditDeliveryRateing(true)} >
                                      Edit
                                    </div>
                                  </div>
                                  {
                                    feedback ?
                                      <h6 className="fs-12 mt-1 ff-poppins-medium p-0 m-0 primary-text">{delivery_partner_feedback} </h6> :
                                      <h6 className="fs-12 mt-1 ff-poppins-medium p-0 m-0 primary-text">Please give your feedback</h6>
                                  }
                                </div>
                              </div>

                            }

                            {editproductRateing ?
                              <div className="feedback_box p-3" >
                                <div className=" text-center">
                                  <h6 className="p-0 m-0 ff-poppins-medium primary-text" >Rate the product</h6>
                                  <p className="p-0 m-0 ff-poppins-medium fs-10" >Rate the product experience!</p>
                                </div>
                                <div className="feedback mt-1 mb-1">
                                  {ratings.map((filled, index) => (
                                    <div key={index} onClick={() => handleStarClick(index)}>
                                      {filled ? <IoMdStar color="gold" size={30} /> : <IoIosStarOutline color="gold" size={30} />}
                                    </div>
                                  ))}
                                </div>
                                <fieldset className="feedbackInput rounded-3 secondary-border ">
                                  <input className="feedbackEnter bg-white fs-12 text-darl ff-poppins-medium" value={feedback} onChange={(e) => setFeedback(e.target.value)} type="text" />
                                  <button className="form-submit col-2 ff-poppins-medium ouline-none primary-bg fs-10 rounded-1 text-light border-0 p-1" onClick={() => starRating()}  >
                                    POST
                                  </button>
                                </fieldset>
                              </div> :

                              <div className="rounded-3 secondary-border mt-2">
                                <div className="p-3">
                                  <h6 className="fs-14 ff-poppins-medium p-0 m-0 primary-text">Product Rating and Review</h6>
                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                    <div className="delivery_star d-flex">
                                      {ratings.map((filled, index) => (
                                        <div key={index} onClick={() => handleStarClick(index)} className="fs-14" >
                                          {filled ? <IoMdStar color="gold" size={25} /> : <IoIosStarOutline color="gold" size={25} />}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="rounded-2 align-self-center secondary-border text-dark text-center fs-12 ff-poppins-semiBold col-2" onClick={() => setEditProductRateing(true)}>
                                      Edit
                                    </div>
                                  </div>
                                  {
                                    feedback ?
                                      <h6 className="fs-12 mt-1 ff-poppins-medium p-0 m-0 primary-text">{feedback} </h6> :
                                      <h6 className="fs-12 mt-1 ff-poppins-medium p-0 m-0 primary-text">Please provide your feedback</h6>
                                  }
                                </div>
                              </div>
                            }


                          </div>
                        ) : (
                          <div className="tab-pane fade show active p-0" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                            <div className="d-flex justify-content-center mt-2">
                              <img src={product.order_qr} height="60%" width="60%" />
                            </div>
                          </div>
                        )}
                      </div> :
                      ticket_tab == 2 ?
                        <div className={`tab-pane  ${ticket_tab == 2 ? "show active" : "fade"} `}  >
                          <div className="stepper-Box p-3" >
                            {
                              product.delivery_staff_status != true &&
                              <div className="">
                                <div className="secondary-box  w-100 rounded-3">
                                  <div className="p-2 d-flex justify-content-between">

                                    <div>
                                      <h6 className="p-0 m-0 ff-poppins-regular primary-text fs-13" >Order Placed</h6>
                                      <p className="p-0 m-0 ff-poppins-regular fs-11">Food preparation will begin shortly.</p>
                                    </div>
                                    <div>
                                      <img src={tick_icon} height="20px" width="20px" />
                                    </div>
                                  </div>
                                </div>
                                <div className="secondary-box  w-100 rounded-3 mt-2">
                                  <div className="p-2 d-flex justify-content-between">

                                    <div>
                                      <h6 className="p-0 m-0 ff-poppins-regular primary-text fs-13" >
                                        {
                                          product.delivery_staff_status == true ?
                                            "Delivery Staff Assigned."
                                            :
                                            "Assigning Your Delivery Staff Soon"
                                        }
                                      </h6>
                                      <p className="p-0 m-0 ff-poppins-regular fs-11">
                                        {
                                          product.delivery_staff_status == true ?
                                            " Your order is now assigned for delivery." :
                                            " Expect a delivery staff assignment shortly."
                                        }
                                      </p>
                                    </div>
                                    {
                                      product.delivery_staff_status == true ?
                                        <div>
                                          <img src={tick_icon} height="20px" width="20px" />
                                        </div> :
                                        <div class="spinner-border mt-1 primary-text" role="status" style={{ height: "25px", width: "25px" }} >
                                          <span class="visually-hidden">Loading...</span>
                                        </div>
                                    }

                                  </div>
                                </div>
                              </div>

                            }

                            {deliverystep && deliverystep.length > 0 && product.delivery_staff_status == true && (
                              <div>
                                <VerticalStepper steps={deliverystep} />

                                <div className="d-flex justify-content-end" onClick={() => navigate(`/live_traking/${order_id}`)} >
                                  <div className="primary-bg col-5 rounded-2 d-flex justify-content-center align-items-center" style={{ height: "32px" }}>
                                    <div className=" d-flex justify-content-between align-items-center">
                                      <img src={live_tracking_lens} height="12px" width="12px" />
                                      <div className="text-light fs-12 ff-poppins-regular ms-2">
                                        Live Tracking
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            )}

                          </div>
                        </div>
                        : ""
                  }
                </div>
              </div>



              {/* <div id="London" className={`w3-container city`}  >
                <div class="tab-content" id="pills-tabContent"></div>
              </div> */}
            </div>
          </div>
          <div>
            <div>
              <div className="servicePassCard">
                <div>
                  <div className="headerDivPP d-flex">
                    <div className="col-8 ms-2">
                      <p className="m-0 fs-13 ff-poppins-semiBold">
                        <b>{product.order_date_string}</b>
                      </p>
                      <p className="bookIdSP fs-13 m-0 ff-poppins-semiBold">
                        <b>
                          Order id - <span>{product.order_id} </span>
                        </b>
                      </p>
                    </div>
                    {product.order_status == 3 ? (
                      <div>
                        <button className="unpaidbtn fs-14 p-1 ff-poppins-semiBold">Cancelled</button>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="d-flex flex-column align-items-center otpBorderProuductDiv">
                          <div className="otpBorderProuduct"></div>
                        </div>
                        <div className="me-2">
                          <p className="m-0 fs-13 ff-poppins-semiBold">
                            <b>OTP:</b>
                          </p>
                          <p className="bookIdSP fs-13 m-0 ff-poppins-semiBold">
                            <b>{product.order_code} </b>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {product.shipping_type == "2" ? (
                  <div>
                    <h6 className="ms-2 m-0 pb-3 mb-0 ff-poppins-bold">
                      <b>Delivery Addres</b>
                    </h6>
                    <div className="addresHeadDivPP">
                      <div className="addressHomeDivPP">
                        <div className="homeIConDivPP">
                          {location.location_type_id == 1 ? (
                            <IoMdHome className="homeIconPP" />
                          ) : location.location_type_id == 2 ? (
                            <MdWork className="homeIconPP" />
                          ) : location.location_type_id == 3 ? (
                            <IoLocationSharp className="homeIconPP" />
                          ) : (
                            <p>No Icon</p>
                          )}
                        </div>
                      </div>

                      <div className="addTextDivPP">
                        <h6 className="ff-poppins-semiBold">
                          <b>{location.location_type} </b>
                        </h6>
                        <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular numberOfLines">
                          {location.address}
                        </h6>
                        <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular">
                          {`${(location.distance?.text || "").substring(0, 10)}-${(location.duration?.text || "").substring(0, 10)}`}
                        </h6>
                      </div>
                      <div className="arrowDivPP">
                        <IoIosArrowForward />
                      </div>
                    </div>


                    <div className="secondary-box rounded-3 mt-2">
                      <div className="p-2">
                        <div className="secondary-bg-4 rounded-4" style={{ padding: '6px 8px', display: "flex", justifyContent: "around" }}>
                          <div className="Tab-container">
                            <div className={`Tab-design fs-10 d-flex justify-content-center align-items-center ff-poppins-semiBold ${delivery_option == 1 ? "selected_type" : ""}`}> <p className="p-0 m-0" onClick={() => setDelivery_option(1)}>Delivery Type</p></div>
                            <div className={`Tab-design fs-10 d-flex justify-content-center align-items-center ff-poppins-semiBold ${delivery_option == 3 ? "selected_type" : ""}`}> <p className="p-0 m-0" onClick={() => setDelivery_option(3)}>Instructions </p></div>
                          </div>
                        </div>
                        {

                          delivery_option == 1 ?
                            <div className="mt-4">
                              {product.shipping_type == '2' ?
                                <div className="d-flex justify-content-between align-items-center secondary-box  rounded-3 p-2">
                                  <div className="p-1">
                                    {
                                      product?.delivery_slot_type == '2' ?
                                        <>
                                          <h6 className="p-0 m-0 fs-12 ff-poppins-semiBold">Any Time</h6>
                                          <p className="p-0 m-0 fs-10 ff-poppins-regular">This Product will be within <span className="primary-text">3 days</span>   </p>
                                        </>
                                        : product?.delivery_slot_type == '1' ?
                                          <>
                                            <h6 className="p-0 m-0 fs-12    ff-poppins-semiBold">Slot Delivery</h6>
                                            <p className="p-0 m-0 fs-11    ff-poppins-medium">Slot delivery,at your door on time   </p>
                                            <p className="p-0 m-0 fs-11    ff-poppins-medium primary-text">{product?.order_delivery_date_time?.delivery_time}  {new Date(product?.order_delivery_date_time?.delivery_date.substring(0, 10)).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", }).replace(",", "")} </p>

                                          </> : product?.delivery_slot_type == '3' ?
                                            <>
                                              <h6 className="p-0 m-0 fs-12 ff-poppins-semiBold">Instant Delivery</h6>
                                              <p className="p-0 m-0 fs-10 ff-poppins-regular">At your door in <span className="primary-text">30 minutes!</span>   </p>
                                            </> : ""
                                    }
                                  </div>
                                </div> : ""
                              }
                            </div>
                            :
                            delivery_option == 3 ?
                              <div>
                                <div>
                                  <p className="text-center fs-11 p-0 m-0  ff-poppins-regular mt-2">Add delivery instructions for a smooth drop-off!</p>
                                  <div className="d-flex scroller-style-x">
                                    {(() => {
                                      const allOptions = [
                                        { id: 1, icon: bell_icon, label: "Don't ring the bell" },
                                        { id: 2, icon: door_icon, label: "Leave at the door" },
                                        { id: 3, icon: Avoid_call, label: "Avoid calling" },
                                        { id: 4, icon: gurard_icon, label: "Leave at the guard" },
                                        { id: 5, icon: pet_foot, label: "Pet at home" },
                                      ];

                                      // setSelectedTexts((prevTexts) =>
                                      //   prevTexts.includes(label)
                                      //     ? prevTexts.filter((text) => text !== label) // Remove if already selected
                                      //     : [...prevTexts, label] // Add if not selected
                                      // );

                                      const filteredOptions =
                                        instructionsOption?.order_notes?.delivery_instructions?.length > 0
                                          ? allOptions?.filter((option) =>
                                            instructionsOption?.order_notes?.delivery_instructions?.includes(option?.label)
                                          )
                                          : allOptions;

                                      return (
                                        <div className="d-flex mt-3 overflow-auto scroller-style-x">
                                          {filteredOptions?.map((option) => (
                                            <div
                                              key={option.id}
                                              className={`${instructionsOption?.includes(option.label) ? "select-primary-box" : ""
                                                } Instructions-box mx-1`}
                                              onClick={() => handleOptionToggle(option.label)} // Pass the label here
                                            >
                                              <img src={option.icon} alt={option.label} />
                                              <p className="fs-10 ff-poppins-semiBold p-0">{option.label}</p>
                                            </div>
                                          ))}
                                        </div>
                                      );
                                    })()}
                                  </div>


                                  <div className="d-flex justify-content-between mt-3 mb-3">
                                    <p className="p-0 m-0 fs-12 ff-poppins-semiBold  primary-text" onClick={() => openinstructionsModal()}>+  Add an instructions note</p>
                                    {isChanged && (
                                      <div className="d-flex w-25 p-1 ps-3 pe-3 text-light justify-content-center ff-poppins-regular primary-bg rounded-2 fs-12" onClick={() => handleSendInstructions()} >
                                        Send <span className="text-light fs-12 ms-2"><IoSend /></span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div> : ""
                        }
                      </div>
                    </div>




                    <h6 className="ms-2 m-0 pt-3 ff-poppins-bold">
                      <b>Delivery Staff</b>
                    </h6>

                    <div className="bookForHeadDivRP ">
                      {product.delivery_staff_status == true ? (
                        <div className="staffCardHead rounded-4">
                          <div className="col-3">
                            <div className="staffProfileDiv">
                              {
                                staff?.delivery_staff_type == "Flyer" ?
                                  <img src={staff.staff_image == "" ? defaultperson : staff.pingledel_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="petProfileRP" />
                                  :
                                  staff?.delivery_staff_type == "Pilot" ?
                                    <img src={staff.pilot_image == "" ? defaultperson : staff.pilot_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="petProfileRP" />
                                    :
                                    <img src={staff.staff_image == "" ? defaultperson : staff.staff_image} onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg'; }} className="petProfileRP" />
                              }
                            </div>
                          </div>
                          <div className="col-9 staffContentHeadDiv">
                            <p className="staffNameStaff ff-poppins-bold m-0">{staff?.delivery_staff_type == "Flyer" ? staff.name : staff?.delivery_staff_type == "Pilot" ? staff.pilot_name : staff.staff_name}</p>
                            <p className="ff-poppins-regular fs-12 m-0"> {staff?.delivery_staff_type == "Flyer" ? staff.phone_number : staff?.delivery_staff_type == "Pilot" ? staff?.phone_number : staff?.staff_designation}</p>

                            <div>
                              {showStaffDetails ? (
                                <div className="staffAboutDiv">
                                  <p className="staffSpecializationText fs-12 m-0 ff-poppins-regular">
                                    {staff.staff_specialist}
                                  </p>
                                  <hr className="staffHr" />
                                  {/* <button className="petBehaviorRP">FRIENDLY</button> */}
                                  <p className="staffAboutStaff fs-12 ff-poppins-regular addersClamped-text"> {
                                    staff?.delivery_staff_type == "Flyer" ? staff.about : staff?.delivery_staff_type == "Pilot" ? staff?.about : staff?.staff_about} </p>
                                  <div className="moreInfoDiv" onClick={toggleStaffDetails} >
                                    <p className="moreInfoText p-0 m-0 ff-poppins-semiBold">
                                      Less info
                                    </p>
                                    <IoIosArrowDropupCircle className="" />
                                  </div>
                                </div>
                              ) : (
                                <div className="moreInfoDiv" onClick={toggleStaffDetails}  >
                                  <p className="moreInfoText p-0 m-0 ff-poppins-semiBold">
                                    More info
                                  </p>
                                  <IoIosArrowDropdownCircle />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" ff-poppins-semiBold staffCardHead fs-13 d-flex justify-content-center border border-2 rounded-3">
                          <p className="align-self-center mt-3"> Staff not assign  </p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* {
                  product?.delivery_slot_status == '1' ?
                    < div className="mt-2">
                      <div className="border border-2 rounded-3">
                        <div className="p-2">
                          <div className="d-flex justify-content-start primary-text">
                            <span className="ff-balooPaaji2-bold fs-16 mt-1">Delivery Time</span>
                          </div>
                          <p className="fs-12 ff-poppins-regular secondary-text m-0">Choose time when order will be delivered</p>
                          <hr />
                          {
                            product?.delivery_slot_type == '2' ?
                              <div className="d-flex justify-content-center">
                                <p className="ff-poppins-bold fs-14 m-0 primary-text">Any Time Delivery</p>
                              </div> :
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-around col-4 ff-poppins-regular">
                                  <div className="me-1 primary-text ff-balooPaaji2-bold">
                                    <CiCalendar />
                                  </div>
                                  <div className="fs-12 mt-1">
                                    {new Date(product?.order_delivery_date_time?.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-around col-4 ff-poppins-regular">
                                  <div className="fs-12">
                                    <span className="me-1 primary-text ff-balooPaaji2-bold">
                                      <BsClock />
                                    </span>
                                    {product?.order_delivery_date_time?.delivery_time}
                                  </div>
                                </div>
                              </div>

                          }

                        </div>
                      </div>
                    </div> : <></>
                } */}

                <h6 className="ms-2 m-0 pt-3 ff-poppins-bold">
                  <b>Booking For</b>
                </h6>

                <div className="bookForHeadDivRP">
                  <div className="staffCardHead rounded-4">
                    <div className="col-3">
                      <div className="staffProfileDiv">
                        <img src={user?.user_img} className="petProfileRP" />
                      </div>
                    </div>
                    <div className="col-9 staffContentHeadDiv">
                      <p className="staffNameStaff ff-poppins-bold">
                        <b>{user?.user_name} </b>
                      </p>
                      {/* <div className='ff-poppins-regular fs-10 mb-1'>
                        {product.booking_details?.booking_for?.gender} - {product.booking_details?.booking_for?.age} Years - {product.booking_details?.booking_for?.weight} Kg
                      </div> */}
                      <div className='ff-poppins-regular fs-12 m-0'>
                        MySelf
                      </div>
                      {showDetails ? (
                        <div className="staffAboutDiv">
                          <p className="staffSpecializationText fs-12 m-0 ff-poppins-regular">
                            {user?.user_gender}
                          </p>
                          <div className="moreInfoDiv" onClick={toggleDetails}    >
                            <p className="moreInfoText p-0 m-0 ff-poppins-semiBold">
                              Less info
                            </p>
                            <IoIosArrowDropupCircle />
                          </div>
                        </div>
                      ) : (
                        <div className="moreInfoDiv" onClick={toggleDetails}>
                          <p className="moreInfoText p-0 m-0 ff-poppins-semiBold">
                            More info
                          </p>
                          <IoIosArrowDropdownCircle />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex SPCard" >
                    <div className="col-3" onClick={() => navigate(`/vendor/${vendor?.vendor_id}`)}>
                      <img src={vendor?.vendor_logo} className="followerImage" alt="No Follower Profile" />
                    </div>
                    <div className="col-7" onClick={() => navigate(`/vendor/${vendor?.vendor_id}`)}>
                      <div className="followerDetDiv">
                        <div className="ff-poppins-semiBold fs-14 name_overflow" >{vendor?.vendor_company_name}</div>
                        <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; MID {vendor?.vendor_id} </div>
                        <div className="ff-poppins-medium fs-12 text-secondary">&#x2022; {vendor?.vendor_type_name} </div>
                        <div className="ff-poppins-medium fs-12 text-secondary singleline-text">&#x2022; {vendor?.vendor_address} </div>
                      </div>
                    </div>
                    <div className="col-2 FollowerHistoryDiv" onClick={() => navigate(`/history/${vendor.vendor_id}`)} >
                      <FaHourglassHalf className="hourClassIConFollower" />
                      <p className="followerHistoryP">History</p>
                    </div>
                  </div>
                </div>

                <div className="FHSIConsSP">
                  <div className="d-flex align-items-center">
                    <a href={`tel:${vendor?.vendor_mbl}`} className="text-decoration-none" >
                      <div className="d-flex justify-content-center align-items-center contactDetails">
                        <PiPhoneCallFill className="IconsFHSSP" />
                        <div className="ms-2 ff-poppins-semiBold fs-14 text-white">
                          Call
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="hourGlassIconSP">
                    <div className="d-flex align-items-center" onClick={() => navigate(`/chat/booking/${order_id}`, { state: { chatFlow: 'order' } })}  >
                      <BiMessageDetail className="IconsFHSSP" />
                      <p className="m-0 ms-2 fw-bold">Text</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center " onClick={() => copyToClipboard()} >
                      <RiShareFill className="IconsFHSSP" />
                      <p className="m-0 ms-2 fw-bold">Share</p>
                    </div>
                  </div>
                </div>
                {
                  product?.card_applied_status == 1 && product?.applied_card_detail.card_type == "coupon" ?
                    <>
                      <h6 className="ff-poppins-bold  fs-15 mt-2">Coupons</h6>
                      <div className="secondary-box mb-1 p-2  d-flex justify-content-between align-items-center rounded-3" >
                        <div className="d-flex ">
                          <img src={apply_coupon} height="16px" width="20px" className="mt-1 ms-2" />
                          <span className="fs-14 ms-2 ff-poppins-semiBold"> {product.applied_card_detail.coupons_name}  </span>
                        </div>
                        <span className="fs-10 mt-1 rounded-4 text-center ms-2 ff-poppins-regular applied_lable">Applied</span>

                      </div>

                    </>
                    : ""
                }
                <div>
                  <div className="d-flex justify-content-between mt-4" onClick={toggleContentBI} >
                    <div>
                      <h6 className="bookInfoTextRP ff-poppins-bold">
                        <b>Billng Information</b>
                      </h6>
                    </div>
                    <div>
                      {showContentBI ? (
                        <SlArrowUp className="me-2" />
                      ) : (
                        <SlArrowDown className="me-2" />
                      )}
                    </div>
                  </div>
                  {showContentBI && (
                    <div>
                      <div>
                        <p className="ff-poppins-regular fs-12">
                          Product Initiated at {product?.order_date_string} by{" "}
                          {user.user_name}
                        </p>
                      </div>
                    </div>
                  )}
                  <hr className="my-2" />

                  <div className="">
                    {product?.applied_card_detail?.offer_type == "3" ? (
                      <div className="servicePriceHeadDiv">
                        <h6 className="serviceTextRP">
                          <b>Buy Products</b>
                        </h6>
                        {buyproduct?.map((list, index) => {
                          return (
                            <div className="d-flex justify-content-between fs-14">
                              <p className="m-0"> {list?.product_name}</p>
                              <p className="m-0">
                                {list?.added_count} x {list?.price}
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar fs-14 m-0 d-flex justify-content-between p-1 mt-2">
                          <p className="m-0 text-success">Total</p>
                          <p className="m-0 text-success">
                            Rs {product?.offer_details?.buy_products_total}
                          </p>
                        </div>

                        <h6 className="serviceTextRP mt-4">
                          <b>Get Products(Free)</b>
                        </h6>
                        {getproduct?.map((list, index) => {
                          return (
                            <div className="d-flex justify-content-between fs-14">
                              <p className="m-0"> {list?.product_name}</p>
                              <p className="m-0">
                                {list?.added_count} x {list?.price}
                              </p>
                            </div>
                          );
                        })}
                        <div className="totalBar fs-14 m-0 d-flex justify-content-between p-1 mt-2">
                          <p className="m-0 text-success">Total</p>
                          <p className="m-0 text-success">
                            Rs {product?.offer_details?.get_products_total}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {
                          product?.pre_order_status == 1 ?
                            <h6 className="serviceTextRP ff-poppins-semiBold">Preorder Details</h6> :
                            <h6 className="serviceTextRP ff-poppins-semiBold">Products</h6>
                        }
                        {
                          product?.pre_order_status == 1 &&
                          <div className="d-flex justify-content-between ff-poppins-regular fs-13" >
                            <p className="m-0 ff-poppins-regular">  {product?.pre_order_ticket?.ticket_name}  </p>
                            <p className="m-0 ms-2 ff-poppins-regular">
                              {product?.pre_order_ticket?.redeem_validity}
                            </p>
                          </div>
                        }
                        {
                          orderdetail &&
                          orderdetail?.map((products, index) => {
                            return (
                              <div>
                                <div className="d-flex justify-content-between ff-poppins-regular fs-13" key={index}  >
                                  <p className="m-0 ff-poppins-regular">  {products?.product_name}  </p>
                                  <div className="d-flex">
                                    {
                                      products?.added_addons_details?.length > 0 ?
                                        <div onClick={() => toggleAddon(index)} style={{ cursor: 'pointer' }}>
                                          {showAddonsState[index] ? (
                                            <SlArrowUp className="me-2" />
                                          ) : (
                                            <SlArrowDown className="me-2" />
                                          )}
                                        </div>
                                        : ""
                                    }
                                    <p className="m-0 ms-2">
                                      {products?.added_count} x {Number(products?.price) + Number(products?.addons_cost)}
                                    </p>
                                  </div>
                                </div>
                                <div className="m-0 fs-12 col-10">
                                  {showAddonsState[index] ? (
                                    products?.added_addons_details?.map((addon, addonIndex) => (
                                      <div className="mt-1 d-flex justify-content-between ff-poppins-regular fs-12 ">
                                        <div>  {addon?.addon_name} - {addon?.name}  </div>
                                        <div>  {products?.added_count} x {addon?.price}  </div>
                                      </div>
                                    ))
                                  ) : (
                                    products?.added_addons_details?.map((addon) => addon.name.trim()).join(', ')
                                  )}
                                </div>
                              </div>


                            );
                          })
                        }



                        {product?.card_applied_status == "1" ? (
                          <div className="totalBar fs-13 m-0 p-1 mt-2">
                            {product?.discount_status == "1" ? (
                              <div className="d-flex justify-content-between">
                                <p className="m-0 ff-poppins-regular fs-12 text-success">
                                  {product?.discount_detail?.lable}{" "}
                                </p>
                                <p className="m-0 ff-poppins-regular fs-12 text-success">
                                  Rs {product?.discount_detail?.discount_amount}
                                </p>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between">
                                <p className="m-0 ff-poppins-regular fs-12 text-success">Total</p>
                                <p className="m-0 ff-poppins-regular fs-12 text-success">
                                  Rs {product?.discount_price}
                                </p>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div style={{ borderBottomColor: "gray", borderBottomWidth: 2, borderBottomStyle: "dashed", marginTop: 10, }}></div>
                        )}
                      </div>
                    )}
                  </div>
                  {
                    product?.redeemed_loyalty?.loyalty_status == "1" ?
                      <div className="ff-poppins-regular fs-13 mt-2">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-12 ff-poppins-regular">Subtotal</p>
                          <p className="m-0 fs-12 ff-poppins-regular">
                            {product.sub_toatl}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-12 ff-poppins-regular">Pingle Points redeemed{(product?.redeemed_loyalty?.loyalty_points)}</p>
                          <p className="m-0 fs-12 ff-poppins-regular">
                            - {product.sub_toatl}
                          </p>
                        </div>
                      </div>
                      :
                      <div className="servicePriceHeadDiv dashBorderRP ff-poppins-regular fs-13">



                        {
                          product?.pre_order_status == 1 ?
                            <div className="d-flex justify-content-between">
                              <p className="m-0 fs-12 ff-poppins-regular">Preorder amount</p>
                              <p className="m-0 fs-12 ff-poppins-regular">
                                {product?.pre_order_ticket.ticket_price}
                              </p>
                            </div>
                            :
                            <div className="d-flex justify-content-between">
                              <p className="m-0 fs-12 ff-poppins-regular">Subtotal</p>
                              <p className="m-0 fs-12 ff-poppins-regular">
                                {product.sub_toatl}
                              </p>
                            </div>

                        }
                        {product.delivery_charge_status == true ?
                          <div className="d-flex justify-content-between">
                            <p className="m-0 fs-12 ff-poppins-regular"> Delivery Charge</p>
                            <p className="m-0 fs-12 ff-poppins-regular">
                              {product.delivery_charge}
                            </p>
                          </div>
                          : ""
                        }
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-12 ff-poppins-regular">Packing Cost</p>
                          <p className="m-0 fs-12 ff-poppins-regular">
                            {product.packing_charge}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-12 ff-poppins-regular"> Platform Charge</p>
                          <p className="m-0 fs-12 ff-poppins-regular">
                            {product.user_platform_charges}
                          </p>
                        </div>
                        {
                          product?.delivery_tip &&
                          <div className="d-flex justify-content-between">
                            <p className="m-0 fs-12 ff-poppins-regular">Delivery Tip</p>
                            <p className="m-0 fs-12 ff-poppins-regular">
                              {product.delivery_tip}
                            </p>
                          </div>
                        }
                        {gststatus == true ? (
                          <div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 fs-12 ff-poppins-regular"> SGST</p>
                              <p className="m-0 fs-12 ff-poppins-regular">
                                {product.sgst_amount}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 fs-12 ff-poppins-regular"> CGST </p>
                              <p className="m-0 fs-12 ff-poppins-regular">
                                {product.cgst_amount}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                  }
                  {/* {
                    
                    <div className="d-flex justify-content-between">
                    <p className="m-0 ff-poppins-regular">Delivery</p>
                    <p className="m-0 ff-poppins-regular">
                      {product.sub_toatl}
                    </p>
                  </div>
                  
                  } */}

                  <div className="dashBorderRP"></div>

                  {
                    // cash after delivery
                    payment.payment_method == '1' ?
                      <div className="ff-poppins-bold mt-3 fs-14 border-Bottom-Dashed">
                        <div className="d-flex justify-content-between">
                          <p className="">Total</p>
                          <p className="d-flex me-2 fs-14">
                            {payment.payment_status == 0 ? (
                              <button className="unpaidbtn me-2 fs-12 ff-poppins-semiBold ">
                                UNPAID
                              </button>
                            ) : (
                              <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold">
                                PAID
                              </button>
                            )}
                            {product.total_amount}
                          </p>
                        </div>
                      </div>
                      : payment.payment_method == '2' ?
                        <div className="ff-poppins-bold mt-3 fs-15">
                          <div className="d-flex justify-content-between">
                            <p className="">Total</p>
                            <p className="d-flex me-2 fs-14">
                              {payment.payment_status == 0 ? (
                                <button className="unpaidbtn me-2 fs-12 ff-poppins-semiBold ">
                                  UNPAID
                                </button>
                              ) : (
                                <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold">
                                  PAID
                                </button>
                              )}
                              {product.total_amount}
                            </p>
                          </div>
                        </div>
                        :
                        payment.payment_method == '3' ?
                          <div>
                            <div className="ff-poppins-bold mt-3 fs-15">
                              <div className="d-flex justify-content-between">
                                <p className="">Total</p>
                                <p className="d-flex me-2 fs-14">
                                  {product.total_amount}
                                </p>
                              </div>
                            </div>
                            <div className="ff-poppins-bold fs-15">
                              <div className="d-flex justify-content-between">
                                <p className="">Advance amount</p>
                                <p className="d-flex me-2 fs-14">
                                  <button className="paidBtnSP fs-12 ff-poppins-semiBold me-1">
                                    PAID
                                  </button>
                                  {payment.advance_amount}
                                </p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="">Balance amount</p>
                                <p className="d-flex me-2 fs-14">
                                  {payment.balance_status == 0 ? (
                                    <button className="unpaidbtn fs-12 ff-poppins-semiBold me-1">
                                      UNPAID
                                    </button>
                                  ) : (
                                    <button className="paidBtnSP fs-12 ff-poppins-semiBold me-1">
                                      PAID
                                    </button>
                                  )}
                                  {payment.balance_amount}
                                </p>
                              </div>
                            </div>
                          </div> :
                          payment.payment_method == '4' ?
                            <div className="ff-poppins-bold mt-2  fs-14 border-top-Dashed">
                              <div className="d-flex justify-content-between mt-2" >
                                <p className="">Total</p>
                                <p className="d-flex me-2 fs-14">
                                  <button className="paidBtnSP me-2 fs-12 ff-poppins-semiBold"> PAID  </button>
                                </p>
                              </div>
                            </div>
                            : <></>
                  }





                  {product.order_status == 3 ? (
                    <div>
                      <h6 className="m-0 ff-poppins-semiBold fs-14">
                        Cancel Information
                      </h6>
                      <p className="fs-12 m-0 p-0 ff-poppins-regular">{cancelorder.cancel_description} </p>
                      <h6 className="m-0 ff-poppins-semiBold fs-14">Refund Information</h6>
                      <p className="fs-12 m-0 p-0 ff-poppins-regular">{cancelorder.refund_description} </p>
                      {cancelorder.refund_status == "1" ? (
                        <div>
                          <h6 className="m-0 ff-poppins-semiBold fs-14">Refund Status</h6>
                          <p className="fs-12 m-0 p-0 ff-poppins-regular">
                            {cancelorder.refund_process_status == "1" ? (
                              "Refund Processing"
                            ) : cancelorder.refund_process_status == "2" ? (
                              "Refund Complete"
                            ) : cancelorder.refund_process_status == "3" ? (
                              "Refund Faild"
                            ) : (
                              <></>
                            )}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {product.dispute == true ?
                    <div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute Status</h5>
                        <p className="secondary-text-1 fs-13 ">{product?.dispute_detail?.dispute_status_name} </p>
                      </div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute description</h5>
                        <p className="secondary-text-1 fs-13">{product?.dispute_detail?.dispute_description} </p>
                      </div>
                      <div>
                        <h5 className="fs-15 ff-poppins-bold m-0">Dispute reason</h5>
                        <p className="secondary-text-1 fs-13">{product?.dispute_detail?.dispute_reason} </p>
                      </div>
                    </div> : <></>
                  }
                </div>
              </div>
            </div>{" "}


            {product.order_status == 1 && product.delivery_status <= 4 ? (
              <>

                <div className=" rounded-3 bg-light d-flex justify-content-between align-items-center mt-3 p-2" onClick={() => getrefund()}>
                  <button className="bg-white border border-0 fs-14 secondary-text-2 ff-poppins-semiBold" >
                    Cancel
                  </button>
                  <IoIosArrowForward />
                </div>
                <a href="https://pingle.in/user/refund-policy.php" className="text-dark text-decoration-none fs-12 text-center ff-poppins-semiBold" target="_blank" rel="noopener noreferrer">
                  <div className="text-center fs-10 mb-3 mt-2">
                    Read our <span className="primary-text">Cancellation Policy</span> and <span className="primary-text">Refund Policy</span>
                  </div>

                </a>
              </>
            ) : (
              <></>
            )}
          </div>
          {
            product.dispute == true ?
              <div>
                <p className="primary-text text-center fs-14 ff-poppins-semiBold mt-4" onClick={openDisputeModal}>Raise Dispute</p>
              </div> : <></>
          }


          {product.payment_details?.balence_request?.request_status == 1 &&
            product.payment_details.payment_status == "0" ? (
            <></>
          ) : (
            // <div className="offcanvas-parentType3 position-relative">
            //   <div class="modal-content p-3">
            //     <div class="">
            //       <h1
            //         class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3"
            //         id="staticBackdropLabel"
            //       >
            //         Pay Balance
            //       </h1>
            //     </div>
            //     <div class="">
            //       <p className="m-0 fs-15 ff-poppins-regular text-center mb-1">
            //         <span>IK Salon</span> Has requested you to pay the balance
            //         amount
            //       </p>
            //       <p className="m-0 ff-poppins-regular text-primary text-center fs-15 mb-3">
            //         {payment.balance_amount}
            //       </p>
            //     </div>
            //     <div class="d-flex justify-content-center gap-3">
            //       <button class="btn btn-success px-5 rounded-pill"  > Pay </button>
            //       <button type="button" class="btn btn-danger px-5 rounded-pill" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
            //       >
            //         Reject
            //       </button>
            //     </div>
            //   </div>
            // </div>
            <></>
          )}
        </div>

        {/* Cancel modal  */}

        <div class="offcanvas mainModal CancelProduct cancelBtnModalProductPass"
          style={{ position: "sticky !important", minHeight: "55px !important", borderTopLeftRadius: 15, borderTopRightRadius: 15 }} tabindex="-1" aria-labelledby="offcanvasBottomLabel" ref={cancelRefundModalRef} >
          <div className="refundContentModal mb-2 refundModal">
            <div className="d-flex justify-content-between align-items-center">
              <div className="text-center">
                <p className="m-0 ff-poppins-bold fs-14">Cancel Product</p>
              </div>
              <div onClick={() => { cancelRefundModalRef.current.classList.remove("show"); setIsModalOpen(false) }} >
                <p className="ff-balooPaaji2-semiBold p-2 m-0 pg-primary"   >
                  <IoMdCloseCircle />
                </p>
              </div>
            </div>
            <p className="m-0 fs-12 ff-poppins-regular" >
              Are you sure you want to cancel the product and get refund? Please
              read the read the refund policy before clicking the 'confirm'
              button.
            </p>
            <p className="mt-3 mb-2 ff-poppins-bold fs-14"> Refund amount </p>
            <p className="m-0 mb-2 ff-poppins-regular fs-12">{refund}</p>
            <Link
              to={"https://pingle.in/user/refund-policy.php"}
              className="text-dark text-decoration-none fs-14 text-center"
            >
              <div className="text-center ff-poppins-regular fs-12">
                Read our{" "}
                <span className="primary-text"> Cancellation Policy </span> and{" "}
                <span className="primary-text">Refund Policy</span>
              </div>
            </Link>
          </div>
          <button className="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold"
            style={{ marginTop: 15, position: "relative", padding: "10px 20px", backgroundColor: "#primary-bg", color: "white", border: "0px", borderRadius: "5px", height: "40px", lineHeight: "20px", verticalAlign: "middle", }}
            onClick={() => {
              // if (!buttonClicked) {
              cancel_order();
              //   }
            }}
            disabled={buttonClicked}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Loading type="spinningBubbles" color="#fff" height={20} width={20} />
              </div>
            )}
            {!loading && "Confirm"}
          </button>

        </div>

        {/* 
       <div className={`modal fade`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={orderSuccessModalRef} style={{ backgroundColor: "#fff" }} >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-success" style={{ width: "80%" }} ></div> */}

        {/*   delivery patner unavalibale Modal */}

        {
          deliveryTypeModal &&
          <div className="offcanvas offcanvas-bottomm modal-top-radies  main"
            tabIndex="-1" aria-labelledby="offcanvasBottomLabel"
            ref={deliverypersonunavailableRef} style={{ maxHeight: '78vh', zIndex: 1045 }}>
            <div className="offcanvas-body small bg-light position-relative">

              <div className="p-1">
                <div>
                  <div className=" d-flex justify-content-end mt-1 mb-1 fs-20 ff-poppins-semiBold" onClick={() => closedeliveryunavailableModal()}>
                    <IoMdClose />
                  </div>
                  <div className="primary-gradient-box text-center rounded-3">
                    <span className="primary-text fs-20">  <RiErrorWarningLine /></span>
                    <h5 className="primary-text ff-poppins-semiBold p-0 m-0 fs-15" >Oh no! Delivery persons unavailable!</h5>
                    <p className="primary-text ff-poppins-regular p-0 m-0 fs-12">Please select a different delivery option, change your delivery address, or opt for store pickup.</p>
                  </div>

                  {/* <h6 className="ms-2 m-0 pb-3 fs-13 mb-0 ff-poppins-bold">
                 Delivery Addres 
                </h6>
                <div className="addresHeadDivPP">
                  <div className="addressHomeDivPP">
                    <div className="homeIConDivPP">
                      {location?.location_type_id == 1 ? (
                        <IoMdHome className="homeIconPP" />
                      ) : location?.location_type_id == 2 ? (
                        <MdWork className="homeIconPP" />
                      ) : location?.location_type_id == 3 ? (
                        <IoLocationSharp className="homeIconPP" />
                      ) : (
                        <p>No Icon</p>
                      )}
                    </div>
                  </div>

                  <div className="addTextDivPP">
                    <h6 className="ff-poppins-semiBold">
                      <b>{location?.location_type} </b>
                    </h6>
                    <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular numberOfLines">
                      {location?.address}
                    </h6>
                    <h6 className="addressTextPP fs-12 m-0 ff-poppins-regular">
                      {`${(location?.distance?.text || "").substring(0, 10)}-${(location?.duration?.text || "").substring(0, 10)}`}
                    </h6>
                  </div>
                  <div className="arrowDivPP">
                    <IoIosArrowForward />
                  </div>
                </div> */}

                  <div>
                    <div className="mt-3">
                      <h5 className="ff-poppins-semiBold fs-16 text-dark ">Choose Delivery Options</h5>
                      <p className=" ff-poppins-regular  fs-12 secondary-text-2 ">Choose timeslot when order will be delivered</p>
                    </div>

                    <div className="scroller-style-y">
                      {/* {
                      productDetail?.delivery_settings?.instant_delivery_status == "1" && */}
                      <div>
                        <div className={`${deliverytype == "instant" ? "primary-box primary-text" : "secondary-box"}  relative-box rounded-3 mt-2 mb-2`} onClick={() => setDeliverytype("instant")} >
                          <div className="d-flex justify-content-between align-items-center p-3"  >
                            <div className="d-flex align-items-center">
                              {
                                deliverytype == "instant" ?
                                  <img src={select_circle} height="25px" width="25px" /> :
                                  <img src={unselect_circle} height="25px" width="25px" />
                              }
                              {/* opacity_5 */}
                              <div className="ms-2 ">
                                <h6 className="fs-13 p-0 m-0 ff-poppins-semiBold"> Re-assign Instant Delivery</h6>
                                <p className="fs-10 p-0 m-0 ff-poppins-regular "> You have one attempt for reassign the Instant delivery</p>
                              </div>
                            </div>
                            <div className=" ff-poppins-semiBold">
                              {/* ₹ {productDetail?.delivery_settings?.instant_delivery_charge} */}
                            </div>
                          </div>
                          {/* <div className="primary-bg-light w-100 absolute-box " style={{ borderRadius: "0px 0px 10px 10px" }}>
                          <p className="p-0 m-0 fs-10 ff-poppins-semiBold primary-text text-center">Delivery Unavailable</p>
                        </div> */}
                        </div>

                      </div>
                      {/* // } */}

                      <div className={`secondary-box rounded-3 mt-2`} onClick={() => setDeliverytype("slot")}>
                        <div className="d-flex justify-content-between align-items-center p-3" >
                          <div className="d-flex align-items-center">
                            {
                              deliverytype == "slot" ?
                                <img src={select_circle} height="25px" width="25px" /> :
                                <img src={unselect_circle} height="25px" width="25px" />
                            }
                            <div className="ms-3">
                              <h6 className="fs-13 p-0 m-0 ff-poppins-semiBold">Change to Slot Delivery</h6>
                              <p className="fs-10 p-0 m-0 ff-poppins-regular ">Hassle free slot delivery</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 text-center">
                        <div className="primary-bg fs-12 ff-poppins-regular rounded-2 text-light  p-2 " onClick={() => handleContinue()}>
                          Continue
                        </div>
                        <p className="fs-12 text-dark ff-poopins-semiBold mt-2 mb-2 ">OR</p>
                        <div className="primary-border fs-12 ff-poppins-semiBold rounded-2  primary-text p-2 " onClick={() => opencancelOrderModal()}>
                          Cancel my order <span className="ms-2"> <IoIosArrowForward /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {cancelConfirmModal && <div className="modal-backdrop fade show"></div>}
            </div>
          </div>

        }

        <div className={`modal fade`} id="exampleModalConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={cancelConfirmModalRef}  >
          <div className="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-success px-4 py-2" style={{ width: "70%" }}>
              <div class="modal-body d-flex justify-content-center ff-poppins-bold">
                Order Cancelled
              </div>

              <img src={AppointmentCancelled} alt="My Image" />

              <div class="mb-4 d-flex text-center justify-content-center ff-poppins-regular">
                The order has been cancelled successfully.
              </div>
              <button class="text-center text-white rescheduleCancelBtnPP ff-poppins-semiBold" onClick={handleOkClick}   >
                OK{" "}
              </button>
            </div>
          </div>
        </div>

        <div class="modal fade p-3" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1"    >
          <div class="modal-dialog modal-dialog-centered mainModal p-4">
            <div class="modal-content p-3">
              <div class="">
                <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel"   >
                  Cancel Request
                </h1>
              </div>
              <div class="">
                <p className="m-0 fs-15 ff-poppins-regular text-center mb-3">
                  Are you sure want to cancel the payment request?
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <button class="btn primary-bg px-5 rounded-pill text-white" data-bs-dismiss="modal" onClick={() => cancelBalanceRequest()}   >
                  Confirm
                </button>
                <button type="button" class="btn secondary-bg-1 px-5 rounded-pill" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"   >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`modal fade`} id="successMoadl" tabIndex="-1" aria-labelledby="successMoadl" aria-hidden="true" ref={paymentSuccessModalRef} data-bs-backdrop="false" style={{ backgroundColor: "#fff3" }} >
          <div className="modal-dialog modal-dialog-centered mainModal">
            <div className="modal-content modal-success" style={{ width: "80%" }}>
              <div className="modal-header">
                <div>
                  <div>
                    <div>
                      <Lottie animationData={SuccessLotie} loop={false} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body modal-bodyRP">
                <div className="text-center fw-bold primary-text">
                  Payment Success.
                </div>
                <div className="text-center fw-bold">
                  Redirecting you to your ticket.
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn okBtnRP" onClick={handleSuccessOkClick}    >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="offcanvas" tabindex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390 }} ref={canceldisputeModalRef}  >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title ff-poppins-semiBold fs-16" id="offcanvasBottomLabel">Dispute reason</h5>
            <button type="button" class="btn-close" onClick={closeDisputeModal}></button>
          </div>
          <div class="offcanvas-body small">
            <textarea className="w-100 rounded-2 p-2 mb-3" placeholder="please provide details of your dispute reason."
              value={dispute}
              onChange={(e) => setDispute(e.target.value)}>
            </textarea>
            <button className="w-100 primary-bg border-0 text-white ff-poppins-semiBold py-2 rounded" onClick={() => raisedispute()}>Raise Dispute</button>
          </div>
        </div>
      </div>

      <div className="offcanvas offcanvas-bottomm offcanvass main" style={{ height: 90 }} data-bs-scroll="true" data-bs-target="#staticBackdrop" tabIndex="-1" id="selectCustomerModalProductRP" aria-labelledby=""  >
        <div className=" ">
          <div className="d-flex justify-content-center">
            <div class="modal-content p-3 bg-white">
              <div class="">
                <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-16 mb-3" id="staticBackdropLabel"  >
                  Pay Balance
                </h1>
              </div>
              <div class="">
                <p className="fs-13 text-center">
                  <span> {vendor?.vendor_company_name}</span> Has requested you
                  to pay the balance amount
                </p>
                <p className="m-0 ff-poppins-regular fs-14 text-primary text-center mb-3">
                  (Rs.{payment?.balance_amount})
                </p>
              </div>
              <div class="d-flex justify-content-center gap-3">
                <button class="PayBtn px-5 rounded-pill" onClick={payBalance}>
                  {" "}
                  Pay{" "}
                </button>
                <button
                  type="button"
                  class="rejectBtn px-5v rounded-pill"
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="offcanvas addOnModel_scroll p-2"
        style={{ maxHeight: "100%", borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390 }} tabindex="-1" aria-labelledby="offcanvasBottomLabel" ref={editOrderModalRef} >
        <div className="mb-2">
          <p className="m-0 mb-3 fs-12 ff-poppins-regular mt-2">{vendor?.vendor_company_name} has requested you to edit the order products</p>

          <div className="mt-2">
            <div className="position-relative ">
              <div className="position-absolute top-0 start-50 translate-middle bg-white fs-14 ff-poppins-semiBold">Order Products</div>
              <div className="edit_Order_Box   mt-2">
                {
                  (() => {
                    let requestedProducts = editOrder?.requested_products ? JSON.parse(editOrder?.requested_products) : [];
                    return orderdetail?.map((proId, index) => {
                      requestedProducts.includes(proId?.product_id)
                      return (
                        <div>
                          <div className="d-flex justify-content-between ff-poppins-regular fs-13 mt-1 mb-1" key={index}  >
                            <div className="m-0 ff-poppins-regular fs-12">  {proId?.product_name} </div>
                            <div className="d-flex ">
                              {
                                proId?.added_addons_details?.length > 0 ?
                                  <div onClick={() => toggleAddon(index)} style={{ cursor: 'pointer' }}>
                                    {showAddonsState[index] ? (
                                      <SlArrowUp className="me-2" />
                                    ) : (
                                      <SlArrowDown className="me-2" />
                                    )}
                                  </div>
                                  : ""
                              }
                              <p className="m-0 fs-12 ms-2">
                                {proId?.added_count} x {Number(proId?.price) + Number(proId?.addons_cost)}
                              </p>
                            </div>
                          </div>
                          {
                            proId?.added_addons_details?.length > 0 ?
                              <div className="m-0 fs-10 col-10 ff-poppins-regular">
                                {showAddonsState[index] ? (
                                  proId?.added_addons_details?.map((addon, addonIndex) => (
                                    <div className="mt-1 d-flex justify-content-between">
                                      <div>  {addon?.addon_name} - {addon?.name}  </div>
                                      <div>  {proId?.added_count} x {addon?.price}  </div>
                                    </div>
                                  ))
                                ) : <span className="fs-10"> ({proId?.added_addons_details?.map((addon) => addon.name.trim()).join(', ')})</span>}
                              </div> : ""
                          }

                        </div>
                      );
                    });
                  })()
                }
              </div>
            </div>
            <div className="position-relative mt-4">
              <div className="position-absolute top-0 start-50 translate-middle bg-white fs-14 ff-poppins-semiBold">
                Changed Products
              </div>
              <div className="edit_Order_Box mt-2">
                {orderdetail?.length > 0 &&
                  orderdetail?.map((data, index) => {
                    let requestedProducts = editOrder?.requested_products ? JSON.parse(editOrder?.requested_products) : [];
                    const checkedIndex = requestedProducts?.findIndex((p) => p.productId === data?.product_id);

                    if (checkedIndex > -1) {
                      const selectedProduct = requestedProducts[checkedIndex];

                      return (
                        <div className="mt-1 mb-1" key={index}>
                          <div className="ff-poppins-regular fs-12">
                            <div className="m-0 primary-text fs-12">{data?.product_name}</div>
                            <span className="fs-10">
                              {data?.added_addons_details
                                ? data?.added_addons_details?.map((addon) => addon?.name?.trim()).join(", ")
                                : ""}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-11 ff-poppins-regular mt-2">
                            <div>
                              <p className="m-0">Order Product: {data?.added_count}</p>
                              <p className="m-0">
                                Available Now: {data?.added_count - selectedProduct?.unAvailableCount}
                              </p>
                            </div>
                            <div>
                              <div className="mt-3">
                                {selectedProduct?.availableType === 4 ? (
                                  <span className="primary-text">Permanently Unavailable</span>
                                ) : (
                                  <>
                                    {"Next Available From: "}
                                    {selectedProduct?.availableType === 1 ? (
                                      <span className="primary-text">
                                        {hoursFromNow(selectedProduct?.availableTime)}
                                      </span>
                                    ) : selectedProduct?.availableType === 2 ? (
                                      <span className="primary-text">Tomorrow</span>
                                    ) : selectedProduct?.availableType === 3 ? (
                                      <span className="primary-text">
                                        {selectedProduct?.availableDate
                                          ? new Date(selectedProduct?.availableDate).toLocaleDateString(
                                            "en-GB",
                                            dateOption
                                          )
                                          : new Date().toLocaleDateString("en-GB", dateOption)}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          {index < orderdetail.length - 1 && (
                            <div className="border_bottom-Dashed mb-2"></div>
                          )}
                        </div>
                      );
                    }
                    return null; // Return null if product is not found
                  })}
              </div>

            </div>
          </div>

          <div className="ff-poppins-regular fs-12 mt-2" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
            <p className="m-0 ff-poppins-semiBold">Reason :</p>
            <p className="ms-2 mb-2 ff-poppins-regular">{editOrder?.request_reason}</p>
            <div className="d-flex justify-content-between">
              <button className="bg-success text-light ff-poppins-regular p-1 fs-12 border-0 rounded-4 col-5 ms-4" onClick={() => acceptEditorderRequest()} >Accept or Edit Order</button>
              <button className="bg-danger text-light ff-poppins-regular p-1 fs-12 border-0  rounded-4 col-5 me-4" onClick={() => cancelEditorderRequest()}>Reject & Cancel Order</button>
            </div>
          </div>



        </div>
      </div>

      {/* Cancel Confirm Booking */}


      <div class="modal fade  " aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1" ref={cancelOrderConfirmModalRef}    >
        <div class="modal-dialog modal-dialog-centered mainModal p-4">
          <div class="modal-content p-3 text-center w-75">
            <div class="">
              <h1 class="d-flex ff-poppins-semiBold justify-content-center fs-14 mb-3" id="staticBackdropLabel"   >
                Cancel Order
              </h1>
            </div>
            <div class="">
              <p className="m-0 fs-10 ff-poppins-regular text-center mb-3">
                Are you sure want to cancel the order 100% refund ?
              </p>
            </div>
            <div className="d-flex justify-content-around">
              <button class="btn primary-bg rounded-pill fs-12 col-4 ff-poppins-regular text-white" onClick={() => cancelBalanceRequest()}   >
                Confirm
              </button>
              <button type="button" class="btn secondary-bg-1 text-dark fs-12 col-4 ff-poppins-regular rounded-pill" onClick={() => closecancelOrderModal()}   >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>


      {isModalOpen && <div className="modal-backdrop fade show" onClick={() => closeCencalConfirmModal()} ></div>}
    </div>
  );
};

export default ProductPass;
