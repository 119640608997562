import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg"
import pingle_conin from "../../Assets/Images/PingleCoins-Business.png"
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import axiosInstance from "../../Services/axios";
const PinglePointsHistory = () => {
    const location = useLocation()
    const { vendorId } = location.state || {}
    const [loyalty, setLoyalty] = useState([])
    const [loyaltyhistory, setLoyaltyhistory] = useState([])
    useEffect(() => {
        if (vendorId) {
            getloyaltyhistory()
        }
        getpointhistory()
    }, [])
    const getloyaltyhistory = () => {
        try {
            let reqData = {
                vendor_id: vendorId
            }
            axiosInstance.post("user/my_vendor_loyalty_points_detail", reqData).then((res) => {
                let data = res.data;
                console.log("my_vendor_loyalty_points_detail vendor_loyalty_history", data);
                setLoyalty(res.data)
                setLoyaltyhistory(res.data.vendor_loyalty_history)
            });
        } catch (error) {
            alert("Try again Later");
            console.log("catch====>>>>");
        }
    };
    const getpointhistory = () => {
        try {

            axiosInstance.post("user/my_referral_loyalty_points_detail").then((res) => {
                let data = res.data;
                console.log("my_referral_loyalty_points_detail====>>>>", data);
                setLoyalty(res.data)
                setLoyaltyhistory(res.data.referral_loyalty_history)
            });
        } catch (error) {
            alert("Try again Later");
            console.log("catch====>>>>");
        }
    };
    const formatDate = (date) => {
        if (date && new Date(date).getTime() === new Date(date).getTime()) {
            const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(date));
        } else {
            return "Invalid Date";
        }
    };
    const navigate = useNavigate()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Pingle Points History
                    </div>
                </div>
            </div>
            <div className="p-2 secondary-bg-2 ">
                <div className="loyaltyHistoryList scroller-style-y p-3 mb-2">
                    <div className="">
                        <div className="d-flex p-4 loyaltycoins rounded-4 mb-2">
                            <div className="col-6 " >
                                <img src={pingle_conin} style={{ width: 100, height: 100 }} />
                            </div>
                            <div className="col-6">
                                <div className="ff-poppins-regular fs-12">Pingle Coins</div>
                                <h1 className="ff-poppins-bold primary-text">{vendorId ? loyalty.total_vendor_points : loyalty.total_referal_points} </h1>
                                <p className="ff-poppins-regular fs-12">{vendorId ? loyalty.vendor_eligble_offers_count : loyalty.referal_eligble_offer_count} offers are eligible</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center   text-light" onClick={() => navigate(vendorId ? `/merchant-loyalty/${loyalty.vendor_id}` : "/LoyaltyOffer")}>
                        <p className="col-5 primary-bg p-2 Collectbtn text-center rounded-4 fs-12 ">Collect Offer <span className="ms-1"><FaArrowRightLong /></span> </p>
                    </div>

                    <div className="d-flex justify-content-between primary-text fs-12 ff-poppins-semiBold mt-5">
                        <p>TRANSACTION</p>
                        <p>POINTS</p>
                    </div>
                    {
                        vendorId ? (
                            loyaltyhistory.length > 0 ?
                                loyaltyhistory?.map((list, index) => (
                                    <div key={index}>
                                        <div className="d-flex justify-content-between ff-poppins-semiBold fs-14">
                                            <div className="m-0">{list.vendor_name}</div>
                                            <div style={{ color: list.loyalty_method === "redeem" ? 'red' : "#4E26A3" }}>
                                                {list.loyalty_method === "redeem" ? "-" : "+"} {list.loyalty_points} Coins
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between m-0 secondary-text-1  ff-poppins-regular fs-13">
                                            <div className=" ">
                                                {list.loyalty_method === "booking" ? 'Booking Id' :
                                                    list.loyalty_method === "billing" ? "Billing Id" : "Redeem Id"} : {list.loyalty_method_id}
                                            </div>
                                            <div>{formatDate(list.loyalty_created_at)}</div>
                                        </div>
                                        <hr />
                                    </div>

                                ))
                                :
                                <div className="d-flex justify-content-center primary-text fs-12 ff-poppins-semiBold mt-5">
                                    <p className="m-0">No Transactions Found</p>
                                </div>
                        ) : (
                            loyaltyhistory.length > 0 ?
                                loyaltyhistory?.map((list, index) => (
                                    <div key={index}>
                                        <div className="d-flex justify-content-between ff-poppins-semiBold fs-14">
                                            <div className="m-0">{list.loyalty_method_id ? "Offer redeemed" : list.user_name}</div>
                                            <div style={{ color: list.loyalty_method_id ? 'red' : "#4E26A3" }}>
                                                {list.loyalty_method == "redeem" ? "-" : "+"} {list.loyalty_points} Coins
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between m-0 secondary-text-1 fs-13 ff-poppins-regular">
                                            <div className="">
                                                {list.loyalty_method == "referral" ? 'Referred' : "Redeem Id :"}  {list.loyalty_method_id}
                                            </div>
                                            <div>{formatDate(list.loyalty_created_at)}</div>
                                        </div>
                                        <hr />
                                    </div>
                                ))
                                :
                                <div className="d-flex justify-content-center primary-text fs-12 ff-poppins-semiBold mt-5">
                                    <p className="m-0">No Transactions Found</p>
                                </div>
                        )
                    }


                </div>
            </div>

        </>
    )
}
export default PinglePointsHistory;





// const getMerchantPoints = async (UserId = userId) => {
//     apibaseURL.post('/my_vendor_loyalty_points_detail', {
//       vendor_id: merchantId,
//       user_id: userId

//     }).then((res: any) => {
//       if (res.status == 200) {

//         setMerchantTotalPoints(res.data.total_vendor_points);
//         setMerchantPointsDetails(res.data.vendor_loyalty_history);
//         setOfferAvailableCount(res.data.vendor_eligble_offers_count)
//         setOfferRedeemedCount(res.data.redeemed_items_count)
//         setMerchantPointsDetails(res.data.vendor_loyalty_history)
//         setRefreshing(false)
//         setPageLoaded(true)

//       }
//     })
//       .catch((err: any) => {
//         console.log(err.response.data);
//         console.log('Invalid merchant Id');
//       });
//   };
//   console.log("getReferralPoints", loyaltyType)

//   const getReferralPoints = async (UserId = userId) => {
//     console.log("getReferralPoints", loyaltyType)
//     apibaseURL.post('/my_referral_loyalty_points_detail', {
//       user_id: userId
//     }).then((res: any) => {
//       if (res.status == 200) {

//         setMerchantTotalPoints(res.data.total_referal_points);
//         setMerchantPointsDetails(res.data.referral_loyalty_history);
//         setOfferAvailableCount(res.data.referal_eligble_offer_count)
//         // setOfferRedeemedCount(res.data.redeemed_items_count)
//         setRefreshing(false)
//         setPageLoaded(true)

//       }
//     })
//       .catch((err: any) => {
//         if (err.response.status == 400) {
//           Toast.showWithGravity(
//             err.response.data.message,
//             Toast.LONG,
//             Toast.BOTTOM
//           );
//         }
//         if (err.response.status == 500) {
//           Toast.showWithGravity(
//             'something went wrong please try again',
//             Toast.LONG,
//             Toast.BOTTOM
//           );
//         }
//       });
//   };