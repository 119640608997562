import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Services/axios";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
const Redeemed = () => {
    const navigate = useNavigate()
    const [redeemlist, setRedeemlist] = useState([])
    useEffect(() => {
        getHistroy()
    }, []);
    const getHistroy = () => {
        try {
            axiosInstance.post('/user/get_referral_loyalty_offer_redeem_history')
                .then((res) => {
                    console.log('res array', res);
                    setRedeemlist(res.data)
                })
        }
        catch {

        }
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img
                        src={p_nav_previous}
                        width={"24px"}
                        height={"16px"}
                        alt="Previous Button"
                        role="button"
                        onClick={() => navigate(-1)}
                    />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Redeemed item
                    </div>
                </div>
            </div>
            <div className="scroller-style-y">
                {
                    redeemlist?.redeem_detail?.length > 0 ?
                        redeemlist.redeem_detail?.map((list) => {

                            return (

                                <div className="row gx-0 m-3 toast-style-1 rounded " onClick={() => navigate('/RedeemedDetails', { state: { purchaseid: list.purchase_id } })}>
                                    <div className="col-4">
                                        <div className="w-100">
                                            <img src={list.offer_detail.offer_images[0]}
                                                className="earlyBirdCardImage"
                                                alt="PLIcon"
                                            />
                                            {list.deleverystatus == '2' ?
                                                <button className="loyaltyStatusBtn fs-16 ff-poppins-regular" style={{ backgroundColor: '#f7b924' }}>
                                                    Shipped
                                                </button> :
                                                <button className="loyaltyStatusBtn fs-16 ff-poppins-regular" style={{ backgroundColor: '#16aaff' }} >
                                                    Processing
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-8 d-flex justify-content-center flex-column align-items-center">
                                        <div className="w-100 px-2">
                                            <p className="m-0 ff-poppins-bold fs-14 elipseLoyalty">
                                                {list.offer_detail.offer_name}
                                            </p>
                                            <p className="m-0 ff-poppins-regular secondary-text fs-12  singleline-text">
                                                {list.offer_detail.offfer_description}
                                            </p>
                                            <p className="m-0 ff-poppins-regular fs-12 elipseLoyalty"> Delivery on
                                                <span> {new Date(list.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}  </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            )
                        })
                        :
                        <Lottie animationData={empotyLottie} />
                }
            </div>
        </>
    )
}
export default Redeemed