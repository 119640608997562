import React, { useState } from "react";
import "../MembershipCard/Membership.css";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import pingleLogo from "../../Assets/Images/pingle.png";

function MembershipCard({
  buttonName,
  icon,
  threeDot,
  Status,
  data,
  cardColor,
  Membership_from_Page
}) {
  const { expiryDate, membershipDetails, VendorDetails } = data;
  const navigate = useNavigate();
  const [showContainer1, setShowContainer1] = useState(true);
  const [showContainer2, setShowContainer2] = useState(false);

  const handleMouseOver = () => {
    setShowContainer1(false);
    setShowContainer2(true);
  };

  const handleMouseLeave = () => {
    setShowContainer1(true);
    setShowContainer2(false);
  };


  let itemLable = "";
  if (membershipDetails?.card_status == 1) {
    itemLable = "Active";
  } else {
    itemLable = "Expired";
    // membershipDetails.card_status = 2;
  }

  return (
    <div>
      <div className="body mt-2 mb-1">
        <div className="container">
          {/* Container 1 */}
          {showContainer1 && (
            <div className="main-div">
              {
                Membership_from_Page == "search_page" ||  Membership_from_Page == "Membership_details" ? "" :
                  <div className={`myMembershipStatus ${membershipDetails?.card_status == 1 ? "membership_Active" : "membership_Expiry"}`}>{itemLable}</div>
              }

              <div className="box-container"
                style={{
                  ...(membershipDetails.card_pattern_status
                    ? { backgroundImage: `url(${membershipDetails.card_pattern_image_url})` }
                    : membershipDetails.card_color_status
                      ? { backgroundColor: membershipDetails.card_color }
                      : {}
                  )
                }}
                id="onMouseOver"
                onMouseOver={handleMouseOver}
              >
                <div className="conatiner-left">
                  <img className="image" src={membershipDetails.membership_image} width={"100px"} height={"212"} style={{ borderRadius: "15px 0px 0px 15px" }} />
                </div>
                <div className="conatiner-right">
                  <div className="content-top d-flex justify-content-between align-items-center">
                    <span className="store-name fs-14 ff-poppins-semiBold text-white">
                      {VendorDetails.vendor_company_name}
                    </span>
                    <span>{threeDot}</span>
                  </div>
                  <div className="content-bottom">
                    <span className="heading fs-14 ff-poppins-semiBold text-uppercase text-white">
                      {membershipDetails.membership_name}
                    </span>
                    <p className="content ff-poppins-regular fs-10 text-white">
                      {membershipDetails.membership_description}
                    </p>
                    <span className="price fs-14 ff-poppins-semiBold text-white">
                      {membershipDetails.price_lable}
                    </span>
                  </div>
                  <div className="button">
                    {expiryDate && (
                      <p className="End date text-white m-0 fs-10 ff-poppins-semiBold">
                        {new Date(expiryDate.substring(0, 10)).toDateString().substring(4, 20)}
                      </p>
                    )}
                  </div>
                  <div className="footer py-2">
                    <span className="bottom-left fs-12 ff-poppins-regular text-white"></span>
                    <button className="buyNow fs-12 ff-poppins-regular">
                      {buttonName} {icon && <FiArrowRight className="ms-1" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Container 2 */}
          {showContainer2 && (
            <div>
              {
                Membership_from_Page == "search_page" ||  Membership_from_Page == "Membership_details" ? "" :
                  <div className={`myMembershipStatus ${membershipDetails?.card_status == 1 ? "membership_Active" : "membership_Expiry"}`}>{itemLable}</div>
              }
              <div
                className="box-container"
                id="onMouseLeave"
                style={{
                  ...(membershipDetails.card_pattern_status
                    ? { backgroundImage: `url(${membershipDetails.card_pattern_image_url})` }
                    : membershipDetails.card_color_status
                      ? { backgroundColor: membershipDetails.card_color }
                      : {}
                  )
                }}
                onMouseLeave={handleMouseLeave}
              >
                <div className="conatiner-center">
                  <div className="content-top-center">
                    <div className="bLL"></div>
                    <div className="bL">
                      <span className="store-name fs-14 ff-poppins-semiBold text-white">
                        {VendorDetails.vendor_company_name}
                      </span>
                    </div>
                    <div className="bR">
                      <span className="text-white">{threeDot}</span>
                    </div>
                  </div>
                  <div className="left">
                    <span className="px-4 fs-14 ff-poppins-semiBold text-uppercase text-white text-left">
                      {membershipDetails.membership_name}
                    </span>
                  </div>
                  <div className="content-center">
                    <div>
                      <span className="content-right ff-poppins-bold fs-19 text-white">
                        {membershipDetails.membership_id && membershipDetails.membership_id.match(/.{1,4}/g).join(" ")}
                      </span>
                      <span className="content-bottom-right text-white">
                        Membership Card ID
                      </span>
                    </div>
                  </div>
                  <div className="bottom-container">
                    <div>
                      <span className="bottom-left fs-12 ff-poppins-regular text-white">
                        {membershipDetails.membership_type_lable} Membership
                      </span>
                    </div>
                    <div className="logo d-flex">
                      <img
                        className="bottom-right"
                        src={pingleLogo}
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MembershipCard;
