import { useRef, useState } from 'react';
import p_nav_previous from '../../../Assets/Images/p_nav_previous.svg';
import p_right_arrow from '../../../Assets/Images/p_right_arrow.svg';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMyProfileDetails, updateProfileDetails, uploadMyProfileImage } from '../../../Services/api';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageCropperModal from '../../../Components/modal/imageCropperModal';

const EditProfileUser = () => {

    const navigate = useNavigate();
    const [profile, setProfileData] = useState('');
    const fileRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [profilePhotoName, setProfilePhotoName] = useState('');
    const [isLoading, setLoadingStatus] = useState(false);
    const [formData, setFormData] = useState({
        "user_name": "",
        "user_email": "",
        "user_city": "",
        "user_address": "",
        "user_gender": "",
        "user_dob": "",
        "user_location": "",
        "user_about": "",
        "user_img": ""
    });
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    // console.log('formData top', formData);

    const abouttest = "Web developer specializing in modern, responsive designs. Skilled in React.js, UX/UI, and creating seamless digital expe"
    console.log('abouttest', abouttest.length);

    useEffect(() => {
        getMyProfileDetails().then((res) => {
            setProfileData(res.data?.user_details);
            const userImage = res.data?.user_details?.user_img;
            const userProfileData = res.data?.user_details;
            console.log('user image - ', userImage);
            console.log('user detail ->>>>> ', res.data);
            setProfilePhoto(userImage);
            setFormData(userProfileData);
        });
    }, []);

    useEffect(() => {
        if (formData.user_dob) {
            setSelectedDate(new Date(formData.user_dob));
        }
    }, [formData.user_dob]);

    console.log("isLoading", isLoading);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };


    const handleCalenderChange = (date) => {
        setSelectedDate(date);
        handleChange({ target: { name: 'user_dob', value: date } });
        // You can set the selected date to your form data here if needed
    };

    const handleInputFocus = (e) => {
        e.target.blur(); // Blur the input field to dismiss the keyboard
    };


    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const onImageCropped = (e) => {

        if (e) {
            let body = {
                "user_img": e
            }
            uploadMyProfileImage(body)
                .then((res) => {

                    if (res.data.status === "1") {
                        setProfilePhoto(res.data.results);
                        setProfilePhotoName(res.data.img_name);
                        setFormData(prevData => ({
                            ...prevData,
                            person_image: res.data.img_name
                        }));

                        setIsImageCropperModaOpen(false);
                        setLoadingStatus(false)
                    }
                    setLoadingStatus(false)
                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });

        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.user_name == '') {
            dispErrorMsg('User Name field required')
            return
        }
        else if (formData.user_gender == '') {
            dispErrorMsg('Gender field required')
            return
        }
        else if (formData.user_dob == '') {
            dispErrorMsg('DOB field required')
            return
        }
        else if (formData.user_city == '') {
            dispErrorMsg('City field required')
            return
        }
        else if (formData.user_about == '') {
            dispErrorMsg('About field required')
            return
        }
        console.log('formData====', formData);
        setLoadingStatus(true);
        updateProfileDetails(formData)
            .then((res) => {
                setLoadingStatus(false);
                console.log('formData res ====>>>>', res);
                if (res.data?.status === '1') {
                    toast(res.data?.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-dark toast-style-1'

                    }

                    );
                    navigate('/edit-profile');
                }
            })
            .catch((error) => {
                setLoadingStatus(false)
                toast(error.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            });
    }

    const dispErrorMsg = (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });
    }






    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1980 + 1 }, (_, i) => 1980 + i);

    const [month, setMonth] = useState("February");
    const [day, setDay] = useState(5);
    const [year, setYear] = useState(2025);

    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const yearRef = useRef(null);

    useEffect(() => {
        const scrollToValue = (ref, options, value) => {
            const index = options.indexOf(value);
            if (ref.current && index !== -1) {
                ref.current.scrollTo({
                    top: index * 50, 
                    behavior: "smooth",
                });
            }
        };

        scrollToValue(monthRef, months, month);
        scrollToValue(dayRef, days, day);
        scrollToValue(yearRef, years, year);
    }, [month, day, year]);

    const handleScroll = (e, options, setValue) => {
        const scrollTop = e.target.scrollTop;
        const index = Math.round(scrollTop / 50);
        if (options[index] !== undefined) {
            setValue(options[index]);
        }
    };

 
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Profile Edit</div>
                </div>
            </div>

            <div className='profile-edit-user scroller-style-y'>
                <div className='m-2 p-1'>
                    <div className='mx-3 px-2 my-3 py-2'>
                        <div className='ff-poppins-semiBold fs-20'>Change</div>
                        <div className='ff-poppins-regular fs-20'>Profile Photo</div>
                    </div>

                    <div className='photoUploadSection text-center'>
                        <img src={profilePhoto} width={'100px'} height={'100px'} alt='Default image' />
                        <div className='d-flex justify-content-center'>
                            <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                            <div className='addPhotoBtn d-flex justify-content-center align-items-center primary-bg text-white ff-poppins-regular fs-16 fw-600' role='button' onClick={() => { fileRef.current?.click() }}>Change Photo</div>
                        </div>
                    </div>

                    <form className='m-4 p-1' onSubmit={handleSubmit}>
                        {/* Person name  */}
                        <label className='ff-poppins-regular fs-12'>User Name</label>
                        <input type='text' className=' w-100 ff-poppins-regular fs-16 text-dark bg-transparent p-1' placeholder='Enter User Name' name="user_name" value={formData.user_name} onChange={handleChange} />

                        {/* Phone Number  */}
                        <label className='ff-poppins-regular fs-12'>Phone Number</label>
                        <input type='text' className=' w-100 ff-poppins-regular fs-16 text-dark bg-transparent p-1' placeholder='Enter Phone Number' name="phone" value={profile.user_mbl} disabled />

                        {/* Person Gender  */}
                        <label className='ff-poppins-regular fs-12'>Gender</label>
                        <select className="form-select ff-poppins-regular fs-16 bg-transparent p-1 rounded-0" aria-label="Default select example" name="user_gender" value={formData.user_gender} onChange={handleChange}>
                            <option selected disabled>Person Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>

                        {/* DOB  */}


                        <div className='dobdatepicker'>
                            <div className='ff-poppins-regular fs-12 mt-4 pt-2 dobdatepicker' >D.O.B <span className="text-danger">*</span></div>
                            <DatePicker
                                className="w-100 ff-poppins-medium fs-20"
                                selected={selectedDate}
                                onChange={handleCalenderChange}
                                dateFormat="dd-MM-yyyy"
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={100}
                                portalId="custom-calendar-portal"
                                onFocus={handleInputFocus}
                            />

                        </div>


                        {/* <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                                backgroundColor: "#f9f9f9",
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",

                            }}
                        > 
                            <div
                                ref={monthRef}
                                style={listStyles}
                                onScroll={(e) => handleScroll(e, months, setMonth)}
                            >
                                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                    {months.map((m) => (
                                        <li key={m} style={itemStyles(m === month)}>{m}</li>
                                    ))}
                                </ul>
                            </div>
 
                            <div
                                ref={dayRef}
                                style={listStyles}
                                onScroll={(e) => handleScroll(e, days, setDay)}
                            >
                                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                    {days.map((d) => (
                                        <li key={d} style={itemStyles(d === day)}>{d}</li>
                                    ))}
                                </ul>
                            </div>
 
                            <div
                                ref={yearRef}
                                style={listStyles}
                                onScroll={(e) => handleScroll(e, years, setYear)}
                            >
                                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                    {years.map((y) => (
                                        <li key={y} style={itemStyles(y === year)}>{y}</li>
                                    ))}
                                </ul>
                            </div>
                        </div> */}


                      {/*  <div className="date-picker-container mt-2">
                            <select onChange={(e) => handleScroll(setMonth, months, e)} value={month} className="date-picker-select form-select bg-transparent " >
                                {months.map((m) => (
                                    <option key={m} value={m}>
                                        {m}
                                    </option>
                                ))}
                            </select>
                            <select onChange={(e) => handleScroll(setDay, days, e)} value={day} className="date-picker-select day form-select"  >
                                {days.map((d) => (
                                    <option key={d} value={d}>
                                        {d}
                                    </option>
                                ))}
                            </select>
                            <select onChange={(e) => handleScroll(setYear, years, e)} value={year} className="date-picker-select form-select"     >
                                {years.map((y) => (
                                    <option key={y} value={y}>
                                        {y}
                                    </option>
                                ))}
                            </select>
                        </div> */}



                        {/* City  */}
                        <label className='ff-poppins-regular fs-12'>City</label>
                        <input type='text' className=' w-100 ff-poppins-regular fs-16 text-dark bg-transparent p-1' maxLength={20} placeholder='Enter City' name="user_city" value={formData.user_city} onChange={handleChange} />

                        {/* About  */}
                        <label className='ff-poppins-regular fs-12'>About</label>
                        <textarea
                            type='text'
                            className='w-100 ff-poppins-regular fs-16 text-dark bg-transparent m-0 p-1'
                            placeholder='Enter About'
                            name="user_about"
                            maxLength={120}
                            value={formData.user_about}
                            onChange={handleChange}
                            rows={5}
                            style={{
                                height: '100px',
                                overflow: 'hidden',
                                outline: 'none',
                                border: 'none', // Remove default border
                                borderBottom: '1px solid rgba(0, 0, 0, 0.50)'
                            }}
                        >
                        </textarea>
                        <div className='d-flex justify-content-end ff-poppins-regular mt-1'>{formData.user_about.length} / 120</div>
                        <div className='d-flex justify-content-center'>
                            <button className='submitBtn d-flex justify-content-center align-items-center primary-bg ff-poppins-regular fs-16 fw-600 text-white border-0'>Update</button>
                        </div>
                    </form>
                </div>
            </div>
            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                isLoading={isLoading}
                onSelect={onImageCropped}
                onCancel={() => setIsImageCropperModaOpen(false)}
            />
        </>
    )
}

export default EditProfileUser


 