import { useState } from 'react';
import p_nav_previous from '../Assets/Images/p_navbars.svg';
import { getChatList } from '../Services/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserSettings = () => {

    const navigate = useNavigate();

    function logOut() {
        localStorage.clear();
        navigate('/login'); 
        sessionStorage.clear();
    }

    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center  ">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Settings</div>
                </div>
            </div>

            <div className='vh-100'>
                <ul class="list-group">
                    <li class="list-group-item bg-transparent d-flex justify-content-between pt-3 pb-3">
                        <span className='ff-poppins-semiBold fs-16'>Language setting</span>
                        <span className='ff-poppins-regular fs-16'>English</span>
                    </li>
                </ul>

                <div role='button' onClick={openModal} className='logoutBtn d-flex align-items-center justify-content-center primary-bg text-white ff-poppins-semiBold fs-16 m-2'>
                    Log out
                </div>
            </div>
            {/* onClick={() => logOut()} */}

            {/* logout Modal  */}

            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm m-0">
                    <div class="modal-content mx-3">
                        <div class="modal-body ps-5 pe-5 pt-4 pb-4">
                            <div className='text-center ff-poppins-semiBold fs-16 mb-3'>Delete account</div>
                            <div className='text-center ff-poppins-regular fs-12'>Are you sure you want to logout your account?</div>

                            <div className='d-flex justify-content-between mt-3'>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger" onClick={() => logOut()} data-bs-dismiss="modal">logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Modal */}
            {modalOpen && (
                <div className="modal fade show successModal" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm m-0">
                        <div className="modal-content">
                            <div className="modal-body ps-5 pe-5 pt-4 pb-4"> 
                            <div className='text-center ff-poppins-regular fs-12'>Are you sure you want to logout your account?</div>

                                <div className="d-flex justify-content-between mt-3">
                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                                    <button type="button" className="btn btn-danger" onClick={() => logOut()}>Logout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Backdrop */}
            {modalOpen && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default UserSettings