import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import p_search_location from "../../Assets/Images/p_search_location.svg";
import p_store_dropdown_arrow from "../../Assets/Images/p_store_dropdown_arrow.svg";
import { FaHome } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import React, { useState, useEffect, useRef } from "react";
import { BiSolidOffer } from "react-icons/bi";
import { MdCardMembership } from "react-icons/md";
import "./searchStore.css";
import axios from "axios";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import {
  addRecentLocation,
  addSearchHistory,
  clearSearchHistory,
  getLocationTypes,
  getMyLocations,
  getSearchFilter,
  getSearchHistory,
  getSearchVendor,
} from "../../Services/api";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Services/axios";
import OfferSearch from "../../Components/offCanvas/offerSearch";

import membership_icon from '../../Assets/Images/icons/membership_card.png';
import offer_icon from '../../Assets/Images/icons/offer_icon.png';
import MembershipSearch from "../../Components/offCanvas/membershipSearch";
import { useGeolocated } from "react-geolocated";
import { MdCancel } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import Loading from "react-loading";
import Lottie from "lottie-react";
import getlocation from "../../Assets/lottieFiles/getlocation.json"
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

import Geocode from "react-geocode";
import { setKey, setRegion, fromLatLng } from "react-geocode";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";

const SearchStore = () => {

  setKey("AIzaSyC7cC8d67-AjIHnXizRFxTj1uFkOwnF2zo");


  const rescheduleModalRef = useRef(null);
  const membershipSearchModalRef = useRef(null)
  const offerSearchModalRef = useRef(null)
  const searchSelectedModel = useRef(null);
  const locationModalRef = useRef(null);
  const scrollRef = useRef(null);
  const categoryFillter = useRef(null)
  const [isAtTop, setIsAtTop] = useState(false);
  const locations = useLocation()
  const { lat, long } = locations.state || {};


  const [value, setValue] = useState("");
  const [showLocations, setShowLocations] = useState(false);
  const [vendorTypes, setVendorTypes] = useState([]);
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const [vendorFilteredList, setVendorFilteredList] = useState([]);
  const [recentselect, setRecentselect] = useState(false);

  const navigate = useNavigate();

  // Filters
  const [fil_location, set_fil_location] = useState();
  const [fil_name, set_fil_name] = useState("");
  const [fil_type, set_fil_type] = useState('-1');
  const [newfil_name, setNew_fil_name] = useState('');
  const [fil_category, set_fil_category] = useState(0);
  const [vendor_category_list, set_vendor_category_list] = useState([]);
  const [locationModel, setLocationModel] = useState('1')
  const valueParam = useParams();
  const merchantId = valueParam;
  const [filteredItems, setFilteredItems] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [inputSearchValue, setInputSearchValue] = useState('');
  const geocoder = new window.google.maps.Geocoder();
  const [address, setAddress] = useState('');
  const [city, setCity] = useState("");

  const [currentaddress, setCurrentAddress] = useState('');
  const [currentlatelong, setCurrentlatelong] = useState('')
  const [currentCity, setcurrentCity] = useState("");

  const [locationsList, setLocationsList] = useState([]);
  const [showAnimation, setShowAnimation] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState();
  const [locationAccess, setLocationAccess] = useState(false);
  const [fillterCategory, setFillterCategory] = useState()

  useEffect(() => {
    getSearchFilter().then((res) => {
      res.data.vendor_type.map((item) => {
        item.isSelected = false;
      });
      setVendorTypes(res.data.vendor_type);

      set_fil_location(res.data.selected_location);
    });
    getRecentSearchHistory();


  }, [value, locationModel]);



  useEffect(() => {
    if (fil_type != '-2') {
      getVendor();
    }
    if (fil_type == -1) {
      setShowLocations(false)
    }
  }, [fil_location, fil_name, fil_type, fil_category, locationModel]);

  function getRecentSearchHistory() {
    getSearchHistory().then((res) => {
      if (res.data.search_count > 0) {

        const newSearches = res.data.search_results.filter((item) =>
          item.search_name.toLowerCase().includes(fil_name.toLowerCase())
        );
        setSearchHistoryList(newSearches);
      }
    });
  }

  const open_Model = () => {
    const shouldOpenModal = sessionStorage.getItem('shouldOpenModal');
    if (!shouldOpenModal) {

      locationModalRef.current.classList.add("show");
      locationModalRef.current.style.display = "block";
      setShowModal(true)
      setTimeout(() => {
        if (locationModalRef.current) {
          locationModalRef.current.classList.remove("show");
          locationModalRef.current.style.display = "none";
        }
        sessionStorage.setItem('shouldOpenModal', 'true');
        setShowModal(false)
      }, 4000);
    }
  };


  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {

      },
      (error) => {

      }
    );
  };


  const [modalOpened, setModalOpened] = useState(false); // Track if modal was opened in this session

  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const result = await navigator.permissions.query({ name: "geolocation" });



        if (result.state === "granted") {
          setLocationAccess(true);
          getUserLocation();


          const shouldOpenModal = sessionStorage.getItem('shouldOpenModal');

          if (!shouldOpenModal) {

            open_Model();

            setModalOpened(true);
          }
        } else if (result.state === "prompt") {
          console.log("User has not yet granted or denied permission.");
        } else {
          console.log("Location permission denied.");
        }

        result.onchange = () => {
          if (result.state === "granted") {
            setLocationAccess(true);
            getUserLocation();

            const shouldOpenModal = sessionStorage.getItem('shouldOpenModal');


            if (!shouldOpenModal) {
              open_Model();
              setModalOpened(true);

            }
          }
        };
      } catch (error) {
        console.error("Error checking location permission:", error);
      }

      setTimeout(() => {
        setShowAnimation(false);
      }, 3000);
    };

    checkLocationPermission();
  }, []);



  // function searchVendorByName(e) {

  //   if (fil_type == -1) {
  //     set_fil_type(0);
  //   }
  //   set_fil_name(e.target.value);
  //   getRecentSearchHistory();
  // } 

  const handleInputSearchChange = (event) => {
    setInputSearchValue(event.target.value);
    //searchVendorByName(event)
  };

  function searchVendorByName(e) {
    setShowLocations(true)
    const searchText = e.target ? e.target.value : e;
    setInputSearchValue(searchText);

    if (fil_type == -1) {
      set_fil_type(0);
    }
    set_fil_name(searchText);

    if (
      searchText !== "" &&
      !searchHistoryList.some((item) => item.search_name === searchText)
    ) {
      const newSearchHistoryList = [...searchHistoryList];
      newSearchHistoryList.unshift({ id: Date.now(), search_name: searchText });

    }
    if (searchText == '') {
      setInputSearchValue('');
      set_fil_type(-1)
    }
    setPage(1)

  }



  function getVendor() {

    let param;

    if (recentselect == true) {
      param = {
        user_location: fil_location?.latlng,
        search_name: (newfil_name)?.trimEnd(),
        search_type: 0,
        search_category: fil_category,
      };
    } else {
      param = {
        user_location: fil_location?.latlng,
        search_name: (recentselect ? newfil_name : fil_name).trimEnd(),
        search_type: recentselect ? 0 : fil_type,
        search_category: fil_category,
        current_page: page,
        limit: 10,
      };
    }



    getSearchVendor(param).then((res) => {
      if (page == 1) {
        setVendorList(res.data.search_results);

      } else {
        setVendorList((prev) => [...prev, ...res.data.search_results]);
      }
      setTotalPage(res.data.total_pages)
      setShowLocations(true);
      setVendorFilteredList(res.data.search_results);

    });



    localStorage.removeItem('productAdded');
    localStorage.removeItem('serviceAdded');
  }
  useEffect(() => {

    if (page <= totalpage) {
      setPage((prevPage) => prevPage + 1)
      console.log('111111------', page, totalpage);

      getVendor();
    } else if (totalpage == page) {
      setIsAtTop(false)
    }
  }, [page, isAtTop]);



  const handleScroll = () => {
    if (!scrollRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollTop === 0) {
      setIsAtTop(false);
    } else if (scrollTop + clientHeight >= scrollHeight) {
      setIsAtTop(true);
      setTimeout(() => {
        handleLoadingData();
      }, 1300);

    } else {
      setIsAtTop(true);
      setTimeout(() => {
        handleLoadingData();
      }, 1300);

    }

  };
  const handleLoadingData = () => {
    if (isAtTop) {
      setIsAtTop(false);
    } else {
      setIsAtTop(true);
    }
  }

  function selectCategory(item) {
    vendorTypes.map((obj) => {
      obj.isSelected = false;
    });

    let obj = vendorTypes.find((x) => x.vendor_type_id == item.vendor_type_id);

    if (obj) {
      let index = vendorTypes.indexOf(obj);
      vendorTypes.fill((obj.isSelected = !obj.isSelected), index, index++);

      forceUpdate();
    }
  }

  const clearAllSearches = () => {

    clearSearchHistory().then((res) => {

      if (res.data?.status == '1') {
        setSearchHistoryList([]);
      }
    });
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    // const checkedValues = checkboxes.filter(checkbox => checkbox.checked);
    // console.log('Checked checkboxes:', checkedValues);
    // You can perform further actions here, such as sending data to a server
  };

  const handleCheckboxChange = (index) => {
    console.log('index---------------', index)
    set_vendor_category_list(prevCheckboxes => {
      const updatedCheckboxes = [...prevCheckboxes];
      updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
      filterVendorsByCategory(updatedCheckboxes);
      return updatedCheckboxes;
    });
  };

  const filterVendorsByCategory = (category) => {
    console.log('category-----', category);

    const filterTypeIds = category
      .filter(obj => obj.checked == true)
      .map(obj => obj.vendor_category_id);
    console.log("filterTypeIds : ", filterTypeIds);

    const filteredObjects = vendorFilteredList.filter(obj => filterTypeIds.includes(obj.vendor_cat_id));

    console.log("filteredObjects : ", filteredObjects);

    console.log("vendorFilteredList : ", vendorFilteredList);
    if (filteredObjects.length > 0) {
      setVendorList(filteredObjects);
    } else {
      setVendorList("");
    }
  }

  useEffect(() => {
    getMyLocations().then((res) => {

      setLocationsList(res.data?.saved_locations)
    });
  }, []);

  // useEffect(() => {
  //   if (vendorTypes.length > 0) {
  //     vendorTypes.map(x => {

  //       if (x?.vendor_categories?.length > 0) {
  //         x?.vendor_categories?.map(y => {
  //           y.checked = false;
  //         });

  //         set_vendor_category_list(x?.vendor_categories)
  //       }
  //     })

  //   }

  // }, [vendorTypes]);

  function openVendorProfile(vendorId) {
    let param = {
      "search_vendor_id": vendorId
    }
    addSearchHistory(param).then((res) => {

      if (res.data?.status == '1') {
        navigate(`/vendor/${vendorId}`);
      }
    });
  }

  const openOfferSearchModal = () => {
    offerSearchModalRef.current.classList.add("show");
    offerSearchModalRef.current.style.display = "block";
    setShowModal(true)
  };

  const closeOfferSearchModal = () => {
    offerSearchModalRef.current.classList.remove("show");
    setShowModal(false)
  };

  const openMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.add("show");
    membershipSearchModalRef.current.style.display = "block";
    setShowModal(true)
  };

  const closeMembershipSearchModal = () => {
    membershipSearchModalRef.current.classList.remove("show");
    setShowModal(false)
  };









  // useEffect(() => {
  //   if (locationModel == "1") {
  //     openrescheduleModal()
  //   }
  // }, [locationModel, address])


  window.addEventListener('load', () => {
    const shouldOpenModal = sessionStorage.getItem('shouldOpenModal');
    if (shouldOpenModal === 'false') {
      open_Model();
    }
  });


  function tab_scroll_x(direction) {
    const scrollContainer = document.querySelector('.scroller-style-x');
    const scrollAmount = 100;

    if (direction === 'left') {
      scrollContainer.scrollLeft -= scrollAmount;
    } else if (direction === 'right') {
      scrollContainer.scrollLeft += scrollAmount;
    }
  }



  function openCategoryFillter() {

    let modal = document.getElementById("offcanvasBottom");
    if (modal) {
      modal.classList.add("show");
      setShowModal(true)
    } else {
      console.warn("Modal element not found!");
    }

  }


  function closeCategoryFillter() {
    var modal = document.getElementById("offcanvasBottom");

    if (modal) {
      modal.classList.remove("show");
      setShowModal(false)
    } else {
      console.warn("Modal element not found!");
    }
  }

  const [currentLocation, setCurrentLocation] = useState(null);
  const [displayLocation, setDisplayLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentlatLong, setCurrentlatLong] = useState(null)

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const currentLoc = {
              lat: latitude,
              lng: longitude,
              accuracy: position.coords.accuracy
            };
            setCurrentlatLong(currentLoc);

            const addressData = await getAddressFromCoords(latitude, longitude);
            const matches = findMatchingLocations(currentLoc, locationsList, 100);

            if (matches.length > 0) {
              setDisplayLocation({
                ...matches[0],
                type: 'matched',
                distance: matches[0].distance
              });
              setCity(matches[0].area)
            } else {
              setDisplayLocation({
                lat: latitude,
                lng: longitude,
                address: addressData.display_name,
                location_type_id: "3",
                type: 'current',
                location_type: "current location",
                accuracy: position.coords.accuracy
              });
              setCity(addressData.address.suburb)
              setAddress(addressData.display_name)
            }
            setIsLoading(false);
          },
          (err) => {
            setError(err.message);
            setDisplayLocation({
              ...locationsList[0],
              type: 'fallback',
              reason: 'geolocation_error'
            });
            setIsLoading(false);
          },
          { enableHighAccuracy: true, timeout: 10000 }
        );
      } else {
        setError("Geolocation not supported");
        setDisplayLocation({
          ...locationsList[0],
          type: 'fallback',
          reason: 'geolocation_unsupported'
        });
        setIsLoading(false);
      }
    };

    getLocation();
  }, [locationsList]);



  const parseLatLng = (latlngString) => {
    const [lat, lng] = latlngString.split(',').map(Number);
    return { lat, lng };
  };

  const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371e3; // Earth radius in meters
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lng2 - lng1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const findMatchingLocations = (currentLoc, locations, threshold) => {
    return locations
      .map(loc => {
        const savedLoc = parseLatLng(loc.latlng);

        const distance = calculateDistance(
          currentLoc.lat, currentLoc.lng,
          savedLoc.lat, savedLoc.lng
        );
        return { ...loc, distance };
      })
      .filter(loc => loc.distance <= threshold)
      .sort((a, b) => a.distance - b.distance);
  };

  const getAddressFromCoords = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const data = await response.json();
      setCurrentlatLong({ lat: lat, long: lng })
      setCurrentLocation(data)

      return data || "Address not available";
    } catch (err) {
      console.error("Reverse geocoding error:", err);
      return "Could not retrieve address";
    }
  };


  return (
    <>
      <div>
        <div className="searchStore offcanvas-parentType2">

          <div className="d-flex justify-content-between align-items-center p-3">
            <div className="d-flex justify-content-between align-items-center ms-2 me-2">
              <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate('/')} />
              <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Search</div>
            </div>
            <Link className="text-decoration-none" to={`/find-location`}>
              <div className="location-label d-flex align-items-center justify-content-center bg-white">
                <img src={p_search_location} width={"14px"} height={"14px"} alt="Location" />
                <div className="ff-poppins-bold fs-13 ms-1  text-truncate secondary-text-2">
                  {city}
                </div>
              </div>
            </Link>
          </div>

          <div className=" p-3">
            <input type="search" className="w-100 ff-poppins-semiBold fs-16" placeholder="Search store by name" onChange={searchVendorByName} value={inputSearchValue} />

            <div className="d-flex justify-content-around align-items-center p-3">
              <div className=" align-items-center text-center" onClick={() => { openOfferSearchModal() }}>
                <img src={offer_icon} width={"40px"} height={"40px"} />
                <div className="ff-balooPaaji2-bold fs-16 mt-1">OFFER</div>
              </div>
              <div className=" align-items-center text-center" onClick={() => { openMembershipSearchModal() }}>
                <img src={membership_icon} width={"40px"} height={"40px"} />
                <div className="ff-balooPaaji2-bold fs-16 mt-1">MEMBERSHIP</div>
              </div>
            </div>

            <div className="arrow-controls d-flex justify-content-end  ">
              <FaArrowLeftLong
                className="primary-text me-2   arrow-icon"
                onClick={() => tab_scroll_x("left")}
              />
              <FaArrowRightLong
                className="primary-text me-2  arrow-icon"
                onClick={() => tab_scroll_x("right")}
              />
            </div>

            <div className="searchFilter scroller-style-x mt-4 mb-4">

              <div
                className={`d-flex align-items-center ${fil_type == '-1' ? "active" : ""}`}
                role="button"
                key={'-1'}
                onClick={() => {
                  set_fil_type('-1');
                  setShowLocations(false);
                  setInputSearchValue('')
                  setRecentselect(false)
                  set_fil_name('')
                }}
              >
                Recent
              </div>
              {vendorTypes.map((item) => {
                const isActive = fil_type == item.vendor_type_id;
                if (item.vendor_categories.length > 1) {
                  return (
                    <div
                      key={item.vendor_type_id}
                      className={`d-flex align-items-center ${isActive ? "active" : ""}`}
                      role="button"

                      onClick={() => {
                        set_fil_type(item.vendor_type_id);
                        if (fil_type !== item.vendor_type_id) {
                          set_vendor_category_list(item.vendor_categories);
                        }
                        setFillterCategory(item.vendor_type_name)
                        selectCategory(item);
                        setRecentselect(false)
                        setNew_fil_name('');
                        set_fil_name('');
                        setInputSearchValue('');
                        setPage(1)
                        openCategoryFillter()
                      }}
                    >
                      {item.vendor_type_name}
                      {item.vendor_categories.length > 1 && (
                        <img src={p_store_dropdown_arrow} width={"10px"} height={"10px"} alt="Location" className="ms-2" />
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={item.vendor_type_id}
                      className={`d-flex align-items-center ${isActive ? "active" : ""}`}
                      role="button"
                      onClick={() => {
                        set_fil_type(item.vendor_type_id);
                        selectCategory(item);
                        setRecentselect(false)
                        setNew_fil_name('');
                        set_fil_name('');
                        setInputSearchValue('');
                        setPage(1)
                      }}
                    >
                      {item.vendor_type_name}
                    </div>
                  );
                }
              })}
            </div>









            {showLocations && fil_type != '-1' ? (
              <div className="recentSearch">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="ff-poppins-semiBold fs-16">Location</div>
                    <div className="ff-poppins-thin fs-18 secondary-text-2">
                      result
                    </div>
                  </div>
                  {/* <button>
                  <div className="ff-poppins-light fs-11" onClick={() => setShowLocations(false)}  >
                    Recent Search
                  </div>
                </button> */}
                </div>

                <div className="storeList scroller-style-y" onScroll={handleScroll} ref={scrollRef}>
                  {vendorList.length > 0 ? (
                    vendorList.map((item, index) => (
                      <div key={`${item.vendor_id}-${index}`} className="storeCard bg-white d-flex mb-3" role="button" onClick={() => openVendorProfile(item.vendor_id)}>
                        <img src={item.vendor_logo} width={"81px"} height={"81px"} className="userImg" alt="Vendor Logo" />
                        <div>
                          <div className="ff-balooPaaji2-medium fs-17">{item.vendor_company_name}</div>
                          <div className="d-flex">
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              MID {item.vendor_id}
                            </div>
                            <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                              <div className="bullets me-2"></div>
                              {item.vendor_cat_name}
                            </div>
                          </div>
                          <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                            <div className="bullets me-2"></div>
                            {item.vendor_address}
                          </div>
                          <div className="ff-poppins-regular fs-9">{item.distance} -</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="ff-poppins-light fs-13">No Data Found</div>
                    </div>
                  )}

                  {vendorList.length > 9 && !totalpage == page && (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status"  >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            ) : (
              <div className="recentSearch">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="ff-poppins-semiBold fs-16">Recent</div>
                    <div className="ff-poppins-thin fs-18 secondary-text-2">
                      searches
                    </div>
                  </div>
                  <button onClick={clearAllSearches}>
                    <div className="ff-poppins-light fs-11">Clear all</div>
                  </button>
                </div>

                {
                  // recentselect == false ?
                  searchHistoryList?.length > 0 ? (
                    <div className="list scroller-style-y">
                      <ul className="ps-0 mb-0">
                        {searchHistoryList?.map((item) => {

                          return (
                            <li role="button" className="ff-poppins-regular fs-15 p-3 border-bottom" onClick={() => {
                              setInputSearchValue(item.search_name); searchVendorByName(item.search_name); setShowLocations(true); set_fil_type('-3'); setNew_fil_name(item.search_name); setRecentselect(true); getVendor()
                            }}>
                              {item.search_name}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) :
                    <div className="list scroller-style-y d-flex justify-content-center align-items-center">
                      <div className="ff-poppins-light fs-13">No Recent Result</div>
                    </div>
                  //   :
                  //   recentselect == true && vendorList.length > 0 ? (
                  //     vendorList.map((item) => {
                  //       return (
                  //         <div className="storeCard bg-white d-flex mb-3" role="button" onClick={() => openVendorProfile(item.vendor_id)}>
                  //           <img src={item.vendor_logo} width={"81px"} height={"81px"} className="userImg" />
                  //           <div>
                  //             <div className="ff-balooPaaji2-medium fs-17">
                  //               {item.vendor_company_name}
                  //             </div>
                  //             <div className="d-flex">
                  //               <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                  //                 <div className="bullets me-2"></div>
                  //                 MID {item.vendor_id}
                  //               </div>
                  //               <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                  //                 <div className="bullets me-2"></div>
                  //                 {item.vendor_cat_name}
                  //               </div>
                  //             </div>
                  //             <div className="ff-poppins-regular fs-9 d-flex align-items-center">
                  //               <div className="bullets me-2"></div>
                  //               {item.vendor_address}
                  //             </div>
                  //             <div className="ff-poppins-regular fs-9">
                  //               {item.distance} -
                  //             </div>
                  //           </div>
                  //         </div>
                  //       );
                  //     })
                  //   ) : 

                  //   <div className="d-flex justify-content-center align-items-center">
                  //   <div className="ff-poppins-light fs-13">No Data Found in recent</div>
                  // </div>

                }


              </div>
            )}
            <div className="offcanvas offcanvas-bottom" id="offcanvasBottom" tabIndex="-1" aria-labelledby="offcanvasBottomLabel"
              style={{ height: "90vh", maxHeight: "80vh", width: "100%", bottom: "0", marginLeft: -15, borderTopLeftRadius: "15px", borderTopRightRadius: "15px", overflow: "hidden", zIndex: "1045", display: "flex", flexDirection: "column" }} >
              <div className="offcanvas-header border-bottom ">
                <h5 className="offcanvas-title fs-16 ff-poppins-semiBold   " id="offcanvasBottomLabel">
                  {fillterCategory}
                </h5>
                <button type="button" className="btn-close text-reset"
                  onClick={() => closeCategoryFillter()}
                ></button>
              </div>

              {/* Content inside the modal (Scrollable) */}
              <div className="offcanvas-body small scroller-style-y" style={{ flex: "1", overflowY: "auto" }}    >


                <form onSubmit={handleSubmit}>
                  {vendor_category_list.map((checkbox, index) => (
                    <div className="d-flex justify-content-between mt-1 mb-1" key={checkbox?.vendor_category_id} role="button"   >
                      <label className="ff-poppins-medium mt-1 mb-1 fs-14" role="button" htmlFor={`checkbox-${checkbox?.vendor_category_id}`}  >
                        {checkbox?.vendor_category_name}
                      </label>
                      {checkbox?.checked ? (
                        <MdCheckBox className="primary-text " size={20} onClick={() => handleCheckboxChange(index)} />
                      ) : (
                        <MdCheckBoxOutlineBlank className="primary-color" size={20} onClick={() => handleCheckboxChange(index)} />
                      )}

                    </div>
                  ))}
                </form>

              </div>

              <div className="container-fluid cat-custom-footer"  >
                <div className="row">
                  <div className="col-6">
                    <div role="button" data-bs-dismiss="offcanvas"
                      onClick={() => {
                        vendor_category_list?.map((y) => {
                          y.checked = false;
                        });
                        set_vendor_category_list([...vendor_category_list]); // Ensure state updates correctly
                        setVendorList(vendorFilteredList);
                      }}
                      className="ff-poppins-semiBold fs-14 text-danger d-flex justify-content-center align-items-center btn-type-1"
                    >
                      Clear All
                    </div>
                  </div>
                  <div className="col-6">
                    <div role="button"
                      onClick={vendor_category_list.some(item => item.checked) ? closeCategoryFillter : null}
                      className={`ff-poppins-medium fs-16 d-flex justify-content-center align-items-center btn-type-1 
                     ${vendor_category_list.some(item => item.checked) ? "primary-bg text-white" : "secondaryslot-bg text-muted"}`}>
                      Apply
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas offcanvas-bottom"
            style={{
              width: "-webkit-fill-available",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              height: '95%',
              bottom: 0,
              marginBottom: 0,
              backgroundColor: '#fff'
            }}
            ref={offerSearchModalRef}
            tabIndex="-1">
            <div className="offcanvas-header  d-flex justify-content-end">
              {/* <h5 className="ff-balooPaaji2-bold fs-20 ms-2 ps-1" id="offcanvasBottomLabel">
                Search Offer
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                onClick={() => { closeOfferSearchModal() }}
              ></button> */}

              <div className=" fs-14 ff-poppins-regular" onClick={() => { closeOfferSearchModal() }}>cloes</div>

            </div>
            <div className="offcanvas-body small ">
              <OfferSearch></OfferSearch>
            </div>
          </div>

          {/* <div class="modal fade " id="exampleModalToggle" tabindex="-1" ref={rescheduleModalRef}>
            <div class="modal-dialog modal-dialog-centered mainModal searchScreen" >
              <div class="modal-content mx-5   bg-white">
                <div className=" p-3 m-0">
                  <div className="d-flex justify-content-between">
                    <p className=" ff-poppins-bold text-dark m-0">Current Location:</p>
                    <p onClick={() => closerescheduleModal()}> <MdCancel /> </p>
                  </div>
                  {
                    address == "" ?
                      <p> Please Give the Location Permission</p>
                      :
                      <p className="secondary-text  ">
                        {address && <p className="d-flex justify-content-center ff-poppins-regular m-0 fs-13"> {address}</p>}
                      </p>
                  }

                </div>
                <div className="d-flex justify-content-center mb-3 fs-12 text-center ff-poppins-bold">
                  <div class="primary-bg  col-5 selectLocationBtn rounded-3  text-light p-1" onClick={() => handelLocation()} >Confirm Location</div>
                  <div class="primary-bg  col-5 ms-3 selectLocationBtn rounded-3 text-light p-1" onClick={() => navigate("/find-location", { state: { fromPage: "SelectAddres" } })}  >Enter Location Manually </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="offcanvas offcanvas-bottom"
            style={{
              width: "-webkit-fill-available",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              height: '95%',
              bottom: 0,
              marginBottom: 0,
              backgroundColor: '#fff'
            }}
            ref={membershipSearchModalRef}
            tabIndex="-1">
            <div className="offcanvas-header d-flex justify-content-end">
              {/* <h5 className="ff-balooPaaji2-bold fs-20 ms-2 ps-1`" id="offcanvasBottomLabel">
                Search Membership
              </h5> */}
              {/* <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close"  ></button> */}

              <div className=" fs-14 ff-poppins-regular" onClick={() => { closeMembershipSearchModal() }}>cloes</div>
            </div>
            <div className="offcanvas-body small  mb-5">
              <MembershipSearch></MembershipSearch>
            </div>
          </div>





          <div className="offcanvas" tabIndex="-1" style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20, width: 390, zIndex: 9992, minHeight: "30vh" }} ref={locationModalRef} >
            {showAnimation ? (
              <div
                className="getLocation d-flex justify-content-center align-items-center"
                style={{ height: "100px" }}
              >
                <Lottie
                  animationData={getlocation}
                  style={{ width: "250px", height: "200px", margin: "auto" }}
                />
              </div>
            ) : (
              <div className="refundContentModal mb-2  refundModal">
                <div className="text-center d-flex justify-content-center mt-3 align-items-center">
                  <span className="primary-text fs-24">
                    {displayLocation?.location_type_id === "1" && <FaHome />}
                    {displayLocation?.location_type_id === "2" && <MdWork />}
                    {displayLocation?.location_type_id === "3" && <FaLocationDot />}
                  </span>
                  <p className="m-0 ff-poppins-bold fs-18 mt-2 ms-1">
                    {displayLocation?.location_type}
                  </p>
                </div>
                <p className="text-dark text-center mt-3">
                  <p className="d-flex justify-content-center ff-poppins-regular m-0 fs-13">
                    {displayLocation?.address}
                  </p>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}


    </>
  );
};

export default SearchStore;


