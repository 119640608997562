import React, { useEffect, useState } from "react";
import "../../Assets/Styles/offCanvas.css";
import {
  cartSelectPetOrPerson,
  getVendorProfile,
  getVendorProfileWithoutLogin,
  userFamilyList,
  userPetsList,
  userVehiclesList,
} from "../../Services/api";
import p_checked_radio_button from "../../Assets/Images/p_checked_radio_button.svg";
import p_unchecked_radio_button from "../../Assets/Images/p_unchecked_radio_button.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
const BookForOffCanvas = ({
  isOpen,
  cartAppId,
  vendorId,
  vendorType,
  toggleOffCanvas,
  sendDataToDoorstep,
  AddPage
}) => {
  const navigate = useNavigate();

  const [membersList, setMembersList] = useState([]);
  const [petsList, setPetsList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [vendorProfile, setVendorProfile] = useState([]);
  const [vendorid, setVendorid] = useState('')

  const [selectedBookForItem, setSelectedBookForItem] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  let selectedServiceOption = localStorage.getItem("selectedServiceOption");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (isOpen) {
      if (vendorType == 1) {
        getFamilyMembers();
      } else if (vendorType == 2) {
        getPetsDetail();
      } else if (vendorType == 3) {

        getVehicles();
      }
    }
  }, [isOpen]);
  useEffect(() => {
    let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
    setVendorid(localServiceDetail.vendorId);

  }, [])

  useEffect(() => {
    if (token) {
      getVendorProfile({ vendor_id: vendorId }).then((res) => {
        setVendorProfile(res.data);
      });
    }
    else {
      getVendorProfileWithoutLogin({ vendor_id: vendorId }).then((res) => {
        setVendorProfile(res.data);
      });
    }
  }, [])

  function getFamilyMembers() {
    let localServiceDetail = localStorage.getItem("serviceDetail")
      ? JSON.parse(localStorage.getItem("serviceDetail"))
      : "";

    let param = {
      vendor_id: vendorId,
      cart_app_id: cartAppId,
      service_type: localServiceDetail?.serviceType
        ? localServiceDetail?.serviceType
        : "",
    };
    userFamilyList(param).then((res) => {

      res.data?.user_family_member.map((item) => {
        item.isSelected = false;
      });
      setMembersList(res.data?.user_family_member);
    });
  }

  function getPetsDetail() {
    let localServiceDetail = localStorage.getItem("serviceDetail")
      ? JSON.parse(localStorage.getItem("serviceDetail"))
      : "";

    let param = {
      vendor_id: vendorId,
      cart_app_id: cartAppId,
      service_type: localServiceDetail?.serviceType
        ? localServiceDetail?.serviceType
        : "",
    };
    userPetsList(param).then((res) => {
      res.data?.pets_detail.map((item) => {
        item.isSelected = false;
      });
      setPetsList(res.data?.pets_detail);

    });
  }

  function getVehicles() {
    let localServiceDetail = localStorage.getItem("serviceDetail")
      ? JSON.parse(localStorage.getItem("serviceDetail"))
      : "";

    let param = {
      vendor_id: vendorId,
      cart_app_id: cartAppId,
      service_type: localServiceDetail?.serviceType
        ? localServiceDetail?.serviceType
        : "",
    };

    userVehiclesList(param).then((res) => {
      res.data?.user_vehicles.map((item) => {
        item.isSelected = false;
      });

      setVehicleList(res.data?.user_vehicles);
    });
  }

  function selectBookForItem(item) {
    if (vendorType == 1) {
      let obj = membersList.find((x) => x.id == item.id);

      if (obj) {
        membersList.forEach((obj) => {
          obj.isSelected = false;
        });

        let index = membersList.indexOf(obj);
        membersList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    } else if (vendorType == 2) {

      let obj = petsList.find((x) => x.pet_id == item.pet_id);

      if (obj) {
        petsList.forEach((obj) => {
          obj.isSelected = false;
        });

        let index = petsList.indexOf(obj);
        petsList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    } else if (vendorType == 3) {
      let obj = vehicleList.find((x) => x.id == item.id);

      if (obj) {
        vehicleList.forEach((obj) => {
          obj.isSelected = false;
        });

        let index = vehicleList.indexOf(obj);
        vehicleList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    }
  } 

  //   const showToast = (message) => {
  //     console.log(message); 
  //   };
  const sendDataToParentHandler = () => {
    console.log('selectedBookForItem', selectedBookForItem);
    sendDataToDoorstep(selectedBookForItem);
    if (selectedServiceOption == "staff") {
      let localServiceDetail = localStorage.getItem('serviceDetail')
        ? JSON.parse(localStorage.getItem('serviceDetail'))
        : '';
      console.log("localServiceDetail", localServiceDetail.vendor_type);

      let selectedItemId = (localServiceDetail.vendor_type == 1 || localServiceDetail.vendor_type == 3)
        ? selectedBookForItem.id
        : selectedBookForItem.pet_id


      let param = {
        "vendor_id": vendorid,
        "cart_app_id": localServiceDetail?.cart_app_id ? localServiceDetail?.cart_app_id : "",
        "service_type": localServiceDetail?.serviceType ? localServiceDetail?.serviceType : '',
        "booking_for": selectedItemId
      };
      console.log("param", param);

      cartSelectPetOrPerson(param).then((res) => {
        console.log("select staff ", res.data);

        if (res.data.status == '1') {
          localServiceDetail.bookForStatus = true;
          console.log("select staff res.data.status", res.data);

          localStorage.setItem("serviceDetail", JSON.stringify(localServiceDetail));
          navigate(`/door-step-service/${vendorid}`)
        }
      });
    }


    // if (selectedServiceOption === "staff") {
    //   navigate(`/door-step-service/${vendorid}`);
    // }
  };


  // const toggleOffCanvas = () => {
  //     setOpen(!isOpen); // assuming setOpen is a function to set isOpen state
  // };

  return (
    <div
      className={`offcanvas offcanvas-bottom selectCustomerModal ${isOpen ? "show" : ""
        }`}
      tabindex="-1"
      aria-labelledby="selectCustomerModalLabel"
      style={{ position: "sticky" }}
    >
      <div className="offcanvas-body small">
        <div className="d-flex justify-content-between">
          <div className="ff-poppins-bold fs-20 mt-2">This is For</div>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={toggleOffCanvas}
          ></button>
        </div>
        <div className="customerList scroller-style-y">
          {
            // Family members list
            vendorType == 1 ? membersList.length > 0 ? (
              membersList.map((item) => {
                return (
                  <div
                    className={
                      (item.select_status == 0 ? "opacity-40" : "") + " item"
                    }
                    onClick={() => {
                      if (item.select_status == 1) {
                        selectBookForItem(item);
                      } else if (item.select_status == 0) {

                        toast.error('Selected service is incompatible with this person.so please choose service based on gender', {
                          position: toast.POSITION.BOTTOM_CENTER
                        });

                      }
                    }}
                    role="button"
                  >
                    <div className="d-flex">
                      <img className="profileImg" src={item.image} />

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <div className="ff-poppins-bold fs-18">
                            {item.name}
                          </div>
                          <div className="ff-poppins-regular fs-14 text-secondary">
                            {item.book_for}
                          </div>
                        </div>

                        {item.isSelected ? (
                          <img
                            src={p_checked_radio_button}
                            width={"22px"}
                            height={"22px"}
                            alt="radio Button"
                            role="button"
                          />
                        ) : (
                          <img
                            src={p_unchecked_radio_button}
                            width={"22px"}
                            height={"22px"}
                            alt="radio Button"
                            role="button"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                <div className="ff-poppins-regular fs-14">
                  No Family Member Available
                </div>
              </div>
            ) : <></>
          }
          {
            // Pet List
            vendorType == 2 ? petsList.length > 0 ? (
              petsList.map((item) => {
                return (
                  <div
                    className={
                      (item.select_status == 0 ? "opacity-40" : "") +
                      " item"
                    }
                    onClick={() => {
                      if (item.select_status == 1) {
                        selectBookForItem(item);
                      } else if (item.select_status == 0) {

                        toast.error('Selected service is incompatible with this pet.so please choose service based on pet', {
                          position: toast.POSITION.BOTTOM_CENTER
                        });

                      }
                    }}
                    role="button"
                  >
                    <div className="d-flex">
                      <img className="profileImg" src={item.pet_image} />

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <div className="ff-poppins-semiBold fs-12">
                            {item.name}
                          </div>
                          <div className="ff-poppins-regular fs-10 text-secondary">
                            {item.type}
                          </div>
                        </div>

                        {item.isSelected ? (
                          <img
                            src={p_checked_radio_button}
                            width={"15px"}
                            height={"15px"}
                            alt="radio Button"
                            role="button"
                          />
                        ) : (
                          <img
                            src={p_unchecked_radio_button}
                            width={"15px"}
                            height={"15px"}
                            alt="radio Button"
                            role="button"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                <div className="ff-poppins-regular fs-14">
                  No Pet Available
                </div>
              </div>
            ) : <></>}
          {
            // Vehicle List

            vendorType == 3 ? vehicleList.length > 0 ? (
              vehicleList.map((item) => {

                return (
                  <div
                    className={
                      (item.select_status == 0 ? "opacity-40 " : "") +
                      " item"
                    }
                    onClick={() => {
                      if (item.select_status == 1) {
                        selectBookForItem(item);
                      } else if (item.select_status == 0) {

                        toast.error('Selected service is incompatible with this vehicle.so please choose service based on vehicle', {
                          position: toast.POSITION.BOTTOM_CENTER
                        });

                      }
                    }}
                    role="button"
                  >
                    <div className="d-flex">
                      <img className="profileImg" src={item.image} />

                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <div className="ff-poppins-semiBold fs-12">
                            {item.brand}
                          </div>
                          <div className="ff-poppins-regular fs-10 text-secondary">
                            {item.category_name}
                          </div>
                        </div>

                        {item.isSelected ? (
                          <img
                            src={p_checked_radio_button}
                            width={"15px"}
                            height={"15px"}
                            alt="radio Button"
                            role="button"
                          />
                        ) : (
                          <img
                            src={p_unchecked_radio_button}
                            width={"15px"}
                            height={"15px"}
                            alt="radio Button"
                            role="button"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                <div className="ff-poppins-regular fs-14">
                  No Vehicle Available
                </div>
              </div>
            ) : <></>
          }
        </div>

        <div>
          <button
            type="button"
            onClick={() => {
              if (vendorType == 1) {
                navigate("/add-family-member", { state: { frompage: "ServiceBookingFlow", AddPage: "family", vendorId: vendorid } });
              } else if (vendorType == 2) {
                navigate("/pet-register", { state: { frompage: "ServiceBookingFlow", AddPage: "pet", vendorId: vendorid } });
              } else if (vendorType == 3) {
                navigate("/vehicle-register", { state: { frompage: "ServiceBookingFlow", AddPage: "vehicle", vendorId: vendorid } });
              }
            }}
            className="btn-type-4 ff-poppins-bold fs-18 w-100 bg-white border mb-2"
          >
            Add New
            {vendorType == 1 && <span> Members</span>}
            {vendorType == 2 && <span> Pet</span>}
            {vendorType == 3 && <span> Vehicle</span>}
          </button>

          <button
            type="button"
            className={
              (selectedBookForItem == null
                ? "bg-secondary opacity-40"
                : "primary-bg opacity-100") +
              " " +
              "btn-type-4 ff-poppins-bold fs-18 w-100 border-0 text-white mt-1"
            }
            onClick={sendDataToParentHandler}
          >
            {vendorProfile?.vendor_profile?.vendor_details?.staff_app_status == '1' ? selectedServiceOption == "staff"
              ? "Select Service"
              : "Select Staff" : "Select Timeslot"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookForOffCanvas;
