import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import "../../Components/PreOrderTicketCard/TicketCard.css";
import { IoIosArrowDown, IoIosArrowDropleftCircle, IoIosArrowDroprightCircle, IoIosArrowUp } from "react-icons/io";
import { toast } from 'react-toastify';
const generateWeeks = (selectedDate) => {
    return Array.from({ length: 6 }).map((_, index) => {
        const start = moment(selectedDate)
            .startOf('month')
            .add(index, 'week')
            .startOf('week');
        return Array.from({ length: 7 }).map((_, dayIndex) => {
            const date = moment(start).add(dayIndex, 'day');
            return {
                weekday: date.format('ddd'),
                date: date.toDate(),
            };
        });
    });
};
const TicketCalender = ({ handleFilteredTicketDate }) => {
    const [AllONOFF, setAllONOFF] = useState(false);
    const [weekMonthONOFF, setweekMonthONOFF] = useState(false);
    const [value, setValue] = useState(new Date());
    const [disable, setDisable] = useState(false);

    const [selectedDate, setSelectedDate] = useState('');
    const [weeks, setWeeks] = useState(generateWeeks(value));
    const scrollViewRef = useRef(null);
    const [showVerticalCalendar, setShowVerticalCalendar] = useState(false);
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [currentMonth, setCurrentMonth] = useState(moment().month());
    const [weekDisabled, setWeekDisabled] = useState(false)
    const [weekOrMonth, setWeekOrMonth] = useState('Week')
    const [selectStatus, setSelectStatus] = useState(true)


    useEffect(() => {
        setWeeks(generateWeeks(value));
    }, [value]);


    useEffect(() => {
        if (value && typeof handleFilteredTicketDate === 'function') {
            const momentDate = moment(value);
            const formattedDate = momentDate.format("DD-MM-YYYY");

            const today = moment().format("DD-MM-YYYY");
            const newDates = selectStatus == true ? moment(value).format("DD-MM-YYYY") : "";

            const day = momentDate.date();
            const month = momentDate.month() + 1;
            const year = momentDate.year();
            const d = new Date();
            const current_month = d.getMonth() + 1;
            const current_year = d.getFullYear(); 


            if (weekOrMonth == "Week") {
                const weekresult = today > newDates && month == moment(value).format("M")
                setWeekDisabled(weekresult)
            } else if (weekOrMonth == "Month") {
                const weekresult = today > newDates && (
                    current_year < year || (current_year === year && current_month < month)
                );

                setWeekDisabled(weekresult)
            }

            if (!disable && !selectStatus) {

                handleFilteredTicketDate("");
            } else {
                handleFilteredTicketDate(formattedDate);
            }
        }
    }, [value, disable]);



    useEffect(() => {
        const hasDisabledDates = weeks.some((week) =>
            week.some((day) => isDisabled(day.date))
        );
        setDisable(hasDisabledDates);
    }, [weeks, value]);


    const isDisabled = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return today > date;
    };


    const toggleOption = (option) => {


        if (option == 'Month') {
            setweekMonthONOFF(true);
            setWeekOrMonth('Month')
        } else {
            setweekMonthONOFF(false);
            setWeekOrMonth('Week')
        }
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        setShowVerticalCalendar(prev => !prev);
    };
    const handleCalenderBoxPress = async (clickedDate, disabled) => {

        const today = new Date()

        const newDate = clickedDate ? moment(clickedDate) : moment(today);
        setValue(newDate.toDate());
        const formattedDate = newDate.format("DD-MM-YYYY");

        const selectedDateFormat = {
            day: newDate.date(),
            month: newDate.month() + 1,
            year: newDate.year()
        };
        // setSelectedDate(formattedDate);
        setweekMonthONOFF(false);
        handleFilteredTicketDate(formattedDate);

        if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }


        setCurrentYear(newDate.year());
        setCurrentMonth(newDate.month())

    };


    const generateWeeksForMonth = (selectedDate) => {
        const startOfMonth = moment(selectedDate).startOf('month');
        const endOfMonth = moment(selectedDate).endOf('month');

        const weeksArray = [];
        let currentWeek = [];

        for (
            let currentDay = startOfMonth.clone();
            currentDay.isBefore(endOfMonth);
            currentDay.add(1, 'day')
        ) {
            currentWeek.push({
                weekday: currentDay.format('ddd'),
                date: currentDay.toDate(),
            });

            if (currentDay.weekday() === 6) {
                weeksArray.push([...currentWeek]);
                currentWeek = [];
            }
        }

        if (currentWeek.length > 0) {
            weeksArray.push([...currentWeek]);
        }

        return weeksArray;
    };
    const updateMonthYear = (increment) => {

        setSelectStatus(false)
        const newDate = moment(value).add(increment, weekOrMonth).toDate()


        setValue(newDate);

        const previousMonth = moment(value).month();

        const newMonth = moment(newDate).month();

        if (weekOrMonth != "Week" || newMonth !== previousMonth) {
            const NewestDate = moment().set({
                month: currentMonth + increment,
                year: currentYear,
            });
            setCurrentYear(NewestDate.year());
            setCurrentMonth(NewestDate.month());
        }

        // setWeeks(generateWeeksForMonth(newDate));


    };

    const showToast = () => {
        toast.error("The previous week is not  selectable", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
        });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '' }}>

            <div style={{ backgroundColor: '#fff', width: '100%', flexDirection: 'column', paddingBottom: 10, borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                    <div style={{ minWidth: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <button style={{ border: "none", backgroundColor: 'white' }} onClick={() => { weekDisabled == false ? showToast() : updateMonthYear(-1) }}>
                            <IoIosArrowDropleftCircle size={25} className={`ms-1 ${weekDisabled == false ? "opacity_5 primary-text" : "primary-text"}  `} />
                        </button>
                        <span style={{ fontFamily: 'Poppins-SemiBold', fontSize: 12, fontWeight: '600', marginTop: 3 }}>


                            {moment().month(currentMonth).format('MMMM')} {currentYear}
                        </span>
                        <button style={{ border: "none", backgroundColor: 'white' }} onClick={() => { updateMonthYear(1); }}>
                            <IoIosArrowDroprightCircle size={25} className="ms-1 primary-text" />
                        </button>
                    </div>

                    <div style={{
                        backgroundColor: '#fff', width: 99, height: 20, display: 'flex', border: 'none', overflow: 'hidden'
                    }}>
                        <button className={` ${!weekMonthONOFF ? 'primary-bg' : '#ECECEC'}`} style={{
                            flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid",
                            borderColor: "#ccc", borderBottomLeftRadius: 6, borderTopLeftRadius: 6,
                        }}
                            onClick={() => toggleOption('Week')}>
                            <span style={{
                                color: !weekMonthONOFF ? '#fff' : '#000000CC', fontSize: 10, fontFamily: 'Poppins-Medium'
                            }}>
                                Week
                            </span>
                        </button>
                        <button className={` ${weekMonthONOFF ? 'primary-bg' : '#ECECEC'}`} style={{
                            border: "1px solid", borderBottomRightRadius: 6, borderTopRightRadius: 6,
                            borderColor: "#ccc", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}
                            onClick={() => toggleOption('Month')}>
                            <span style={{
                                color: weekMonthONOFF ? '#fff' : '#000000CC', fontSize: 10, fontFamily: 'Poppins-Medium'
                            }}>
                                Month
                            </span>
                        </button>
                    </div>
                </div>

                {/* Calendar Container */}
                {weekMonthONOFF ? (
                    <div style={{ backgroundColor: '#fff', paddingLeft: 5, paddingRight: 5, }}>
                        {showVerticalCalendar ? (
                            <div ref={scrollViewRef} style={{ overflowX: 'hidden', scrollBehavior: 'smooth' }}>
                                {weeks.map((dates, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginHorizontal: -4, paddingVertical: 10, paddingHorizontal: 5 }}>
                                        {dates.map((item, dateIndex) => {
                                            const isActive = selectStatus == true ? value.toDateString() === item.date.toDateString() : false
                                            const disabled = isDisabled(item.date);
                                            return (
                                                <div key={dateIndex}
                                                    onClick={() => {
                                                        if (!disabled) handleCalenderBoxPress(item.date, item.weekday);
                                                    }}>
                                                    <div style={{ backgroundColor: disabled ? '#cccc' : '' }} className={`calender-cardBox ${isActive ? 'primary-bg' : '#ECECEC'} `}
                                                    >
                                                        <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 12 }}>
                                                            {item.weekday}
                                                        </span>
                                                        <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 10 }}>
                                                            {item.date.getDate()}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div ref={scrollViewRef} style={{ overflowX: 'hidden', scrollBehavior: 'smooth' }}>
                                {weeks.map((dates, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginHorizontal: -4, paddingVertical: 10, paddingHorizontal: 5 }}>
                                        {dates.map((item, dateIndex) => {
                                            const isActive = value.toDateString() === item.date.toDateString();
                                            const disabled = isDisabled(item.date);


                                            return (
                                                <div key={dateIndex} onClick={() => {
                                                    if (!disabled) handleCalenderBoxPress(item.date, item.weekday);
                                                }}>
                                                    <div style={{ backgroundColor: disabled ? '#cccc' : '' }} className={` calender-cardBox test ${isActive ? 'primary-bg' : '#ECECEC'}`}  >
                                                        <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 12 }}>
                                                            {item.weekday}
                                                        </span>
                                                        <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 10 }}>
                                                            {item.date.getDate()}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div
                            style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 10 }}
                            onClick={() => setweekMonthONOFF(false)}> <IoIosArrowUp onClick={() => setweekMonthONOFF(false)} size={20} color='#ccc' /></div>
                    </div>
                ) : (
                    // Horizontal ScrollView for Week
                    <div style={{ backgroundColor: '#fff', paddingLeft: 5, paddingRight: 5 }}>
                        {weeks.filter((week) =>
                            week.some(
                                (day) =>
                                    day.date.getFullYear() === value.getFullYear() &&
                                    day.date.getMonth() === value.getMonth() &&
                                    day.date.getDate() === value.getDate(),
                            ),
                        ).map((dates, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginHorizontal: -4, paddingVertical: 10, paddingHorizontal: 5 }}>
                                {dates.map((item, dateIndex) => {

                                    const isActive = selectStatus == true ? value.toDateString() === item.date.toDateString() : false
                                    const disabled = isDisabled(item.date);

                                    return (
                                        <div key={dateIndex} onClick={() => {
                                            if (!disabled) handleCalenderBoxPress(item.date, item.weekday);
                                        }}>
                                            <div style={{ backgroundColor: disabled ? '#cccc' : '' }} className={`calender-cardBox test ${isActive ? 'primary-bg' : '#ECECEC'}`}  >
                                                <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 12 }}>
                                                    {item.weekday}
                                                </span>
                                                <span style={{ color: disabled ? '#000' : isActive ? '#fff' : '#000', fontSize: 10 }}>
                                                    {item.date.getDate()}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TicketCalender;
