 
// import { initializeApp } from "firebase/app";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
 
// const firebaseConfig = {
//     apiKey: "AIzaSyAAzlVNSP9hg8LAC9S0TPQ-BHILbpFUg-I",
//     authDomain: "pingleproject.firebaseapp.com",
//     databaseURL: "https://pingleproject-default-rtdb.firebaseio.com",
//     projectId: "pingleproject",
//     storageBucket: "pingleproject.appspot.com",
//     messagingSenderId: "225253645526",
//     appId: "1:225253645526:android:fe65bb9bb39e2899053d77",
//     measurementId: "G-KYPCL4N7BN"
// };
 
// const app = initializeApp(firebaseConfig);
// const auth = getAuth();

// const database = ""; // This line might need modification based on your usage.

// signInWithEmailAndPassword(auth, 'episodetechnologies@gmail.com', 'Gangster003')
//     .then((userCredential) => {
//         // Signed in 
//         const user = userCredential.user;
//         // ...
//     })
//     .catch((error) => {
//         const errorCode = error.code;
//         const errorMessage = error.message;
//     });

// export default database;


// src/Services/firebase-service/firebase.js
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAAzlVNSP9hg8LAC9S0TPQ-BHILbpFUg-I",
    authDomain: "pingleproject.firebaseapp.com",
    databaseURL: "https://pingleproject-default-rtdb.firebaseio.com",
    projectId: "pingleproject",
    storageBucket: "pingleproject.appspot.com",
    messagingSenderId: "225253645526",
    appId: "1:225253645526:web:67f61c305a8885ba053d77",
    measurementId: "G-KYPCL4N7BN",
};

// ✅ Check if Firebase app already exists before initializing
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const database = getDatabase(app);

export { app, auth, database };

