import React from "react";
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";

import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import { FaRupeeSign } from "react-icons/fa";

const Single_service_detail = () => {


    const navigate = useNavigate();
    const [service, setService] = useState()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() => navigate(-1)} />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Service</div>
                </div>
            </div>

            {service?.imges?.length !== 0 && (
                <div id="carouselExampleInterval" className="carousel slide " data-bs-ride="carousel" data-bs-interval="2000"  >
                    <div className="carousel-inner">
                        {service?.imges?.map((item, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}   >
                                <img src={item.image == "" ? defaultimg : item.image_url} className="d-block w-100 curve" alt={`slide-${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev"  ></button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next"  ></button>
                </div>
            )}
            <div className="" style={{ marginBottom: 100 }}>
                <div className="ff-poppins-semiBold fs-16 ms-2 ps-1 ">
                    {/* {service.product_name} */}
                </div>
                <div className="ff-poppins-semiBold fs-14 ms-2 ps-1">
                    {/* <FaRupeeSign /> {service.price} */}
                </div>
                <div className="ff-poppins-semiBold primary-text fs-14 ms-2 ps-1">
                    {/* {service.unit_quantity}   {service.unit_type_name} */}
                    <p className="primary-text m-0 ff-poppins-regular fs-12">{service}Left </p>
                </div>

                <div>
                    <p className="ff-poppins-regular secondary-text-1 fs-13 m-0 p-3">
                        {/* {service.description} */}
                    </p>
                </div>
            </div>

        </>
    )
}
export default Single_service_detail;
