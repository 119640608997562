import { json, useNavigate, useParams, useLocation } from "react-router-dom";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import p_checked_radio_button from "../../Assets/Images/p_checked_radio_button.svg";
import p_unchecked_radio_button from "../../Assets/Images/p_unchecked_radio_button.svg";
import enquire_arrow_right from "../../Assets/Images/enquire-arrow-right.svg";
import React, { useEffect, useRef, useState } from "react";
import {
  cartSelectPetOrPerson,
  cartSelectService,
  getUserAllVehicles,
  getVendorServiceDetails,
  userAllFamilyMembers,
  userAllPetsDetail,
  userFamilyList,
  userPetsList,
  userVehiclesList,
} from "../../Services/api";
import BookForOffCanvas from "../../Components/offCanvas/bookForOffCanvas";
import DatePickerOffCanvas from "../../Components/offCanvas/datePickerOffCanvas";
import "../../Assets/Styles/offCanvas.css";
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import emptyImg from "../../Assets/Images/default-placeholder.png";
import { RxHamburgerMenu } from "react-icons/rx";
import ServiceEnquiryOffCanvas from "../../Components/offCanvas/serviceEnquiryOffCanvas";
import { FaSearch } from "react-icons/fa";
import axiosInstance from "../../Services/axios";
import { IoMdClose } from "react-icons/io";
import ReactLoading from "react-loading";
import { IoSearch } from "react-icons/io5";
import { toast } from "react-toastify";
const DoorstepService = () => {
  const navigate = useNavigate();
  const searchserviceModalRef = useRef(null);
  const bookForOffcanvasBottom = useRef(null)
  const [serviceDetail, setServiceDetail] = useState();
  const [allservice, setAllservice] = useState([])
  const [cartAppId, setCartAppId] = useState("");
  const [selectedCartServiceCount, setSelectedCartServiceCount] = useState(0);
  const valueParam = useParams();

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // console.log("serviceDetail serviceDetail",serviceDetail);

  const [membersList, setMembersList] = useState([]);
  const [petsList, setPetsList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedBookForItem, setSelectedBookForItem] = useState(null);
  const [staffAppStatus, setStaffAppStatus] = useState(true);

  const [isBookForOffCanvasOpen, setBookForOffCanvasOpen] = useState(false);
  const [isDatePickerOffCanvasOpen, setDatePickerOffCanvasOpen] = useState(false);
  const [cartSelectServiceDetails, setCartSelectServiceDetails] = useState("");
  const [dataFromBookForOffCanvas, setDataFromBookForOffCanvas] = useState(null);

  const token = localStorage.getItem("token");
  const location = useLocation();
  const { flowparent, fromPage, offertype, AddFrom, AddPage, flowtype } = location.state || {}
  const [showModal, setShowModal] = useState(false);
  const [showBookForModal, setShowBookForModal] = useState(false);
  const [isServiceEnquiryOpen, setServiceEnquiryOpen] = useState(false);
  const [showServiceEnquiryModal, setServiceEnquiryModal] = useState(false);
  const [showDatePickerModal, setDatePickerModal] = useState(false);


  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  const [cardDetail, setCardDetail] = useState();
  const [vendorType, setVendorType] = useState();
  const [vendorId, setVendorID] = useState(valueParam.vendor_id);

  const [enquireServiceId, setEnquireServiceId] = useState('');
  const [enquireServiceDescription, setEnquireServiceDescription] = useState('');
  const [searchFilter, setSearchFilter] = useState('')
  const [filterArr, setFilterArr] = useState([])
  const [serviceTypeFilterData, setServiceTypeFilterData] = useState([])
  const [searchServiceTotalArr, setSearchServiceTotalArr] = useState([]);
  const [serviceRawData, setServiceRawData] = useState([]);
  const [selectedCateId, setSelectedCateId] = useState(0);
  const [searchServiceArr, setSearchServiceArr] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [isSelect, setIsSelect] = useState('')
  const [activeTab, setActiveTab] = useState(0);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [btnLoaded, setBtnLoaded] = useState(false);
  const [btnid, setBtnId] = useState('');
  const [locService, setLocService] = useState([])
  const [selectedcat_id, setSelectedcat_id] = useState()


  let globalCartId = "";

  const openbookForOffcanvasBottom = () => {
    if (bookForOffcanvasBottom.current) {
      bookForOffcanvasBottom.current.classList.add("show");
      bookForOffcanvasBottom.current.style.display = "block";
    }
  };

  const closebookForOffcanvasBottom = () => {
    if (bookForOffcanvasBottom.current) {
      bookForOffcanvasBottom.current.classList.remove("show");
      bookForOffcanvasBottom.current.style.display = "none";
    }
  };
  let selectedServiceOption = localStorage.getItem("selectedServiceOption");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleFollow = () => {
    if (!token) {
      handleShow();
    }
  };

  const handleCategoryClick = (category_id) => {
    const accordionItem = document.getElementById(`collapseOne${category_id}`);
    if (accordionItem) {
      accordionItem.scrollIntoView({ behavior: "smooth", block: "start", });
    }
  };


  const handleDataFromBookForOffCanvas = (data) => {


    // data != "" ? navigate(`/door-step-service/${vendorId}`) : ""
    setDataFromBookForOffCanvas(data);
    setSelectedBookForItem(data);
    forceUpdate();
  };

  const toggleBookForOffCanvas = () => {

    if (cardStatus == 1 && cardType == 'offer' && (cardDetail?.offer_type == 2 || cardDetail?.offer_type == 3)) {
      let selectedServiceOption = localStorage.getItem("selectedServiceOption");
      if (selectedServiceOption && selectedServiceOption == 'service') {
        localStorage.removeItem("serviceDetail");
        localStorage.removeItem("selectedServiceOption");
        localStorage.removeItem("appliedCard");
        
      }
      navigate(-1)
    } else {
      setBookForOffCanvasOpen(!isBookForOffCanvasOpen);
    }

  };

  // Function to toggle date picker visibility
  const toggleDatePickerOffCanvas = () => {
    setDatePickerOffCanvasOpen(!isDatePickerOffCanvasOpen);
  };

  // Function to toggle Service Enquiry visibility
  const toggleServiceEnquiryOffCanvas = () => {
    setServiceEnquiryOpen(!isServiceEnquiryOpen);
  };

  useEffect(() => {
    let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
    let selectedServiceOption = localStorage.getItem('selectedServiceOption');

    if (localServiceDetail != "") {
      if (localServiceDetail.cart_app_id) {
        setCartAppId(localServiceDetail.cart_app_id);
        setVendorID(localServiceDetail.vendorId);
      }
      // 143 to 150
      // if (localServiceDetail.timslotStatus == true && selectedServiceOption && selectedServiceOption == 'staff') {
      //   setDatePickerOffCanvasOpen(true)
      // } else if (localServiceDetail.bookForStatus == true && selectedServiceOption && selectedServiceOption == 'service') {
      //   setVendorID(localServiceDetail.vendorId);
      //   setShowBookForModal(true)
      //   showMenus(false)
      //   setBookForOffCanvasOpen(true)
      // }
    }

    let appliedCard = localStorage.getItem("appliedCard") ? JSON.parse(localStorage.getItem("appliedCard")) : "";
    if (appliedCard) {
      setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
      setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
      setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
    }

    getVendorServices();
  }, []);


  useEffect(() => {
    let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";

    if (localServiceDetail != "") {
      if (localServiceDetail.timslotStatus == true && selectedServiceOption == "staff") {
        setDatePickerModal(true)
        setMenuOpen(false)
        setDatePickerOffCanvasOpen(true)
      }
    }
    let localServiceseleted = localStorage.getItem("serviceAdded") ? JSON.parse(localStorage.getItem("serviceAdded")) : "";
    setLocService(localServiceseleted.selectedService || []);
  }, [])

  useEffect(() => {
    if (selectedBookForItem && (selectedBookForItem.id || selectedBookForItem.pet_id)) {
      submitCartSelectPetOrPerson();
    }
  }, [selectedBookForItem]);



  useEffect(() => {
    if (cartAppId != "") {
    }
  }, [serviceDetail]);

  function getVendorServices() {
    let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
    let currentCartAppId = "";

    if (cartAppId && cartAppId != "") {
      currentCartAppId = cartAppId;
    } else {
      currentCartAppId =
        localServiceDetail && localServiceDetail?.cart_app_id
          ? localServiceDetail?.cart_app_id
          : "";
    }

    let appliedCard = localStorage.getItem("appliedCard") ? JSON.parse(localStorage.getItem("appliedCard")) : "";
    let card_status = appliedCard?.cardStatus ? appliedCard?.cardStatus : 0;
    let card_type = appliedCard?.cardType ? appliedCard?.cardType : '';
    let card_id = appliedCard?.cardId ? appliedCard?.cardId : '';

    let param = {
      vendor_id: vendorId,
      cart_app_id: currentCartAppId,
      service_type: localServiceDetail?.serviceType ? localServiceDetail?.serviceType : "",
      card_status: card_status,
      card_type: card_type,
      card_id: card_id,
    };

    getVendorServiceDetails(param).then((res) => {
      let cartServices = [];
      setMenuOpen(true)
      if (currentCartAppId) {
        cartServices = localServiceDetail?.cart_service ? localServiceDetail?.cart_service : []
      }
      if (cartServices.length > 0) {
        openbookForOffcanvasBottom()
        // setSelectedCartServiceCount(cartServices.length);
      } else {
        closebookForOffcanvasBottom()
      }
      setPageLoaded(true);
      // console.log("res.data?.services?.all",res.data?.services?.all);

      res.data?.services?.all?.map((item) => {
        item?.services?.map((subItem) => {
          subItem.isSelected = cartServices.some(cartItem => cartItem.service_id == subItem.service_id);
        });
      });
      let data = res.data;
      // console.log("data data data",data);

      // let vendor_services = res.data?.services?.all;  
      // const updatedState = vendor_services.map(category => ({
      //   ...category,
      //   services: category.services.map(service => ({
      //     ...service,
      //     is_selected: cartServices.some(cartItem => cartItem.service_id === service.service_id)
      //   }))
      // }));
      // data.services.all = updatedState; 
      console.log('data', data);

      setAllservice(data)
      setServiceDetail(data);
      let selectedServiceOption = localStorage.getItem('selectedServiceOption');
      setBtnLoaded(false)
      if (localServiceDetail != "") {
        if (localServiceDetail.timslotStatus == true && selectedServiceOption && selectedServiceOption == 'staff') {
          setCartAppId(data.cart_app_id);
          globalCartId = data.cart_app_id ? data.cart_app_id : ""
          setDatePickerOffCanvasOpen(true)
        } else if (localServiceDetail.bookForStatus == true && selectedServiceOption && selectedServiceOption == 'service') {
          setVendorID(localServiceDetail.vendorId);
          setShowBookForModal(true)
          globalCartId = ''
          setBookForOffCanvasOpen(true)
        }
      }
      // setServiceDetail(data);
      if (card_status == 1) {
        setCardDetail(data?.card);
        if (card_type == 'offer' && (data?.card?.offer_type == 2 || data?.card?.offer_type == 3)) {
          openBookForModal()
        }
      }

      if (flowparent == 'Loyalty') {
        openBookForModal()
      }
    });
  }

  const addLocal_service_details = (item) => {
    let serviceAdded = localStorage.getItem("serviceAdded")
      ? JSON.parse(localStorage.getItem("serviceAdded"))
      : { selectedService: [], general_category_id: "" };

    const selectService = serviceAdded.selectedService || [];
    let category_id = serviceAdded.general_category_id;

    if (!category_id) {
      serviceAdded.general_category_id = item.general_category_id;
      category_id = item.general_category_id;
      setSelectedcat_id(category_id);
    }

    if (serviceAdded.general_category_id !== item.general_category_id) {
      toast("Please select one category service at the time", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        className: "text-danger toast-style-1",
      });
      return;
    }

    // Check if service already exists
    const existingServiceIndex = selectService.findIndex(
      (service) => service.service_id === item.service_id
    );

    let updatedList;
    if (existingServiceIndex !== -1) {
      updatedList = selectService.filter(
        (service) => service.service_id !== item.service_id
      );
    } else {
      const newService = {
        service_id: item.service_id,
        service_type: item.service_type,
        service_name: item.service_name,
      };
      updatedList = [...selectService, newService];
    }

    const totalserviceCount = updatedList.length;

    // Clear general_category_id if no services are selected
    const updatedStorageData = {
      selectedService: updatedList,
      general_category_id: totalserviceCount > 0 ? serviceAdded.general_category_id : "",
      service_count: totalserviceCount,
    };

    localStorage.setItem("serviceAdded", JSON.stringify(updatedStorageData));
    setSelectedCartServiceCount(totalserviceCount);

    if (totalserviceCount === 0) {
      setSelectedcat_id(""); // Clear selected category ID
    }

    const serviceAddeds = localStorage.getItem("serviceAdded")
      ? JSON.parse(localStorage.getItem("serviceAdded"))
      : [];
    setLocService(serviceAddeds.selectedService || []);
  };



  async function send_serviceid_to_Api() {
    const services = locService || [];

    if (Array.isArray(services) && services.length > 0) {
      for (const service of services) {
        await selectService(service.service_id);
      }

      // Run the conditional function after all services are added
      if (
        (offertype === "4" && selectedCartServiceCount === cardDetail?.combo_count) ||
        offertype !== "4"
      ) {
        openBookForModal();
      }
    } else {
      console.warn("No services available in locService");
    }
  }


  async function selectService(id) {
    try {
      let localServiceDetail = localStorage.getItem("serviceDetail")
        ? JSON.parse(localStorage.getItem("serviceDetail"))
        : "";

      let updatedCartAppId = globalCartId || cartAppId;
      console.log("globalCartId", globalCartId);

      let param = {
        vendor_id: vendorId,
        cart_app_id: selectedServiceOption == "staff" ? cartAppId : globalCartId,
        service_id: id,
        service_type: localServiceDetail?.serviceType || "",
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
      };
      console.log("param ------------->>>>>>>", param);

      const res = await cartSelectService(param);
      console.log("res.data?.cart_app_id", res.data);

      globalCartId = res.data?.cart_app_id;
      setCartAppId(globalCartId)
      if (localServiceDetail) {
        localServiceDetail.cart_app_id = globalCartId;
        localServiceDetail.cart_service = res.data?.cart_service;
        localStorage.setItem("serviceDetail", JSON.stringify(localServiceDetail));
      }


      setCartSelectServiceDetails(res.data);
      // setSelectedCartServiceCount(res.data?.cart_service?.length);

      if (res.data?.cart_service?.length > 0) {
        openbookForOffcanvasBottom();
      } else {
        closebookForOffcanvasBottom();
      }

      getVendorServices();
      vendorAllservice();

      forceUpdate();
    } catch (error) {
      console.error("Error in selectService:", error);
    }
  }

  // function openBookForModal() {

  //     if (token) {
  //         // Closing Modal with information of Services added
  //         document.getElementById('bookForOffcanvasBottom').classList.remove('show');
  //         // Opening Select customer modal
  //         document.getElementById('selectCustomerModal').classList.add('show');

  //         if (serviceDetail?.vendor_type == 1) { getFamilyMembers() }
  //         else if (serviceDetail?.vendor_type == 2) { getPetsDetail() }
  //         else if (serviceDetail?.vendor_type == 3) { getVehicles() }
  //     }
  //     else {
  //         handleShow()
  //     }

  // }

  function openBookForModal() {


    setShowMenus(false)
    if (token) {
      if (selectedServiceOption == "staff") {
        setDatePickerModal(true);
        setMenuOpen(false)
        setShowBookForModal(false);
        toggleDatePickerOffCanvas();
      } else {
        setShowBookForModal(true);
        // globalCartId = ''
        toggleBookForOffCanvas();
      }
    } else {
      handleShow();
    }
  }

  function closeBookForModal() {
    openbookForOffcanvasBottom()
    document.getElementById("selectCustomerModal").classList.remove("show");
  }



  function selectBookForItem(item) {
    if (item?.age < 18) {
      showToast("You are under 18");
      return;
    }
    if (serviceDetail?.vendor_type == 1) {
      let obj = membersList.find((x) => x.id == item.id);

      if (obj) {
        let index = membersList.indexOf(obj);
        membersList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    } else if (serviceDetail?.vendor_type == 2) {
      let obj = petsList?.find((x) => x.pet_id == item.pet_id);

      if (obj) {
        let index = petsList.indexOf(obj);
        petsList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    } else if (serviceDetail?.vendor_type == 3) {
      let obj = vehicleList?.find((x) => x.id == item.id);

      if (obj) {
        let index = vehicleList.indexOf(obj);
        vehicleList.fill((obj.isSelected = !obj.isSelected), index, index++);

        setSelectedBookForItem(item.isSelected ? item : null);

        forceUpdate();
      }
    }
  }

  function submitCartSelectPetOrPerson() {
    let localServiceDetail = localStorage.getItem("serviceDetail")
      ? JSON.parse(localStorage.getItem("serviceDetail"))
      : "";

    let selectedItemId =
      serviceDetail?.vendor_type == 1 || serviceDetail?.vendor_type == 3
        ? selectedBookForItem.id
        : selectedBookForItem.pet_id;

    let param = {
      vendor_id: vendorId,
      cart_app_id: cartAppId,
      service_type: localServiceDetail?.serviceType
        ? localServiceDetail?.serviceType
        : "",
      booking_for: selectedItemId,
    };

    cartSelectPetOrPerson(param).then((res) => {
      if (res.data.status == "1") {
        localServiceDetail.bookForStatus = true;
        localStorage.setItem("serviceDetail", JSON.stringify(localServiceDetail));
        if (res.data.staff_app_status == 1) {
          navigate(`/select-staff/${vendorId}`);
        } else {
          toggleBookForOffCanvas();
          setShowBookForModal(false);
          setDatePickerModal(true);
          setMenuOpen(false)
          toggleDatePickerOffCanvas();
        }
      }
    });
  }

  const [showMenus, setShowMenus] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);

  const dropUpMenuPress = () => {
    setShowMenus((prevShowMenus) => {
      const newState = !prevShowMenus;
      return newState;
    });
  };

  const [openDescriptions, setOpenDescriptions] = useState({});

  const toggleAccordion = (serviceId) => {
    setOpenDescriptions((prevOpenDescriptions) => ({
      ...prevOpenDescriptions,
      [serviceId]: !prevOpenDescriptions[serviceId],
    }));
  };

  const [openIndices, setOpenIndices] = useState([]);

  useEffect(() => {
    if (serviceDetail && serviceDetail.services && serviceDetail.services.all) {
      const allIndices = Array.from(
        { length: serviceDetail.services.all.length },
        (_, index) => index
      );
      setOpenIndices(allIndices);
    }
  }, [serviceDetail]);

  const toggleAccordion2 = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  const closeMenuList = (event) => {
    const menuButton = document.getElementById("menuButton");
    if (menuButton && !menuButton.contains(event.target)) {
      setShowMenus(false);
    }
  };
  const isItemOpen = (index) => openIndices.includes(index);

  const showToast = (message) => {
  };
  const opensearchserviceModal = () => {
    searchserviceModalRef.current.classList.add("show");
    searchserviceModalRef.current.style.display = "block";
    vendorAllservice()
    setMenuOpen(false)
    // vendorAllservice()
  };
  function getBestService(services, query) {
    return services
      .filter(service => service.service_name.toLowerCase().includes(query.toLowerCase()))
      .sort((a, b) => parseInt(b.service_price) - parseInt(a.service_price))
    // .slice(0, 1);
  }
  useEffect(() => {
    if (searchData.length > 1) {
      const searchService = getBestService(searchServiceTotalArr, searchData);
      setSearchServiceArr(searchService)
    } else if (searchData == "") {
      setSearchServiceArr(searchServiceTotalArr)
    } else {

    }
  }, [searchData, searchServiceTotalArr])
  const closesearchserviceModal = () => {
    searchserviceModalRef.current.classList.remove("show");
    searchserviceModalRef.current.style.display = "none";
    setMenuOpen(true)
  };
  const vendorAllservice = () => {
    try {
      let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
      let reaData = {
        "vendor_id": vendorId,
        "search_filter": searchData,
        "cart_id": localServiceDetail && localServiceDetail?.cart_app_id ? localServiceDetail?.cart_app_id : "",
        "service_type": localServiceDetail?.serviceType ? localServiceDetail?.serviceType : "",
        "card_status": 0,
        "card_type": cardType,
        "card_id": cardId
      }
      axiosInstance.post('/user/search_vendor_service', reaData)
        .then((res => {
          let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
          let currentCartAppId = "";

          if (cartAppId && cartAppId != "") {
            currentCartAppId = cartAppId;
          } else {
            currentCartAppId =
              localServiceDetail && localServiceDetail?.cart_app_id
                ? localServiceDetail?.cart_app_id
                : "";
          }

          let appliedCard = localStorage.getItem("appliedCard") ? JSON.parse(localStorage.getItem("appliedCard")) : "";
          let card_status = appliedCard?.cardStatus ? appliedCard?.cardStatus : 0;
          let card_type = appliedCard?.cardType ? appliedCard?.cardType : '';
          let card_id = appliedCard?.cardId ? appliedCard?.cardId : '';

          let cartServices = [];
          if (currentCartAppId) {
            cartServices = localServiceDetail?.cart_service ? localServiceDetail?.cart_service : []
          }

          if (cartServices.length > 0) {
            openbookForOffcanvasBottom()
            // setSelectedCartServiceCount(cartServices.length);
          } else {
            closebookForOffcanvasBottom()
          }
          res.data?.filter?.map((item) => {
            item?.services?.map((subItem) => {
              subItem.isSelected = cartServices.some(cartItem => cartItem.service_id == subItem.service_id);
              setIsSelect(subItem.isSelected)
            });
          });

          let filterdata = res.data.filter;
          if (filterdata.length > 0) {
            let serviceTypeFilterData = [];
            filterdata.map((data, index) => {
              if (index === 0 && filterdata.length !== 2) {
                serviceTypeFilterData.push({ index_id: index, id: data.id, category_name: data.name });
              } else {
                serviceTypeFilterData.push({ index_id: index, id: data.id, category_name: data.name });
              }
            });
            setServiceTypeFilterData(serviceTypeFilterData);
          }
          setServiceRawData(filterdata)
        }))

    }
    catch {

    }
  }

  useEffect(() => {
    let localServiceDetail = localStorage.getItem("serviceDetail") ? JSON.parse(localStorage.getItem("serviceDetail")) : "";
    if (localServiceDetail != "") {
      if (localServiceDetail.cart_app_id) {
        setCartAppId(localServiceDetail.cart_app_id);
        setVendorID(localServiceDetail.vendorId);
      }
    }
    vendorAllservice()
  }, [isSelect])

  const toggleAccordions = (service_id) => {
    setOpenDescriptions((prevOpenDescriptions) => ({
      ...prevOpenDescriptions,
      [service_id]: !prevOpenDescriptions[service_id],
    }));
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  useEffect(() => {
    const selectedCategory = serviceTypeFilterData[activeTab];
    const selectedCategoryId = selectedCategory?.index_id;
    setSearchServiceTotalArr(serviceRawData[selectedCategoryId]?.services);
    setSearchServiceArr(serviceRawData[selectedCategoryId]?.services)
  }, [activeTab, serviceRawData, serviceTypeFilterData])

  useEffect(() => {
    if (serviceRawData.length > 2) {
      setSearchServiceTotalArr(serviceRawData[selectedCateId]?.services)
    }
  }, [selectedCateId])
  useEffect(() => {
    vendorAllservice()
    if (AddPage && AddPage == "AddMember" || AddPage == "vehicle" || AddPage == "pet") {
      // openBookForModal()
      selectBookForItem()
    }
  }, [AddPage]);


  return (
    <>
      <div>
        <div className=" d-flex flex-column" style={{ overflowY: "auto", maxHeight: "300px" }}>
          <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
            <div className="d-flex justify-content-between align-items-center ms-2 me-2">
              <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button"
                onClick={() => {
                  if (AddPage == "AddMember" || AddPage == "vehicle" || AddPage == "pet") {
                    navigate(`/vendor/${vendorId}`)

                  }
                  else {
                    navigate(-1)
                  }
                  let selectedServiceOption = localStorage.getItem("selectedServiceOption");
                  if (selectedServiceOption && selectedServiceOption == 'service') {
                    localStorage.removeItem("serviceDetail");
                    localStorage.removeItem("selectedServiceOption");
                    localStorage.removeItem("appliedCard");
                  }
                }} />
              <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Services</div>
            </div>
            <div className="  rounded-pill   border  border-2 " onClick={() => opensearchserviceModal()}>
              <div className="d-flex justify-content-between searchServiceBox ">
                <div className="primary-text me-1 ">
                  <FaSearch className="mb-2" />
                </div>
                <div className="fs-14 secondary-text ff-balooPaaji2-regular">
                  Search service
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>

      {
        pageLoaded ?
          <>

            <div className='scroller-style-y'>
              <div className="mt-2 p-2 d-none" >
                <div className="d-flex justify-content-between deleiveryAddress rounded-pill bg-white secondary-text-1 p-1">
                  <input type="text" className="SearchServiceBox ms-2  ff-balooPaaji2-regular rounded-pill fs-14 mt-1" placeholder="Search " />

                </div>
              </div>
              <div className="d-none">
                <div className="mt-2 p-2 " onClick={() => opensearchserviceModal()}>
                  <div className="d-flex justify-content-between deleiveryAddress rounded-pill bg-white secondary-text-1 p-1">
                    <input type="text" className="SearchServiceBox ms-2  ff-balooPaaji2-regular rounded-pill fs-14 mt-1" placeholder="Search " />
                    <span className="me-3"> <IoSearch /> </span>
                  </div>
                </div>
              </div>
              <div className="offcanvas-parentType3 " id="scroll" onClick={closeMenuList} >
                <div className="doorStepService p-0 position-relative">
                  <div class="scroller-style-y">
                    <div className="accordion px-3  margin_bottom_140 ">
                      {serviceDetail?.services?.all?.length > 0 ? (
                        serviceDetail?.services?.all?.map((item, index) => {
                          return (
                            <div className="accordion-item product_list_accordion" key={index}>
                              <h2 className="accordion-header mb-1" id={"collapseOne" + item.service_category_id}>
                                <button className={`serviceMenu-but p-2 m-0 accordion-button ff-poppins-bold fs-16 text-white ${isItemOpen(index) ? "" : "collapsed"}`}
                                  type="button" onClick={() => toggleAccordion2(index)} aria-expanded={isItemOpen(index) ? "true" : "false"}  >
                                  <p class="mt-2 mb-2"> {item.service_category_name} - {item.general_category_name}</p>
                                  <p class="mt-2 mb-2">{isItemOpen(index) ? (<IoIosArrowUp style={{ fontSize: 20 }} />) : (<IoChevronDown style={{ fontSize: 20 }} />)}</p>
                                  <style>{`button::after {   display: none;  position: absolute;   content: '';   }`}  </style>
                                </button>
                              </h2>
                              <div className={`accordion-collapse collapse ${isItemOpen(index) ? "show" : ""}`} aria-labelledby={"heading" + index}>
                                <div className="accordion-body">
                                  {item?.services?.length > 0 ? (
                                    item?.services?.map((subItem, subIndex) => {

                                      return (
                                        <div key={subIndex}>
                                          <div className="container-fluid">
                                            <div className="row">
                                              <div className="col-8 ps-2">
                                                <div className="ff-poppins-semiBold fs-14">
                                                  {subItem.service_name}
                                                </div>
                                                <div>
                                                  <span className="ff-poppins-semiBold fs-12">
                                                    Rs.{subItem.service_price}
                                                    <span className="ff-poppins-regular primary-text  ">
                                                      {offertype != "4" ? subItem.applied_card_status == 1 ? `(${subItem.benefit_lable})` : '' : ""}
                                                    </span>
                                                  </span>
                                                </div>
                                                <div>
                                                  <span className="ff-poppins-regular fs-12 text-uppercase">   {subItem.service_category_name}  </span>
                                                </div>
                                                {/* more detailssssss */}
                                                <div className="moreDetails">
                                                  <div className="accordion" id={"accordionExample1Sub" + index + "" + subIndex}   >
                                                    <div className="accordion-item border-0 bg-transparent">
                                                      <h2 className="accordion-header" id={"headingSubOne" + index} > <button className={`morebut accordion-button ff-poppins-regular fs-12 bg-transparent ${openDescriptions[subItem.service_id] ? "" : "collapsed"}`} type="button"
                                                        aria-expanded={openDescriptions[subItem.service_id] ? "true" : "false"} aria-controls={"collapseSubOne" + index} onClick={() => toggleAccordion(subItem.service_id)}          >
                                                        Read More
                                                        <p className="mb-0"> {" "} {openDescriptions[subItem.service_id] ? (<IoIosArrowUp className="pl-2" style={{ fontSize: 15 }} />) : (<IoChevronDown className="pl-2" style={{ fontSize: 15 }} />)}
                                                        </p>
                                                      </button>
                                                      </h2>
                                                      <div id={"collapseSubOne" + index}
                                                        className={`accordion-collapse collapse  ${openDescriptions[subItem.service_id] ? "show" : ""}`}
                                                        aria-labelledby={"headingSubOne" + index} data-bs-parent={"#accordionExample1Sub" + index}  >
                                                        <div className="accordion-body col-12 description">

                                                          {subItem.description.split('*').map((item, index) => (
                                                            <p key={index} className="ff-poppins-regular fs-12"> {index === 0 ? item.trim() : `* ${item.trim()}`}</p>
                                                          ))}
                                                          <div className="d-flex justify-content-between">
                                                            <div
                                                              className="searchEnquireBtns  p-1 primary-bg text-white ff-poppins-regular fs-10 rounded-3 mt-2 d-flex justify-content-between align-items-center"
                                                              role="button"
                                                              onClick={() => {
                                                                setEnquireServiceId(subItem.service_id);
                                                                setEnquireServiceDescription(subItem.general_category_name + ' - ' + subItem.service_name);
                                                                setServiceEnquiryModal(true);
                                                                toggleServiceEnquiryOffCanvas();
                                                              }}
                                                            >
                                                              Enquire
                                                              <img src={enquire_arrow_right} width={"15px"} height={"15px"} alt="Enquire" />
                                                            </div>
                                                            <div className="searchEnquireBtns p-1 primary-bg text-white ff-poppins-regular fs-10 rounded-3 mt-2 d-flex justify-content-between align-items-center"
                                                              role="button"

                                                            >
                                                              More Details
                                                              <img src={enquire_arrow_right} width={"15px"} height={"15px"} alt="Enquire" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* {
                                                      btnid == subItem.service_id && btnLoaded ?
                                                        <div class="spinner-border btn_spinner" role="status">
                                                          <span class="visually-hidden">Loading...</span>
                                                        </div> : */}
                                              {/* } */}
                                              <div className="col-4">
                                                <div className="imgBox position-relative"> {subItem.image_status != 0 && subItem.image ? (<img src={subItem.image} width={"89px"} height={"82px"} />) : (<img src={emptyImg} width={"89px"} height={"82px"} alt="Empty Image" />)}
                                                  <button
                                                    type="button"
                                                    disabled={offertype === "4" ? (selectedCartServiceCount >= cardDetail?.combo_count && !subItem.isSelected || subItem.select_status == 0) : subItem.select_status === 0}
                                                    className={`d-flex justify-content-center align-items-center ff-poppins-medium fs-12 border-0 position-absolute 
                                                      ${offertype === "4" && selectedCartServiceCount >= cardDetail?.combo_count && !subItem.isSelected ? "bg-secondary text-white" :
                                                        locService?.length > 0 && locService.find((id) => id.service_id === subItem.service_id) ? "primary-bg text-white" : "bg-white primary-text"}`}
                                                    style={{ bottom: subItem.image_status !== 0 ? "-14px" : "unset" }}
                                                    onClick={() => { addLocal_service_details(subItem); setBtnId(subItem.service_id); setBtnLoaded(true) }}  >

                                                    {locService?.length > 0 && locService.find((id) => id.service_id === subItem.service_id) ? "Selected" : "Select"}
                                                  </button>

                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <hr />
                                        </div>
                                      )
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>


                  {isMenuOpen && (
                    <div className="menu-div">
                      <div className="left-div"></div>
                      <div className="right-div">
                        <div className="dropup-center dropup" style={{ zIndex: 9996 }}>
                          <button id="menuButton" className="btn btn-secondary dropdown-toggle menuBtn" type="button" onClick={dropUpMenuPress} style={{ display: showMenus ? "none" : " ", zIndex: 9998 }} >
                            <RxHamburgerMenu className="menuIcon" />
                            Menu
                          </button>
                        </div>
                      </div>
                      {showMenus && (
                        <>
                          <div className="backdrop" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9996 }} onClick={(e) => { e.stopPropagation(); setShowMenus(false) }}></div>
                          <div
                            className="menu-list-container bg-info "

                          >
                            <ul
                              className="dropdown-menu show scroller-style-y"
                              style={{ maxHeight: "200px" }}
                              id="serviceMenuList"
                            >
                              {serviceDetail?.services.all.map((cate, index) => (
                                <li key={index} className="d-flex justify-content-between p-1 fs-12 ff-poppins-regular">
                                  <button className="dropdown-item d-flex collapsed justify-content-between" onClick={() => { handleCategoryClick(cate.service_category_id); }}
                                  >
                                    <span className="truncate-text">   {cate?.service_category_name} - {cate?.general_category_name} </span>
                                  </button>
                                  <span className="me-1 text-light">{cate?.services?.length}</span>
                                </li>
                              ))}
                            </ul>
                          </div></>
                      )}
                    </div>)}


                    
                  {selectedCartServiceCount && !isDatePickerOffCanvasOpen != 0 && (
                    <div className="productsHeroDiv" style={{ zIndex: 9997 }} >

                      <div className="reviewPayDivs" style={{ backgroundColor: "#fff", boxShadow: "0px -8px 16px rgba(0, 0, 0, 0.25)" }} >


                        <div className="offcanvas-body small" style={{ overflowY: 'hidden' }}>
                          <div className="d-flex justify-content-center">
                            <div className="custom-pill"></div>
                          </div>

                          <div className="ff-poppins-semiBold fs-14 mt-3 mb-2">
                            <span className="primary-text">
                              {selectedCartServiceCount} Service </span>{" "}  added
                          </div>
                          <button
                            type="button"
                            className={`btn-type-1 ff-poppins-semiBold fs-14 w-100 ${offertype == "4"
                              ? selectedCartServiceCount == cardDetail?.combo_count
                                ? "primary-bg"
                                : "bg-secondary"
                              : "primary-bg"
                              } border-0 text-white`}
                            onClick={() => send_serviceid_to_Api()} >
                            {selectedServiceOption == "staff" ? "Select TimeSlot" : "Book For"}
                          </button>


                        </div> </div>

                    </div>
                  )}



                  {/* Modal with information of Services added  */}
                  {/* <div className="offcanvas"
                    style={{ width: "-webkit-fill-available", position: 'sticky', bottom: 0, zIndex: 9999 }}
                    ref={bookForOffcanvasBottom}
                    data-bs-scroll="true"
                    data-bs-backdrop="false"
                    tabIndex="-1"
                    id="bookForOffcanvasBottom"
                    aria-labelledby="offcanvasBottomLabel">
                    <div className="offcanvas-body small" style={{ overflowY: 'hidden' }}>
                      <div className="d-flex justify-content-center">
                        <div className="custom-pill"></div>
                      </div>

                      <div className="ff-poppins-semiBold fs-14 mt-3 mb-2">
                        <span className="primary-text">
                          {selectedCartServiceCount} Service </span>{" "}  added
                      </div>
                      <button
                        type="button"
                        className={`btn-type-1 ff-poppins-semiBold fs-14 w-100 ${offertype == "4"
                          ? selectedCartServiceCount == cardDetail?.combo_count
                            ? "primary-bg"
                            : "bg-secondary"
                          : "primary-bg"
                          } border-0 text-white`}
                        onClick={
                          offertype == "4" && selectedCartServiceCount == cardDetail?.combo_count
                            ? openBookForModal
                            : offertype != "4"
                              ? openBookForModal
                              : undefined
                        }
                      >
                        {selectedServiceOption == "staff" ? "Select TimeSlot" : "Book For"}
                      </button>

                    </div>
                  </div> */}

                  {/* Select customer modal  */}
                  <div className="offcanvas offcanvas-bottom selectCustomerModal" tabindex="-1" id="selectCustomerModal" aria-labelledby="selectCustomerModalLabel" >
                    <div className="offcanvas-body small">
                      <div className="d-flex justify-content-center">
                        <div className="custom-pill" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></div>
                      </div>
                      <div className="ff-poppins-semiBold fs-16 bg-info">This is For</div>
                      <div className="customerList scroller-style-y">
                        {
                          // Family members list
                          serviceDetail?.vendor_type == 1 && membersList.length > 0 ? (
                            membersList.map((item) => {
                              return (
                                <div className={(item.select_status == 0 ? "opacity-40 pe-none" : "") + " item"} onClick={() => selectBookForItem(item)} role="button"  >
                                  <div className="d-flex">
                                    <img className="profileImg" src={item.image} />

                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <div>
                                        <div className="ff-poppins-semiBold fs-12">
                                          {item.name}
                                        </div>
                                        <div className="ff-poppins-regular fs-10 text-secondary">
                                          {item.book_for}
                                        </div>
                                      </div>

                                      {item.isSelected ? (
                                        <img src={p_checked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />) :
                                        (<img src={p_unchecked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                              <div className="ff-poppins-regular fs-14">
                                No Family Member Available
                              </div>
                            </div>
                          ) ||
                            // Pet List
                            (serviceDetail?.vendor_type == 2 && petsList.length > 0) ? (
                            petsList.map((item) => {
                              return (
                                <div className={(item.select_status == 0 ? "opacity-40 pe-none" : "") + " item"} onClick={() => selectBookForItem(item)} role="button"   >
                                  <div className="d-flex">
                                    <img className="profileImg" src={item.pet_image} />

                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <div>
                                        <div className="ff-poppins-semiBold fs-12">
                                          {item.name}
                                        </div>
                                        <div className="ff-poppins-regular fs-10 text-secondary">
                                          {item.about}
                                        </div>
                                      </div>

                                      {item.isSelected ? (
                                        <img src={p_checked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />) : (<img src={p_unchecked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />)}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                              <div className="ff-poppins-regular fs-14">
                                No Family Member Available
                              </div>
                            </div>
                          ) ||
                            // Vehicle List
                            (serviceDetail?.vendor_type == 3 &&
                              vehicleList.length > 0) ? (
                            vehicleList?.map((item) => {
                              return (
                                <div className={(item?.select_status == 0 ? "opacity-40 pe-none" : "") + " item"} onClick={() => selectBookForItem(item)} role="button"  >
                                  <div className="d-flex">
                                    <img className="profileImg" src={item?.image} />

                                    <div className="d-flex justify-content-between align-items-center w-100">
                                      <div>
                                        <div className="ff-poppins-semiBold fs-12">
                                          {item?.brand}
                                        </div>
                                        <div className="ff-poppins-regular fs-10 text-secondary">
                                          {item?.category_name}
                                        </div>
                                      </div>

                                      {item.isSelected ? (
                                        <img src={p_checked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                      ) : (
                                        <img src={p_unchecked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                              <div className="ff-poppins-regular fs-14">
                                No Family Member Available
                              </div>
                            </div>
                          )
                        }
                      </div>

                      <div>
                        <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 w-100 bg-white border mb-2"   >
                          Add New
                          {serviceDetail?.vendor_type == 1 && <span> Members</span>}
                          {serviceDetail?.vendor_type == 2 && <span> Pet</span>}
                          {serviceDetail?.vendor_type == 3 && <span> Vehicle</span>}
                        </button>

                        <button type="button" className={(selectedBookForItem == null ? "bg-secondary opacity-40" : "primary-bg opacity-100") + " " + "btn-type-1 ff-poppins-semiBold fs-14 w-100 border-0 text-white mt-1"} onClick={submitCartSelectPetOrPerson}   >
                          Select Staff
                        </button>
                      </div>
                    </div>
                  </div>

                  {showBookForModal && (
                    <div className="modal-backdrop show" onClick={() => {
                      toggleBookForOffCanvas(); setShowBookForModal(false);
                    }}></div>
                  )}

                  {showBookForModal && (
                    <BookForOffCanvas
                      isOpen={isBookForOffCanvasOpen}
                      toggleOffCanvas={() => { toggleBookForOffCanvas(); setShowBookForModal(false); }}
                      cartAppId={cartAppId}
                      vendorId={vendorId}
                      vendorType={serviceDetail?.vendor_type}
                      sendDataToDoorstep={handleDataFromBookForOffCanvas}
                      AddPage={AddPage}
                    />
                  )}

                  {showServiceEnquiryModal && (
                    <div className="modal-backdrop show" onClick={() => {
                      setEnquireServiceId('');
                      setEnquireServiceDescription('');
                      setServiceEnquiryModal(false);
                      toggleServiceEnquiryOffCanvas();
                    }}></div>
                  )}

                  {showServiceEnquiryModal && (
                    <ServiceEnquiryOffCanvas
                      isOpen={isServiceEnquiryOpen}
                      toggleOffCanvas={toggleServiceEnquiryOffCanvas}
                      cartAppId={cartAppId}
                      vendorId={vendorId}
                      serviceId={enquireServiceId}
                      category={enquireServiceDescription}
                    />
                  )}

                  {showDatePickerModal && (
                    <DatePickerOffCanvas
                      isOpen={isDatePickerOffCanvasOpen}
                      toggleOffCanvas={toggleDatePickerOffCanvas}
                    />
                  )}

                </div>
              </div>


              {showModal && <div className="modal-backdrop show"></div>}

              {showModal && (
                <div className="modal d-flex justify-content-center" tabIndex="-1" style={{ display: "block" }}>
                  <div className="modal-dialog-centered loginWarningDialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="ff-poppins-semiBold fs-1 primary-text text-center">
                          Attention!
                        </div>
                        <div className="ff-poppins-regular fs-14 text-center mt-2">
                          To proceed further you need to login/register and get access
                          to all the features.
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-1 mb-1" role="button" onClick={() => { localStorage.setItem('vendorUrl', location.pathname); navigate("/login") }}  >
                          <div className="loginBtn primary-bg text-center text-white p-1 ff-poppins-regular fs-16">
                            Login
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" role="button" onClick={handleClose} >
                          <div className="loginBtn text-center text-secondary p-1 ff-poppins-regular fs-16 secondary-bg-1 mt-2">
                            Close
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="offcanvas " style={{
                width: "-webkit-fill-available", position: 'sticky', bottom: 0, zIndex: 9998, borderTopRightRadius: "22px",
                borderTopLeftRadius: "22px",
                marginTop: "14px", boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.2)",
              }} data-bs-scroll="true" tabIndex="-1" aria-labelledby="offcanvasBottomLabel" ref={searchserviceModalRef} >
                <div className="offcanvas-body small" style={{ overflowY: 'hidden' }}>

                  <div className="d-flex justify-content-between   ff-poppins-semiBold">
                    <p>Search</p>
                    <p onClick={() => { closesearchserviceModal(); setSearchData('') }} className="fs-20"><IoMdClose /></p>
                  </div>
                  <div>
                    <ul className="nav search_Nav ff-poppins-regular  searchServiceModel nav-tabs mb-3" id="myTab" role="tablist">
                      {serviceTypeFilterData?.map((item, index) => (
                        <li className="nav-item search_Nav_item    mb-3 ms-2 me-2" role="presentation" key={index}>
                          <button className={`nav-link bg-light border border-secondary-subtle rounded-2 p-2 ${activeTab === index ? 'active' : ''}`} id={`tab${index}`} data-bs-toggle="tab" data-bs-target={`#tab-pane${index}`} type="button" role="tab" aria-controls={`tab-pane${index}`} aria-selected={activeTab === index} onClick={() => handleTabClick(index)}  >
                            {item.category_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <div className="border border-2 rounded-2 mt-2">
                        <div className="p-2">
                          <span className="primary-text"><FaSearch />  </span>
                          <input
                            type="text"
                            style={{ width: '75%' }}
                            className="SearchServiceBox ff-poppins-regular me-2 secondary-text-1 "
                            value={searchData}
                            onChange={(e) => setSearchData(e.target.value)}
                            placeholder="Search within the services"
                          />
                        </div>
                      </div>
                      <div className="offcanvas offcanvas-bottom selectCustomerModal" tabindex="-1" id="selectCustomerModal" aria-labelledby="selectCustomerModalLabel" >
                        <div className="offcanvas-body small">
                          <div className="d-flex justify-content-center">
                            <div className="custom-pill" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></div>
                          </div>

                          <div className="ff-poppins-semiBold fs-16 bg-danger">This is For</div>

                          <div className="customerList scroller-style-y">
                            {
                              // Family members list
                              serviceDetail?.vendor_type == 1 && membersList.length > 0 ? (
                                membersList.map((item) => {
                                  return (
                                    <div
                                      className={
                                        (item.select_status == 0
                                          ? "opacity-40 pe-none"
                                          : "") + " item"
                                      }
                                      onClick={() => selectBookForItem(item)}
                                      role="button"
                                    >
                                      <div className="d-flex">
                                        <img className="profileImg" src={item.image} />

                                        <div className="d-flex justify-content-between align-items-center w-100">
                                          <div>
                                            <div className="ff-poppins-semiBold fs-12">
                                              {item.name}
                                            </div>
                                            <div className="ff-poppins-regular fs-10 text-secondary">
                                              {item.book_for}
                                            </div>
                                          </div>

                                          {item.isSelected ? (
                                            <img
                                              src={p_checked_radio_button}
                                              width={"15px"}
                                              height={"15px"}
                                              alt="radio Button"
                                              role="button"
                                            />
                                          ) : (
                                            <img
                                              src={p_unchecked_radio_button}
                                              width={"15px"}
                                              height={"15px"}
                                              alt="radio Button"
                                              role="button"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                                  <div className="ff-poppins-regular fs-14">
                                    No Family Member Available
                                  </div>
                                </div>
                              ) ||
                                // Pet List
                                (serviceDetail?.vendor_type == 2 && petsList.length > 0) ? (
                                petsList.map((item) => {
                                  return (
                                    <div FclassName={(item.select_status == 0 ? "opacity-40 pe-none" : "") + " item"} onClick={() => selectBookForItem(item)} role="button"  >
                                      <div className="d-flex b">
                                        <img className="profileImg" src={item.pet_image} />

                                        <div className="d-flex justify-content-between align-items-center w-100">
                                          <div>
                                            <div className="ff-poppins-semiBold fs-12">
                                              {item.name}
                                            </div>
                                            <div className="ff-poppins-regular fs-10 text-secondary">
                                              {item.about}
                                            </div>
                                          </div>

                                          {item.isSelected ? (
                                            <img src={p_checked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                          ) : (
                                            <img src={p_unchecked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                                  <div className="ff-poppins-regular fs-14">
                                    No Family Member Available
                                  </div>
                                </div>
                              ) ||
                                // Vehicle List
                                (serviceDetail?.vendor_type == 3 &&
                                  vehicleList.length > 0) ? (
                                vehicleList.map((item) => {
                                  return (
                                    <div className={(item.select_status == 0 ? "opacity-40 pe-none" : "") + " item"} onClick={() => selectBookForItem(item)} role="button"  >
                                      <div className="d-flex">
                                        <img className="profileImg" src={item.image} />

                                        <div className="d-flex justify-content-between align-items-center w-100">
                                          <div>
                                            <div className="ff-poppins-semiBold fs-12">
                                              {item.brand}
                                            </div>
                                            <div className="ff-poppins-regular fs-10 text-secondary" >
                                              {item.about}
                                            </div>
                                          </div>

                                          {item.isSelected ? (
                                            <img
                                              src={p_checked_radio_button}
                                              width={"15px"}
                                              height={"15px"}
                                              alt="radio Button"
                                              role="button"
                                            />
                                          ) : (
                                            <img src={p_unchecked_radio_button} width={"15px"} height={"15px"} alt="radio Button" role="button" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center">
                                  <div className="ff-poppins-regular fs-14">
                                    No Family Member Available
                                  </div>
                                </div>
                              )
                            }
                          </div>

                          <div>
                            <button
                              type="button"
                              className="btn-type-1 ff-poppins-semiBold fs-14 w-100 bg-white border mb-2"
                            >
                              Add New
                              {serviceDetail?.vendor_type == 1 && <span> Members</span>}
                              {serviceDetail?.vendor_type == 2 && <span> Pet</span>}
                              {serviceDetail?.vendor_type == 3 && <span> Vehicle</span>}
                            </button>

                            <button
                              type="button"
                              className={
                                (selectedBookForItem == null
                                  ? "bg-secondary opacity-40"
                                  : "primary-bg opacity-100") +
                                " " +
                                "btn-type-1 ff-poppins-semiBold fs-14 w-100 border-0 text-white mt-1"
                              }
                              onClick={submitCartSelectPetOrPerson}
                            >
                              Select Staff
                            </button>
                          </div>
                        </div>
                      </div>

                      {showBookForModal && (
                        <div className="modal-backdrop show" onClick={() => {
                          toggleBookForOffCanvas(); setShowBookForModal(false);
                        }}></div>
                      )}

                      {showBookForModal && (
                        <BookForOffCanvas
                          isOpen={isBookForOffCanvasOpen}
                          toggleOffCanvas={() => { toggleBookForOffCanvas(); setShowBookForModal(false); }}
                          cartAppId={cartAppId}
                          vendorId={vendorId}
                          vendorType={serviceDetail?.vendor_type}
                          sendDataToDoorstep={handleDataFromBookForOffCanvas}

                        />
                      )}

                      {showServiceEnquiryModal && (
                        <div className="modal-backdrop show" onClick={() => {
                          setEnquireServiceId('');
                          setEnquireServiceDescription('');
                          setServiceEnquiryModal(false);
                          toggleServiceEnquiryOffCanvas();
                        }}></div>
                      )}

                      {showServiceEnquiryModal && (
                        <ServiceEnquiryOffCanvas
                          isOpen={isServiceEnquiryOpen}
                          toggleOffCanvas={toggleServiceEnquiryOffCanvas}
                          cartAppId={cartAppId}
                          vendorId={vendorId}
                          serviceId={enquireServiceId}
                          category={enquireServiceDescription}
                        />
                      )}

                      {/* {showDatePickerModal && (
                        <DatePickerOffCanvas
                          isOpen={isDatePickerOffCanvasOpen}
                          toggleOffCanvas={toggleDatePickerOffCanvas}
                        />
                      )} */}


                      {showModal && <div className="modal-backdrop show"></div>}

                      {showModal && (
                        <div className="modal d-flex justify-content-center" tabIndex="-1" style={{ display: "block" }}>
                          <div className="modal-dialog-centered loginWarningDialog">
                            <div className="modal-content">
                              <div className="modal-body">
                                <div className="ff-poppins-semiBold fs-1 primary-text text-center">
                                  Attention!
                                </div>
                                <div className="ff-poppins-regular fs-14 text-center mt-2">
                                  To proceed further you need to login/register and get access
                                  to all the features.
                                </div>
                                <div className="d-flex justify-content-center align-items-center mt-1 mb-1" role="button" onClick={() => { localStorage.setItem('vendorUrl', location.pathname); navigate("/login") }}  >
                                  <div className="loginBtn primary-bg text-center text-white p-1 ff-poppins-regular fs-16">
                                    Login
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" role="button" onClick={handleClose} >
                                  <div className="loginBtn text-center text-secondary p-1 ff-poppins-regular fs-16 secondary-bg-1 mt-2">
                                    Close
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <p className="mt-2 secondary-text-1 fs-14">Services</p>
                    </div>
                    <div className="scroller-style-y">
                      <div className="tab-content doorStepService" id="myTabContent">
                        {
                          serviceTypeFilterData?.map((item, tabIndex) => (
                            <div key={tabIndex} className={`tab-pane fade ${activeTab === tabIndex ? 'show active' : ''}`} id={`tab-pane${tabIndex}`} role="tabpanel" aria-labelledby={`tab${tabIndex}`} tabIndex="0"  >
                              {
                                searchServiceArr?.length > 0 ?
                                  searchServiceArr?.map((subItem, subIndex) =>
                                  // console.log("subItem", subItem)
                                  (
                                    <div key={subIndex}>
                                      <div className="row">
                                        <div className="col-8 ps-2">
                                          <div className="ff-poppins-semiBold fs-14">{subItem.service_name}</div>
                                          <div>
                                            <span className="ff-poppins-semiBold fs-12">
                                              Rs.{subItem.service_price}
                                              <span className="ff-poppins-regular primary-text">
                                                {subItem.applied_card_status == 1 ? `(${subItem.benefit_lable})` : ''}
                                              </span>
                                            </span>
                                          </div>
                                          <div>
                                            <span className="ff-poppins-regular fs-12 text-uppercase">{subItem.service_category_name}</span>
                                          </div>
                                          <div className="moreDetails">
                                            <div className="accordion" id={`accordionExample1Sub${subIndex}`}>
                                              <div className="accordion-item border-0 bg-transparent">
                                                <h2 className="accordion-header" id={`headingSubOne${subIndex}`}>
                                                  <button className={`morebut accordion-button ff-poppins-regular fs-12 bg-transparent ${openDescriptions[subItem.service_id] ? '' : 'collapsed'}`} type="button"
                                                    aria-expanded={openDescriptions[subItem.service_id] ? 'true' : 'false'} aria-controls={`collapseSubOne${subIndex}`} onClick={() => toggleAccordions(subItem.service_id)}  >
                                                    More Details
                                                    <p className="mb-0">
                                                      {openDescriptions[subItem.service_id] ? (
                                                        <IoIosArrowUp className="pl-2" style={{ fontSize: 15 }} />
                                                      ) : (
                                                        <IoChevronDown className="pl-2" style={{ fontSize: 15 }} />
                                                      )}
                                                    </p>
                                                  </button>
                                                </h2>
                                                <div id={`collapseSubOne${subIndex}`} className={`accordion-collapse collapse ${openDescriptions[subItem.service_id] ? 'show' : ''}`}
                                                  aria-labelledby={`headingSubOne${subIndex}`} data-bs-parent={`#accordionExample1Sub${subIndex}`} >
                                                  <div className="accordion-body w-50 bg-white">
                                                    <div className="ff-poppins-regular fs-12">{subItem.description}</div>
                                                    <div className="enquireBtn primary-bg p-1 ms-2 text-white ff-poppins-regular fs-11 rounded-3 mt-2 d-flex justify-content-between align-items-center"
                                                      role="button" onClick={() => {
                                                        setEnquireServiceId(subItem.service_id);
                                                        setEnquireServiceDescription(subItem.general_category_name + ' - ' + subItem.service_name);
                                                        setServiceEnquiryModal(true); toggleServiceEnquiryOffCanvas();
                                                      }} >
                                                      Enquire
                                                      <img src={enquire_arrow_right} width={"15px"} height={"15px"} alt="Enquire" />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="imgBox position-relative">
                                            {subItem.image_status != 0 && subItem.image ? (
                                              <img src={subItem.image} width={"89px"} height={"82px"} />
                                            ) : (
                                              <img src={emptyImg} width={"89px"} height={"82px"} alt="Empty Image" />
                                            )}
                                            <button
                                              type="button"
                                              disabled={subItem.select_status == 0}
                                              className={`d-flex justify-content-center align-items-center ff-poppins-medium p-2 fs-12 border-0 position-absolute top-100 start-50 translate-middle ${subItem.isSelected ? 'primary-bg text-white' : 'bg-white primary-text'}`}
                                              style={{ bottom: subItem.image_status != 0 ? '-14px' : 'unset' }}
                                              onClick={() => send_serviceid_to_Api()}
                                            >
                                              {subItem.isSelected ? 'Selected' : 'Select'}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <hr />
                                    </div>
                                  )) : <>
                                    <div className="d-flex justify-content-center align-items-center secondary-text-1">
                                      No Services
                                    </div>
                                  </>}
                            </div>
                          ))

                        }
                      </div>

                    </div>
                  </div>

                </div>

              </div >
            </div>
          </>

          :
          <div className=" ReactLoading">
            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
          </div>
      }

    </>
  );
};

export default DoorstepService;
