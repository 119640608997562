import React from "react";
import { IoMdClose } from "react-icons/io";
import full_circle_tick from "../../Assets/Images/icons/full_circle_tick.png"

export default function Preorder_Buynow() {
    return (
        <>
            <div className="bg-white  " style={{height:"100%"}}>
                <div className="p-3" >
                    <div className="d-flex justify-content-between">
                        <h6>Upgrade to Pre Order</h6>
                        <IoMdClose />
                    </div>

                    <div className="p-3">
                        <div className="d-flex ">
                            <img src={full_circle_tick} height="18px" width="18px" />
                            <h6 className="fs-15 ff-poppins-regular ms-2">Enjoy added Benefits</h6>
                        </div>
                        <div className="d-flex mt-2 mb-2">
                            <img src={full_circle_tick} height="18px" width="18px" />
                            <h6 className="fs-15 ff-poppins-regular ms-2">Enjoy you Delivery with Pilot Pro</h6>
                        </div>

                        <div className="primary-box mt-3 rounded-2">
                            <div className="p-2">
                                <h5 className="p-0 m-0 ff-poppins-regular fs-12">6 Month</h5>
                                <h6 className="p-0 m-0 ff-poppins-semiBold fs-15 primary-text">₹ 4999</h6>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div>
                                <h6 className="ff-poppins-semiBold fs-16 ">Subscription Details</h6>
                            </div>
                            <div className="border-top border-secondary">
                                <div className="mt-3">
                                    <div className="d-flex justify-content-between">
                                        <h6 className="p-0 m-0 fs-14 ff-poppins-regular">Validity expires on</h6>
                                        <p className="p-0 m-0 fs-14 ff-poppins-semiBold">30 Jun 2025</p>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2 mb-2">
                                        <h6 className="p-0 m-0 fs-14 ff-poppins-regular">Plan Amont</h6>
                                        <p className="p-0 m-0 fs-14 ff-poppins-semiBold">₹4999</p>
                                    </div>
                                    <div className="border-top-Dashed border-Bottom-Dashed ">
                                        <div className="d-flex justify-content-between mt-2 mb-2">
                                            <h6 className="p-0 m-0 fs-14 ff-poppins-semiBold">Amount To Pay</h6>
                                            <p className="p-0 m-0 fs-14 ff-poppins-semiBold">₹4999</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="d-flex justify-content-center ms-1 p-2" style={{ position: "fixed", bottom: 0, width: "48%",backgroundColor:"white" }}>
                    <button className="p-2 rounded-3 fs-13 ff-poppins-regular primary-bg  text-white w-100 border border-0">Pay Now</button>
                </div>


            </div>
        </>
    )
}