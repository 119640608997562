import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Services/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database'; // Correct Firebase import
import { GoogleMap, Marker, useLoadScript, DirectionsRenderer, LoadScript, InfoWindow } from '@react-google-maps/api';
import rider_icon from '../../Assets/Images/icons/Ridersvg.jpg'
import store_icon from '../../Assets/Images/icons/Store.png'
import bike_icon from '../../Assets/Images/icons/Deliverer.png'
import user_location from "../../Assets/Images/icons/tabler_location-filled.png"
import { toast } from 'react-toastify';
import p_map_back from "../../Assets/Images/p_map_back.svg";
import p_map_location from "../../Assets/Images/p_map_location.svg";
import demo from '../../Assets/Images/default-placeholder.png'
import { LuPhoneCall } from "react-icons/lu";
import { TbMessage } from "react-icons/tb";

const mapContainerStyle = {
    width: "100%",
    height: "500px",
};

const initialCenter = { lat: 11.00675, lng: 76.92961 };

const DeliveryFlow = () => {
    const { order_id } = useParams(); // Destructure order_id from useParams
    const navigate = useNavigate();
    const [deliveryStaffId, setDeliveryStaffId] = useState(null);
    const [riderLocation, setRiderLocation] = useState(null);
    const [vendorLocation, setVendorLocation] = useState({});
    const [vendorDetails, setVendorDetails] = useState({});
    const [deliveryStaff, setDeliveryStaff] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [lastUpdate, setLastUpdate] = useState("");
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [riderHeadingPosition, setRiderHeadingPosition] = useState(0);
    const [deliveryStatus, setDeliveryStatus] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState();
    const [driverLocation, setDriverLocation] = useState({ lat: 11.0033297, lng: 76.9449266 });
    const [directions, setDirections] = useState(null);
    const [locaton, setLocaton] = useState('');
    const [locationPermission, setLocationPermission] = useState('');
    const [datas, setDatas] = useState("");
    const [error, setError] = useState("");
    const [eta, setEta] = useState("");

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC7cC8d67-AjIHnXizRFxTj1uFkOwnF2zo', // Replace with your API key
    });

    useEffect(() => {
        fetchOrder();
        // getLocatonPermission()
    }, []);

    useEffect(() => {
        if (directions) {
            setEta(directions.routes[0].legs[0].duration.text);
        }
    }, [directions]);

    const fetchOrder = async () => {
        try {
            const reqData = {
                order_id: order_id,
            };
            const response = await axiosInstance.post("user/products/get_order_delivery_details", reqData);
            if (response.status === 200) {
                const data = response.data;
                console.log('Order Data:', data);
                setDatas(data)
                setVendorDetails(data?.vendor_detail);
                setVendorLocation({
                    latitude: Number(data?.vendor_detail?.vendor_lat),
                    longitude: Number(data?.vendor_detail?.vendor_long),
                });
                setDeliveryStaff(data?.delivery_staff_detail);
                setDeliveryStatus(data?.delivery_status);
                setUserDetails(data?.user_detail);

                const [latitude, longitude] = data?.delevery_address?.latlng.split(",").map(Number);

                console.log('data?.delivery_staff_detail?.id', data?.delivery_staff_detail?.pilotId);

                setDeliveryAddress({
                    lat: Number(latitude),
                    lng: Number(longitude)
                });

                setDeliveryStaffId(data?.delivery_staff_detail?.pilotId); // Set delivery staff ID for Firebase tracking
            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        }
    };



    useEffect(() => {
        if (deliveryStaffId) {
            const db = getDatabase();
            const riderRef = ref(db, `/live_tracking/${deliveryStaffId}`);


            const unsubscribe = onValue(riderRef, (snapshot) => {
                const data = snapshot.val();
                // if (data && data.timestamp !== lastUpdate) { 
                setRiderLocation({ lat: Number(data.latitude), lng: Number(data.longitude) });
                setLastUpdate(data.timestamp);
                setRiderHeadingPosition(data.heading);
                // }
            });

            return () => unsubscribe(); // Cleanup listener on unmount
        }
    }, [deliveryStaffId, lastUpdate]);

    useEffect(() => {
        const fetchDirections = () => {
            if (riderLocation && deliveryAddress) {
                const directionsService = new window.google.maps.DirectionsService();
                directionsService.route(
                    {
                        origin: riderLocation,
                        destination: deliveryAddress,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    },
                    (result, status) => {
                        if (status == window.google.maps.DirectionsStatus.OK) {
                            console.log('result----------',);
                            
                            setDirections(result);
                        } else {
                            console.error("Error fetching directions:", status);
                        }
                    }
                );
            }
        };

        fetchDirections(); // Initial call

        const interval = setInterval(() => {
            fetchDirections(); // Refresh every 30 seconds
        }, 30000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [riderLocation, deliveryAddress]);

    if (!isLoaded) return <div>Loading Map...</div>;



    return (


        <div className="bg-white  vh-100">
            <div className="position-relative">

                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={initialCenter}
                    zoom={13}
                    options={{
                        mapTypeControl: false, // Hides the Map/Satellite button
                    }}
                >

                    {directions && (
                        <>
                            {/* <div className=''> */}
                            <Marker
                                position={directions.routes[0].legs[0].start_location}
                                icon={{
                                    url: rider_icon,
                                    scaledSize: new window.google.maps.Size(40, 50),
                                    // labelOrigin: new window.google.maps.Point(20, -10), // Adjust label position
                                }}
                                label={{
                                    text: datas.delevery_address.location_type,
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    backgroundColor:"white"
                                }}
                            />

                            {/* <div>
                                    Home
                                </div>
                            </div> */}
                            {/*  */}
                            <Marker
                                position={directions.routes[0].legs[0].end_location}
                                icon={{
                                    url: user_location,
                                    scaledSize: new window.google.maps.Size(20, 20),
                                }}
                            />

                            <DirectionsRenderer
                                directions={directions}
                                options={{
                                    polylineOptions: {
                                        strokeColor: "#4E26A3",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 5,
                                    },
                                    suppressMarkers: true,
                                }}
                            />
                        </>
                    )}
                </GoogleMap>
                <button
                    className="mapBackBtn position-absolute p-3 bg-white rounded-circle border-0"
                    onClick={() => {
                        navigate(-1)

                    }} >
                    <img src={p_map_back} width={"24px"} height={"24px"} />
                </button>

                <button
                    className="mapLocationBtn position-absolute p-2 bg-white border-0 rounded-1"
                    id="locationBtn">
                    <img src={p_map_location} width={"24px"} height={"24px"} />
                </button>



                <div className="locationDetails" style={{
                    padding: 20,
                    position: 'absolute',
                    bottom: -250,
                    backgroundColor: '#fff',
                    width: '100%',
                }}>
                    <div className='map-modal-top d-flex justify-content-between'>
                        <div className='OrderStatus-right'>
                            <div className='ff-poppins-semiBold fs-20 primary-text'>
                                Order Ready
                            </div>
                            <div className='OrderStatus-subtitle fs-14'>
                                Your order is ready for pickup soon!
                            </div>
                        </div>
                        <div className='OrderStatus-left'>
                            <span className=''>
                                {directions && (
                                    <div className="eta-display orderStatus-time fs-14 primary-text">
                                        {directions.routes[0].legs[0].duration.text}
                                    </div>
                                )}
                            </span>
                            {/* <spa className='orderStatus-min fs-16'>
                                Min
                            </spa> */}
                        </div>
                    </div>
                    <div className='map-modal-mid'>
                        <div className='ff-poppins-semiBold fs-15'>Delivery Staff</div>
                        <div className='delivery-person-container'>
                            <div className='deliveryPerson-detail-left'>
                                <div className='delivery-person-image'>
                                    <img src={deliveryStaff?.pilot_image} alt='' height={'60px'} width={'60px'} style={{ borderRadius: '10px' }} />
                                </div>
                                <div className='delivery-person-details'>
                                    <div className='delivery-person-name fs-16 ff-poppins-semiBold'>{deliveryStaff?.pilot_name}</div>
                                    <div className='delivery-staff-badge fs-12 ff-poppins-regular'>{deliveryStaff?.delivery_staff_type} </div>
                                </div>
                            </div>
                            <div className='delivery-person-contact'>
                                <div className='delivery-person-call' onClick={() => navigate(`/chat/booking/${order_id}`, { state: { chatFlow: 'order' } })} >  <TbMessage size={24} /></div>
                                <div className='delivery-person-call' onClick={() => window.location.href = 'tel:' + deliveryStaff?.phone_number} ><LuPhoneCall size={22} /></div>
                            </div>
                        </div>

                    </div>
                    <button className='returnToTicket ff-poppins-semiBold mt-4 mb-2' onClick={() => navigate(-1)}>
                        Return to ticket
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeliveryFlow;