import React from "react";

import { useState, useEffect } from 'react';

export default function Get_currentLocation() {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [matchedLocations, setMatchedLocations] = useState([]);
    const [displayLocation, setDisplayLocation] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentlatLong, setCurrentlatLong] = useState(null)
    // Your saved locations
    const [locationsList] = useState([
        {
            "id": "26",
            "user_id": "24UAA00002",
            "latlng": "11.014254,76.971094",
            "location_type": "Work",
            "locality": "Coimbatore",
            "location_type": "Work",
            "location_type_id": "2",
            "user_id": "24UAA00002",
            "address": "33/A, 33/A, Cauvery St, Siddhapudur, Sengadu, Illango Nagar, Pappanaickenpalayam, Coimbatore, Tamil Nadu 641037, India"

        },
        {
            "id": "16",
            "user_id": "24UAA00002",
            "latlng": "11.3198239,78.1788212",
            "location_type": "Home",
            "locality": "Namakkal",
            "location_type_id": "2",
            "user_id": "24UAA00002",
            "address": "6/1-A minam palli anna nager sentha mangal road namakkal,Tamil nadu 630019 , India"
        }
        // ... other locations
    ]);
    // 11.3149202,78.1859471
    // useEffect(() => {
    //     const getLocation = () => {
    //         if ("geolocation" in navigator) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     const { latitude, longitude } = position.coords;
    //                     const currentLoc = {
    //                         lat: latitude,
    //                         lng: longitude,
    //                         accuracy: position.coords.accuracy
    //                     };
    //                     getAddressFromCoords(latitude, longitude)
    //                     setCurrentlatLong(currentLoc);

    //                     const matches = findMatchingLocations(currentLoc, locationsList, 100);

    //                     console.log("matches-------------------------------+++++++++++++++++++++++",matches);

    //                     setDisplayLocation(
    //                         matches ? { ...matches[0], type: 'matched' } : { currentLocation, type: 'fallback' }
    //                     );
    //                     console.log("---------------", displayLocation);

    //                     setIsLoading(false);
    //                 },
    //                 (err) => { 
    //                     setError(err.message);
    //                     setDisplayLocation({ ...locationsList[0], type: 'fallback' });
    //                     setIsLoading(false);
    //                 },
    //                 { enableHighAccuracy: true, timeout: 10000 }
    //             );
    //         } else {
    //             // If geolocation not supported, fall back to first saved location
    //             setError("Geolocation not supported");
    //             setDisplayLocation({ ...locationsList[0], type: 'fallback' });
    //             setIsLoading(false);
    //         }
    //     };

    //     getLocation();
    // }, [locationsList]);

    useEffect(() => {
        const getLocation = () => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        const currentLoc = {
                            lat: latitude,
                            lng: longitude,
                            accuracy: position.coords.accuracy
                        };
                        setCurrentlatLong(currentLoc);
    
                        // Get address for current location
                        const addressData = await getAddressFromCoords(latitude, longitude);
                        
                        // Find matching saved locations
                        const matches = findMatchingLocations(currentLoc, locationsList, 100);
    
                        if (matches.length > 0) {
                            // If matches found, show the closest one
                            setDisplayLocation({
                                ...matches[0],
                                type: 'matched',
                                distance: matches[0].distance
                            });
                        } else {
                            // If no matches, show current location with reverse geocoded address
                            setDisplayLocation({
                                lat: latitude,
                                lng: longitude,
                                address: addressData.display_name || "Address not available",
                                type: 'current',
                                accuracy: position.coords.accuracy
                            });
                        }
                        setIsLoading(false);
                    },
                    (err) => { 
                        setError(err.message);
                        setDisplayLocation({ 
                            ...locationsList[0], 
                            type: 'fallback',
                            reason: 'geolocation_error'
                        });
                        setIsLoading(false);
                    },
                    { enableHighAccuracy: true, timeout: 10000 }
                );
            } else {
                setError("Geolocation not supported");
                setDisplayLocation({ 
                    ...locationsList[0], 
                    type: 'fallback',
                    reason: 'geolocation_unsupported'
                });
                setIsLoading(false);
            }
        };
    
        getLocation();
    }, [locationsList]);

    

    const parseLatLng = (latlngString) => {
        const [lat, lng] = latlngString.split(',').map(Number);
        return { lat, lng };
    };

    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const R = 6371e3; // Earth radius in meters
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lng2 - lng1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        // console.log("R * c R * c R * c R * c R * c R * c", R * c);

        return R * c;
    };

    const findMatchingLocations = (currentLoc, locations, threshold) => {
        return locations
            .map(loc => {
                const savedLoc = parseLatLng(loc.latlng);

                const distance = calculateDistance(
                    currentLoc.lat, currentLoc.lng,
                    savedLoc.lat, savedLoc.lng
                );
                return { ...loc, distance };
            })
            .filter(loc => loc.distance <= threshold)
            .sort((a, b) => a.distance - b.distance);
    };

    const getAddressFromCoords = async (lat, lng) => {
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
            );
            const data = await response.json();
            setCurrentlatLong({ lat: lat, long: lng })
            console.log("data-----------------", data);
            setCurrentLocation(data)
            return data.display_name || "Address not available";
        } catch (err) {
            console.error("Reverse geocoding error:", err);
            return "Could not retrieve address";
        }
    };

 
    return (
        <div className="location-container">
            <h2>Location Information</h2>

            {isLoading ? (
                <p>Loading location information...</p>
            ) : displayLocation ? (
                <div className="display-location">
                    {displayLocation.type == 'matched' ? (
                        <>
                            <h3>You're near your {displayLocation.location_type}</h3>
                            <p><strong>Address:</strong> {displayLocation.address}</p>

                        </>
                    ) : (
                        <>
                            <h3>Current Location</h3>
                            <p>{currentLocation.display_name}</p>

                        </>
                    )}


                </div>
            ) : (
                <p>No location information available</p>
            )}

            {/* <div className="fallback-location">
                <h2>Couldn't get current location</h2>
                {error && <p className="error">Error: {error}</p>}

                <div className="saved-location">
                    <h3>Showing default location instead:</h3>
                    <p>Type: {savedLocations[0].location_type}</p>
                    <p>Address: {savedLocations[0].address}</p>
                    <p>Coordinates: {savedLocations[0].latlng}</p>
                </div>
            </div> */}

        </div>
    );
}