import { useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { getChatList } from "../../Services/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MembershipCard from "../../Components/MembershipCard/MembershipCard";
import axiosInstance from "../../Services/axios";
import { BsThreeDots } from "react-icons/bs";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
const UserMembership = () => {
  const [myMembership, setMyMembership] = useState([]);
  const [myMembershipVendor, setMyMembershipVendor] = useState([]);
  const [selectedCard, setSelectedCard] = useState({
    vendorID: null,
    membershipID: null,
    PurchaseID: null,
  });
  const navigate = useNavigate();
  const getMyMembership = () => {
    try {
      axiosInstance
        .post("/user/membership/my_membership_cards")
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            console.log("total memberhsip detail", data);
            const membershipDetails = data.user_memberships_details.map(
              (membership) => membership
            );
            const membershipVendorDetails = data.user_memberships_details.map(
              (vendors) => vendors.vendor_detail
            );
            setMyMembership(membershipDetails);
            console.log("totsl frf", membershipDetails);
            setMyMembershipVendor(membershipVendorDetails);
            console.log("Its from the component", membershipDetails);
            console.log("Its from the component vendor details", membershipVendorDetails);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            alert("No membership cards availbale");
          }
        });
    } catch (error) {
      alert("Tryagin Later");
    }
  };
  useEffect(() => {
    getMyMembership();
  }, []);

  useEffect(() => { }, [myMembershipVendor]);
  const handleCardClick = (vendorID, membershipID, PurchaseID) => {
    setSelectedCard({ vendorID, membershipID, PurchaseID });
  };


  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            My Membership
          </div>
        </div>
      </div>
      <div className="pt-2 messengerList " >
        <div className="scroller-style-y">
          {myMembership.length > 0 ? (
            myMembership.map((membership, index) => (
              <div key={index}>
                <div
                  className="MembershipDetailButton"
                  onClick={() => {
                    if (new Date(membership?.expiry_date) > new Date()) {
                      navigate("/MembershipBooking", {
                        state: {
                          vendorId: membership.vendor_id, MembershipStatus:
                            membership.membership_detail.category_status, membership_ID: membership.membership_purchase_id,
                        },
                      })
                    }
                  }}  >
                  {membership.membership_detail && myMembershipVendor[index] ? (
                    <MembershipCard
                      buttonName="Subscription"
                      threeDot={
                        <BsThreeDots
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasBottom"
                          aria-controls="offcanvasBottom"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCardClick(
                              membership?.vendor_detail.vendor_id,
                              membership?.membership_detail.membership_id,
                              membership?.membership_purchase_id);
                          }} />
                      }
                      Status={membership?.expiry_date}
                      data={{
                        membershipDetails: membership.membership_detail,
                        VendorDetails: myMembershipVendor[index],
                      }}
                      // expiryDate={}
                      cardColor={membership?.membership_detail?.card_color_status ?
                        membership?.membership_detail.card_color :
                        membership?.membership_detail.card_pattern_image_url
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))
          ) :
            <>
              <div>
                <Lottie animationData={empotyLottie} />
              </div>
              <p className='text-dark text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Membership Available</p>
            </>
          }
        </div>

        <div
          class="offcanvas offcanvas-bottom mainModal"
          style={{
            position: "absolute !important",
            right: "initial", left: "initial", padding: '2%'
          }}
          tabindex="-1"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
        >
          <div className="">
            <div className="classExtentdMemberShip">
              <div></div>
              <div>
                <p className="m-0 ff-poppins-semiBold mb-3">Options</p>
              </div>
              <div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className="position-relative"></div>
            <p
              className="m-0 ff-poppins-regular"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  selectedCard.vendorID &&
                  selectedCard.membershipID &&
                  selectedCard.PurchaseID
                ) {
                  navigate("/BuyNow", {
                    state: {
                      vendorId: selectedCard.vendorID,
                      membership_ID: selectedCard.membershipID,
                      PurchaseID: selectedCard.PurchaseID,
                      flowParent: "extend",
                    },
                  });
                }
              }}
            >
              Extend Plan
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserMembership;
