import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import { getBulletinDetails } from '../../Services/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
const BulletinBoard = () => {

    const navigate = useNavigate();
    const [bulletinDetails, setBulletinDetails] = useState([]);

    useEffect(() => {
        getBulletinDetails().then((res) => {
            setBulletinDetails(res.data?.bulletin_details);
        });
    }, []);
    console.log('bulletinDetails', bulletinDetails);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>User Bulletin Board</div>
                </div>
            </div>

            <div className='bulletinBoardPage scroller-style-y'>
                <div className='ff-poppins-semiBold fs-20'>Bulletin</div>
                <div className='ff-poppins-light fs-20'>board</div>

                {bulletinDetails.map((item, index) => {
                    const formattedDate = moment(item.broadcastdate, "YYYY-MM-DD hh:mm A").format("DD-MM-YYYY hh:mm A");

                    return (
                        <div key={index} className='bg-white mt-4 mb-4 p-4 rounded-4'>
                            <div className='ff-poppins-light fs-10'>
                                {formattedDate}
                            </div>
                            <div className='ff-poppins-semiBold fs-15'>
                                {item.broadcast_title}
                            </div>
                            <div className="ff-poppins-regular fs-13">
                                {item.broadcast_msg?.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </div>
                        </div>
                    );
                })}

            </div>
        </>
    )
}

export default BulletinBoard