import { useState } from 'react';
import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import service_alert from '../../Assets/Images/icons/service_alert.png';
import service_missed from '../../Assets/Images/icons/service_missed.png';
import reschedule from '../../Assets/Images/icons/reschedule.png';
import { getBookingList, getChatList } from '../../Services/api';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import p_appointment_right_arrow from "../../Assets/Images/p_appointment_right_arrow.svg";
import p_appointment_inStore from "../../Assets/Images/p_appointment_inStore.svg";
import axiosInstance from '../../Services/axios';
import inStore from "../../Assets/Images/In-Store.png";
import { FaStar } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import AppointmentProductCard from '../../Components/appointment_card/AppointmentProductCard';
import AppointmentServiceCard from '../../Components/appointment_card/AppointmentServiceCard';
import ReactLoading from "react-loading";
const BookingHistory = () => {

    const navigate = useNavigate();
    const [bookingDetails, setBookingDetails] = useState("");
    let [allAppointmentList, setAllAppointmentList] = useState([]);
    const [activeTab, setActiveTab] = useState("");
    const [allBookings, setAllBookings] = useState('')
    const [upcomingBookings, setUpcomingBookings] = useState([])
    const [completedBookings, setCompletedBookings] = useState([])
    const [expiredBookings, setExpiredBookings] = useState('')
    const [canelledBookings, setCancelledBookings] = useState('')
    const [bookingdetails, setbookingdetails] = useState([]);
    const [productRawData, setProductRawData] = useState([]);
    const [completedProduct, setCompletedProduct] = useState([])
    const [canelledProduct, setCancelledProduct] = useState([])
    const [allProduct, setAllProduct] = useState([])
    const [data, setData] = useState('')
    const [pageLoaded, setPageLoaded] = useState(false);
    const valueParam = useParams();
    const locations = useLocation();
    const { vendor_type } = locations.state || {};

    console.log("datadatadatadata", data);


    useEffect(() => {
        getBookingDetails()
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const getBookingDetails = async () => {
        const reqData = {
            "vendor_id": valueParam.vendor_id
        };
        axiosInstance.post('user/my_merchant_history', reqData)
            .then((res) => {
                if (res.status == 200) {
                    setbookingdetails(res?.data?.history_details[0]?.history);
                    setProductRawData(res?.data?.history_details[1]?.history)

                    let data = res.data

                    if (res.data?.vendor_details?.vendor_service_status === '1' && res.data?.vendor_details?.vendor_product_status === '1') {
                        setActiveTab("services");
                    }
                    else if (res.data?.vendor_details?.vendor_service_status === '1') {
                        setActiveTab("services");
                        console.log("vendor_service_status", "services");
                    } else if (res.data?.vendor_details?.vendor_product_status === '1') {
                        setActiveTab("products");
                        console.log("vendor_product_status", "products");
                    }


                    setData(data)
                    let allProducts = []
                    let completedProducts = []
                    let canelledProducts = []

                    data.history_details.map((tabs) => {
                        if (tabs.tab == 'product') {
                            tabs.history.map((product) => {
                                if (product.order_status == "1") {
                                    allProducts.push(product)
                                }
                                else if (product.order_status == '2') {
                                    completedProducts.push(product)
                                }
                                else if (product.order_status == '3') {
                                    canelledProducts.push(product)
                                }
                            })
                        }
                    })
                    setCompletedProduct(completedProducts)
                    setCancelledProduct(canelledProducts)
                    setAllProduct([...allProducts, ...completedProducts, ...canelledProducts])
                    let allBookings = []
                    let upcomingBooking = []
                    let completedBookings = []
                    let expiredBookings = []
                    let cancelBookings = []

                    data.history_details.map((tabs) => {
                        if (tabs.tab == 'service') {
                            tabs.history.map((book) => {
                                if (book.book_status == '1') {
                                    allBookings.push(book)
                                    upcomingBooking.push(book)
                                } else if (book.book_status == '2') {
                                    allBookings.push(book)
                                    completedBookings.push(book)
                                }
                                else if (book.book_status == '6') {
                                    allBookings.push(book)
                                    expiredBookings.push(book)
                                }
                                else if (book.book_status == '4') {
                                    allBookings.push(book)
                                } else if (book.book_status == '3') {
                                    allBookings.push(book)
                                    cancelBookings.push(book)
                                }
                            })
                        }
                    })
                    setAllBookings(allBookings)
                    setCompletedBookings(completedBookings)
                    setUpcomingBookings(upcomingBooking)
                    setExpiredBookings(expiredBookings)
                    setCancelledBookings(cancelBookings)
                    setPageLoaded(true)
                }
            })

    };
    useEffect(() => {
        console.log("Updated activeTab:", activeTab);
    }, [activeTab]);

    // console.log("history screen vendor_type", vendor_type);
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Booking History</div>
                </div>
            </div>
            <div className='mx-3 px-2 my-3 py-2'>
                <div className='ff-poppins-semiBold fs-20'>Booking</div>
                <div className='ff-poppins-regular fs-20'>History</div>
            </div>
            {
                pageLoaded ?

                    <div>

                        {
                            data?.vendor_details?.vendor_service_status == "1" && data?.vendor_details?.vendor_product_status == "1" ?
                                <div className="d-flex justify-content-center history_page">
                                    <ul className="nav nav-pills py-4" id="pills-tab" role="tablist">
                                        {/* Services Tab */}
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === "services" ? "active" : ""} rounded-start serviceBtnBM ff-poppins-regular`}
                                                id="pills-services-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-services"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-services"
                                                aria-selected={activeTab === "services" ? "true" : "false"}
                                                onClick={() => handleTabClick("services")}
                                            >
                                                Services
                                            </button>
                                        </li>

                                        {/* Products Tab */}
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link ${activeTab === "products" ? "active" : ""} rounded-end productBtnBM ff-poppins-regular`}
                                                id="pills-products-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-products"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-products"
                                                aria-selected={activeTab === "products" ? "true" : "false"}
                                                onClick={() => handleTabClick("products")}
                                            >
                                                Products
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                : ""
                        }



                        {
                            data?.vendor_details?.vendor_service_status == "1" ?


                                <div className="tab-content p-1" id="pills-tabContent">

                                    <div className={`tab-pane ${activeTab === "services" ? "show active" : "fade"} p-1`} id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab" tabIndex="0" >
                                        <ul
                                            className="appointmentFilter scroller-style-x nav nav-pills pt-2 pb-3 ff-poppins-regular"
                                            id="pills-services-tab"
                                            role="tablist"
                                        >
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="pills-all-services-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-all-services"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-all-services"
                                                    aria-selected="true"
                                                >
                                                    All
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-upcoming-services-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-upcoming-services"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-upcoming-services"
                                                    aria-selected="false"
                                                >
                                                    Upcoming
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-completed-services-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-completed-services"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-completed-services"
                                                    aria-selected="false"
                                                >
                                                    Completed
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="pills-cancelled-services-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#pills-cancelled-services"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="pills-cancelled-services"
                                                    aria-selected="false"
                                                >
                                                    Cancelled
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link"
                                                    id="pills-expired-services-tab" data-bs-toggle="pill" data-bs-target="#pills-expired-services"
                                                    type="button" role="tab" aria-controls="pills-expired-services" aria-selected="false" >
                                                    Expired
                                                </button>
                                            </li>
                                        </ul>

                                        <div
                                            className={`tab-pane fade ${activeTab === "services" ? "show active" : ""} `}
                                            id="pills-all-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-all-services-tab"
                                        >
                                            <div className='scroller-style-y pb-5'>
                                                <div style={{ marginBottom: 160 }} >
                                                    {
                                                        allBookings.length > 0 ?
                                                            allBookings.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No service appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="pills-upcoming-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-upcoming-services-tab"
                                        >
                                            <div className='scroller-style-y  '>
                                                <div style={{ marginBottom: 160 }} >
                                                    {
                                                        upcomingBookings.length > 0 ?
                                                            upcomingBookings.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                Today No service appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="pills-completed-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-completed-services-tab"
                                        >
                                             <div className='scroller-style-y  '>
                                             <div style={{ marginBottom: 160 }} >
                                                    {
                                                        completedBookings.length > 0 ?
                                                            completedBookings.map(item => {
                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                Today No service appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="pills-cancelled-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-cancelled-services-tab"
                                        >
                                            <div className='scroller-style-y  '>
                                            <div style={{ marginBottom: 160 }} >
                                                    {
                                                        canelledBookings.length > 0 ?
                                                            canelledBookings.map(item => {

                                                                return (
                                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No unconfirmed service appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade w-100"
                                            id="pills-expired-services"
                                            role="tabpanel"
                                            aria-labelledby="pills-expired-services-tab"
                                        >
                                             <div className='scroller-style-y  '>
                                             <div style={{ marginBottom: 160 }} >
                                                    {
                                                        expiredBookings.length > 0 ?
                                                            expiredBookings.map(item => {
                                                                return (
                                                                    <div >
                                                                        <AppointmentServiceCard data={item} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                                No expired  appointments
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div
                                        className="tab-pane fade"
                                        id="pills-missed-services"
                                        role="tabpanel"
                                        aria-labelledby="pills-missed-services-tab"
                                    >
                                        <div style={{ marginBottom: 80 }} >
                                            {
                                                bookingDetails?.missed_appointment_status
                                                    ?

                                                    bookingDetails?.missed_appointment_details.map(item => {
                                                        return (
                                                            <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                                <AppointmentServiceCard data={item} />
                                                            </div>
                                                        )
                                                    })

                                                    :
                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                        No missed service appointments
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div >
                                : ""
                        }
                        {/* For products  */}



                        {data?.vendor_details?.vendor_product_status === "1" && (
                            <div className={`tab-pane ${activeTab === "products" ? "show" : "fade"} p-1`}
                                id="pills-products"
                                role="tabpanel"
                                aria-labelledby="pills-products-tab"
                                tabIndex="0"
                            >
                                <ul className="nav nav-pills mb-3 ff-poppins-regular" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="pills-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-all"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-all"
                                            aria-selected="true"
                                        >
                                            All
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-completed-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-completed"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-completed"
                                            aria-selected="false"
                                        >
                                            Completed
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-cancelled-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-cancelled"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-cancelled"
                                            aria-selected="false"
                                        >
                                            Cancelled
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content p-1" id="pills-tabContent">
                                    {/* All Products Tab */}
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-all"
                                        role="tabpanel"
                                        aria-labelledby="pills-all-tab"
                                        tabIndex="0"
                                    >
                                         <div className='scroller-style-y  '>
                                         <div style={{ marginBottom: 160 }} >
                                                {allProduct.length > 0 ? (
                                                    allProduct.map((item, subindex) => (
                                                        <div
                                                            key={subindex}
                                                            className="secondary-text"
                                                            onClick={() => navigate(`/ProductPass/${item.order_id}`) }
                                                        >
                                                            <AppointmentProductCard data={item} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                        No Products
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Completed Products Tab */}
                                    <div
                                        className="tab-pane fade"
                                        id="pills-completed"
                                        role="tabpanel"
                                        aria-labelledby="pills-completed-tab"
                                        tabIndex="0"
                                    >
                                         <div className='scroller-style-y  '>
                                         <div style={{ marginBottom: 160 }} >
                                                {completedProduct.length > 0 ? (
                                                    completedProduct.map((item, subindex) => (
                                                        <div key={subindex}>
                                                            <AppointmentProductCard data={item} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                        No Completed Products
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Cancelled Products Tab */}
                                    <div
                                        className="tab-pane fade"
                                        id="pills-cancelled"
                                        role="tabpanel"
                                        aria-labelledby="pills-cancelled-tab"
                                        tabIndex="0"
                                    >
                                        <div className='scroller-style-y  '>
                                        <div style={{ marginBottom: 160 }} >
                                                {canelledProduct.length > 0 ? (
                                                    canelledProduct.map((item, subindex) => (
                                                        <div key={subindex}>
                                                            <AppointmentProductCard data={item} />
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center ff-poppins-regular fs-14 secondary-text">
                                                        No Cancelled Products
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}





                    </div >
                    :

                    <div className=" ReactLoading">
                        <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                    </div>

            }
        </>
    )
}

export default BookingHistory;