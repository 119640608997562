import { Link, useNavigate } from "react-router-dom";

import p_navbars from "../../Assets/Images/p_navbars.svg";
import p_nav_search from "../../Assets/Images/p_nav_search.svg";
import p_booking_appointments from "../../Assets/Images/p_booking_appointments.svg";
import p_booking_messenger from "../../Assets/Images/p_booking_messenger.svg";
import p_bookings_resech from "../../Assets/Images/p_bookings_resech.svg";
import p_booking_loyalty from "../../Assets/Images/p_booking_loyalty.svg";
import p_bookings_makeItEasy from "../../Assets/Images/p_bookings_makeItEasy.svg";
import p_bookings_connect from "../../Assets/Images/p_bookings_connect.svg";
import p_nav_location from "../../Assets/Images/p_nav_location.svg";
import p_nav_next_outline from "../../Assets/Images/p_nav_next_outline.svg";
import p_nav_book_outline from "../../Assets/Images/p_nav_book_outline.svg";
import p_nav_message_outline from "../../Assets/Images/p_nav_message_outline.svg";
import p_nav_notifications_outline from "../../Assets/Images/p_nav_notifications_outline.svg";
import p_nav_about_outline from "../../Assets/Images/p_nav_about_outline.svg";
import p_nav_setting_outline from "../../Assets/Images/p_nav_setting_outline.svg";
import p_appointment_right_arrow from "../../Assets/Images/p_appointment_right_arrow.svg";
import p_appointment_inStore from "../../Assets/Images/p_appointment_inStore.svg";
import inStore from "../../Assets/Images/In-Store.png";
import { FaExclamation } from "react-icons/fa";
import FooterButton from '../../Assets/Images/FooterButton.png';
import pingleLogoWhite from '../../Assets/Images/Pingle-Logo_White-62.png';

import { FaDoorOpen } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { getBookingList, getMyProfileDetails, notificationCounts } from "../../Services/api";
import axiosInstance from "../../Services/axios";
// import ProductCard from "../../Components/appointment_card/ProductCard";
import AppointmentCard from "../../Components/appointment_card/AppointmentCard";
import CameraScan from "../../Components/Camera/CameraScan";
import AppointmentProductCard from "../../Components/appointment_card/AppointmentProductCard";
import AppointmentServiceCard from "../../Components/appointment_card/AppointmentServiceCard";
import dayjs from 'dayjs';
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";
import axios from "axios";
const BookingsManager = () => {
    const yetTopayRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    const [value, setValue] = useState("");
    const [profile, setProfileData] = useState("");
    const [bookingDetails, setBookingDetails] = useState("");
    const [notificationcount, setNotificationcount] = useState("");
    const [activeTab, setActiveTab] = useState("");
    const token = localStorage.getItem("token");
    const [userDetail, setUserDetail] = useState("");
    const [userOrders, setUserOrders] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [unconfirmed, setUnconfirmed] = useState([])
    const [todayproduct, setTodayproduct] = useState([])
    const [upcomingproduct, setUpcomingproduct] = useState([])
    const [missedService, setMissedSeivice] = useState([])
    const [servicetabcategory, setServiceTabcategory] = useState('')
    const [producttabcategory, setProductTabcategory] = useState('')

    const [alltabcategory, setAlltabcategory] = useState('')
    const [allTab_product_service, setAllTab_product_service] = useState([]);


    const navigate = useNavigate();
    let [allAppointmentList, setAllAppointmentList] = useState([]);

    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

    const handlePaySuccessClose = () => setShowPaymentSuccessModal(false);
    const handlePaySuccessShow = () => setShowPaymentSuccessModal(true);
    const [appointmentDetails, setAppointmentDetails] = useState([]);
    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        getBookingList().then((res) => {

            setBookingDetails(res.data);
            setMissedSeivice(res.data?.missed_appointment_details)
            const combinedData = [
                res.data?.unconfirmed_appointment_details,
                res.data?.active_appointment_details,
                res.data?.upcoming_appointment_details,
                res.data?.missed_appointment_details,
            ];
            setAppointmentDetails(combinedData);
            setAllAppointmentList(
                allAppointmentList.concat(
                    res.data?.unconfirmed_appointment_details,
                    res.data?.active_appointment_details,
                    res.data?.upcoming_appointment_details,
                    res.data?.missed_appointment_details,
                    // res.data?.unconfirmed_appointment_details,
                    // res.data?.all_cancel_book_details

                )

            );
        });
        getMyProfileDetails().then((res) => {
            setProfileData(res.data?.user_details);
        });
        notificationCounts().then((res) => {

            setNotificationcount(res.data);
        });
        getIndex();
        sessionStorage.removeItem('dashboardtab');
        localStorage.removeItem('vendortype');
        sessionStorage.removeItem('shouldOpenModal');
    }, [value]);


    function removeExtraFadeFromOffcanvas() {
        const myOffcanvas = document.getElementById('offcanvasTop');
        myOffcanvas.addEventListener('shown.bs.offcanvas', event => {
            let fade = document.getElementsByClassName('offcanvas-backdrop fade show')
            for (let i = 0; i < fade.length; i++) {
                while (fade.length > 1) {
                    fade[i].remove()
                }
            }
        });
    }

    const getIndex = () => {
        try {
            axiosInstance.post("/user/products/index").then((res) => {
                let data = res.data;
                setUserDetail(data.user_detail);


                let userOrders = data.user_orders
                userOrders?.map((order) => {
                    order.type = 'ORDER';
                });;


                // setUserOrders(userOrders);
                let unconfirmedOrders = data?.unconfirmed_orders
                data?.unconfirmed_orders?.map((order) => {
                    order.type = 'CART';
                });
                setTicket(data.user_orders.order_products);
                setUnconfirmed(unconfirmedOrders)
                let allOrders = unconfirmedOrders?.concat(userOrders);
                setUserOrders(allOrders);
                const todayProduct = [];
                const upcomingProduct = [];

                data.user_orders.forEach((tab) => {
                    if (tab.delivery_status === "2" || tab.delivery_status === "4") {
                        todayProduct.push(tab);
                    } else if (tab.delivery_status === "1") {
                        upcomingProduct.push(tab);
                    }
                });
                setTodayproduct(todayProduct);
                setUpcomingproduct(upcomingProduct);


            });
            // handelTabUpdate()
        } catch (error) {
            alert("Try Again later");
        }
    };





    const openYettopayModal = (item) => {
        yetTopayRef.current.classList.add("show");
        yetTopayRef.current.style.display = "block";

        handelYettoPay(item)
    };
    const closeYettopayModal = () => {
        yetTopayRef.current.classList.remove("show");
    };
    // Updated handleTabChange function
    const handleTabChange = (type, value) => {
        let tabDetail = JSON.parse(sessionStorage.getItem('tabDetail')) || {
            ActiveTab: activeTab,
            Servicecategory: servicetabcategory,
            Productcategory: producttabcategory,
            Alltabcategory: alltabcategory
        };

        if (type === 'ActiveTab') {
            setActiveTab(value);
            tabDetail.ActiveTab = value;
        } else if (type === 'Servicecategory') {
            setServiceTabcategory(value);
            tabDetail.Servicecategory = value;
        } else if (type === 'Productcategory') {
            setProductTabcategory(value);
            tabDetail.Productcategory = value;
        } else if (type === 'Alltabcategory') {
            setAlltabcategory(value);
            tabDetail.Alltabcategory = value;
        }

        sessionStorage.setItem('tabDetail', JSON.stringify(tabDetail));
    };

    // Updated useEffect to load initial tab state from session storage
    useEffect(() => {
        let localActivetab = JSON.parse(sessionStorage.getItem("tabDetail"));
        if (localActivetab) {
            setProductTabcategory(localActivetab.Productcategory || "pills-all-tab");
            setServiceTabcategory(localActivetab.Servicecategory || "pills-all-services");
            setAlltabcategory(localActivetab.Alltabcategory || "pills-all-tab");
            setActiveTab(localActivetab.ActiveTab || "Alltabcategory");
        } else {
            setProductTabcategory("pills-all-tab");
            setServiceTabcategory("pills-all-services");
            setAlltabcategory("pills-all-tab")
            setActiveTab("Alltabcategory");
        }
    }, []); // Only run this effect on component mount

    const handelYettoPay = (item) => {
        let serviceDetail = {
            serviceType: item.book_service_type,
            vendorId: item.book_vendor_id,
            cart_app_id: item.cart_app_id
        }
        localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
        navigate(`/review-and-pay/${item.book_vendor_id}`)

    }

    const [allTabb_today_list, setAllTabb_today_list] = useState([])
    const [allTabb_upcoming_list, setAllTabb_upcoming_list] = useState([])
    const [allTabb_unconfirmed_list, setAllTabb_unconfirmed_list] = useState([])
    const [allTabb_missed_list, setAllTabb_missed_list] = useState([])
    useEffect(() => {
        let combinedData = [...userOrders ,...allAppointmentList];
        setAllTab_product_service(combinedData);

        let NewallTab_today_list = [
            ...(bookingDetails.active_appointment_details ? bookingDetails.active_appointment_details : []),
            ...todayproduct
        ];
        setAllTabb_today_list(NewallTab_today_list)

        let NewallTab_upcoming_list = [
            ...(bookingDetails?.upcoming_appointment_details ? bookingDetails?.upcoming_appointment_details : []),
            ...upcomingproduct
        ];
        setAllTabb_upcoming_list(NewallTab_upcoming_list)

        let NewallTab_unconfirmed_list = [
            ...(bookingDetails?.unconfirmed_appointment_details ? bookingDetails?.unconfirmed_appointment_details : []),
            ...unconfirmed
        ];
        setAllTabb_unconfirmed_list(NewallTab_unconfirmed_list)
    }, [userOrders, bookingDetails]);

    const recentOrders = (items) => {
        if (!Array.isArray(items)) {
            return [];
        }

        const sortedItems = items
            .map(item => {
                const date = item.order_date_string ? dayjs(item.order_date_string, "ddd Do MMM YYYY, hh:mm A") : item.book_date
                    ? dayjs(item.book_date, "MMMM, DD YYYY")
                    : null;

                return date ? { ...item, date } : null;
            })
            .filter(Boolean)
            .sort((a, b) => b.date - a.date);

        // Update state with the sorted items
        // setAllTab_product_service(sortedItems);

        return sortedItems;
    };

    function scrollTutorial(direction) {
        const scrollContainer = document.querySelector('.scroller-style-x-new');
        const scrollAmount = 200;

        if (direction === 'left') {
            scrollContainer.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
            scrollContainer.scrollLeft += scrollAmount;
        }
    }

    function tab_scroll_x(direction) {
        const scrollContainer = document.querySelector('.scroller-style-x');
        const scrollAmount = 200;

        if (direction === 'left') {
            scrollContainer.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
            scrollContainer.scrollLeft += scrollAmount;
        }
    }



    const [location, setLocation] = useState({ lat: "", long: "" });
    const [error, setError] = useState("");

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                    });

                },
                (error) => {
                    setError("Location permission denied. Please enable GPS.");
                }
            );
        } else {
            setError("Geolocation is not supported by your browser.");
        }
        sessionStorage.removeItem('shouldOpenModal');
    }, []);


    // /search-store
    return (
        <>





        
            <div className="d-flex justify-content-between align-items-center p-3 white-bg p-navbar offcanvas-parentType1">
                <div className="d-flex justify-content-between align-items-center">
                    <img src={p_navbars} width={"24px"} height={"16px"} role="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" onClick={removeExtraFadeFromOffcanvas} />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1"> Bookings Manager </div>
                </div>
                {/* <Link to={`/search-store`}> */}
                <img src={p_nav_search} width={"20px"} height={"20px"} onClick={() => navigate('/search-store', { state: { lat: location.lat, long: location.lat } })} />
                {/* </Link> */}
                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel" >
                    <div className="offcanvas-body p-0">
                        <Link to={`/dashboard`} className="text-decoration-none">
                            <div className="profileDetails primary-bg p-3 text-white d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                    <img src={profile?.user_img} width={"84px"} height={"81px"} />
                                    <div className="ms-2">
                                        <div className="ff-poppins-semiBold fs-16">
                                            {profile?.user_name}
                                        </div>
                                        <div className="ff-balooPaaji2-regular fs-12">
                                            UID:{profile?.user_id}
                                        </div>
                                        <div className="ff-balooPaaji2-regular fs-12">
                                            <img src={p_nav_location} width={"12px"} height={"12px"} className="me-1" />
                                            {profile?.user_city}
                                        </div>
                                    </div>
                                </div>
                                <img src={p_nav_next_outline} width={"24px"} height={"24px"} />
                            </div>
                        </Link>

                        <div>
                            <div className="d-flex p-3" role="button" data-bs-dismiss="offcanvas" aria-label="Close"  >
                                <img src={p_nav_book_outline} width={"24px"} height={"24px"} />
                                <div className="ff-poppins-regular fs-16 ms-3">
                                    Bookings Manager
                                </div>
                            </div>

                            <Link
                                to={`/messenger-list`}
                                className="text-decoration-none text-dark"
                            >
                                <div className="d-flex p-3" role="button">
                                    <img src={p_nav_message_outline} width={"24px"} height={"24px"} />
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="ff-poppins-regular fs-16 ms-3">Messenger</div>
                                        <p className="">
                                            {notificationcount.unread_message_count > 0 ?
                                                <span className="notification_count">
                                                    {notificationcount.unread_message_count >= 9 ? "9+" : notificationcount.unread_message_count}
                                                </span> : <></>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Link>

                            <div className="d-flex sidebar p-3 " role="button" onClick={() => navigate("/notifications", { state: { UserId: profile.user_id } })}>
                                <img src={p_nav_notifications_outline} width={"24px"} height={"24px"}
                                />
                                <div className="d-flex justify-content-between w-100">
                                    <div className="ff-poppins-regular fs-16 ms-3">
                                        Notification
                                    </div>
                                    <p className="p-0 m-0">
                                        {notificationcount.unread_count > 0 ?
                                            <span className="notification_count">
                                                {notificationcount.unread_count >= 9 ? "9+" : notificationcount.unread_count}
                                            </span> : <></>
                                        }
                                    </p>
                                </div>
                            </div>

                            <Link to={`/aboutPingle`} className="text-decoration-none text-dark" >
                                <div className="d-flex p-3" role="button">
                                    <img src={p_nav_about_outline} width={"24px"} height={"24px"} />
                                    <div className="ff-poppins-regular fs-16 ms-3">
                                        About Pingle
                                    </div>
                                </div>
                            </Link>

                            <Link to={`/settings`} className="text-decoration-none text-dark">
                                <div className="d-flex p-3" role="button">
                                    <img
                                        src={p_nav_setting_outline}
                                        width={"24px"}
                                        height={"24px"}
                                    />
                                    <div className="ff-poppins-regular fs-16 ms-3">Settings</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bookingManager pb-5 scroller-style-y">
                <div className="header">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="ff-balooPaaji2-bold fs-20">Hello</div>
                            <div className="ff-balooPaaji2-bold fs-20">
                                {profile?.user_name}
                            </div>
                        </div>
                    </div>


                    <div className="ff-poppins-regular fs-13 primary-text mt-2">
                        Your Appointments
                    </div>

                    {/* All,services and product  */}
                    <ul className="nav nav-pills py-4  " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "Alltabcategory" ? "active" : ""} rounded-start serviceProductTab allBtnBM ff-poppins-regular`}
                                id="pills-Alltabcategory-tab" data-bs-toggle="pill" data-bs-target="#pills-Alltabcategory"
                                type="button" role="tab" aria-controls="pills-Alltabcategory"
                                aria-selected={activeTab === "Alltabcategory" ? "true" : "false"} onClick={() => handleTabChange("ActiveTab", "Alltabcategory")}  >
                                All
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "services" ? "active" : ""} rounded-start serviceProductTab serviceBtnBM ff-poppins-regular`}
                                id="pills-services-tab" data-bs-toggle="pill" data-bs-target="#pills-services"
                                type="button" role="tab" aria-controls="pills-services"
                                aria-selected={activeTab === "services" ? "true" : "false"} onClick={() => handleTabChange("ActiveTab", "services")}  >
                                Services
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${activeTab === "products" ? "active" : ""} serviceProductTab rounded-end productBtnBM ff-poppins-regular`}
                                id="    Aab" data-bs-toggle="pill" data-bs-target="#pills-products"
                                type="button" role="tab" aria-controls="pills-products"
                                aria-selected={activeTab === "products" ? "true" : "false"} onClick={() => handleTabChange("ActiveTab", "products")} >
                                Products
                            </button>
                        </li>
                    </ul>


                    <div className="arrow-controls d-flex justify-content-end mt-1">
                        <FaArrowLeftLong
                            className="primary-text me-2 mt-1 arrow-icon"
                            onClick={() => tab_scroll_x("left")}
                        />
                        <FaArrowRightLong
                            className="primary-text me-2 mt-1 arrow-icon"
                            onClick={() => tab_scroll_x("right")}
                        />
                    </div>

                    {/* Seperate componant  */}
                    <div className="tab-content" id="pills-tabContent">

                        {activeTab === "Alltabcategory" &&
                            <div className={`tab-pane fade ${activeTab === "Alltabcategory" ? "show active" : ""}`} id="pills-All" role="tabpanel" aria-labelledby="pills-All-tab">
                                <ul className="appointmentFilter scroller-style-x mouse_Default nav d-flex flex-nowrap nav-pills pt-2 pb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${alltabcategory === "pills-all-tab" ? "active" : ""}`}
                                            id="pills-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-all"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-all"
                                            onClick={() => handleTabChange("Productcategory", 'pills-all-tab')}
                                            aria-selected={alltabcategory === "pills-all-tab" ? "true" : "false"}
                                        >
                                            All
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${alltabcategory === "pills-today-tab" ? "active" : ""}`}
                                            id="pills-today-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-today"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-today"
                                            onClick={() => handleTabChange("Productcategory", 'pills-today-tab')}
                                            aria-selected={alltabcategory === "pills-today-tab" ? "true" : "false"}
                                        >
                                            Today
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${alltabcategory === "pills-upcoming-tab" ? "active" : ""}`}
                                            id="pills-upcoming-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-upcoming"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-upcoming"
                                            onClick={() => handleTabChange("Productcategory", 'pills-upcoming-tab')}
                                            aria-selected={alltabcategory === "pills-upcoming-tab" ? "true" : "false"}
                                        >
                                            Upcoming
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${alltabcategory === "pills-unconfirmed-tab" ? "active" : ""}`}
                                            id="pills-unconfirmed-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-unconfirmed"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-unconfirmed"
                                            onClick={() => handleTabChange("Productcategory", 'pills-unconfirmed-tab')}
                                            aria-selected={alltabcategory === "pills-unconfirmed-tab" ? "true" : "false"}
                                        >
                                            Unconfirmed
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${alltabcategory === "pills-missed-tab" ? "active" : ""}`}
                                            id="pills-missed-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-missed"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-missed"
                                            onClick={() => handleTabChange("Productcategory", 'pills-missed-tab')}
                                            aria-selected={alltabcategory === "pills-missed-tab" ? "true" : "false"}
                                        >
                                            Missed
                                        </button>
                                    </li>
                                </ul>

                                <div class="tab-content" id="pills-tabContent">
                                    <div className={`tab-pane fade ${alltabcategory === "pills-all-tab" ? "show active" : ""}`} id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabIndex="0">
                                        {allTab_product_service?.length > 0 ? (

                                            allTab_product_service.map((item, index) => {

                                                return (
                                                    <div className="tab-pane mt-2" id="pills-all-tab" role="tabpanel" aria-labelledby="pills-all-tab" >
                                                        <div onClick={() => {

                                                            if (item.order_id) {
                                                                if (item.type == 'CART') {
                                                                    navigate("/ReviewPay", { state: { merchantId: item.vendor_id, shipingtype: item.shipping_type }, })
                                                                    let productCart = {
                                                                        cartId: item.cart_id
                                                                    };
                                                                    localStorage.setItem("productCart", JSON.stringify(productCart));
                                                                }
                                                                else {
                                                                    navigate(`/ProductPass/${item.order_id}`)
                                                                }
                                                            }
                                                            else if (!item.order_id && item.order_status == "") {
                                                                navigate("/ReviewPay", { state: { merchantId: item.vendor_id, shipingtype: item.shipping_type }, })
                                                                let productCart = {
                                                                    cartId: item.cart_id
                                                                };
                                                                localStorage.setItem("productCart", JSON.stringify(productCart));
                                                            }
                                                            else if (item.booking_id || item.book_status == '5') {
                                                                if (item.book_status == '5') {
                                                                    if (((Number(item.book_service_type) == 2 && Number(item.doorstep_request_status) == 2) || Number(item.book_service_type) == 1)) {
                                                                        openYettopayModal(item)


                                                                    } else {
                                                                        let serviceDetail = {
                                                                            serviceType: item.book_service_type,
                                                                            vendorId: item.book_vendor_id,
                                                                            cart_app_id: item.cart_app_id
                                                                        }
                                                                        localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                        navigate(`/review-and-pay/${item.book_vendor_id}`)


                                                                    }
                                                                } else { navigate(`/details/${item.booking_id}`); }
                                                            }


                                                        }}>
                                                            {
                                                                item.booking_id || item.book_status == "5" ? <AppointmentServiceCard data={item} /> : <AppointmentProductCard data={item} />
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No service / product booking
                                            </div>
                                        )}
                                    </div>
                                    <div className={`tab-pane fade ${alltabcategory === "pills-complete-tab" ? "show active" : ""}`} id="pills-today" role="tabpanel" aria-labelledby="pills-today-tab" tabindex="0">
                                        {
                                            allTabb_today_list?.length > 0 ?
                                                allTabb_today_list.map((item, subindex) => {
                                                    return (
                                                        <div onClick={() => {
                                                            if (item.order_id) {
                                                                navigate(`/ProductPass/${item.order_id}`)
                                                            }
                                                            else if (item.booking_id) {
                                                                navigate(`/details/${item.booking_id}`)
                                                            }
                                                        }}>
                                                            {
                                                                item.order_id ? <AppointmentProductCard data={item} /> : <AppointmentServiceCard data={item} />
                                                            }
                                                        </div>
                                                    );
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No  service / product booking today
                                                </div>
                                        }
                                    </div>

                                    <div className={`tab-pane fade ${alltabcategory === "pills-cancel-tab" ? "show active" : ""}`} id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabindex="0" >
                                        {
                                            allTabb_upcoming_list?.length > 0 ?
                                                allTabb_upcoming_list.map((item, subindex) => {
                                                    return (
                                                        <div onClick={() => {
                                                            if (item.order_id) {
                                                                navigate(`/ProductPass/${item.order_id}`)
                                                            } else if (item.booking_id) {
                                                                navigate(`/details/${item.booking_id}`)
                                                            }
                                                        }
                                                        }>
                                                            {
                                                                item.order_id ? <AppointmentProductCard data={item} /> : <AppointmentServiceCard data={item} />
                                                            }
                                                        </div>
                                                    )
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No upcoming service / product Booking
                                                </div>}
                                    </div>

                                    <div className={`tab-pane fade ${alltabcategory === "pills-expired-tab" ? "show active" : ""}`} id="pills-unconfirmed" role="tabpanel" aria-labelledby="pills-unconfirmed-tab" tabindex="0" >
                                        {

                                            allTabb_unconfirmed_list?.length > 0 ?
                                                allTabb_unconfirmed_list.map((item, subindex) => {
                                                    return (
                                                        <>
                                                            <div onClick={() => {
                                                                if (item.order_products) {
                                                                    navigate("/ReviewPay", { state: { merchantId: item.vendor_id, shipingtype: item.shipping_type }, })
                                                                    let productCart = {
                                                                        cartId: item.cart_id
                                                                    };
                                                                    localStorage.setItem("productCart", JSON.stringify(productCart));
                                                                } else if (item.booking_id) {
                                                                    openYettopayModal(item)
                                                                }
                                                            }}>
                                                                {
                                                                    item.order_products ? <AppointmentProductCard data={item} /> : <AppointmentServiceCard data={item} />
                                                                }

                                                            </div>
                                                            <div class="modal fade" id="exampleModal" tabindex="-1" ref={yetTopayRef} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div class="modal-dialog modal-dialog-centered modal-sm m-0">
                                                                    <div class="modal-content mx-5">
                                                                        <div class="modal-body px-3 pt-4 pb-4">
                                                                            <div className="fs-1 text-center"><FaExclamation className="exclamationIcon primary-text" /></div>
                                                                            <div className='text-center ff-poppins-semiBold fs-5 mb-3'>Confirm Booking</div>
                                                                            <div className='text-center ff-poppins-semiBold secondary-text fs-16' >Please confirm your booking by completing the payment by completing the payment process.</div>

                                                                            <div className='d-flex justify-content-center mt-3'>
                                                                                <button type="button" class="btn primary-bg text-white px-5 ff-poppins-semiBold rounded-pill" data-bs-dismiss="modal" onClick={() => {


                                                                                    let serviceDetail = {
                                                                                        serviceType: item.book_service_type,
                                                                                        vendorId: item.book_vendor_id,
                                                                                        cart_app_id: item.cart_app_id
                                                                                    }


                                                                                    localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                                    navigate(`/review-and-pay/${item.book_vendor_id}`)
                                                                                }}  >OK</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No unconfirmed service / product booking
                                                </div>
                                        }
                                    </div>



                                    <div className={`tab-pane fade ${alltabcategory === "pills-missed-tab" ? "show active" : ""}`} id="pills-missed" role="tabpanel" aria-labelledby="pills-missed-tab" tabindex="0" >
                                        {bookingDetails?.missed_appointment_status ? (
                                            bookingDetails?.missed_appointment_details.map((item) => {
                                                return (
                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}  >
                                                        <AppointmentServiceCard data={item} />
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text pt-4">
                                                No missed service appointments
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        }

                        {/*For Service */}

                        {activeTab === "services" &&
                            <div className={`tab-pane fade ${activeTab == "services" ? "show active" : ""}`} id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">
                                <ul className="appointmentFilter scroller-style-x mouse_Default nav d-flex flex-nowrap nav-pills pt-2 pb-3" id="pills-services-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <div className={`nav-link ${servicetabcategory == "pills-all-services" ? "active" : ""}`}
                                            id="pills-all-services-tab" data-bs-toggle="pill" data-bs-target="#pills-all-services"
                                            type="button" role="tab" aria-controls="pills-all-services"
                                            onClick={() => handleTabChange("Servicecategory", 'pills-all-services')}
                                            aria-selected={servicetabcategory == "pills-all-services" ? "true" : "false"}
                                        >
                                            All
                                        </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <div className={`nav-link ${servicetabcategory == "pills-today-services-tab" ? "active" : ""}`}
                                            id="pills-today-services-tab" data-bs-toggle="pill" data-bs-target="#pills-today-services"
                                            type="button" role="tab" aria-controls="pills-today-services"
                                            onClick={() => handleTabChange("Servicecategory", 'pills-today-services-tab')}
                                            aria-selected={servicetabcategory == "pills-today-services-tab" ? "true" : "false"}
                                        >
                                            Today
                                        </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <div className={`nav-link ${servicetabcategory == "pills-unconfirmed-services-tab" ? "active" : ""}`}
                                            id="pills-unconfirmed-services-tab" data-bs-toggle="pill" data-bs-target="#pills-unconfirmed-services"
                                            type="button" role="tab" aria-controls="pills-unconfirmed-services"
                                            onClick={() => handleTabChange("Servicecategory", 'pills-unconfirmed-services-tab')}
                                            aria-selected={servicetabcategory == "pills-unconfirmed-services-tab" ? "true" : "false"}
                                        >
                                            Unconfirmed
                                        </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <div className={`nav-link ${servicetabcategory == "pills-upcoming-services-tab" ? "active" : ""}`}
                                            id="pills-upcoming-services-tab" data-bs-toggle="pill" data-bs-target="#pills-upcoming-services"
                                            type="button" role="tab" aria-controls="pills-upcoming-services"
                                            onClick={() => handleTabChange("Servicecategory", 'pills-upcoming-services-tab')}
                                            aria-selected={servicetabcategory == "pills-upcoming-services-tab" ? "true" : "false"}
                                        >
                                            Upcoming
                                        </div>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <div className={`nav-link ${servicetabcategory == "pills-missed-services-tab" ? "active" : ""}`}
                                            id="pills-missed-services-tab" data-bs-toggle="pill" data-bs-target="#pills-missed-services"
                                            type="button" role="tab" aria-controls="pills-missed-services"
                                            onClick={() => handleTabChange("Servicecategory", 'pills-missed-services-tab')}
                                            aria-selected={servicetabcategory == "pills-missed-services-tab" ? "true" : "false"} >
                                            Missed
                                        </div>
                                    </li>
                                </ul>

                                <div className={`tab-pane fade ${servicetabcategory == "pills-all-services" ? "show active" : ""}`} id="pills-all-services" role="tabpanel" aria-labelledby="pills-all-services-tab"    >
                                    {
                                        allAppointmentList?.length > 0 ?
                                            allAppointmentList.map(item => {
                                                return (
                                                    <div className="" onClick={() => {
                                                        if (item.book_status === '5') {
                                                            if (((Number(item.book_service_type) == 2 && Number(item.doorstep_request_status) == 2) || Number(item.book_service_type) == 1)) {
                                                                openYettopayModal(item)


                                                            } else {
                                                                let serviceDetail = {
                                                                    serviceType: item.book_service_type,
                                                                    vendorId: item.book_vendor_id,
                                                                    cart_app_id: item.cart_app_id
                                                                }
                                                                localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                navigate(`/review-and-pay/${item.book_vendor_id}`)


                                                            }
                                                        } else { navigate(`/details/${item.booking_id}`); }


                                                    }}>
                                                        <AppointmentServiceCard data={item} />
                                                        <div className="modal fade" id="exampleModal" tabindex="-1" ref={yetTopayRef} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog modal-dialog-centered modal-sm m-0">
                                                                <div class="modal-content mx-5">
                                                                    <div class="modal-body px-3 pt-4 pb-4">
                                                                        <div className="fs-1 text-center"><FaExclamation className="exclamationIcon primary-text" /></div>
                                                                        <div className='text-center ff-poppins-semiBold fs-5 mb-3'>Confirm Booking</div>
                                                                        <div className='text-center ff-poppins-semiBold secondary-text fs-16' >Please confirm your booking by completing the payment by completing the payment process.</div>

                                                                        <div className='d-flex justify-content-center mt-3'>
                                                                            <button type="button" class="btn primary-bg text-white px-5 ff-poppins-semiBold rounded-pill" data-bs-dismiss="modal" onClick={() => {


                                                                                let serviceDetail = {
                                                                                    serviceType: item.book_service_type,
                                                                                    vendorId: item.book_vendor_id,
                                                                                    cart_app_id: item.cart_app_id
                                                                                }


                                                                                localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
                                                                                navigate(`/review-and-pay/${item.book_vendor_id}`)
                                                                            }}  >OK</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No service appointments
                                            </div>}
                                </div>
                                <div className={`tab-pane fade ${servicetabcategory == "pills-today-services-tab" ? "show active" : ""}`} id="pills-today-services-tab" role="tabpanel" aria-labelledby="pills-today-services-tab" >
                                    {
                                        bookingDetails?.active_appointment_status ?
                                            bookingDetails?.active_appointment_details.map(item => {
                                                return (
                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                        <AppointmentServiceCard data={item} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text pt-4">
                                                No service appointments today
                                            </div>
                                    }
                                </div>
                                <div className={`tab-pane fade ${servicetabcategory == "pills-unconfirmed-services-tab" ? "show active" : ""}`} id="pills-unconfirmed-services-tab" role="tabpanel" aria-labelledby="pills-unconfirmed-services-tab" >
                                    {
                                        bookingDetails?.unconfirmed_appointment_details?.length > 0 ? bookingDetails?.unconfirmed_appointment_details.map(item => {
                                            return (
                                                <div onClick={() => openYettopayModal(item)}>
                                                    <AppointmentServiceCard data={item} />

                                                </div>
                                            )
                                        })
                                            :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No unconfirmed service appointments
                                            </div>
                                    }
                                </div>
                                <div className={`tab-pane fade  ${servicetabcategory == "pills-upcoming-services-tab" ? "show active" : ""}`} id="pills-upcoming-services-tab" role="tabpanel" aria-labelledby="pills-upcoming-services-tab"  >
                                    {
                                        bookingDetails?.upcoming_appointment_status ?
                                            bookingDetails?.upcoming_appointment_details.map(item => {
                                                return (
                                                    <div onClick={() => navigate(`/details/${item.booking_id}`)}>
                                                        <AppointmentServiceCard data={item} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                No upcoming service appointments
                                            </div>}
                                </div>
                                <div className={`tab-pane fade ${servicetabcategory == "pills-missed-services-tab" ? "show active" : ""}`} id="pills-missed-services-tab" role="tabpanel" aria-labelledby="pills-missed-services-tab" >
                                    {bookingDetails?.missed_appointment_status ? (
                                        bookingDetails?.missed_appointment_details.map((item) => {
                                            return (
                                                <div onClick={() => navigate(`/details/${item.booking_id}`)}  >
                                                    <AppointmentServiceCard data={item} />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center ff-poppins-regular fs-14 secondary-text pt-4">
                                            No missed service appointments
                                        </div>
                                    )}
                                </div>
                                <div class="modal fade" id="exampleModal" tabindex="-1" ref={yetTopayRef} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-sm m-0">
                                        <div class="modal-content mx-5">
                                            <div class="modal-body px-3 pt-4 pb-4">
                                                <div className="fs-1 text-center"><FaExclamation className="exclamationIcon primary-text" /></div>
                                                <div className='text-center ff-poppins-semiBold fs-5 mb-3'>Confirm Booking</div>
                                                <div className='text-center ff-poppins-semiBold secondary-text fs-16' >Please confirm your booking by completing the payment by completing the payment process.</div>

                                                <div className='d-flex justify-content-center mt-3'>
                                                    <button type="button" class="btn primary-bg text-white px-5 ff-poppins-semiBold rounded-pill" data-bs-dismiss="modal" onClick={() => {


                                                    }}  >OK</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* For products  */}
                        {activeTab === "products" &&
                            <div className={`tab-pane fade ${activeTab === "products" ? "show active" : ""}`} id="pills-products" role="tabpanel" aria-labelledby="pills-products-tab">
                                <ul className="appointmentFilter scroller-style-x mouse_Default nav d-flex flex-nowrap nav-pills pt-2 pb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${producttabcategory === "pills-all-tab" ? "active" : ""}`}
                                            id="pills-all-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-all"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-all"
                                            onClick={() => handleTabChange("Productcategory", 'pills-all-tab')}
                                            aria-selected={producttabcategory === "pills-all-tab" ? "true" : "false"}
                                        >
                                            All
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${producttabcategory === "pills-today-tab" ? "active" : ""}`}
                                            id="pills-today-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-today"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-today"
                                            onClick={() => handleTabChange("Productcategory", 'pills-today-tab')}
                                            aria-selected={producttabcategory === "pills-today-tab" ? "true" : "false"}
                                        >
                                            Today
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${producttabcategory === "pills-upcoming-tab" ? "active" : ""}`}
                                            id="pills-upcoming-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-upcoming"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-upcoming"
                                            onClick={() => handleTabChange("Productcategory", 'pills-upcoming-tab')}
                                            aria-selected={producttabcategory === "pills-upcoming-tab" ? "true" : "false"}
                                        >
                                            Upcoming
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${producttabcategory === "pills-unconfirmed-tab" ? "active" : ""}`}
                                            id="pills-unconfirmed-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-unconfirmed"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-unconfirmed"
                                            onClick={() => handleTabChange("Productcategory", 'pills-unconfirmed-tab')}
                                            aria-selected={producttabcategory === "pills-unconfirmed-tab" ? "true" : "false"}
                                        >
                                            Unconfirmed
                                        </button>
                                    </li>
                                </ul>

                                <div class="tab-content" id="pills-tabContent">
                                    {/* <div class="tab-pane fade show active" id=" -all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0"  > */}
                                    {isOpen ? (
                                        <div className={`tab-pane fade ${producttabcategory === "pills-all-tab" ? "show active" : ""}`} id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabIndex="0">
                                            {userOrders?.length > 0 ? (
                                                userOrders.map((product, index) => (
                                                    <div className="tab-pane mt-2" id="pills-all-tab" role="tabpanel" aria-labelledby="pills-all-tab" >
                                                        <div onClick={() => {
                                                            if (product.type == 'CART') {
                                                                navigate("/ReviewPay", { state: { merchantId: product.vendor_id, shipingtype: product.shipping_type }, })
                                                                let productCart = {
                                                                    cartId: product.cart_id
                                                                };
                                                                localStorage.setItem("productCart", JSON.stringify(productCart));
                                                            }
                                                            else {
                                                                navigate(`/ProductPass/${product.order_id}`)
                                                            }
                                                        }}>
                                                            <AppointmentProductCard data={product} />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No products
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className={`tab-pane fade ${producttabcategory === "pills-today-tab" ? "show active" : ""}`} id="pills-today" role="tabpanel" aria-labelledby="pills-today-tab" tabindex="0">
                                        {
                                            todayproduct?.length > 0 ?
                                                todayproduct.map((product, subindex) => {
                                                    return (
                                                        <div onClick={() => {
                                                            navigate(`/ProductPass/${product.order_id}`)
                                                        }}>
                                                            <AppointmentProductCard data={product} />
                                                        </div>
                                                    );
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No delivery products
                                                </div>
                                        }
                                    </div>
                                    <div className={`tab-pane fade ${producttabcategory === "pills-upcoming-tab" ? "show active" : ""}`} id="pills-upcoming" role="tabpanel" aria-labelledby="pills-upcoming-tab" tabindex="0" >
                                        {
                                            upcomingproduct?.length > 0 ?
                                                upcomingproduct.map((product, subindex) => {
                                                    return (
                                                        <div onClick={() => navigate(`/ProductPass/${product.order_id}`)}>
                                                            <AppointmentProductCard data={product} />
                                                        </div>
                                                    )
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No upcoming products
                                                </div>}
                                    </div>
                                    <div className={`tab-pane fade ${producttabcategory === "pills-unconfirmed-tab" ? "show active" : ""}`} id="pills-unconfirmed" role="tabpanel" aria-labelledby="pills-unconfirmed-tab" tabindex="0" >
                                        {

                                            unconfirmed?.length > 0 ?
                                                unconfirmed.map((product, subindex) => {
                                                    return (
                                                        <div onClick={() => {
                                                            navigate("/ReviewPay", { state: { merchantId: product.vendor_id, shipingtype: product.shipping_type }, })
                                                            let productCart = {
                                                                cartId: product.cart_id
                                                            };
                                                            localStorage.setItem("productCart", JSON.stringify(productCart));
                                                        }}>
                                                            <AppointmentProductCard data={product} />
                                                        </div>
                                                    );
                                                }) :
                                                <div className="text-center ff-poppins-regular fs-14 secondary-text  pt-4">
                                                    No Unconfirmed products
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* Book Appointments  */}

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/search-store')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_appointments} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end">
                            Book <br /> Appointments
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Skip the queue or avoid the hassle of driving to your appointment.
                        With Pingle, you can easily check available slots, customize your service,
                        and book your appointment—all at your convenience!
                    </div>
                </div>

                <div className="pb-0 features">
                    <div className="ff-poppins-medium fs-21">Tutorial</div>
                    <div className="ff-poppins-regular fs-11">
                        This step-by-step Beginner-friendly tutoial will guide you on how to
                        use the app and make your first pingle booking.
                    </div>
                </div>
                <div className=" ">
                    <div className="tutorialSteps scroller-style-x-new ">
                        <div className="bg-white tutorialCard">
                            <div className="ff-poppins-semiBold fs-34 primary-text">01</div>
                            <div className="ff-poppins-semiBold fs-10 mt-1">Step One</div>
                            <div className="ff-poppins-semiBold fs-16">Scan</div>
                            <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                                Use the Pingle button to open the QR code scanner to scan the Shop QR code.
                            </div>
                        </div>
                        <div className="bg-white tutorialCard">
                            <div className="ff-poppins-semiBold fs-34 primary-text">02</div>
                            <div className="ff-poppins-semiBold fs-10 mt-1">Step Two</div>
                            <div className="ff-poppins-semiBold fs-16">Select</div>
                            <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                                Click "View Services," then choose your preferred service and time slot.
                            </div>
                        </div>
                        <div className="bg-white tutorialCard">
                            <div className="ff-poppins-semiBold fs-34 primary-text">03</div>
                            <div className="ff-poppins-semiBold fs-10 mt-1">Step Three</div>
                            <div className="ff-poppins-semiBold fs-16">Book</div>
                            <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                                Book and pay. You will receive a ticket, accessible from the Bookings Manager.
                            </div>
                        </div>
                        <div className="bg-white tutorialCard">
                            <div className="ff-poppins-semiBold fs-34 primary-text">04</div>
                            <div className="ff-poppins-semiBold fs-10 mt-1">Step Four</div>
                            <div className="ff-poppins-semiBold fs-16">Redeem</div>
                            <div className="ff-poppins-regular fs-10 mt-2 text-wrap">
                                Visit the store at your scheduled time to redeem your ticket or expect the product to be delivered at your doorstep
                            </div>
                        </div>
                        <div className="bg-white tutorialCard">
                            <div className="ff-poppins-regular fs-14 mt-2 text-wrap">
                                Note: Bookings are marked missed if you're more than 20 minutes late, and you'll need to reschedule.
                            </div>
                        </div>

                    </div>
                    <div className="arrow-controls d-flex justify-content-end mt-2">
                        <FaArrowLeftLong
                            className="primary-text me-2 mt-1 arrow-icon"
                            onClick={() => scrollTutorial("left")}
                        />
                        <FaArrowRightLong
                            className="primary-text me-2 mt-1 arrow-icon"
                            onClick={() => scrollTutorial("right")}
                        />
                    </div>



                </div>
                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/messenger-list')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_messenger} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Pingle <br /> Messenger
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Got a question? No worries! Our Pingle Messenger is here to assist you in real-time and enhance your app experience.
                        Simply chat with our bot, like you would with a friend.
                        And that's not all—you can also message the service provider directly for complete clarity.
                    </div>
                </div>

                <div className="white-bg features">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="ff-poppins-semiBold fs-24 primary-text lh-sm">
                            Reschedule <br /> Appointments
                        </div>
                        <img src={p_bookings_resech} width={"75px"} height={"75px"} />
                    </div>
                    <div className="ff-poppins-regular fs-11 mt-3">
                        Feeling under the weather or wanting to switch up your plans? We’ve all been there—life happens! With Pingle,
                        you can easily adjust upcoming appointments with just one click. No more waiting on the phone or trying to find a
                        time that works for everyone. Simply click, select a new time, and reschedule.
                    </div>
                </div>

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/PinglePoints')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_booking_loyalty} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Loyalty <br /> Points
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Here’s another "ing" we bring to you—saving! With every referral and booking,
                        you earn Pingle Points that can be redeemed for exciting products in our Reward Store.
                        Your satisfaction makes it all worthwhile
                    </div>
                </div>

                <div className="white-bg features">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="ff-poppins-semiBold fs-24 primary-text lh-sm">
                            Make <br /> it easy
                        </div>
                        <img src={p_bookings_makeItEasy} width={"75px"} height={"75px"} />
                    </div>
                    <div className="ff-poppins-regular fs-11 mt-3">
                        With Pingle, you can effortlessly connect with your favorite outlets, book appointments, make payments,
                        earn loyalty points, access exclusive offers & discounts, provide feedback, and even reschedule appointments.
                        In this busy, fast-paced world, Pingle makes life easier—skip the queue and save time.
                        Life’s busy, but with Pingle, it’s easy!
                    </div>
                </div>

                <div className="primary-bg text-white features" role="button" onClick={() => navigate('/chat/PINGLE')}>
                    <div className="d-flex justify-content-between align-items-center">
                        <img src={p_bookings_connect} width={"75px"} height={"75px"} />
                        <div className="ff-poppins-semiBold fs-24 text-end lh-sm">
                            Connect <br /> Directly
                        </div>
                    </div>
                    <div className="ff-poppins-regular fs-11 text-end mt-3">
                        Need assistance or want to share your Pingle experience? Reach out to our customer service center
                        with your booking number to share your query or feedback. Your satisfaction is our top priority.
                    </div>
                </div>


            </div>
            <div className="position-absolute FooterButton_main_div" style={{ bottom: 0, justifyContent: 'center' }}>
                <div className="FooterButton_img_div">
                    <img src={FooterButton} width="100%" height="90" className="FooterButton_img" />
                </div>
                <div className="position-absolute" style={{ bottom: 0, justifyContent: 'center', left: '50%', transform: 'translateX(-50px)', width: 100 }}>
                    <div className="d-flex justify-content-center">
                        <Link to={'/QRCodeScan'}>
                            <img src={pingleLogoWhite} height={50} width={50} className="primary-bg rounded-circle" />
                        </Link>
                    </div>
                    <div className="ff-balooPaaji2-semiBold primary-text fs-12 d-flex justify-content-center mb-2">
                        PINGLE SCAN
                    </div>
                </div>
            </div>

            {showPaymentSuccessModal && (
                <div className="modal-backdrop show"></div>
            )
            }

            {
                showPaymentSuccessModal && (
                    <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog-centered paymentSuccessModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                                        Attention!
                                    </div>
                                    <div className='ff-poppins-regular fs-14 text-center mt-2'>
                                        To proceed further you need to login/register and get access to all the features.
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button' onClick={() => navigate('/login')}>
                                        <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center' role='button' onClick={handlePaySuccessClose}>
                                        <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }












        </>
    );
};

export default BookingsManager;
