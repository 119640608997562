import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import ReactLoading from "react-loading";
import { FiArrowRight } from "react-icons/fi";
import charm_circle from "../../Assets/Images/icons/charm_circle-tick.png"
import axiosInstance from "../../Services/axios";
import { toast } from "react-toastify";
import select_circle from "../../Assets/Images/icons/select_rectangle.png"
import unselect_circle from "../../Assets/Images/icons/unselect_rectangle.png"
import { IoMdClose } from "react-icons/io";
import moment from "moment";

export default function Single_preorder_detail() {

    const [data, setData] = useState();

    const { vendor_id, ticket_id } = useParams();

    const navigate = useNavigate();
    const location = useLocation()
    const { select_date } = location.state || {};

    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem("token");
    const [pageLoaded, setPageLoaded] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [isTouch, setIsTouch] = useState(false);
    const shippingtypeRef = useRef(null)
    const [shipping_type, setShiping_type] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null);
    const DEFAULT_Ticket_IMAGE_URL = "https://theme-assets.getbento.com/sensei/6941e9b.sensei/assets/images/catering-item-placeholder-704x520.png";
    const DEFAULT_IMAGE_URL = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScO99JkPvdfSyT3CbMrYsKBpRuXOACVR2cP9F6DBcP9B6nQ9oszX_18T-2oNv6Gyxwyhk&usqp=CAU";

    const handleTouch = () => {
        setIsTouch(!isTouch);
    };

    const openShippingModal = () => {
        if (shippingtypeRef.current) {
            shippingtypeRef.current.classList.add("show");
            shippingtypeRef.current.style.display = "block";
            setIsModalOpen(true)
        } else {
            console.warn("shippingtypeRef is null");
        }
    };


    const closeShippingModal = () => {
        shippingtypeRef.current.classList.remove("show");
        shippingtypeRef.current.style.display = "none";
        setIsModalOpen(false)

    }



    useEffect(() => {
        fetch_ticket_deta()
    }, [])

    const fetch_ticket_deta = () => {
        try {
            let reqData = {
                "vendor_id": vendor_id,
                "date": select_date,
                "ticket_id": ticket_id
            }

            axiosInstance
                .post("/user/pre_order/fetch_single_preorder_ticket", reqData)
                .then((res) => {
                    console.log("res-------------", res.data);
                    setData(res.data.preorder_ticket)
                    setPageLoaded(true)
                });

        } catch (error) {
            toast("Try Again Later", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "text-danger toast-style-1",
            })
        }
    }

    const add_to_cart = () => {
        try {
            let reqData = {
                "vendor_id": vendor_id,
                "date": select_date,
                "ticket_id": ticket_id,
                "shipping_type": shipping_type,
            }

            axiosInstance
                .post("/user/pre_order/add_to_cart", reqData)
                .then((res) => {
                    if (res.status == 200) {

                        let productCart = {
                            shipping_type: shipping_type,
                            cartId: res.data.cart_id,
                            vendorId: vendor_id
                        };
                        localStorage.setItem("productCart", JSON.stringify(productCart));
                        let cart_id = res.data.cart_id
                        navigate("/ReviewPay", { state: { vendor_id, cart_id, shipping_type, flowtype: "preorder" } });
                    }
                });

        } catch (error) {
            toast("Try Again Later", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "text-danger toast-style-1",
            })
        }
    }

    const ticket_timer = () => {

    }


    useEffect(() => {
        if (!data?.book_before_time) return;

        let endTime;
        let book_before_time = 7;

        if (book_before_time == 1) {
            endTime = moment().set({ hour: 23, minute: 0, second: 0 });
        } else if (book_before_time == 2) {
            endTime = moment().set({ hour: 21, minute: 0, second: 0 });
        } else if (book_before_time == 3) {
            endTime = moment().set({ hour: 18, minute: 0, second: 0 });
        } else if (book_before_time == 4) {
            endTime = moment().set({ hour: 15, minute: 0, second: 0 });
        } else if (book_before_time == 5) {
            endTime = moment().set({ hour: 12, minute: 0, second: 0 });
        } else if (book_before_time == 6) {
            endTime = moment().add(24, 'hours');
        } else if (book_before_time == 7) {
            endTime = moment().add(48, 'hours');
        } else {
            return;
        }

        const now = moment();

        if (now.isAfter(endTime)) {
            setTimeLeft(0);
            return;
        }

        setTimeLeft(endTime.diff(now, "seconds"));

        const interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [data?.book_before_time]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h ${minutes}m ${secs}s`;;
    };



    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button" onClick={() =>
                        navigate(-1)
                    } />
                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Preorder</div>
                </div>
            </div>

            {
                pageLoaded ?
                    <div className="p-2 scroller-style-y">
                        <div className="">
                            <div>

                                <div className="mb-2">
                                    <div className={`preorder-card-main-box  ${data?.card_colour && data?.card_colour !== "transparent" && "ImageDesign"}`}
                                        style={{
                                            //    data.card_colour && data.card_colour !='' ?  data.card_colour:`${data.card_pattern}`,
                                            background: data.card_colour && data.card_colour !== "transparent" ? data.card_colour : data.card_pattern ? `url(${data.card_pattern})` : 'rgb(231, 52, 96)',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    >
                                        <div className="conatiner-left">

                                            <img
                                                src={`${data.images}`}
                                                alt="Preview"
                                                className="image" width={"100px"} height={"165px"}
                                                onError={(e) => e.target.src = DEFAULT_Ticket_IMAGE_URL}
                                            />

                                        </div>

                                        <div className="conatiner-right-Card">

                                            <div className="content-top p-0 d-flex justify-content-between align-items-center">
                                                <span className="store-name p-0 fs-14 ff-poppins-semiBold text-white">
                                                    {data.vendor_company_name}
                                                </span>
                                                <span></span>
                                            </div>
                                            <div className="content-bottom">
                                                <span className=" fs-14 ff-poppins-semiBold text-uppercase text-white ">
                                                    {data.ticket_name || "TICKET NAME"}
                                                </span>
                                                <div className="gradient-line"
                                                    style={{
                                                        width: `${Math.min(data.ticket_name?.length * 20, 200) || 200
                                                            }px`,
                                                        marginTop: 0,
                                                        marginBottom: "10px",
                                                    }}
                                                ></div>
                                                <p className="content ff-poppins-regular fs-10 text-white">
                                                    {(
                                                        data?.about ||
                                                        "This ticket is for food, making preordering easy, convenient, budget..."
                                                    ).slice(0, 70) + (data?.about?.length > 70 ? "..." : "")}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <span className="price fs-12 ff-poppins-semiBold text-white">
                                                        ₹ {data.ticket_price || "00.0"}
                                                    </span>
                                                    {data?.quantity_count &&
                                                        <span className="price fs-12 ff-poppins-medium text-white">
                                                            Available :  {data.quantity_count}
                                                        </span>}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center col-8">
                                                    {
                                                        data.book_before == 1 &&
                                                        <>
                                                            <p className="p-0 m-0 ff-poppins-medium fs-10 text-light">Deadline :</p>
                                                            <div className="timer-container ms-1">
                                                                <div className="timer-box text-center ff-poppins-medium fs-10">
                                                                    {formatTime(timeLeft)}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>


                                                <div className="footer py-3 ">
                                                    <button className="buyNow fs-10  ff-poppins-regular">
                                                        Ticket
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <p className="m-0 ff-poppins-semiBold fs-14 p-2">
                                    Ticket Description
                                </p>
                                <p className="m-0  ff-poppins-regular fs-14 p-2">
                                    {data?.about}
                                </p>
                            </div>
                            <div className="mt-2">
                                <h5 className="p-0 m-0 fs-14 ff-poppins-medium">Added Benefits</h5>

                                <div className="p-3">
                                    <div className=" d-flex justify-content-between">
                                        <div className="mt-2">
                                            <img src={charm_circle} height="26px" width="26px" />
                                        </div>
                                        <div className="ms-3">
                                            <h6 className="fs-15 p-0 m-0  ff-poppins-medium primary-text">Pre-Order & Save Big!</h6>
                                            <p className="fs-12  p-0 m-0 ff-poppins-regular">Exclusive discounts for early buyers—lock in your price today!</p>
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-2 d-flex justify-content-between">
                                        <div className="mt-2">
                                            <img src={charm_circle} height="26px" width="26px" />
                                        </div>
                                        <div className="ms-3">
                                            <h6 className="fs-15 p-0 m-0  ff-poppins-medium primary-text">Get It First, Skip the Wait!</h6>
                                            <p className="fs-12  p-0 m-0 ff-poppins-regular">Pre-order now and enjoy priority delivery before everyone else!</p>
                                        </div>
                                    </div>
                                    <div className=" d-flex justify-content-between">
                                        <div className="mt-2">
                                            <img src={charm_circle} height="26px" width="26px" />
                                        </div>
                                        <div className="ms-3">
                                            <h6 className="fs-15 p-0 m-0  ff-poppins-medium primary-text">Secure It Before It's Gone!</h6>
                                            <p className="fs-12  p-0 m-0 ff-poppins-regular">Be the first to own our hottest product—limited stock available!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-3">
                                <div className="d-flex justify-content-center col-6">
                                    <div className="membershipListBox">
                                        <p className="ff-poppins-semiBold m-0 fs-14">Products</p>
                                        <div className="d-flex align-items-center">
                                            <p className="ff-poppins-semiBold m-0 fs-15 primary-text">
                                                {data?.products_count}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center col-6">
                                    <div className="membershipListBox">
                                        <p className="ff-poppins-semiBold m-0 fs-14">Delivery Type</p>
                                        <div className="d-flex align-items-center">
                                            <p className="ff-poppins-semiBold m-0 fs-15 primary-text">
                                                {data?.delivery_type_lable}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start mt-4">
                                <div className="d-flex justify-content-center col-6">
                                    <div className="membershipListBox">
                                        <p className="ff-poppins-semiBold m-0 fs-14">Redeem Validity</p>
                                        <div className="d-flex align-items-center">
                                            <p className="ff-poppins-semiBold m-0 fs-15 primary-text">
                                                {data?.redeem_validity}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="p-2">
                                <h5 className="fs-15 ff-poppins-semiBold p-0">Product</h5>
                                {
                                    data?.products?.map((item, index) => {
                                        return (
                                            <div className="d-flex secondary-box p-2 mt-2 rounded-3">
                                                <div>
                                                    <img
                                                        src={item.imges && item.imges[0] ? item.imges[0].image_url : DEFAULT_IMAGE_URL}
                                                        onError={(e) => e.target.src = DEFAULT_Ticket_IMAGE_URL}
                                                        height="80px"
                                                        width="80px"
                                                        className="rounded-3"
                                                    />

                                                </div>
                                                <div className="ms-3">
                                                    <h5 className="p-0 m-0 fs-14 ff-poppins-regular" >{item.product_name} </h5>
                                                    <p className="p-0 m-0 fs-10 ff-poppins-regular" >{item.price} </p>
                                                    <p className="p-0 m-0 fs-10 ff-poppins-regular" > {item.unit_quantity} {item.unit_type_name}</p>
                                                    <p className="p-0 m-0 fs-10 ff-poppins-regular" >More details</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            <div className="mb-4">
                                <h6 className="p-0 m-0 fs-12 ff-poppins-regular">Ticket <span className="primary-text"> Terms & condition</span></h6>
                                {
                                    data?.terms_conditions?.map((item, index) => {
                                        return (
                                            <div className="mt-2 mb-2 d-flex ">
                                                <div className="p-0 m-0 fs-12  primary-text ff-poppins-semi-Bold">
                                                    {index + 1}.
                                                </div>
                                                <p className="p-0 m-0 fs-12 ms-2 ff-poppins-regular">{item} </p>
                                            </div>
                                        )
                                    })

                                }
                            </div>
                        </div>



                        <div className="bg-light w-100" style={{ position: 'sticky', bottom: -8 }} >
                            <button className="ff-poppins-regular primary-bg mt-3 mb-3 text-white w-100 sticky_bottom_btn_child" onClick={() => openShippingModal()} >
                                Buy Now
                            </button>
                        </div>





                    </div> :
                    (
                        <div className=" ReactLoading">
                            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                        </div>
                    )
            }

            {
                showModal && (
                    <div className="modal-backdrop show"></div>
                )
            }
            {
                showModal && (
                    <div className="modal justify-content-center" tabIndex="-1" style={{ display: 'block' }}>
                        <div className="modal-dialog-centered loginWarningDialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='ff-poppins-semiBold fs-1 primary-text text-center'>
                                        Attention!
                                    </div>
                                    <div className='ff-poppins-regular fs-14 text-center mt-2'>
                                        To proceed further you need to login/register and get access to all the features.
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center mt-1 mb-1' role='button'  >
                                        <div className='loginBtn primary-bg text-center text-white p-1 ff-poppins-medium fs-16'>Login</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center' role='button' onClick={handleClose}>
                                        <div className='loginBtn text-center text-secondary p-1 ff-poppins-medium fs-16 secondary-bg-1 mt-2'>Close</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <div className="offcanvas offcanvas-bottomm Choose_delivery_options main top_border_radius" tabIndex="-1" aria-labelledby="offcanvasBottomLabel"
                ref={shippingtypeRef} style={{ maxHeight: "32vh", zIndex: 1045 }}>
                <div className="offcanvas-body small bg-light ">
                    <div className="div-container">

                        <div className="d-flex justify-content-between">
                            <h5 className="ff-poppins-semiBold fs-16 text-dark ">Select Your Preferred Delivery Type</h5>

                            <p className=" ff-poppins-regular  fs-14 secondary-text-2 " onClick={() => closeShippingModal()}> <IoMdClose /> </p>
                        </div>

                        <div className={`${shipping_type == "1" ? "primary-box primary-text" : "secondary-box"} rounded-3`}>
                            <div className="d-flex justify-content-between align-items-center p-2" onClick={() => { setShiping_type("1"); }}>
                                <div className="d-flex align-items-center">
                                    {
                                        shipping_type == "1" ?
                                            <img src={select_circle} height="25px" width="25px" /> :
                                            <img src={unselect_circle} height="25px" width="25px" />
                                    }
                                    <div className="ms-3">
                                        <h6 className="fs-13 p-0 m-0 ff-poppins-semiBold">Instore Pickup</h6>
                                        <p className="fs-11 p-0 m-0 ff-poppins-regular ">Hassle-free in-store pickup available!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`${shipping_type == "2" ? "primary-box primary-text" : "secondary-box"} rounded-3 mt-2 mb-2`}>
                            <div className="d-flex justify-content-between align-items-center p-2" onClick={() => setShiping_type("2")}>
                                <div className="d-flex align-items-center">
                                    {
                                        shipping_type == "2" ?
                                            <img src={select_circle} height="25px" width="25px" /> :
                                            <img src={unselect_circle} height="25px" width="25px" />
                                    }
                                    <div className="ms-3">
                                        <h6 className="fs-13 p-0 m-0 ff-poppins-semiBold">Doorstep Delivery</h6>
                                        <p className="fs-11 p-0 m-0 ff-poppins-regular ">Hassle-free doorstep delivery available!</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <button className={`primary-bottom-btn rounded-3 fs-14 ff-poppins-regular ${shipping_type == "" ? "opacity_Cls" : ""} `} onClick={() => shipping_type ? add_to_cart() : ""} >
                                continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && <div className="modal-backdrop fade show"></div>}


        </>
    )
}














