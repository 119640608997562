import { useRef, useState } from 'react';
import p_nav_previous from '../../../Assets/Images/p_nav_previous.svg';
import p_add_aadhar from '../../../Assets/Images/p_add_aadhar.svg';
import p_right_arrow from '../../../Assets/Images/p_right_arrow.svg';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMyProfileDetails, uploadAadharImage } from '../../../Services/api';
import { toast } from 'react-toastify';
import ImageCropperModal from '../../../Components/modal/imageCropperModal';

const EditProfileAadhar = () => {

    const navigate = useNavigate();
    const fileRef1 = useRef(null);
    const fileRef2 = useRef(null);
    const [aadharFontSide, setAadharFrontSide] = useState('');
    const [aadharBackSide, setAadharBackSide] = useState('');
    const [isLoading, setLoadingStatus] = useState(false);

    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const [imagePosition, setImagePosition] = useState(0);


    useEffect(() => {
        getMyProfile();
    }, []);

    const getMyProfile = () => {
        getMyProfileDetails().then((res) => {
            const aadharFront = res.data?.user_details?.aadhar_front_side;
            const aadharBack = res.data?.user_details?.aadhar_backend_side;
            setAadharFrontSide(aadharFront);
            setAadharBackSide(aadharBack);
            console.log('front - ', aadharFront.charAt(aadharFront.length - 1))
        });
    }

    const handleFileRead = async (event, position) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);
        setImagePosition(position)

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const onImageCropped = (e) => {
setLoadingStatus(true)
        if (e) {
            let body = {
                "aadhar_img": e,
                "img_position": imagePosition
            }

            uploadAadharImage(body)
                .then((res) => {

                    if (res.data.status === "1") {

                        (imagePosition == 1)
                            ? setAadharFrontSide(res.data.results)
                            : setAadharBackSide(res.data.results);

                        setIsImageCropperModaOpen(false);
                    }
                    setLoadingStatus(false);

                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
            clearFileInput();
        }
    }

    const clearFileInput = () => {
        if (fileRef1.current) {
            fileRef1.current.value = null;
        }
        if (fileRef2.current) {
            fileRef2.current.value = null;
        }
    };

    // const handleFileRead = async (event, position) => {
    //     const file = event.target.files[0]
    //     const base64 = await convertBase64(file);

    //     if (base64) {
    //         let body = {
    //             "aadhar_img": base64.replace('data:image/jpeg;base64,', ''),
    //             "img_position": position
    //         }
    //         uploadAadharImage(body)
    //             .then((res) => {

    //                 if (res.data.status === "1") {

    //                     (position == 1)
    //                         ? setAadharFrontSide(res.data.results)
    //                         : setAadharBackSide(res.data.results);
    //                 }
    //                 setLoadingStatus(false);

    //             })
    //             .catch((error) => {
    //                 setLoadingStatus(false)
    //                 toast(error.message, {
    //                     position: "bottom-center",
    //                     autoClose: 5000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "light",
    //                     className: 'text-danger toast-style-1'
    //                 });
    //             });

    //     }
    // }

    // const convertBase64 = (file) => {

    //     if (file) {
    //         return new Promise((resolve, reject) => {
    //             const fileReader = new FileReader();
    //             fileReader.readAsDataURL(file)
    //             fileReader.onload = () => {
    //                 resolve(fileReader.result);
    //             }
    //             fileReader.onerror = (error) => {
    //                 reject('custom error', error);
    //             }
    //         })
    //     }
    // }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => navigate(-1)} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>Verify Identity</div>
                </div>
            </div>

            <div className='profile-edit-aadhar scroller-style-y'>
                <div className='m-2 p-1'>
                    <div className='mx-3 px-2 my-3 py-2'>
                        <div className='ff-poppins-semiBold fs-20'>AADHAR CARD</div>
                        <div className='ff-poppins-regular fs-20'>ADD COPY</div>
                    </div>

                    {/* Aadhar fornt  */}
                    <div className='uploadAadhar primary-bg d-flex justify-content-center align-items-center' role='button' onClick={() => { fileRef1.current?.click() }}
                        style={{
                            backgroundImage: aadharFontSide.charAt(aadharFontSide.length - 1) == '/'
                                ? 'unset'
                                : `url(${aadharFontSide})`
                        }}>
                        <input className='d-none' type='file' accept="image/*" onChange={(e) => handleFileRead(e, 1)} ref={fileRef1} />
                        <img src={p_add_aadhar} width={'46px'} height={'46px'} alt='Add Aadhar Button' />
                    </div>
                    <div className='ff-poppins-semiBold fs-16 text-center'>Front Side</div>

                    {/* Aadhar back  */}
                    <div className='uploadAadhar primary-bg d-flex justify-content-center align-items-center' role='button' onClick={() => { fileRef2.current?.click() }}
                        style={{
                            backgroundImage: aadharBackSide.charAt(aadharBackSide.length - 1) == '/'
                                ? 'unset'
                                : `url(${aadharBackSide})`
                        }}>
                        <input className='d-none' type='file' accept="image/*" onChange={(e) => handleFileRead(e, 2)} ref={fileRef2} />
                        <img src={p_add_aadhar} width={'46px'} height={'46px'} alt='Add Aadhar Button' />
                    </div>
                    <div className='ff-poppins-semiBold fs-16 text-center'>Back Side</div>

                </div>
            </div>

            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                onSelect={onImageCropped}
                isLoading={isLoading}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
                enableRatio={true}
            />
        </>
    )
}

export default EditProfileAadhar