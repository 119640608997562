import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_dummyImage from '../../Assets/Images/p_dummyImage.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { addUserFamilyMember, deleteFamilyMember, uploadFamilyMemberImage, userSingleFamilyMemberDetail, userUpdateSingleFamilyMemberDetail } from '../../Services/api';
import p_options_vertical from '../../Assets/Images/p_options_vertical.svg';
import ImageCropperModal from '../../Components/modal/imageCropperModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import default_person_img from "../../Assets/Images/defaultperson.jpg"
const AddFamilyMember = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setLoadingStatus] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const { frompage, vendorId } = location.state || {};
    const [vendorid, setVendorid] = useState(vendorId)
    const [profilePhoto, setProfilePhoto] = useState(p_dummyImage);
    const [profilePhotoName, setProfilePhotoName] = useState('');
    const [value, setValue] = useState('');
    const fileRef = useRef(null);
    const [isImageCropperModaOpen, setIsImageCropperModaOpen] = useState(false);
    const [imageToCrop, setImageToCrop] = useState('');
    const today = new Date();
    const [modalOpen, setModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        "date_of_birth": '',
        "person_image": "",
        "name": "",
        "gender": "",
        "relationship": "",
        "about": "",
        "person_id": ""
    });
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {

        if (location.state && location.state?.person_id) {
            userSingleFamilyMemberDetail(location.state).then((res) => {
                setFormData({
                    date_of_birth: res.data?.person_detail?.date_of_birth,
                    person_image: res.data?.person_detail?.person_image_name,
                    name: res.data?.person_detail?.name,
                    gender: res.data?.person_detail?.gender,
                    date_of_birth: res.data?.person_detail?.date_of_birth,
                    relationship: res.data?.person_detail?.relationship,
                    about: res.data?.person_detail?.about,
                    person_id: res.data?.person_detail?.person_id,
                });

                setProfilePhoto(res.data?.person_detail?.person_image);
            });
        }
    }, [value]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleInputFocus = (e) => {
        e.target.blur(); // Blur the input field to dismiss the keyboard
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        handleChange({ target: { name: 'date_of_birth', value: date } });
    };

    const dispErrorMsg = (msg) => {
        toast(msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file);

        if (base64) {
            setImageToCrop(base64);
            setIsImageCropperModaOpen(true);
        }
    }

    const convertBase64 = (file) => {

        if (file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject('custom error', error);
                }
            })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.name == '') {
            dispErrorMsg('Person Name field required')
            return
        }
        else if (formData.gender == '') {
            dispErrorMsg('Person Gender field required')
            return
        }
        else if (formData.date_of_birth == '') {
            dispErrorMsg('Person DOB field required')
            return
        }
        else if (formData.relationship == '') {
            dispErrorMsg('Person relationship field required')
            return
        }
        else if (formData.about == '') {
            dispErrorMsg('About Them field required')
            return
        }

        setLoadingStatus(true);
        setBtnLoading(true)
        if (formData.person_id != '') {
            userUpdateSingleFamilyMemberDetail(formData)
                .then((res) => {
                    setLoadingStatus(false);

                    if (res.data?.status === '1') {
                        navigate('/profile', { state: { divRef: 'familyMembers' } })
                        setBtnLoading(false)
                    }

                })
                .catch((error) => {
                    setLoadingStatus(false)  
                       setBtnLoading(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        } else {
            addUserFamilyMember(formData)
                .then((res) => {
                    setLoadingStatus(false);
                    setBtnLoading(false)
                    if (res.data?.status === '1') {
                        if (frompage == "ServiceBookingFlow") {
                            navigate(`/door-step-service/${vendorid}`, { state: { AddPage: 'AddMember' } })
                        } else {
                            navigate('/profile', { state: { divRef: 'familyMembers' } })

                        }
                    }
                })
                .catch((error) => {
                    setLoadingStatus(false)
                    setBtnLoading(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        }

    }

    const deleteMember = () => {
        deleteFamilyMember(location.state).then((res) => {

            if (res.data?.status === '1') {
                navigate('/profile', { state: { divRef: 'familyMembers' } })
            }
        })
    }

    const onImageCropped = (e) => {

        if (e) {
            let body = {
                "person_image": e
            }
            uploadFamilyMemberImage(body)
                .then((res) => {

                    if (res.data.status === "1") {
                        setProfilePhoto(res.data.results);
                        setProfilePhotoName(res.data.img_name);
                        setFormData(prevData => ({
                            ...prevData,
                            person_image: res.data.img_name
                        }));

                        setIsImageCropperModaOpen(false);
                        setLoadingStatus(false);
                    }
                    setLoadingStatus(false);

                })
                .catch((error) => {
                    setLoadingStatus(false)
                    toast(error.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
            clearFileInput();
        }
    }

    const clearFileInput = () => {
        if (fileRef.current) {
            fileRef.current.value = null;
        }
    };
    const openModal = () => {
        setModalOpen(true);
        setIsOpen(false)
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-2 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} alt='Previous Button' role='button' onClick={() => {
                        if (frompage == "ServiceBookingFlow") {
                            navigate(`/door-step-service/${vendorid}`, { state: { AddPage: 'AddMember' } })
                        }
                        else {
                            navigate('/profile', { state: { divRef: 'familyMembers' } })
                        }
                    }
                    }

                    />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>
                        {
                            location.state && location.state?.person_id
                                ?
                                <span>Edit </span>
                                :
                                <span>Add </span>
                        }
                        Family Member
                    </div>

                </div>

                {
                    formData?.person_id &&
                    <div className="profileEdit position-relative">

                        <img src={p_options_vertical} width="27px" height="27px" role="button" onClick={toggleDropdown} alt="Profile Options" />
                        {isOpen && (

                            <ul className="delete_dropdown position-top-end primary-bg p-0 rounded-3" aria-labelledby="profile-options"
                                style={{ display: isOpen ? 'block' : 'none', }} onClick={() => openModal()}>
                                <li className='delete_dropdown_ul '>
                                    <span className="delete_dropdown-item ms-2  text-light ff-poppins-bold fs-12" role="button">Delete</span>
                                </li>
                            </ul>

                        )}
                    </div>
                }
            </div>

            <div className='formPage scroller-style-y' >
                <div className='text-center'>
                    <img className='bg-white rounded-3' src={profilePhoto ? profilePhoto : default_person_img} width={'110px'} height={'110px'} alt='Default image' onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }} />
                    <div className='d-flex justify-content-center'>
                        <input className='d-none' type='file' accept="image/*" onChange={handleFileRead} ref={fileRef} />
                        <div className='addPhotoBtn d-flex justify-content-center align-items-center primary-bg text-white ff-poppins-regular fs-16 fw-600' role='button' onClick={() => { fileRef.current?.click() }}>Add Photo</div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    {/* Person name  */}
                    <input type='text' className='mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-16 bg-white text-dark' placeholder='Person Name' name="name" value={formData.name} onChange={handleChange} maxLength={20} />

                    {/* Person Gender  */}
                    <select className="form-select border-0 ff-poppins-regular fs-16 mb-3" aria-label="Default select example" name="gender" value={formData.gender} onChange={handleChange} >
                        <option value='' selected disabled>Person Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>

                    {/* Person DOB */}
                    <DatePicker
                        className='mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-16 bg-white text-dark'
                        selected={formData.date_of_birth}
                        onChange={handleDateChange}
                        placeholderText='Person DOB'
                        dateFormat='EEE MMM dd yyyy'
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        name='date_of_birth'
                        onFocus={handleInputFocus}
                        maxDate={today}
                    />

                    {/* Person relationship  */}
                    <input type='text' className='mb-3 ps-3 pe-3 pt-0 pb-0 w-100 ff-poppins-regular fs-16 bg-white text-dark' placeholder='Person relationship' maxLength={20} name="relationship" value={formData.relationship} onChange={handleChange} />

                    {/* About Them  */}
                    <textarea className=' w-100 border-0 ff-poppins-regular fs-16' rows={5} placeholder="About Them" maxLength={120} name="about" value={formData.about} onChange={handleChange}></textarea>
                    <div className='d-flex justify-content-end ff-poppins-regular fs-14 mt-1 mb-3'>{formData?.about?.length} / 120</div>
                    <div className='d-flex justify-content-center'>
                        <button className='submitBtn d-flex justify-content-center align-items-center primary-bg ff-poppins-regular fs-16 fw-600 text-white border-0'>

                            {formData.person_id != '' ? 'UPDATE' : 'ADD'} MEMBER

                        </button>
                    </div>
                </form>
            </div>

            {/* Delete Modal  */}

            {
                modalOpen &&
                <div class={`modal   d-flex align-items-center`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-body ps-5 pe-5 pt-4 pb-4">
                                <div className='text-center'>Are you sure you want to delete this person</div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <button type="button" class="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                                    <button type="button" class="btn btn-danger" onClick={() => deleteMember()} data-bs-dismiss="modal">Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <ImageCropperModal
                isOpen={isImageCropperModaOpen}
                inputImage={imageToCrop}
                isLoading={isLoading}
                onSelect={onImageCropped}
                onCancel={() => {
                    setIsImageCropperModaOpen(false);
                    clearFileInput();
                }}
            />
        </>
    )
}

export default AddFamilyMember;