import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CountdownTimer from "../../Components/CountdownTimer";
import { userResendOTP, userVerifyOTP } from "../../Services/api";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";

const OtpVerification = () => {

    const [value, setValue] = useState('');
    // const [otp, setOTP] = useState("");
    const [otpError, setOTPError] = useState("");
    const [showResendOTP, setShowResendOTP] = useState(false);
    const [triggerTimer, setTriggerTimer] = useState(false);
    const countDownTimerRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");
    const vendorUrl = localStorage.getItem("vendorUrl");
    const [isLoading, setLoadingStatus] = useState(false);


    // const [otp, setOtp] = useState(['', '', '', '']);
    // const otpInputs = useRef([]);

    // const [otp, setOTP] = useState(['', '', '', '']);
    // const inputs = useRef([]);
    const [otp, setOTP] = useState([]);
    console.log("setOTP", otp);

    useEffect(() => {

        // if (token) {
        //     navigate('/');
        //     return
        // }

        if (!location.state.mobileNumber && location.state.mobileNumber == '') {
            navigate('/login')
        }

        // const inputs = document.getElementById("otpInputGroup");

        // inputs.addEventListener("input", function (e) {
        //     const target = e.target;
        //     const val = target.value;

        //     if (isNaN(val)) {
        //         target.value = "";
        //         return;
        //     }

        //     if (val !== "") {
        //         const next = target.nextElementSibling;
        //         if (next) {
        //             next.focus();
        //         }
        //     }
        // });

        // inputs.addEventListener("keydown", function (e) {
        //     const target = e.target;
        //     const key = e.key.toLowerCase();

        //     if (key === "backspace" || key === "delete") {
        //         target.value = "";
        //         const prev = target.previousElementSibling;
        //         if (prev) {
        //             prev.focus();
        //         }
        //     }
        // });

        // inputs.addEventListener("focusout", function (e) {
        //     const target = e.target;
        //     const val = target.value;

        //     // Ensure that focus remains on the input or its sibling
        //     if (val === "") {
        //         const prev = target.previousElementSibling;
        //         if (prev) {
        //             prev.focus();
        //         } else {
        //             const next = target.nextElementSibling;
        //             if (next) {
        //                 next.focus();
        //             }
        //         }
        //     }
        // });
    }, [value]);

    const verifyOtp = () => {
        setOTPError('');

        if (document.getElementsByClassName("otpInput").item(0).value == '') {
            toast("Please Enter a Valid OTP", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'text-danger toast-style-1'
            });
            return
        }

        if (document.getElementsByClassName("otpInput").item(1).value == '') {
            toast("Please Enter a Valid OTP", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'text-danger toast-style-1'
            });
            return
        }

        if (document.getElementsByClassName("otpInput").item(2).value == '') {
            toast("Please Enter a Valid OTP", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'text-danger toast-style-1'
            });
            return
        }

        if (document.getElementsByClassName("otpInput").item(3).value == '') {
            toast("Please Enter a Valid OTP", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: 'text-danger toast-style-1'
            });
            return
        }

        let OTP = '' + Number(document.getElementsByClassName("otpInput").item(0).value)
            + Number(document.getElementsByClassName("otpInput").item(1).value)
            + Number(document.getElementsByClassName("otpInput").item(2).value)
            + Number(document.getElementsByClassName("otpInput").item(3).value)

        let body = {
            "user_mbl": location.state.mobileNumber,
            "otp_value": OTP,
            "device_token": "ekZyxQJ7TYaxXZPHO7RcBs:APA91bHj9MIRcnEIRQiOKFnpHJqjkcpY86pRyd7KA0vV5nNQ9xHrhC3kjU5IsRo_hfEIIRJInhHrpxIut_gnDN8dbdHfAPrg-TheFCT2qiQ-2R0wgDYotxixAwSV0ohE4rN17Hysju3h"
        };

        userVerifyOTP(body)
            .then((res) => {
                console.log('verifyOTP details - ', res);
                localStorage.setItem('token', res.data.jwt_token);

                if (res.data.reg_status === "2") {
                    navigate('/userRegister', { state: { mobileNumber: location.state.mobileNumber } })
                }
                else {
                    navigate(vendorUrl ? vendorUrl : '/')
                }
            })
            .catch((error) => {
                toast("Please Enter a Valid OTP", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    className: 'text-danger toast-style-1'
                });
            });
    }

    const resendOTP = () => {
        let body = {
            "user_mbl": location.state.mobileNumber,
            "sms_hash_code": "AXZOLD23de"
        };

        userResendOTP(body)
            .then((res) => {
                setTriggerTimer(triggerTimer => !triggerTimer);
                setShowResendOTP(false)
            });
    }



    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        const enteredOtp = otp;
        if (enteredOtp.length === 4) {
            setLoadingStatus(true);
            let body = {
                "user_mbl": location.state.mobileNumber,
                "otp_value": enteredOtp,
                "device_token": "ekZyxQJ7TYaxXZPHO7RcBs:APA91bHj9MIRcnEIRQiOKFnpHJqjkcpY86pRyd7KA0vV5nNQ9xHrhC3kjU5IsRo_hfEIIRJInhHrpxIut_gnDN8dbdHfAPrg-TheFCT2qiQ-2R0wgDYotxixAwSV0ohE4rN17Hysju3h"
            };

            userVerifyOTP(body)
                .then((res) => {
                    console.log('verifyOTP details - ', res);
                    localStorage.setItem('token', res.data.jwt_token);

                    if (res.data.reg_status === "2") {
                        navigate('/userRegister', { state: { mobileNumber: location.state.mobileNumber } })
                        setLoadingStatus(false);
                    }
                    else {
                        navigate(vendorUrl ? vendorUrl : '/')
                        setLoadingStatus(false);
                    }
                })
                .catch((error) => {
                    setLoadingStatus(false);
                    toast("Please Enter a Valid OTP", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        className: 'text-danger toast-style-1'
                    });
                });
        } else {
            console.log('Invalid OTP');
        }
    };


    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            // If backspace is pressed and the current input is empty, move to the previous input
            inputs.current[index - 1].focus();
            inputs.current[index - 1].select();
        }
    };

    const inputs = useRef([]);

 
    const handleChange = (otp, index) => {
        const numericOtp = otp.replace(/\D/g, ''); // Remove non-numeric characters
    
        // Prevent automatic shifting of digits
        if (numericOtp.length < otp.length) {
            setOTP((prevOtp) => {
                const otpArray = prevOtp.split("");
                otpArray[index] = ""; // Clear the deleted input
                return otpArray.join("");
            });
        } else {
            setOTP(numericOtp); // Update only if input is numeric
        }
    };
    
    return (
        <>
            <div className="loginPage p-0 vh-100 bg-white d-flex align-items-center">
                <div className="px-3">
                    <div className="ff-balooPaaji-regular fs-24 secondary-text">Enter OTP</div>
                    <div className="ff-poppins-light fs-14 secondary-text">We have sent you an SMS with 4 digit Verification code</div>

                    <form className='mt-4 text-center' onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-center">

                            {/* <OTPInput
                                value={otp}
                                onChange={(otp, index) => {
                                    const numericOtp = otp.replace(/\D/g, ''); // Remove non-numeric characters
                                    if (numericOtp.length === otp.length) {
                                        handleChange(otp); // Update only if input is numeric
                                    }
                                }}
                                numInputs={4}
                                inputStyle={{
                                    width: '3rem',
                                    height: '3rem',
                                    margin: '0 0.5rem',
                                    textAlign: 'center',
                                    background: 'transparent',
                                    fontSize: '2rem',
                                    color: 'var(--primary-text-color)',
                                    borderRadius: '4px',
                                }}
                                renderInput={(props) => (
                                    <input
                                        {...props}
                                        inputMode="numeric" // Set input mode to numeric
                                        className="otp-input bg-transparent me-3 text-center ff-balooPaaji2-regular fs-40 primary-text"
                                    />
                                )}
                            /> */}

                            <OTPInput
                                value={otp}
                                onChange={(otp) => {
                                    const numericOtp = otp.replace(/\D/g, ''); // Remove non-numeric characters
                                    if (numericOtp.length === otp.length) {
                                        handleChange(otp); // Update only if input is numeric
                                        if (numericOtp.length === 4) {
                                            document.activeElement.blur(); // Remove focus when all 4 digits are entered
                                        }
                                    }
                                }}

                                 shouldAutoFocus={false}
                                numInputs={4}
                                inputStyle={{
                                    width: '3rem',
                                    height: '3rem',
                                    margin: '0 0.5rem',
                                    textAlign: 'center',
                                    background: 'transparent',
                                    fontSize: '2rem',
                                    color: 'var(--primary-text-color)',
                                    borderRadius: '4px',
                                }}
                                renderInput={(props) => (
                                    <input
                                        {...props}
                                        inputMode="numeric"
                                        className="otp-input bg-transparent me-3 text-center ff-balooPaaji2-regular fs-40 primary-text"
                                    />
                                )}
                            />

                        </div>
                        <span className="ff-poppins-regular fs-14 text-danger">{otpError}</span>
                        <div className="d-flex justify-content-center" onClick={() => {
                            if (otp.length < 4) {
                                toast("Please Enter the OTP", {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    className: 'text-danger toast-style-1'
                                });
                            }
                        }}   >
                            {/* <button type="submit" className="text-center w-100 border-0 primary-bg rounded-pill ff-poppins-semiBold fs-6 text-white mt-5" >
                                Continue
                            </button> */}

                            <button type="submit" className={(isLoading ? "loading" : "") + " " + "w-100 border-0 primary-bg rounded-pill ff-poppins-semiBold fs-20 text-white mt-5 d-flex justify-content-center align-items-center"}>
                                {
                                    isLoading
                                        ?
                                        <span className="loader-style-1"></span>
                                        :
                                        <span>Continue</span>
                                }
                            </button>
                        </div>
                        <div className="ff-poppins-light fs-16 text-center secondary-text-1 mt-3 pt-2">
                            {showResendOTP ?
                                <span>
                                    Didn’t receive code?
                                    <span role="button" className="primary-text" onClick={() => {
                                        resendOTP()
                                    }}> Click Here</span>
                                </span>
                                :
                                <span>Resend OTP in 0:<CountdownTimer trigger={triggerTimer} triggerStart={() => setShowResendOTP(true)} /></span>
                            }
                        </div>

                        {/* <button
                            className='submitBtn mt-4 primary-bg text-white ff-poppins-semiBold fs-20 d-flex justify-content-center align-items-center border-0 w-100'
                            type="submit">
                            Submit
                        </button> */}

                    </form>

                    {/* <div className="d-flex justify-content-center align-items-center mt-4 pt-1">
                        <div id="otpInputGroup" className="w-100 d-flex justify-content-between inputs">
                            <input className="ff-balooPaaji2-regular fs-40 otpInput" type="text"
                                inputMode="numeric" maxLength="1" />
                            <input className="ff-balooPaaji2-regular fs-40 otpInput" type="text"
                                inputMode="numeric" maxLength="1" />
                            <input className="ff-balooPaaji2-regular fs-40 otpInput" type="text"
                                inputMode="numeric" maxLength="1" />
                            <input className="ff-balooPaaji2-regular fs-40 otpInput" type="text"
                                inputMode="numeric" maxLength="1" />
                        </div>
                    </div> */}

                </div >
            </div >
        </>
    )
};

export default OtpVerification;