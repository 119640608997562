import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../Services/axios';
import { IoArrowBackSharp } from "react-icons/io5";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
import TicketCard from '../../Components/PreOrderTicketCard/TicketCard';
import TicketCalender from '../../Components/calender/TicketCalender';
import ReactLoading from "react-loading";

const TicketList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const { vendor_Id, flowParent, vendorName } = location.state || {};
    const [data, setData] = useState('')
    const [tickets, setTickets] = useState([])
    const [screen, setScreen] = useState('list_screen')
    const [pageLoading, setPageLoading] = useState(false)
    let { vendorId } = useParams();



    const getFilteredTicketDetails = (formattedDate) => {
        try {
            if (formattedDate) {
                let reqData = {
                    "vendor_id": vendorId,
                    "date": formattedDate
                }
                axiosInstance.post('/user/pre_order/fetch_vendor_preorder_tickets', reqData)
                    .then((res) => {
                        setTickets(res.data.tickets)
                        setData(res.data)
                        setScreen("list_screen")
                        setPageLoading(true)
                    })

            } else {
                setTickets([])
                setData('')
                setScreen("list_screen")
            }

        }
        catch (err) {
            alert('try again later')
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(true);
        }, 2000);
    }, [])


    return (
        <div className=' '>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <IoArrowBackSharp size={25} onClick={() => navigate(`/vendor/${data.vendor_id}`)} />


                    <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
                        Preorder
                    </div>
                </div>
            </div>


            <TicketCalender handleFilteredTicketDate={(formattedDate) => getFilteredTicketDetails(formattedDate)} />
            {
                pageLoading ?
                    <>
                        <div className='scroller-style-y  ' style={{paddingBottom:"50%"}}>
                            <div className="p-3">
                                <p className="ff-poppins-bold fs-24 ms-2 secondary-text m-0"> {vendorName}</p>
                            </div>
                            {
                                tickets.length > 0 ?
                                    tickets.map((list, index) => {
                                        return (
                                            <div>
                                                <TicketCard Data={list} /> 

                                            </div>
                                        )
                                    })
                                    :
                                    <>
                                        <div>
                                            <Lottie animationData={empotyLottie} />
                                        </div>
                                        <p className='text-dark text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Ticket Available</p>
                                    </>
                            }

                            <div style={{ height: 200 }}></div>
                        </div>
                    </> :
                    <div className="ReactLoading">
                        <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                    </div>
            }
        </div>
    )
}

export default TicketList
