import { useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { getChatList } from "../../Services/api";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MembershipCard from "../../Components/MembershipCard/MembershipCard";
import axiosInstance from "../../Services/axios";
import { BsThreeDots } from "react-icons/bs";
import "./membershipList.css";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
import ReactLoading from "react-loading";
const VendorMembership = () => {
  const [myMembershipVendor, setMyMembershipVendor] = useState([]);
  const location = useLocation();
  const [myMembershipID, setMyMembershipID] = useState([]);
  const [myMembershipVendorID, setMyMembershipVendorID] = useState();
  const { vendorId, vendorName } = location.state || {};
  const [membershipdetails, setMembershipdetails] = useState();
  const [membershipdetailsForUser, setMembershipdetailsForUser] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState({
    vendorID: null,
    membershipID: null,
    PurchaseID: null,
  });

  useEffect(() => {
    const getMyMembership = async () => {
      try {
        let reqData = {
          vendor_id: vendorId,
        };
        const res = await axiosInstance.post(
          "/user/membership/my_membership_cards",
          reqData
        );
        let Data = res.data.user_memberships_details;


        setMyMembershipVendor(Data);
        const MID = res.data.user_memberships_details.map(
          (membershipid) => membershipid.membership_id
        );
        const VID = res.data.user_memberships_details.map(
          (membershipVendorid) => membershipVendorid.vendor_id
        );
        setMyMembershipID(MID);
        setMyMembershipVendorID(VID);

        const MembershipDetailss = res.data.user_memberships_details.map(
          (membershipStatus) =>
            membershipStatus.membership_detail.category_status
        );
        setMembershipdetailsForUser(MembershipDetailss);
      } catch (error) {

        alert("Failed to fetch membership details. Please try again later.");
      }
    };

    if (vendorId) {
      getMyMembership();
    }
    
  }, []);

  useEffect(() => { }, [myMembershipVendor]);
  const handleCardClick = (vendorID, membershipID, PurchaseID) => {
    setSelectedCard({ vendorID, membershipID, PurchaseID });;
  };
  setTimeout(() => {
    setPageLoaded(true);
  }, 3000);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            My Membership merchant
          </div>
        </div>
      </div>
      {/* <div className="p-3">
        <p className="ff-poppins-bold fs-24 ms-2 secondary-text m-0"> {vendorName}</p>
       
      </div> */}
      <div className="messengerList-container ">
        <div className="messengerList scroller-style-y" style={{ overflowX: 'hidden' }}>
          <div>
            {
              pageLoaded ?

                myMembershipVendor.length > 0 ? (
                  myMembershipVendor.map((membership, index) => (
                    <div className="" key={index}>
                      <button
                        className="MembershipDetailButton"
                        onClick={() =>
                          navigate("/MembershipBooking", {
                            state: {
                              vendorId: membership.vendor_id,
                              MembershipStatus: membership.membership_detail.category_status,
                              membership_ID: membership.membership_purchase_id
                            },
                          })
                        }
                      >
                        {membership.membership_detail && myMembershipVendor ? (

                          <MembershipCard
                            buttonName="Subscription"

                            threeDot={
                              <BsThreeDots
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasBottom"
                                aria-controls="offcanvasBottom"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCardClick(
                                    membership.vendor_detail.vendor_id,
                                    membership.membership_detail.membership_id,
                                    membership.membership_purchase_id
                                  );
                                }}
                              />
                            }
                            Status={membership.expiry_date}
                            data={{
                              membershipDetails: membership.membership_detail,
                              VendorDetails: membership.vendor_detail,
                              expiryDate: membership.expiry_date
                            }}
                            cardColor={membership.membership_detail.card_color}

                          />
                        ) : (
                          <></>
                        )}
                      </button>
                    </div>
                  ))
                ) : (
                  <>
                    <div>
                      <Lottie animationData={empotyLottie} />
                    </div>
                    <p className='text-dark text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Membership Available</p>
                  </>

                ) :
                <div className="ReactLoading">
                  <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
                </div>}
          </div>

          <div
            className="offcanvas "
            tabIndex="-1"
            id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel"
            style={{
              width: 390,
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              padding: "2%",
              borderRadius: "15px 15px 0px 0px"
            }}
          >
            <div className="">
              <div className="classExtentdMemberShip">
                <div></div>
                <div>
                  <p className="m-0 ff-poppins-semiBold mb-3">Options</p>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
              <div className="position-relative"></div>
              <p
                className="m-0 ff-poppins-regular"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    selectedCard.vendorID &&
                    selectedCard.membershipID &&
                    selectedCard.PurchaseID
                  ) {
                    navigate("/BuyNow", {
                      state: {
                        vendorId: selectedCard.vendorID,
                        membership_ID: selectedCard.membershipID,
                        PurchaseID: selectedCard.PurchaseID,
                        flowParent: "extend",
                      },
                    });
                  }
                }}
              >
                Extend Plan
              </p>
            </div>
            <div className="position-relative"></div>
            <p
              className="m-0 ff-poppins-regular"
              style={{ cursor: "pointer" }}
              onClick={() => {
                {

                  if (
                    selectedCard.vendorID &&
                    selectedCard.membershipID &&
                    selectedCard.PurchaseID
                  ) {
                    navigate("/BuyNow", {
                      state: {
                        vendorId: selectedCard.vendorID,
                        membership_ID: selectedCard.membershipID,
                        PurchaseID: selectedCard.PurchaseID,
                        flowParent: "extend",
                      },

                    });
                  }

                }
              }}
            >
              Extend Plan
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorMembership;
