import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom"; // Import useLocation here
import axiosInstance from "../../Services/axios";
import "./LoyaltyOffer.css";
import pingleUserIcon from "../../Assets/Images/Pingle _UserCoin 1.svg";
import placeholderImage from "../../Assets/Images/default-placeholder.png";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
import loyaltydefalty from '../../Assets/Images/Loyaltydefalty.png'

const MerchantLoyalty = () => {
  const valueParam = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorId } = location.state || {};
  const [loyaltyData, setLoyaltyData] = useState({});

  console.log("loyaltyData", loyaltyData);

  useEffect(() => {
    getLoyaltyOffers();
  }, []);

  const getLoyaltyOffers = () => {
    try {
      let reqData = {
        vendor_id: valueParam.vendorId,
      };
      axiosInstance
        .post("user/my_vendor_loyalty_offers_detail", reqData)
        .then((res) => {
          let data = res.data;
          console.log("data", data);

          setLoyaltyData(data);
        });
    } catch (error) {
      alert("Try again Later");
    }
  };
  const setLoyaltyDetail = (offerid,) => {
    let loyaltyDetail = { 'offerId': offerid, 'vendorid': valueParam.vendorId }
    localStorage.setItem('loyaltyDetail', JSON.stringify(loyaltyDetail));
    navigate(`/singleLoyaltydetails/${valueParam.vendorId}/${offerid}`);


  }
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1"> Loyalty Offers</div>
        </div>
      </div>

      <div className="p-2 scroller-style-y">
        <div className="ms-3">
          <p className="ff-poppins-bold fs-24 secondary-text m-0">{loyaltyData?.vendor_name}</p>
          <p className="ff-poppins-regular fs-16 secondary-text-1">

          </p>
        </div>
        <div>
          <div className="pingleOfferHero" id="availbleofferid">
            {
              loyaltyData?.vendor_unlock_offers_count == 0 && loyaltyData?.vendor_eligble_offers_count == 0 ?
                <div>
                  <Lottie animationData={empotyLottie} />
                </div>
                :
                <>
                  {
                    loyaltyData.vendor_eligble_offers_count != 0 &&
                    <div className="pingleOfferHero" id="availbleofferid">
                      <div className="pinglerOfferItemsCardsDiv">
                        <div className="fw-bold pb-3 fs-5">
                          {loyaltyData.vendor_eligble_offers_count} Offers for You!
                        </div>
                        <div className="row gx-0 px-0">
                          {loyaltyData?.vendor_eligble_offers?.map((offers, index) => (
                            <div className="col-6 px-1 " key={index}>
                              <div className="col-12 d-flex flex-column mx-1 pingleOfferCardBox">
                                <div className="col-6 px-0 w-100">
                                  <img
                                    src={
                                      offers.offer_images.length > 0
                                        ? offers.offer_images[0]
                                        : placeholderImage
                                    }
                                    onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }}
                                    alt={
                                      offers.offer_images.length > 0
                                        ? "Offer Image"
                                        : "No Offer Image"
                                    }
                                    className="pingleOfferImage"
                                  />
                                </div>

                                <div className="col-6 px-0 mainDivPingleOfferCard">
                                  <div className="pingleOfferPointsDiv d-flex justify-content-around primary-bg text-light p-2 ms-2 me-2">
                                    <img src={pingleUserIcon} className="PingleLoyaltyIcon me-2" alt="PLIcon" />
                                    <p className="m-0 ms-1 ff-poppins-semiBold fs-12 ms-1">{offers.offer_points}</p>
                                  </div>
                                  <p
                                    className="mt-3 mb-1 ff-poppins-semiBold fs-12"
                                    style={{
                                      textAlign: "center",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "100%", // Adjust the width as needed
                                    }}
                                    title={offers.offer_name} // Shows the full text on hover
                                  >
                                    {offers.offer_name}
                                  </p>
                                  <p
                                    className="pingleOfferExplainText py-2"
                                    style={{
                                      fontFamily: "Poppins-Light",
                                      fontSize: "11px",
                                      textAlign: "center",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      height: "60px",
                                    }}
                                  >
                                    {offers.offer_discription}
                                  </p>

                                  <button className="pingleOfferRedeemBtnGreen primary-bg ff-poppins-semiBold fs-12" onClick={() => setLoyaltyDetail(offers.offer_id)}>
                                    REDEEM
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  }
                  {
                    loyaltyData?.vendor_unlock_offers_count ?
                      <div className="pingleOfferHero" id="availbleofferid">
                        <div className="pinglerOfferItemsCardsDiv">
                          <div className="row gx-0 px-0">
                            <div className="fw-bold pb-3 fs-5">
                              {loyaltyData?.vendor_unlock_offers_count} Item to unlock
                            </div>
                            <div className="row gx-0 px-0">
                              {loyaltyData?.vendor_unlock_offers && loyaltyData?.vendor_unlock_offers.map((offers, index) => (
                                
                                  <div className="col-6 px-1 " key={index}>
                                    <div className="col-12 d-flex flex-column mx-1 pingleOfferCardBox">
                                      <div className="col-6 px-0 w-100">
                                        <img
                                          src={offers.offer_images.length > 0 ? offers.offer_images[0] : placeholderImage}
                                          onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }}
                                          alt="No Offer Images"
                                          className="pingleOfferImage"
                                        />
                                      </div>

                                      <div className="text-center ">
                                        <div className="d-flex justify-content-center align-item-center ">
                                          <div className="d-flex justify-content-around pingleOfferPointsDiv col-5 primary-bg p-1">
                                            <img src={pingleUserIcon} className="PingleLoyaltyIcon " style={{ width: 25, height: 25, }} alt="PLIcon" />
                                            <p className="m-0 ff-poppins-semiBold text-white  fs-14">{offers?.offer_points}</p>
                                          </div>
                                        </div>
                                        <div className="p-1">
                                          <div className="mt-3 mb-1 offer-description" >
                                            <p className="ff-poppins-bold">
                                              {offers?.offer_name?.length > 10 ? `${offers.offer_name.slice(0, 10)}...` : offers?.offer_name}
                                            </p>

                                            {/* </div>
                           <div className=" text-center"> */}
                                            <p className="fs-12 ff-poppins-regular" numberOfLines={2}>{offers?.offer_discription}</p>
                                          </div>
                                        </div>

                                        <button className=" pingleOfferRedeemBtnGreen secondary-btn-disable" >
                                          REDEEM
                                        </button>
                                      </div>

                                    </div>
                                  </div>
                              
                              ))}
                            </div>
                          </div>
                        </div>
                      </div> : ""
                  }

                </>
            }






          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantLoyalty;
