import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, Polyline, useLoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const defaultCenter = {
  lat: 28.7041, // Default center (Latitude)
  lng: 77.1025, // Default center (Longitude)
};

const LiveTracking = ({ driverLocation, deliveryLocation }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC7cC8d67-AjIHnXizRFxTj1uFkOwnF2zo', // Replace with your API key
  });

  if (!isLoaded) return <div>Loading...</div>;
//   useEffect(() => {
//     const fetchDriverLocation = async () => {
//       try {
//         const response = await fetch('YOUR_API_ENDPOINT');
//         const data = await response.json();
//         setDriverLocation({ lat: data.lat, lng: data.lng });
//       } catch (error) {
//         console.error('Error fetching driver location:', error);
//       }
//     };
  
//     fetchDriverLocation();
//     const interval = setInterval(fetchDriverLocation, 5000);
  
//     return () => clearInterval(interval);
//   }, []);

  
  return (
    <GoogleMap mapContainerStyle={containerStyle} center={defaultCenter} zoom={14}>
      {/* Driver Marker */}
      <Marker position={driverLocation} label="🚚" />

      {/* Delivery Location Marker */}
      <Marker position={deliveryLocation} label="🏠" />

      {/* Route Line */}
      <Polyline
        path={[driverLocation, deliveryLocation]}
        options={{
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 4,
        }}
      />
    </GoogleMap>
  );
};

export default LiveTracking;
