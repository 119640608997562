import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./App.css";
import Login from "./Pages/Auth/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider, createStoreHook } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
//fonts
import "./Assets/Styles/fonts.css";
import "./Assets/Fonts/BalooPaaji-Regular.ttf";
import "./Assets/Fonts/BalooPaaji2-Regular.ttf";
import "./Assets/Fonts/Poppins-SemiBold.ttf";
import "./Assets/Fonts/Poppins-Light.ttf";
import "./Assets/Fonts/Poppins-Regular.ttf";
import OtpVerification from "./Pages/Auth/otpVerification";
import BookingsManager from "./Pages/Booking/bookingsManager";
import UserDashboard from "./Pages/User/dashboard";
import UserProfile from "./Pages/User/profile";
import StoreProfile from "./Pages/Booking/storeProfile";
import DoorstepService from "./Pages/Booking/doorstepService";
import MessengerList from "./Pages/Messenger/messengerList";
import SearchStore from "./Pages/Search/searchStore";
import MapView from "./Pages/Search/mapView";
import UserRegister from "./Pages/Auth/userRegister";
import AddFamilyMember from "./Pages/User/addFamilyMember";
import FindLocation from "./Pages/Search/findLocation";
import Transactions from "./Pages/User/transaction";
import AboutPingle from "./Pages/aboutPingle";
import NotificationList from "./Pages/Messenger/notificationList";
import VendorProfile from "./Pages/Booking/vendorProfile";
import EditProfileMenu from "./Pages/User/profile-edit/editProfileMenu";
import EditProfileUser from "./Pages/User/profile-edit/editProfileUser";
import EditProfileEmailVerify from "./Pages/User/profile-edit/editProfileEmailVerify";
import EditProfileAadhar from "./Pages/User/profile-edit/editProfileAadhar";
import BookingSelectStaff from "./Pages/Booking/bookingSelectStaff";
import ReviewAndPay from "./Pages/Booking/reviewAndPay";
import BulletinBoard from "./Pages/User/bulletinBoard";
import UserMembership from "./Pages/User/membership";
import Products from "./Pages/Products/product";
import ReviewPay from "./Pages/Products/Reviewpay";
import ProductBasket from "./Pages/Products/productBasket/productBasket";
import SingleProduct from "./Pages/Products/singleProduct/singleProduct";
import ReviewPayDoorstep from "./Pages/Products/Doorstep/reviewPay";
import RazorPay from "./Components/razorPay";
import ProductPass from "./Pages/Products/Product Pass/ProductPass";
import UserLoyalty from "./Pages/User/loyalty";
import UserFollowing from "./Pages/User/following";
import UserSettings from "./Pages/userSettings";
import BuyNow from "./Pages/Membership/BuyNow";
import Offerslist from "./Pages/Offers/offerslist";
import { initializeApp } from "firebase/app";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect } from "react";
import { UserProvider } from "./Components/UserContext";
import OfferCard from "./Pages/Offers/offercard";
import Singleofferdetails from "./Pages/Offers/singleofferdetails";
import OfferBooking from "./Pages/Offers/offerBooking";
import MembershipCard from "./Components/MembershipCard/MembershipCard";
import VendorMembership from "./Pages/Membership/VendorMembership";
import MembershipBuyNow from "./Pages/Membership/MembershipBuyNow";
import MemberShipDetails from "./Pages/Membership/MemberShipDetails";
import MembershipBooking from "./Pages/Membership/membershipBooking";
import Paynow from "./Pages/Membership/Paynow";
import LoyaltyOffer from "./Pages/Loyalty/LoyaltyOffer";
import PinglePoints from "./Pages/Loyalty/PinglePoints";
import MerchantLoyalty from "./Pages/Loyalty/MerchantLoyalty";


import EditProfileEmailVerifyOtp from "./Pages/User/profile-edit/editProfileEmailVerifyOtp";
import BookingHistory from "./Pages/Booking/bookingHistory";
import UserBookingDetails from "./Pages/Booking/bookingDetails";
import SingleChat from "./Pages/Messenger/singleChat";
import BookingChat from "./Pages/Messenger/bookingChat";
import EnqueryChat from "./Pages/Messenger/enqueryChat";
import AddDeliveryAddress from "./Pages/Products/AddDeliveryAddress";
import AddAddress from "./Pages/Booking/AddAddress";
import VehicleRegister from "./Pages/User/vehicleRegister";
import PetRegister from "./Pages/User/petRegister";
import InstallPrompt from "./Pages/InstallPrompt";
import PinglePointsHistory from "./Pages/Loyalty/pointsHistory";
import SingleLoyaltydetail from "./Pages/Loyalty/singleLoyaltydetail";
import Redeemed from "./Pages/Loyalty/Redeemedlist";
import RedeemedDetails from "./Pages/Loyalty/RedeemedDetails";
import { genarateToken, messaging } from "./Pages/Loyalty/notification";
import { onMessage } from "firebase/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import RegistrationAlert from "./Pages/Alerts/registrationAlert";
import AppointmentCard from "./Components/appointment_card/AppointmentCard";
// import ProductCard from "./Components/appointment_card/ProductCard";
import CameraScan from "./Components/Camera/CameraScan";
import SearchMerchant from "./Pages/Scanner/SearchMerchant";
import QRCodeScan from "./Pages/Scanner/QRCodeScan";
import Scanner from "../src/Components/Scanner";
import WebcamCaptureWithCrop from "./Components/Camera/Camera";
import SingleRefrelPointsdetail from "./Pages/Loyalty/SingleRefrelPointsdetails";
import DeliveryTime_Slot from "./Components/DeliveryTImeslot/DelivrySlot";
import Demo from "./Components/testpage";
import Single_service_detail from "./Pages/Booking/single_service_detail";
import Coupon_card_list from "./Pages/Coupon/coupon_list";
import TicketList from "./Pages/PreOrder/TicketList";
import Single_preorder_detail from "../src/Pages/PreOrder/Single_preorder_detail";
import Preorder_Buynow from "./Components/PreOrderTicketCard/Preorder_Buynow";
import LiveTracking from "./Pages/Map live tracking/Live_tracking";
import DeliveryFlow from "./Pages/Map live tracking/Delivery_Flow";
import { auth } from "./Services/firebase-service/firebase"; // Adjust the import path if necessary
import { signInWithEmailAndPassword } from "firebase/auth";
import Get_currentLocation from "./Pages/Search/map_testing";


function App() {
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAAzlVNSP9hg8LAC9S0TPQ-BHILbpFUg-I",
  //   authDomain: "pingleproject.firebaseapp.com",
  //   databaseURL: "https://pingleproject-default-rtdb.firebaseio.com",
  //   projectId: "pingleproject",
  //   storageBucket: "pingleproject.appspot.com",
  //   messagingSenderId: "225253645526",
  //   appId: "1:225253645526:web:67f61c305a8885ba053d77",
  //   measurementId: "G-KYPCL4N7BN",
  // };


  // const app = initializeApp(firebaseConfig);


  useEffect(() => {
    signInWithEmailAndPassword(auth, "episodetechnologies@gmail.com", "Gangster003")
      .then((userCredential) => {
        const user = userCredential.user;
      })
      .catch((error) => {
        console.error("Error signing in:", error.message);
      });
  }, []);

  // useEffect(() => {
  //   // FileSystemDirectoryHandle
  //   const auth = getAuth(app);
  //   signInWithEmailAndPassword(
  //     auth,
  //     "episodetechnologies@gmail.com",
  //     "Gangster003"
  //   )
  //     .then((userCredential) => {
  //       // Signed in
  //       const user = userCredential.user;
  //       // You can use the user info here
  //       // console.log(user);
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       // Handle errors here
  //       // console.error(errorCode, errorMessage);
  //     });
  // }, []);

  // const messaging = getMessaging(app)

  // const genarateToken = async () => {
  //       const permission = await Notification.requestPermission();
  //       console.log(permission)

  //       if (permission === "granted") {
  //           const baseToken = await getToken(messaging, { vapidKey: "BPPEZcZsVJ5C3Ften18XcPcJciZPNWB3UUDyLQwatqXBXKxVDolAASn1cdHkasXd1fqjiZstmdNO3nT7vhrr9LU" })
  //           console.log(baseToken);
  //       }
  //   }

  return (
    <div className="d-flex justify-content-center App">
      <InstallPrompt />
      <div className="main" >
        <UserProvider>

          {/* <BrowserRouter basename="/pre-production/web-app/"> */}
          {/* <BrowserRouter basename="/pre-production/web-app/">   */}
          {/* <BrowserRouter basename="/web-test/"> */}
          <BrowserRouter >

            <ToastContainer limit={1} />
            <Routes>
              {/* Auth  */}
              <Route path="/login" element={<Login />} />
              <Route path="/otpVerification" element={<OtpVerification />} />
              <Route path="/userRegister" element={<UserRegister />} />
              {/* Booking  */}
              <Route path="/" element={<BookingsManager />} />
              <Route path="/details/:id" element={<UserBookingDetails />} />
              {/* User  */}
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/bulletin-board" element={<BulletinBoard />} />

              <Route path="/edit-profile" element={<EditProfileMenu />} />
              <Route path="/edit-profile/user" element={<EditProfileUser />} />
              <Route path="/edit-profile/email-verify" element={<EditProfileEmailVerify />} />
              <Route path="/edit-profile/email-otp-verify" element={<EditProfileEmailVerifyOtp />} />
              <Route path="/edit-profile/aadhar" element={<EditProfileAadhar />} />
              <Route path="/add-family-member" element={<AddFamilyMember />} />
              <Route path="/pet-register" element={<PetRegister />} />
              <Route path="/vehicle-register" element={<VehicleRegister />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/aboutPingle" element={<AboutPingle />} />
              <Route path="/settings" element={<UserSettings />} />
              <Route path="/UserFollowing" element={<UserFollowing />} />



              {/* Alerts */}
              <Route path="/registration-success" element={<RegistrationAlert />} />

              {/* Store Profile & booking  */}
              <Route path="/store-profile/:vendor_id" element={<StoreProfile />} />
              <Route path="/door-step-service/:vendor_id" element={<DoorstepService />} />
              <Route path="/select-staff/:vendor_id" element={<BookingSelectStaff />} />
              <Route path="/review-and-pay/:vendor_id" element={<ReviewAndPay />} />
              <Route path="/history/:vendor_id" element={<BookingHistory />} />
              <Route path="/store-profile/:vendor_id" element={<StoreProfile />} />
              <Route path="/door-step-service/:vendor_id" element={<DoorstepService />} />
              <Route path="/single_service_detail/:service_id" element={<Single_service_detail />} />

              <Route
                path="/select-staff/:vendor_id"
                element={<BookingSelectStaff />}
              />
              {/* Messenger  */}
              <Route path="/messenger-list" element={<MessengerList />} />
              <Route path="/chat/:sender_id" element={<SingleChat />} />
              <Route path="/chat/booking/:chat_id" element={<BookingChat />} />
              <Route path="/chat/enquery/:enquery_id" element={<EnqueryChat />} />
              <Route path="/notifications" element={<NotificationList />} />

              {/* Search  */}
              <Route path="/search-store" element={<SearchStore />} />
              <Route path="/map-view" element={<MapView />} />
              <Route path="/find-location" element={<FindLocation />} />

              <Route path="/vehicle-register" element={<VehicleRegister />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/aboutPingle" element={<AboutPingle />} />

              {/* Store Profile & booking  */}
              <Route path="/vendor/:vendor_id" element={<VendorProfile />} />
              <Route
                path="/store-profile/:vendor_id"
                element={<StoreProfile />}
              />
              <Route
                path="/door-step-service/:vendor_id"
                element={<DoorstepService />}
              />
              <Route
                path="/select-staff/:vendor_id"
                element={<BookingSelectStaff />}
              />
              <Route
                path="/review-and-pay/:vendor_id"
                element={<ReviewAndPay />}
              />
              <Route
                path="/store-profile/:vendor_id"
                element={<StoreProfile />}
              />
              <Route
                path="/door-step-service/:vendor_id"
                element={<DoorstepService />}
              />
              <Route
                path="/select-staff/:vendor_id"
                element={<BookingSelectStaff />}
              />
              {/* Messenger  */}
              <Route path="/messenger-list" element={<MessengerList />} />
              <Route path="/notifications" element={<NotificationList />} />

              {/* Search  */}
              <Route path="/search-store" element={<SearchStore />} />
              <Route path="/map-view" element={<MapView />} />
              <Route path="/find-location" element={<FindLocation />} />
              <Route path="/demo-Location" element={<Demo />} />
              {/* Products  */}

              <Route path="/ReviewPay" element={<ReviewPay />} />
              <Route
                path="/ReviewPayDoorstep"
                element={<ReviewPayDoorstep />}
              />

              <Route path="/productBasket" element={<ProductBasket />} />
              <Route path="/ProductPass/:order_id" element={<ProductPass />} />
              <Route path="/RazorPay" element={<RazorPay />} />

              {/* Member ship */}
              <Route path="/MembershipCard" element={<MembershipCard />} />
              <Route path="/BuyNow" element={<BuyNow />} />
              <Route path="/MemberShipDetails/:vendorId/:membership_ID" element={<MemberShipDetails />} />
              <Route path="/my-membership" element={<UserMembership />} />
              <Route path="/VendorMembership" element={<VendorMembership />} />
              <Route path="/MembershipBuyNow/:vendorId" element={<MembershipBuyNow />} />
              <Route path="/MembershipBooking" element={<MembershipBooking />} />
              <Route path="/Paynow" element={<Paynow />} />

              {/* Offer Module */}
              <Route path="/Offerlist/:vendorId" element={<Offerslist />} />
              <Route path="/Offercard" element={<OfferCard />} />
              <Route path="/OfferDetails/:vendorId/:offerid" element={<Singleofferdetails />} />
              <Route path="/offerBooknow" element={<OfferBooking />} />
              {/* Preorder Module */}
              <Route path="/TicketList/:vendorId" element={<TicketList />} />



              {/* Products  */}
              <Route path="/products/:vendor_id" element={<Products />} />
              <Route path="/ReviewPay/:vendor_id" element={<ReviewPay />} />
              <Route path="/ReviewPayDoorstep" element={<ReviewPayDoorstep />} />
              <Route path="/products" element={<Products />} />
              <Route path="/productBasket" element={<ProductBasket />} />
              <Route path="/singleProductDetails/:vendorId/:productid" element={<SingleProduct />} />
              <Route path="/ProductPass" element={<ProductPass />} />
              <Route path="/RazorPay" element={<RazorPay />} />
              <Route path="/AddDeliveryAddress" element={<AddDeliveryAddress />} />
              <Route path="/AddAddress" element={<AddAddress />} />

              {/* Loyalty */}
              <Route path="/PinglePointsHistory" element={<PinglePointsHistory />} />
              <Route path="/LoyaltyOffer" element={<LoyaltyOffer />} />
              <Route path="/PinglePoints" element={<PinglePoints />} />
              <Route path="/merchant-loyalty/:vendorId" element={<MerchantLoyalty />} />
              <Route path="/singleLoyaltydetails/:vendorId/:offerid" element={<SingleLoyaltydetail />} />
              <Route path="/redeemedlist" element={<Redeemed />} />
              <Route path="/RedeemedDetails" element={<RedeemedDetails />} />
              <Route path="/SingleRefrelPointsdetail" element={<SingleRefrelPointsdetail />} />
              {/* <Route path="/pushnotification" element={<Pushnotification />} /> */}

              {/* Scanner */}
              <Route path="/CameraScan" element={<CameraScan />} />
              <Route path="/SearchMerchant" element={<SearchMerchant />} />
              <Route path="/QRCodeScan" element={<QRCodeScan />} />
              <Route path="/Scan" element={<Scanner />} />

              {/* <Route path="/CameraInMessager" element={<CameraComponent />} /> */}
              {/* Camera  */}
              <Route path="/WebcamCaptureWithCrop" element={<WebcamCaptureWithCrop />} />
              <Route path="/Delivery_Time_Slot" element={<DeliveryTime_Slot />} />

              {/* Coupon */}

              <Route path="/Coupon_list/:vendor_id" element={<Coupon_card_list />} />
              <Route path="/single_tickt_detail/:vendor_id/:ticket_id" element={<Single_preorder_detail />} />
              <Route path="/pre_order_buynow/:vendor_id" element={<Preorder_Buynow />} />
              <Route path="/live_traking/:order_id" element={<DeliveryFlow />} />
              <Route path="/get_currentLocation" element={<Get_currentLocation />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
