import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useLocation, useNavigate } from "react-router";
import axiosInstance from "../../Services/axios";

const MembershipBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorId, MembershipStatus, membership_ID } = location.state || {};
  const [vendorStatus, setVendorstatus] = useState(); 
  useEffect(() => {
    getvendorStatus();
  }, []);

  const getvendorStatus = () => {
    try {
      let reqData = {
        vendor_id: vendorId,
      };

      axiosInstance
        .post("/user/vendor_profile_details_without_login", reqData)
        .then((res) => {
          console.log(res.data);

          setVendorstatus(res.data.vendor_profile.vendor_details);
        });
    } catch { }
  };

  const setProductDetails = (shipping_type) => {

    let productDetail = {
      'shipping_type': shipping_type,
      'membershipId': membership_ID,
    }

    let cardDetail = {
      cardStatus: '1',
      cardType: 'membership',
      cardId: membership_ID,
    }

    let productCart = {
      shipping_type: shipping_type,
      cartId: "",
      vendorId: vendorId
    } 
    localStorage.setItem('productCart', JSON.stringify(productCart));
    localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
    localStorage.setItem('shipping_type', JSON.stringify(productDetail));

    navigate(`/products/${vendorId}`, { state: { offer: "offer", cardStatus: '1', cardType: 'membership', cardId: membership_ID } })
  };

  const selectService = (serviceType) => {
    let serviceDetail = {
      serviceType: serviceType,
      vendorId: vendorId
    }

    let cardDetail = {
      cardStatus: '1',
      cardType: 'membership',
      cardId: membership_ID,
    }

    localStorage.setItem('serviceDetail', JSON.stringify(serviceDetail));
    localStorage.setItem('appliedCard', JSON.stringify(cardDetail));
    localStorage.setItem('selectedServiceOption', 'service');
    navigate(`/door-step-service/${vendorId}`, { state: { fromPage: 'membershipPage' } })
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            {vendorStatus?.vendor_company_name}
          </div>
        </div>
      </div>
      <div className="scroller-style-y">
        <div>
          {vendorStatus?.service_status == "1" &&
            MembershipStatus.service_status == "1" ? (
            <div className="px-3 py-2">
              <div className="p-3 BookingCard rounded-2">
                <h6 className="primary-text ff-poppins-semiBold">
                  Book a service{" "}
                </h6>
                <p className="fs-14">
                  Browsen through the service offered on this Offer and book a
                  service{" "}
                </p>
                <div className="d-flex justify-content-end">
                  <button className="primary-bg text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold border border-white"
                    onClick={() => selectService(1, vendorId,
                      membership_ID)} >
                    select
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {MembershipStatus.service_status &&
            MembershipStatus.doorstep_status == "1" &&
            vendorStatus?.doorstep_status == "1" ? (
            <div className="px-3 py-2">
              <div className="p-3 BookingCard rounded-2">
                <h6 className="primary-text ff-poppins-semiBold">
                  Door step Service{" "}
                </h6>
                <p className="fs-14">
                  Browsen through the service in this offered and book a service{" "}
                </p>
                <div className="d-flex justify-content-end">
                  <button className="primary-bg text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold border border-white"
                    onClick={() => selectService(2, vendorId,
                      membership_ID)} >
                    select
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {MembershipStatus.product_status == "1" &&
            vendorStatus?.product_status == "1" &&
            vendorStatus?.product_delivery_status == "1" ? (
            <div className="px-3 py-2">
              <div className="p-3 BookingCard rounded-2">
                <h6 className="primary-text ff-poppins-semiBold">
                  Book a store pickup Product
                </h6>
                <p className="fs-14">
                  Browsen through the store store pickup Products offered on
                  this Offer and book a service{" "}
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="primary-bg text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold border border-white"
                    onClick={() =>
                      setProductDetails(
                        1,
                        vendorId,
                        membership_ID
                      )
                    }
                  >
                    select
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {MembershipStatus.product_status == "1" &&
            vendorStatus?.product_delivery_status == "1" ? (
            <div className="px-3 py-2">
              <div className="p-3 BookingCard rounded-2">
                <h6 className="primary-text ff-poppins-semiBold">
                  Book a Doorstep Product{" "}
                </h6>
                <p className="fs-14">
                  Browsen through the store store pickup Products offered on
                  this Offer and book a service{" "}
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="primary-bg text-white col-5 align-self-center rounded fs-14 btnoutline p-1 ff-poppins-semiBold border border-white"
                    onClick={() =>
                      setProductDetails(
                        2,
                        vendorId,
                        membership_ID
                      )
                    }
                  >
                    select
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default MembershipBooking;
