import React, { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import "../Products/product.css";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import ion_cart_outline from "../../Assets/Images//ion_cart-outline.svg";
import { Await, json, useLocation, useNavigate, useParams } from "react-router-dom";
import { vendorProductList } from "../../Services/api";
import axiosInstance from "../../Services/axios";
// import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuPlusCircle } from "react-icons/lu";
import { FiMinusCircle } from "react-icons/fi";
import hamburgerMenu from "../../Assets/Images/p_navbars.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoSearch } from "react-icons/io5";
import defaultimg from "../../Assets/Images/default-placeholder.png";
import { toast } from "react-toastify";
import { BsChevronDown } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { IoChevronDown } from "react-icons/io5";
import ReactLoading from "react-loading";
import vegIcon from "../../Assets/Images/vegpngoutput.png"
import nonvegIcon from "../../Assets/Images/nomvegoutput.png"
import eggIcon from "../../Assets/Images/eggpngoutput.png"
import Toggle from "../../Components/toggle-button/Toggle";
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineCheckBox, MdTimer3 } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
import { IoIosCloseCircle } from "react-icons/io";

const Products = () => {
  const navigate = useNavigate();
  const addonModalRef = useRef(null);
  const addonEditeModalRef = useRef(null);

  const [productlist, setProductlist] = useState([]);
  const [cartId, setCartId] = useState("");
  const [cardCount, setCardCount] = useState();
  const [shipingtype, setShipingtype] = useState("");
  const valueParam = useParams();
  const merchantId = valueParam;
  const [isModelOpen, setModelOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [showMenus, setShowMenus] = useState(false);
  const [searchitem, setSearchitem] = useState("");
  const [listitem, setListitem] = useState([]);
  const [searhModelItems, setSearhModelItems] = useState([]);
  const [loadingButtonId, setLoadingButtonId] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [productPrice, setProductPrice] = useState('')
  const [productActions, setProductActions] = useState('');

  const [selectItem, setSelectItem] = useState('')
  const [openIndices, setOpenIndices] = useState([0]);
  // Offer or Membership
  const [cardStatus, setCardStatus] = useState();
  const [cardType, setcardType] = useState();
  const [cardId, setcardId] = useState();
  // Food menu
  const [menuStatus, setMenuStatus] = useState('');
  //  const [menuSelect, setMenuSelect] = useState('');
  const [isVegSelected, setIsVegSelected] = useState(false);
  const [isNonVegSelected, setIsNonVegSelected] = useState(false);
  const [isEggSelected, setIsEggSelected] = useState(false);

  const [productlistRaw, setProductlistRaw] = useState('')

  const [productid, setProductid] = useState('')
  const [productCost, setProductCost] = useState('')
  const [addonsCost, setAddonsCost] = useState('')
  const [selectedAddons, setSelectedAddons] = useState([])
  const [productCount, setProductCount] = useState(0);
  const [cartDetails, setCartDetails] = useState([])

  const [card, setCard] = useState("")
  const [filteredItems, setFilteredItems] = useState([]);

  const location = useLocation();
  const { offer, flowtype } = location.state || {};


  const [productsCategories, setProductsCategories] = useState()
  const [productsList, setProductsList] = useState([])
  const [allProductsList, setAllProductsList] = useState([])
  const [productsAddons, setProductsAddons] = useState()
  const [cardDetails, setCardDetails] = useState()
  const [vendorType, setVendorType] = useState()
  const [mergedProductsRaw, setMergedProductsRaw] = useState([])
  const [mergedProducts, setMergedProducts] = useState()
  const [TotalPagesCount, setTotalPagesCount] = useState()
  const [MenuLoaded, setMenuLoaded] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)


  const closeMenuList = (event) => {
    const menuButton = document.getElementById("menuButton");
    if (menuButton && !menuButton.contains(event.target)) {
      setShowMenus(false);
    }
  };



  useEffect(() => {
    filterFoodProducts()
  }, [isNonVegSelected, isVegSelected, isEggSelected]);

  useEffect(() => {
    setOpenIndices(mergedProducts?.map((_, index) => index));
  }, [mergedProducts]);


  useEffect(() => {
    let productCart = localStorage.getItem("productCart") ? JSON.parse(localStorage.getItem("productCart")) : "";
    setCartId(productCart?.cartId ? productCart?.cartId : "");

    setShipingtype(productCart?.shipping_type ? productCart?.shipping_type : "");
    let appliedCard = JSON.parse(localStorage.getItem("appliedCard"));
    setCardStatus(appliedCard?.cardStatus ? appliedCard?.cardStatus : "");
    setcardType(appliedCard?.cardType ? appliedCard?.cardType : "");
    setcardId(appliedCard?.cardId ? appliedCard?.cardId : "");
    let vendortype = localStorage.getItem("vendortype") ? JSON.parse(localStorage.getItem("vendortype")) : "";
    setMenuStatus(vendortype?.vendor_type ? vendortype?.vendor_type : "")

    let productAdded = localStorage.getItem("productAdded") ? JSON.parse(localStorage.getItem("productAdded")) : "";
    setProductActions(productAdded?.all_products ? { all_products: productAdded?.all_products } : "")
    setCardCount(productAdded?.cart_count ? productAdded?.cart_count : "")

    if (cardCount != 0) {
      setModelOpen(true)
    }

  }, []);

  useEffect(() => {
    if (shipingtype != "") {
      getproductlist();
    }
  }, [shipingtype]);

  useEffect(() => {
    if (cartId != "") {
      getproductlist();
    }
  }, [cartId]);
  useEffect(() => {
    if (flowtype) {
      let EditCart = localStorage.getItem("EditOrder_Detail") ? JSON.parse(localStorage.getItem("EditOrder_Detail")) : "";
      setCartId(EditCart?.orderid ? EditCart?.orderid : "");
      setShipingtype(EditCart?.shippingtype ? EditCart?.shippingtype : "");
    }
  }, [flowtype]);



  const getproductlist = () => {
    try {

      let reqData = {
        vendor_id: valueParam.vendor_id,
        shipping_type: shipingtype,
        cart_id: cartId,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      axiosInstance.post("/user/products/vendor_products_list_without_login ", reqData)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;

            setProductlistRaw(data?.result);
            setProductlist(data?.result);
            // if (data?.cart_count) {
            //   setCardCount(data?.cart_count);

            // }
            setListitem(data?.all_products);

            // setPageLoaded(true);
            setCartDetails(data.cart)
            setCard(data?.card)

          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("Try again Later", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const handleClick = (buttonId, products) => {
    setLoadingButtonId(buttonId);
    setModelOpen(true)
    setProductid(buttonId)
    action_productcount(products, "add")
  };


  const action_productcount = (products, action, addon) => {
    setProductActions((prevActions) => {
      const allProduct = prevActions.all_products || [];
      const existingProduct = allProduct.find((product) => product.product_id === products.product_id);


      let updatedActions;
      if (existingProduct) {
        updatedActions = allProduct
          .map((product) =>
            product.product_id === products.product_id
              ? {
                ...product,
                added_count: action === "remove" ? Math.max(product.added_count - 1, 0) : action === "add" ? product.added_count + 1 : product.added_count,
                action: action,
                price: product.price,
                max_allowed_quantity: products.max_allowed_quantity,
                stock: products.stock_quantity,
                addons: (product?.addons || []).map((addonItem) =>
                  addonItem.product_id === products.product_id
                    ? {
                      ...addonItem,
                      options: addonItem.options.map((option) =>
                        option.optionId === products.optionId
                          ? { ...option, price: products.price }
                          : option
                      ),
                    }
                    : addonItem
                ),
              }
              : product


          )
          .filter((product) => product.added_count > 0);
      }
      else {
        if (action !== "remove") {
          const newProduct = {
            product_id: products.product_id,
            added_count: 1,
            action: action,
            price: products.price,
            stock: products.stock_status == 1 ? products.stock_quantity : "",
            max_allowed_quantity: products.max_allowed_quantity,
            addons: addon ? addon.filter((addonItem) => addonItem.product_id === products.product_id) : "", // Filter addons based on product_id
          };
          updatedActions = [...allProduct, newProduct];
        } else {
          updatedActions = allProduct;
        }
      }


      const totalProductCount = updatedActions.length;

      const updatedStorageData = {
        all_products: updatedActions,
        cart_count: totalProductCount,
      };
      setCardCount(totalProductCount)
      localStorage.setItem("productAdded", JSON.stringify(updatedStorageData));


      getproductlist();

      return { all_products: updatedActions };
    });
  };



  const sendProductActions = async (flowtypeValue, product_id) => {
    if (Array.isArray(productActions?.all_products)) {
      for (const action of productActions.all_products) {
        const { product_id, added_count, action: operation, addons } = action;
        await Add_Remove_productcount(addons, product_id, added_count, operation);
      }
    } else {
      console.warn("No products available in productActions.all_products");
    }

    if (flowtypeValue === 'basket') {
      navigate("/productBasket", {
        state: { merchantId, cartId, flowtype, offer_type: card?.offer_type, offer_count: card?.combo_count },
      });
    } else if (flowtypeValue === 'single') {
      navigate(`/singleProductDetails/${merchantId.vendor_id}/${product_id}`, {
        state: { productId: product_id, merchantId, flowtype },
      });
    } else {
      navigate("/ReviewPay", {
        state: { merchantId, cartId, shipingtype, flowtype: flowtypeValue },
      });
    }
  };

  let globalCartId = null;
  const Add_Remove_productcount = async (addonid, productId, count, Operation, addonstate) => {
    try {
      // Extract optionIds grouped by productId
      const Addition = selectedAddons.reduce((acc, addon) => {
        // Ensure the addon.product_id matches the current productId
        if (addon.product_id === productId && addon.options && Array.isArray(addon.options)) {
          acc.push(...addon.options.map(option => option.optionId));
        }
        return acc;
      }, []);

      // Create the request payload
      let reqData = {
        vendor_id: valueParam.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        cart_id: globalCartId,
        quantity: count,
        operation: Operation,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: Addition.length > 0 ? Addition : "",
        flow_type: flowtype === "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };

      // Make the API call
      const res = await axiosInstance.post(
        "user/products/add_or_remove_product_cart_without_login",
        reqData
      );

      if (res.status === 200) {
        if (!globalCartId) {
          globalCartId = res.data.cart_id;
          localStorage.setItem("cartid", JSON.stringify(globalCartId));
        }

        let productCart = {
          shipping_type: shipingtype,
          cartId: globalCartId,
          vendorId: valueParam.vendor_id
        };
        localStorage.setItem("productCart", JSON.stringify(productCart));

        setCartId(globalCartId);
        setCartDetails(res?.data?.cart_items);
        getproductlist();

        // Handle modal logic based on addonstate
        if (addonstate === "0") {
          setModelOpen(true);
        }

        // Reset selected addons
        setSelectedAddons([]);
      }
    } catch (err) {
      // Handle errors
      if (err.response?.status === 400 || err.response?.status === 500) {
        toast(err.response.data.message, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          className: "text-danger toast-style-1"
        });
      } else {
        toast("Can't select product. Please contact support or try again later.", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          className: "text-danger toast-style-1"
        });
      }
    }
  };



  function openserachProductModal() {
    document.getElementById("serachProductModalProduct").classList.add("show");
    setMenuOpen(false)

    closeAddonModal()
    closeEditAddonModal()

  }

  function closeserachProductModal() {
    var modal = document.getElementById("serachProductModalProduct");
    modal.classList.remove("show");
    setMenuOpen(true)
  }


  const toggleAccordion = (index) => {

    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  const isItemOpen = (index) => openIndices.includes(index);

  // console.log("isItemOpen-------", openIndices);

  const handleModelClose = () => {
    setModelOpen(false);
  };



  useEffect(() => {
    filterProducts()

  }, [isVegSelected, isNonVegSelected, isEggSelected])

  const filterProducts = () => {
    let filteredData = mergedProductsRaw;

    if (isVegSelected) {
      filteredData = mergedProductsRaw.map((category) => {
        return {
          ...category,
          products: category.products.filter((product) =>
            isEggSelected ? product.food_type == 1 || product.food_type == 3 : product.food_type == 1
          ),
        };
      }).filter((category) => category.products.length > 0);
    }
    else if (isNonVegSelected) {
      filteredData = mergedProductsRaw.map((category) => {
        return {
          ...category,
          products: category.products.filter((product) => product.food_type == 2 || product.food_type == 3),
        };
      }).filter((category) => category.products.length > 0);
    }

    else {
      filteredData = mergedProductsRaw;
    }

    setMergedProducts(filteredData);
  };


  const handleVegToggle = () => {
    setIsVegSelected(!isVegSelected);
    if (!isVegSelected) {
      setIsNonVegSelected(false);
      setIsEggSelected(false)
    }
  };
  const handleNonVegToggle = () => {
    setIsNonVegSelected(!isNonVegSelected);
    if (!isNonVegSelected) {
      setIsVegSelected(false);
    }
  };

  // const handleEggToggle = () => {
  //   setIsEggSelected(!isEggSelected);
  // };
  const handleEggToggle = () => {
    if (isVegSelected) {
      setIsEggSelected(prevState => !prevState);
    }
  };

  const dropUpMenuPress = () => {
    setShowMenus((prevShowMenus) => {
      const newState = !prevShowMenus;
      return newState;
    });
  };

  const handleCategoryClick = (category_id) => {
    const accordionItem = document.getElementById(`collapseOne${category_id}`);
    if (accordionItem) {
      accordionItem.scrollIntoView({ behavior: "smooth", block: "start", });
    }
  };

  const filterFoodProducts = () => {
    let filteredList = allProductsList;

    if (isVegSelected) {
      if (isEggSelected) {
        filteredList = allProductsList?.filter((product) => product.food_type == 1 || product.food_type == 3)

      } else {
        filteredList = allProductsList?.filter((product) => product.food_type == 1)
      }
    } else if (isNonVegSelected) {
      filteredList = allProductsList?.filter((product) => product.food_type == 2 || product.food_type == 3)
    }
    setFilteredItems(filteredList)
  }
  useEffect(() => {
    const updatedList = allProductsList.filter((item) => {
      const hasValidProductName = typeof item.product_name === "string";
      const hasValidProductCategory = typeof item.category_name === "string";

      const productNameMatches =
        hasValidProductName &&
        item.product_name.toLowerCase().includes(searchitem.toLowerCase());
      const productCategoryMatches =
        hasValidProductCategory &&
        item.category_name.toLowerCase().includes(searchitem.toLowerCase());
      const updatedList = allProductsList.filter((item) => {
        const hasValidProductName = typeof item.product_name === "string";
        const hasValidProductCategory = typeof item.category_name === "string";
        const productNameMatches =
          hasValidProductName &&
          item.product_name.toLowerCase().includes(searchitem.toLowerCase());
        const productCategoryMatches =
          hasValidProductCategory &&
          item.category_name.toLowerCase().includes(searchitem.toLowerCase());
        if (productNameMatches || productCategoryMatches) {
          return true;
        }
        if (searchitem && (!hasValidProductName || !hasValidProductCategory)) {

        }

        return false;
      });
      setFilteredItems(updatedList);
    });
  }, [searchitem, allProductsList]);

  const openAddonModal = (productid, item) => {
    addonModalRef.current.classList.add("show");
    addonModalRef.current.style.display = "block";
    setLoadingButtonId(productid)
    setProductid(productid)
    setMenuOpen(false)
    setModelOpen(false)
    setIsModalOpen(true)
  };

  const closeAddonModal = () => {
    addonModalRef.current.classList.remove("show");
    addonModalRef.current.style.display = "none";
    setMenuOpen(true)
    setIsModalOpen(false)

  };


  const openEditAddonModal = (addons, addedAddons, productid, added_count) => {
    let transformedData = transformDataAndCalculateTotalCost(addons, addedAddons);
    setProductid(productid);
    setProductCount(added_count)
    addonEditeModalRef.current.classList.add("show");
    addonEditeModalRef.current.style.display = "block";
    setModelOpen(false)
    setMenuOpen(false)
    setIsModalOpen(true)

  };

  const closeEditAddonModal = () => {
    addonEditeModalRef.current.classList.remove("show");
    addonEditeModalRef.current.style.display = "none";
    setMenuOpen(true)
    setIsModalOpen(false)

  };

  const handelEdittoAddonmodel = (productid) => {
    closeEditAddonModal()
    openAddonModal()
    setModelOpen(false)
    setProductid(productid)
    setProductCost((totalPrice) * (productCount))
  }

  const transformDataAndCalculateTotalCost = (rawData, selectedOptions) => {
    let transformedData = [];
    let totalCost = 0;

    if (rawData && rawData.length > 0) {
      rawData.forEach((addon) => {
        let addonObj = {
          addonId: addon.id,
          options: []
        };

        addon?.options?.forEach((option) => {
          if (selectedOptions?.includes(option?.id)) {
            addonObj.options?.push(option?.id);
            totalCost += parseInt(option?.price, 10);
          }
        });

        if (addonObj.options.length > 0) {
          transformedData.push(addonObj);
        }
      });
    }

    return { addons: transformedData, cost: totalCost };
  };



  const selectAddon = (addon, option, price, options) => {
    let addonId = addon.addon_id;
    let selectedAddonsList = [...selectedAddons];
    let found = false;


    selectedAddonsList.forEach(item => {
      if (item.addonId === addonId) {
        found = true;
        const existingOption = item.options.find(opt => opt.optionId === option);
        if (!existingOption) {
          if ((addon.selection_type == 1 && item.options.length > 0) ||
            (addon.selection_type == 2 && item.options.length >= addon.max_select)) {


            toast("You have already selected max addons for this category", {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "light",
              className: "text-danger toast-style-1",
            });
          } else {
            // Add the new option   
            item.options.push({
              optionId: option,
              name: options.name,
              type: options.type,
              price: price,
            });

            setProductCost(Number(productCost) + Number(price));
            setAddonsCost(Number(addonsCost) + Number(price));
            setProductPrice(productCost);
          }
        } else {
          // Remove the option if it already exists
          item.options = item.options.filter(opt => opt.optionId !== option);

          setProductCost(Number(productCost) - Number(price));
          setAddonsCost(Number(addonsCost) - Number(price));
        }
      }
    });

    if (!found) {
      selectedAddonsList.push({
        addonId: addonId,
        product_id: addon.product_id,
        options: [
          {
            optionId: option,
            name: options.name,
            type: options.type,
            price: price,
          },
        ],

      });
      // selectedAddonsList.push({ "addonId": addonId, "options": [option] });
      setProductCost(Number(productCost) + Number(price));
      setAddonsCost(Number(addonsCost) + Number(price));
    }

    setSelectedAddons(selectedAddonsList);
  };



  // const checkIsSelectedAddon = (addonId, option) => {
  //   let selectedAddonsList = [...selectedAddons];
  //   let found = false; 
  //   selectedAddonsList.forEach(item => {
  //     if (item.addonId == addonId) {
  //       if (item.options.optionId.includes(option)) {
  //         found = true;
  //       }
  //     }
  //   });

  //   return found;
  // }


  const checkIsSelectedAddon = (addonId, optionId) => {

    const selectedAddon = selectedAddons.find(item => item.addonId === addonId);

    return selectedAddon?.options.some(opt => opt.optionId === optionId) || false;
  };


  const checkButtonStatus = () => {
    // Ensure selectItem?.addons is a valid array
    if (!Array.isArray(selectItem?.addons)) {
      return;
    }

    for (const addon of selectItem.addons) {
      const selectedAddon = selectedAddons?.find((sel) => sel?.addonId == addon?.id);
      const selectedOptionsCount = selectedAddon ? selectedAddon?.options?.length : 0;

      if (addon?.required == 1 && addon?.selection_type == 2 && selectedOptionsCount < addon.min_select) {
        toast(`You need to select at least ${addon.min_select} option(s) from the category ${addon?.addon_name}`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          className: 'text-danger toast-style-1 toast-custom-style'
        });
        return;
      } else if (addon?.required == 1 && addon?.selection_type == 1 && selectedOptionsCount <= 0) {
        toast(`You need to select at least 1 option(s) from the category ${addon?.addon_name}`, {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          className: 'text-danger toast-style-1 toast-custom-style'
        });
        return;
      }
    }

    closeAddonModal();
    // closeEditAddonModal();
    setModelOpen(true);
    let new_data = {
      'product_id': productid,
      'price': selectItem.price,
      'max_allowed_quantity': selectItem.max_allowed_quantity
    }


    action_productcount(new_data, "add", selectedAddons);
  };


  const update_Product = async (productId, Operation, count) => {
    try {
      let reqData = {
        vendor_id: valueParam.vendor_id,
        product_id: productId,
        shipping_type: shipingtype,
        cart_id: cartId,
        quantity: count,
        operation: Operation,
        card_status: cardStatus,
        card_type: cardType,
        card_id: cardId,
        addons: selectItem?.added_addons,
        flow_type: flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : "ORDER"
      };
      axiosInstance.post("user/products/add_or_remove_product_cart_without_login", reqData)
        .then((res) => {
          if (res.status == 200) {
            setCartId(res.data.cart_id);
            getproductlist();
            closeEditAddonModal()
            setModelOpen(true)
          }
        })
        .catch((err) => {
          if (err.response.status == 400 || err.response.status == 500) {
            toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
          }
        });
    } catch (error) {
      toast("can't select product please content pingle are Try again later", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
    }
  };

  const confirmUpdate = (item) => {
    setModelOpen(true)
    closeEditAddonModal()
    const timesToCall = productCount - 1;

    for (let i = 0; i < timesToCall; i++) {
      action_productcount(item, "add");
    }
    // let ProductItem = cartDetails
    // var ProductHave = ProductItem?.some((obj) => obj.product_id == productid);
    // ProductItem?.map((Cartdata, id) => {
    //   if (Cartdata.product_id == productid) {
    //     if (Cartdata.quantity > productCount) {
    //       update_Product(productid, 'remove', Cartdata.quantity - productCount);
    //       setModelOpen(true)
    //     } else if (productCount > Cartdata.quantity) {
    //       update_Product(productid, 'add', productCount - Cartdata.quantity);
    //       setModelOpen(true)
    //     } else {
    //       closeEditAddonModal()
    //     }
    //   }
    // })
  }
  const [totalPrice, setTotalPrice] = useState(0);


  useEffect(() => {
    if (productActions?.all_products) {
      let calculatedTotal = 0;

      productActions.all_products.forEach((product) => {
        if (productid === product?.product_id) {

          calculatedTotal += parseFloat(product?.price || 0); // Add product price

          if (Array.isArray(product?.addons)) {
            product.addons.forEach((addon) => {
              if (Array.isArray(addon?.options)) {
                addon.options.forEach((option) => {

                  // Use `selectedAddons` state instead of `added_addons`
                  // if (selectedAddons.includes(option?.optionId)) { 
                  calculatedTotal += parseFloat(option?.price || 0); // Add option price
                  // } else {

                  // }
                });
              } else {
                console.warn("addon.options is not an array:", addon?.options);
              }
            });
          } else {
            console.warn("product.addons is not an array:", product?.addons);
          }
        }
      });

      setTotalPrice(calculatedTotal);
    }
  }, [productlist, productid, selectedAddons]);




  useEffect(() => {
    if (productsAddons && productsAddons.length > 0) {
      const mergedProducts = productsList.map((product) => {
        let addonsList = productsAddons.filter((addon) => addon.product_id == product.product_id);

        return {
          ...product,
          addons: addonsList,
          addons_status: addonsList.length > 0
        };
      });
      setProductsList(mergedProducts);
    }
  }, [productsAddons])

  useEffect(() => {
    if (productsList && productsList.length > 0) {
      const mergedCategories = productsCategories.map((category) => {
        const categoryProducts = productsList.filter((product) => { return product.category_id === category.id }).map((product) => {
          let updatedProduct = { ...product };

          if (cardStatus == 1) {
            const { discount_type, discount } = cardDetails || {};
            const originalPrice = updatedProduct.price;

            let discountAmount = 0;
            if (discount_type == 1) {
              discountAmount = discount;
            } else if (discount_type == 2) {
              discountAmount = Math.round((discount / 100) * originalPrice);
            }
            const newPrice = originalPrice - discountAmount;
            const benefitType = "discount";
            const benefitLabel = discount_type == 1 ? `₹${discount} ${benefitType} applied` : `${discount}% ${benefitType} applied`;
            updatedProduct = {
              ...updatedProduct,
              applied_card_type: cardType,
              applied_card_status: cardStatus,
              cardDetails,
              benefit_lable: benefitLabel,
              price: newPrice,
              original_price: originalPrice,
            };
          }

          return updatedProduct;
        });


        return {
          id: category.id,
          label: category.label,
          products: categoryProducts,
          numberOfProducts: categoryProducts.length,
          isOpen: true
        };
      });

      setMergedProductsRaw(mergedCategories);
      setMergedProducts(mergedCategories);

      setTimeout(() => {
        setPageLoaded(true);
      }, 2000);
    }
  }, [productsList])

  useEffect(() => {
    if (productsList && productsList.length > 0) {
      const categoryProducts = productsList.map((product) => {
        let updatedProduct = { ...product };

        if (cardStatus == 1) {
          const { discount_type, discount } = cardDetails || {};
          const originalPrice = updatedProduct.price;

          let discountAmount = 0;
          if (discount_type == 1) {
            discountAmount = discount;
          } else if (discount_type == 2) {
            discountAmount = Math.round((discount / 100) * originalPrice);
          }
          const newPrice = originalPrice - discountAmount;
          const benefitType = "discount";
          const benefitLabel = discount_type == 1 ? `₹${discount} ${benefitType} applied` : `${discount}% ${benefitType} applied`;
          updatedProduct = {
            ...updatedProduct,
            applied_card_type: cardType,
            applied_card_status: cardStatus,
            cardDetails,
            benefit_lable: benefitLabel,
            price: newPrice,
            original_price: originalPrice,
          };
        }
        //if Membership or Offer applied

        return updatedProduct;
      });
      setAllProductsList(categoryProducts);
    }
  }, [productsList])

  useEffect(() => {
    getVendorStatuses();
    getVendorProductsCategories();
  }, []);

  const getVendorStatuses = async () => {
    try {
      const response = await axiosInstance.post('user/vendor_profile_details', { vendor_id: valueParam.vendor_id });
      if (response.status == 200) {
        const data = response.data;

        setVendorType(data?.vendor_profile?.vendor_details?.vendor_type_id);
      }
    } catch (error) {

    }
  }

  const getVendorProductsCategories = async () => {
    try {
      const payload = {
        vendor_id: valueParam.vendor_id
      };

      const response = await axiosInstance.post('user/products/fetch_vendor_categories_without_login', payload);
      if (response?.status === 200) {
        const responseData = response?.data;

        let categories = [];
        responseData?.categories.map((category) => {
          let newCategory = {
            id: category.category_id,
            label: category.category_name,
            products: [],
            numberOfProducts: 0
          }
          categories.push(newCategory);
        });

        setProductsCategories(categories);
        getVendorProducts(1);
      }
    } catch (err) {
      if (err.response.status == 400 || err.response.status == 500) {
        toast(err.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
      }
    };
  };

  const getVendorProducts = async (pageNumber) => {
    try {
      const payload = {
        vendor_id: valueParam.vendor_id,
        page: 1,
        shipping_type: shipingtype,
        card_status: cardStatus ? cardStatus : '',
        card_type: cardType ? cardType : '',
        card_id: cardId ? cardId : ''
      };
      const response = await axiosInstance.post('user/products/fetch_paginated_products_without_login', payload);

      if (response?.status === 200) {
        const responseData = response?.data;

        if (cardStatus == 1) {
          setCardDetails(responseData?.card_details);
          // addCardDetail(responseData?.card_details);
        }
        if (responseData?.products.length > 0) {
          setProductsList((prevData) => {
            const validPrevData = Array.isArray(prevData) ? prevData : [];
            const newProducts = responseData.products.filter(
              (product) =>
                !validPrevData.some(
                  (existingProduct) => existingProduct.product_id === product.product_id
                )
            );
            return [...validPrevData, ...newProducts];
          });
        }

        const currentPage = Number(responseData?.current_page);
        const totalPages = Number(responseData?.total_pages);
        setTotalPagesCount(totalPages);
        // setPageLoaded(true);

        fetchNextPageInBackground(2)
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast(error.response.data.message, { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
        } else if (error.response.status === 500) {
          toast('Something went wrong. Please try again.', { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
        }

      } else {
        console.error("API Error:", error);
      }
    }

    // finally {
    //   setPageLoaded(true);
    // }
  };

  const fetchNextPageInBackground = async (nextPage) => {
    try {
      const payload = {
        vendor_id: valueParam.vendor_id,
        page: 2,
        shipping_type: shipingtype,
        card_status: cardStatus ? cardStatus : '',
        card_type: cardType ? cardType : '',
        card_id: cardId ? cardId : ''
      };

      const response = await axiosInstance.post('user/products/fetch_paginated_products_without_login', payload);

      if (response?.status === 200) {
        const responseData = response?.data;

        if (responseData?.products.length > 0) {
          setProductsList((prevData) => {
            const newProducts = responseData.products.filter(
              (product) =>
                !prevData.some((existingProduct) => existingProduct.product_id === product.product_id)
            );
            return [...prevData, ...newProducts];
          });
          setMenuLoaded(true)
          await fetchProductsAddons()
        }

      }
    } catch (error) {
      console.error("Background Fetch Error:", error);
    }
  };

  const fetchProductsAddons = async () => {
    try {
      const payload = {
        vendor_id: valueParam.vendor_id,
      };
      const response = await axiosInstance.post('user/products/fetch_vendor_product_addons_without_login', payload);
      if (response?.status === 200) {
        const responseData = response?.data;
        setProductsAddons(responseData?.products);
      }
    } catch (error) {
      console.error("Background Fetch Error:", error);
    }
  };


  // console.log('list-----', mergedProducts);


  return (
    <>
      <div>
        <div className=" d-flex flex-column" style={{ overflowY: "auto", maxHeight: "300px" }}>
          <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white ">
            <div className="d-flex justify-content-between align-items-center ms-2 me-2">
              <img src={p_nav_previous} width={"24px"} height={"16px"} alt="Previous Button" role="button"
                onClick={() => {
                  flowtype == "EDIT_ORDER" ? navigate('/ProductPass', { state: { orderid: cartId, flowtype: "EDIT_ORDER" } }) :
                    card?.card_status == "1" ? navigate(-1) :
                      navigate(`/vendor/${valueParam.vendor_id}`); localStorage.removeItem("shipping_type"); localStorage.removeItem("productDeliveryTime"); localStorage.removeItem("cartid")
                }} />
              <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Products</div>
            </div>
            {cardCount > 0 ? (
              <div onClick={() => sendProductActions('basket')} style={{ cursor: "pointer" }} >
                <img src={ion_cart_outline} />
                <div className="basketCount">
                  {cardCount > 0 ? (
                    <span style={{
                      height: "20px", width: "20px", backgroundColor: "var(--primary-bg)", color: "white", borderRadius: "50%",
                      display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",
                    }} >
                      {cardCount}
                    </span>
                  ) : (cardCount > 0 && <span>{cardCount}</span>)}
                </div>
              </div>
            ) : (
              <div>
                <img src={ion_cart_outline} />
              </div>
            )}
          </div>
        </div >
      </div>
      {
        pageLoaded ?
          <>
            <div className="scroller-style-y">
              <div className="mt-2 p-2 d-none" >
                <div className="d-flex justify-content-between deleiveryAddress rounded-pill bg-white secondary-text-1 p-1">
                  <input type="text" className="SearchServiceBox ms-2  ff-balooPaaji2-regular rounded-pill fs-14 mt-1" placeholder="Search " />

                </div>
              </div>
              <div className=" ">
                <div className="mt-2 p-2 " onClick={() => openserachProductModal()}>
                  <div className="d-flex justify-content-between deleiveryAddress rounded-pill bg-white secondary-text-1 p-1">
                    <input type="text" className="SearchServiceBox ms-2  ff-balooPaaji2-regular rounded-pill fs-14 mt-1" placeholder="Search " />
                    <span className="me-3"> <IoSearch /> </span>
                  </div>
                </div>
                {
                  vendorType == "9" ?
                    <div className="d-flex fs-13 ff-poppins-semiBold mb-2 p-2">
                      <div className="d-flex ms-2" onClick={handleVegToggle}>
                        <span>Veg</span>
                        <Toggle data={vegIcon} color="veg" isSelected={isVegSelected} />
                      </div>
                      {
                        isVegSelected ? <div className="d-flex" onClick={handleEggToggle}>
                          <span>Egg</span>
                          <Toggle data={eggIcon} color="egg" isSelected={isEggSelected} />
                        </div> : ""
                      }

                      <div className="d-flex" onClick={handleNonVegToggle}>
                        <span>Non-veg</span>
                        <Toggle data={nonvegIcon} color="nonveg" isSelected={isNonVegSelected} />
                      </div>

                    </div> : ""
                }

              </div>
              {mergedProducts?.length > 0 ? (
                <div className="offcanvas-parentType3 " id="scroll" onClick={closeMenuList}>
                  <div className="doorStepService p-0 position-relative" >
                    <div className="scroller-style-y">
                      <div className="accordion px-3  margin_bottom_250 ">
                        {

                          mergedProducts?.map((product, index) => { 

                            return product?.products?.length > 0 ? (
                              <div className="">
                                < div className="accordion-item product_list_accordion" key={index} >
                                  <h2 className="accordion-header mb-1 " id={"collapseOne" + product.id}>
                                    <button className={`serviceMenu-but p-2 m-0 accordion-button ff-poppins-bold fs-16 text-white ${isItemOpen(index) ? "" : "collapsed"
                                      }`} type="button" onClick={() => toggleAccordion(index)} aria-expanded={isItemOpen(index) ? "true" : "false"}   >
                                      <p className="m-0"> {product.label} </p>
                                      <p className="m-0">
                                        {isItemOpen(index) ? (<IoIosArrowUp style={{ fontSize: 20 }} />) : (<IoChevronDown style={{ fontSize: 20 }} />)}
                                      </p>
                                      <style>{`button::after {   display: none;  position: absolute;   content: '';   }`}  </style>
                                    </button>
                                  </h2>
                                  <div className={`accordion-collapse collapse ${isItemOpen(index) ? "show" : ""}`} aria-labelledby={"heading" + index}  >
                                    <div className="accordion-body">
                                      {
                                        product?.products.map((item, itemIndex) => {

                                          return (
                                            <div key={itemIndex} >
                                              <div>
                                                {/* productDiv */}
                                                {/* className={`w-100 primary-text fontSizeCountIcon ${card?.offer_type == "4" ? productAction.added_count == "1" ? "opacity_Cls rounded-circle" : "" : productAction.added_count == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`} */}
                                                <div className={`${item.stock_status == 1 && item.stock_quantity == 0 ? 'opacity_5 productDiv' : " productDiv"}`}>
                                                  <div className="col-3 ">
                                                    <div className="imgBox position-relative productImageDiv">
                                                      <img src={item.imges[0].image == "" ? defaultimg : item.imges[0].image_url} className="productImage" />
                                                    </div>
                                                  </div>
                                                  <div className="col-6 ms-1">
                                                    <div className="d-flex">
                                                      {vendorType == "9" &&
                                                        <img src={item.food_type == "1" ? vegIcon : item.food_type == "2" ? nonvegIcon : item.food_type == "3" ? eggIcon : ""} height="13px" width="13px" className="me-2" />
                                                      }
                                                      <div className="ff-poppins-semiBold fs-12">

                                                        {item.product_name}
                                                      </div>
                                                    </div>
                                                    <div>
                                                      {item.applied_card_status == "1" ? (
                                                        <div className="d-flex ">
                                                          <div className="ff-poppins-semiBold d-flex  ">
                                                            <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                                          </div>
                                                          <span className="primary-text fs-10">
                                                            ({item.benefit_lable}){" "}
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <div className="ff-poppins-semiBold d-flex me-1 ">
                                                          <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="ff-poppins-semiBold primary-text fs-9">
                                                      {item.unit_quantity} {item.unit_type_name}
                                                    </div>
                                                    <div className="moreDetails ">
                                                      <div className="accordion-item">
                                                        <h2 className="moreInfo m-0 fs-14" id={"headingSubOne"}  >
                                                          <button className="moreInfobtn primary-text ff-poppins-semiBold fs-10 px-0" type="button"
                                                            onClick={() => sendProductActions('single', item.product_id)}   >
                                                            More Details
                                                            <BsChevronDown />
                                                          </button>
                                                        </h2>
                                                      </div>
                                                    </div>

                                                    {item.stock_status === "1" ? (

                                                      item.stock_quantity == 0 ?
                                                        <span className="text-danger fs-10 p-0 m-0 ff-poppins-semiBold"> Out of stock</span> :
                                                        productActions?.all_products?.filter((product) => product.product_id === item.product_id).length > 0 ? (
                                                          productActions?.all_products
                                                            ?.filter((product) => product.product_id === item.product_id)
                                                            .map((product) => (
                                                              <div key={product.product_id}>
                                                                <p className="ff-poppins-semiBold primary-text fs-9 m-0">
                                                                  {item.stock_quantity - (product?.added_count)} left
                                                                </p>
                                                              </div>
                                                            ))
                                                        ) : (
                                                          <div key={item.product_id}>
                                                            <p className="ff-poppins-semiBold primary-text fs-9 m-0">
                                                              {item.stock_quantity} left
                                                            </p>
                                                          </div>
                                                        )
                                                    ) : (
                                                      ""
                                                    )}

                                                  </div>
                                                  <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                                                    <div className="ff-poppins-regular fs-9 primary-text" >
                                                      {
                                                        (() => {
                                                          const productAction = productActions?.all_products?.find(action => action.product_id === item.product_id);
                                                          return productAction ? (
                                                            productAction.added_count != 0 ? (
                                                              <div>
                                                                <div className="row align-items-center w-100 fs-14 gx-0">
                                                                  <div className="col-4">
                                                                    <FiMinusCircle
                                                                      className="w-100 primary-text fontSizeCountIcon"
                                                                      onClick={() => action_productcount(item, "remove")}
                                                                    />
                                                                  </div>
                                                                  <div className="col-4 d-flex justify-content-center align-items-end primary-text">
                                                                    {productAction.added_count}
                                                                  </div>
                                                                  <div className="col-4">
                                                                    <LuPlusCircle
                                                                      className={`w-100 primary-text fontSizeCountIcon ${card?.offer_type == "4" ? productAction.added_count == "1" ? "opacity_Cls rounded-circle" : "" : productAction.added_count == item.max_allowed_quantity || productAction.added_count == item.stock_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                                                      onClick={() => {
                                                                        setSelectItem(item);
                                                                        if (productAction.added_count == item.max_allowed_quantity) {
                                                                          toast("You have reached maximum order product", {
                                                                            position: "bottom-center",
                                                                            autoClose: 2000,
                                                                            hideProgressBar: true,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                            className: "text-danger toast-custom-style toast-style-1",
                                                                          })
                                                                        }
                                                                        else if (productAction.added_count == item.stock_quantity) {
                                                                          toast("Out of Stock", {
                                                                            position: "bottom-center",
                                                                            autoClose: 2000,
                                                                            hideProgressBar: true,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: false,
                                                                            progress: undefined,
                                                                            theme: "light",
                                                                            className: "text-danger toast-custom-style toast-style-1",
                                                                          })
                                                                        }
                                                                        else if (item.addons == '' && productAction.added_count != item.max_allowed_quantity) {
                                                                          action_productcount(item, "add");
                                                                        } else if (card?.offer_type == "4") {
                                                                          if (productAction.added_count == "1") {
                                                                            toast("You have reached maximum order product", {
                                                                              position: "bottom-center",
                                                                              autoClose: 2000,
                                                                              hideProgressBar: true,
                                                                              closeOnClick: true,
                                                                              pauseOnHover: true,
                                                                              draggable: false,
                                                                              progress: undefined,
                                                                              theme: "light",
                                                                              className: "text-danger toast-custom-style toast-style-1",
                                                                            });
                                                                          }
                                                                        } else if (item.addons) {
                                                                          productAction.added_count == item.max_allowed_quantity
                                                                            ? toast("You have reached maximum order product", {
                                                                              position: "bottom-center",
                                                                              autoClose: 2000,
                                                                              hideProgressBar: true,
                                                                              closeOnClick: true,
                                                                              pauseOnHover: true,
                                                                              draggable: false,
                                                                              progress: undefined,
                                                                              theme: "light",
                                                                              className: "text-danger toast-custom-style toast-style-1",
                                                                            })
                                                                            : openEditAddonModal(item.addons, item.added_addons, item.product_id, productAction.added_count);
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : null
                                                          ) : (
                                                            <div className="col-4 selectBtnProductDiv">
                                                              <button
                                                                className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${loadingButtonId == item.product_id ? "loading" : ""}`}
                                                                onClick={() => {

                                                                  if (item.stock_status != 1 || item.stock_quantity > 0) {
                                                                    item?.addons_status == true ? openAddonModal(item?.product_id, item) : handleClick(item.product_id, item);
                                                                    setProductCost(item?.price);
                                                                    setSelectItem(item);
                                                                  }

                                                                }}
                                                              >
                                                                <span>Select</span>
                                                              </button>
                                                            </div>
                                                          );
                                                        })()
                                                      }
                                                    </div>

                                                  </div>

                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      }
                                      <></>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null;  
                          })

                        }
                      </div>
                    </div>

                    {isModelOpen && (
                      <div className="productsHeroDiv" style={{ zIndex: 9999 }} >
                        {
                          productActions?.all_products?.length > 0 && (
                            <div className="reviewPayDivs" >
                              <div className="linetop-container" >
                                <div className="lineTop text-center primary-text" style={{ borderTop: "3px solid" }}   >

                                </div>
                              </div>
                              <div className="d-flex ff-poppins-bold">
                                <p className="primary-text d-flex">
                                  <p className="me-1  ms-1">  {cardCount > 1 ? cardCount + " Products" : cardCount + " Product"}</p>
                                  <span className="text-dark">added</span>{" "}
                                </p>
                              </div>
                              <button
                                className={`${card?.offer_type == "4" ? (card?.combo_count == cardCount ? "primary-bg" : "secondaryslot-bg") : "primary-bg"} reviewAndPayBtnProduct ff-balooPaaji2-bold`}
                                onClick={() => {
                                  const flowtypeValue = flowtype == "EDIT_ORDER" ? "EDIT_ORDER" : flowtype;
                                  if (card?.offer_type != "4") {
                                    sendProductActions(flowtypeValue)
                                  }
                                  else if (card?.offer_type == "4" && card?.combo_count != cardCount) {
                                    toast(`You should select ${card?.combo_count} products only`, {
                                      position: "bottom-center",
                                      autoClose: 2000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: false,
                                      progress: undefined,
                                      theme: "light",
                                      className: "text-danger toast-style-1",
                                    });
                                  }
                                  else if (card?.offer_type == "4" && card?.combo_count == cardCount) {
                                    navigate("/ReviewPay", { state: { merchantId, cartId, shipingtype, flowtype: flowtypeValue } });
                                  }
                                }}
                              >
                                Review and Pay
                              </button>

                            </div>
                          )
                        }
                      </div>
                    )}


                    {isMenuOpen && (
                      <div className="menu-div">
                        <div className="left-div"></div>
                        <div className="right-div">
                          <div className="dropup-center dropup" style={{ zIndex: 9990 }}>
                            <button id="menuButton" className="btn btn-secondary dropdown-toggle menuBtn" type="button" onClick={dropUpMenuPress} style={{ display: showMenus ? "none" : " ", zIndex: 9999 }} >
                              <RxHamburgerMenu className="menuIcon" />
                              Menu
                            </button>
                          </div>
                        </div>
                        {showMenus && (
                          <>
                            <div className="backdrop" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9996 }} onClick={(e) => { e.stopPropagation(); setShowMenus(false) }}></div>
                            <div className="menu-list-container  "  >
                              <ul className="dropdown-menu show scroller-style-y rounded-4" style={{ maxHeight: "200px" }} id="menuList"  >
                                {productlist.map((cate, index) => (
                                  <li key={index} className="d-flex justify-content-between p-1 fs-12 ff-poppins-regular"  >
                                    <button className="dropdown-item d-flex collapsed justify-content-between"
                                      onClick={() => { handleCategoryClick(cate.category_id); }}  >
                                      <span className="truncate-text">{cate?.category_name}</span>
                                    </button>
                                    <span className="me-1 text-light">{cate?.products?.length}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>)}
                  </div>
                </div>
              ) :
                (
                  <>
                    <div>
                      <Lottie animationData={empotyLottie} />
                    </div>
                    <p className='text-dark text-center mt-5 ff-balooPaaji2-bold fs-20 '>No Product Available</p>

                  </>
                )}

              {/* Edit addon madal */}

              <div className="offcanvas addOnModel_scroll" tabIndex="-1" style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15, width: 390, zIndex: 9992, maxHeight: "auto", overflowY: "auto", }} ref={addonEditeModalRef} >
                <div className=" d-flex justify-content-end mt-2 primary-text" onClick={() => closeEditAddonModal()}>
                  <span className="fs-20 ff-poppins-semiBold me-3 " ><IoIosCloseCircle /> </span>
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <p className="m-0 ff-poppins-semiBold fs-14">Your Chosen</p>
                    <p className="m-0 ff-poppins-regular fs-14 primary-text" onClick={() => handelEdittoAddonmodel(productid)}>Edit</p>
                  </div>


                  {productActions?.all_products?.length > 0 &&
                    productActions?.all_products?.map((product, productIndex) => {
                      return (
                        productid == product?.product_id &&
                        Array.isArray(product?.addons) &&
                        product?.addons.map((addon, addonIndex) => {
                          return (
                            Array.isArray(addon?.options) &&
                            addon?.options.map((option, optionIndex) => {
                              return (
                                <div className="d-flex justify-content-between" key={optionIndex}>
                                  <div className="d-flex mt-1">
                                    {(option.type === "1" || option.type === "2") && (
                                      <img src={option.type === "1" ? vegIcon : nonvegIcon} height="13px" width="13px" className="me-1 mt-1" />
                                    )}
                                    <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                                  </div>
                                  <div className="d-flex me-4">
                                    <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>
                                  </div>
                                </div>
                              );
                            })
                          );
                        })
                      );
                    })}


                  <div className="mt-3" style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
                    <div className=" ">
                      {productActions?.all_products?.length > 0 &&
                        productActions?.all_products?.map((item) => (
                          <div className="ff-poppins-regular ">
                            {productid == item?.product_id && item?.added_count > 0 && (
                              <div>
                                <div className="d-flex justify-content-between">
                                  <div className="  d-flex fs-14 col-4  ">
                                    <div className="col-2">
                                      <FiMinusCircle className="primary-text fontSizeCountIcon"
                                        onClick={() => { productCount > 1 && setProductCount((prevCount) => prevCount - 1) }}
                                      />
                                    </div>
                                    <div className="col-2 ms-1 d-flex justify-content-center">
                                      {productCount}
                                    </div>
                                    {/* className={`${item.stock_status == 1 && item.stock_quantity == 0 ? 'opacity_5 productDiv' : " productDiv"}`} */}
                                    <div className="col-2">
                                      <LuPlusCircle
                                        className={`  primary-text fontSizeCountIcon ${card?.offer_type == "4" ? item?.added_count == card?.combo_count ? "opacity_Cls rounded-circle" : "" : productCount == item.max_allowed_quantity || productCount < item.stock ? "opacity_Cls rounded-circle" : ""}`}
                                        onClick={() => {
                                          if (card?.offer_type == "4") {
                                            if (item.added_count != "1") {
                                              // action_productcount(item, "add")
                                              setProductCount((prevCount) => prevCount + 1);
                                              setProductid(item.product_id)
                                            }
                                            else {
                                              toast("You have reached maximum order product", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                                            }
                                          }
                                          else {
                                            if (productCount != item.max_allowed_quantity || productCount < item.stock) {
                                              setProductCount((prevCount) => prevCount + 1);
                                              // action_productcount(item, "add")
                                              setProductid(item.product_id);
                                              setProductCost((totalPrice) * (item.added_count + 1))
                                            } else {
                                              if (productCount == item.max_allowed_quantity) {
                                                toast("You have reached maximum order product", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });
                                              }
                                              else {
                                                toast("out of stock", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, progress: undefined, theme: "light", className: "text-danger toast-style-1", });

                                              }
                                            }
                                          }

                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className=" p-1  ">
                                    <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1 me-3">
                                      Cost: {(totalPrice) * (productCount)}
                                    </p>
                                  </div>

                                </div>
                                <div className="col-12">
                                  <button type="button" className="btn-type-1   ff-poppins-semiBold mt-2 fs-14 w-100 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                                    onClick={() => confirmUpdate(item)} >
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>

              </div>

              {/* Add addon madal */}
              <div className="offcanvas addOnModel_scroll  " tabIndex="-1" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, width: 390, zIndex: 9992, maxHeight: "60%" }} ref={addonModalRef}  >

                {productsAddons?.length > 0 &&
                  productsAddons?.map((product, productIndex) =>
                  (productid == product?.product_id ?
                    <div key={productIndex} className="">
                      <div className="offcanvas-body small">
                        <div className="">
                          <div className=" d-flex justify-content-end mt-1 primary-text" onClick={() => closeAddonModal()}>
                            <span className="fs-20 ff-poppins-semiBold me-3 " ><IoIosCloseCircle /> </span>
                          </div>

                          <div className="mb-2">
                            <h6 className="m-0 ff-poppins-semiBold fs-14">choose {product?.addon_name}</h6>
                            <div className="secondary-text ff-poppins-regular fs-13">select any (optional)</div>
                          </div>
                          {product?.options.map((option, optionIndex) => (
                            <div className="d-flex justify-content-between" key={`${productIndex}-${optionIndex}`}>


                              <div className="d-flex mt-1   ">
                                <img src={option?.type === "1" ? vegIcon : option?.type === "2" ? nonvegIcon : ""} height="13px" width="13px" className="me-1 mt-1" />
                                <div className="ff-poppins-regular fs-13 ms-1">{option?.name}</div>
                              </div>
                              <div className="d-flex">
                                <div className="ff-poppins-semiBold fs-12 mt-1 me-1">+ {option?.price}</div>
                                {
                                  checkIsSelectedAddon(product.addon_id, option.id) ?
                                    <div onClick={() => { selectAddon(product, option.id, option?.price, option); setProductid(product?.product_id) }} className="primary-text fs-15 ff-poppins-semiBold"> <MdOutlineCheckBox /> </div>
                                    :
                                    <div onClick={() => { selectAddon(product, option.id, option?.price, option); setProductid(product?.product_id) }} className="fs-15 ff-poppins-semiBold " >    <MdOutlineCheckBoxOutlineBlank /></div>
                                }


                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    : "")
                  )}

                <div style={{ backgroundColor: '#fff', position: "sticky", bottom: 0, width: '100%' }}>
                  <div className=" p-1  ">
                    <p className="m-0 d-flex justify-content-end primary-text ff-poppins-regular fs-14 mb-1 me-3">
                      Cost: {productCost}  </p>
                    <button type="button" className="btn-type-1 ff-poppins-semiBold fs-14 col-12 primary-bg border-0 text-white justify-content-center d-flex align-items-center"
                      onClick={() => checkButtonStatus()}  >
                      Confirm
                    </button>
                  </div>

                </div>
              </div>
              <div className="offcanvas p-1 " tabindex="-1" id="serachProductModalProduct" aria-labelledby="selectCustomerModalProductLabel" style={{ height: '95vh', width: 390, zIndex: '9991' }}   >
                <div className="  ">
                  <div className="searchModelTop">

                    <div>
                      <div class="modal-header modal-headerSearch d-flex justify-content-between  ms-2 me-2">
                        <h1 class="ff-poppins-semiBold fs-16 mt-2" id="exampleModalLabel">
                          Search
                        </h1>
                        <button type="button" className="btn-close " aria-label="Close" onClick={() => { closeserachProductModal(); setSearchitem(""); }}></button>
                      </div>
                      <div className="mb-3 ms-2 me-2">
                        <div className="search secondary-text-1">
                          <div  >
                            <span className="primary-text ff-poppins-semiBold">  <IoSearch />   </span>
                            <input type="text" placeholder="Search With the product" className="secondary-text-1 ff-poppins-regular fs-14" value={searchitem} onChange={(e) => setSearchitem(e.target.value)} />
                          </div>
                        </div>

                      </div>
                      {
                        vendorType == "9" ?
                          <div className="d-flex fs-14 ff-poppins-semiBold mb-2">
                            <div className="d-flex ms-2" onClick={handleVegToggle}>
                              <span>Veg</span>
                              <Toggle data={vegIcon} color="veg" isSelected={isVegSelected} />
                            </div>
                            {
                              isVegSelected ? <div className="d-flex" onClick={handleEggToggle}>
                                <span>Egg</span>
                                <Toggle data={eggIcon} color="egg" isSelected={isEggSelected} />
                              </div> : ""
                            }

                            <div className="d-flex" onClick={handleNonVegToggle}>
                              <span>Non-veg</span>
                              <Toggle data={nonvegIcon} color="nonveg" isSelected={isNonVegSelected} />
                            </div>

                          </div> :
                          ""
                      }
                      <div className="p-3">
                        <h6 className="ff-poppins-regular secondary-text-1">Product</h6>
                      </div>
                    </div>
                    <div>

                      <div className="padding_Bottom scroller-style-y">
                        {
                          filteredItems.length > 0 || searhModelItems.length > 0 ?
                            filteredItems?.map((item, itemIndex) => (
                              <div key={itemIndex} style={{ zIndex: "6666" }}>
                                <div className="productDiv shadow ">
                                  <div className="col-3 ">
                                    <div className="imgBox productImageDiv">
                                      <img
                                        src={(item.imges && item.imges.length > 0 && item.imges[0].image_url !== "") ? item.imges[0].image_url : defaultimg}
                                        className="productImage"
                                      />


                                    </div>
                                  </div>
                                  <div className="col-6 ps-2">
                                    <div className="ff-poppins-semiBold fs-12">
                                      {item?.food_type !== '0' ?
                                        <img src={item.food_type == "1" ? vegIcon : item.food_type == "2" ? nonvegIcon : item.food_type == "3" ? eggIcon : ""} height="13px" width="13px" className="me-2" />
                                        : ""}
                                      {item.product_name}
                                    </div>
                                    <div>
                                      {item.applied_card_status == "1" ? (
                                        <div className="d-flex ">
                                          <div className="ff-poppins-semiBold d-flex  ">
                                            <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                          </div>
                                          <span className="primary-text fs-10">
                                            ({item.benefit_lable}){" "}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="ff-poppins-semiBold d-flex ">
                                          <span className="fs-11"><FaRupeeSign /> </span>  <div className="fs-12">{item.price} </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="ff-poppins-regular primary-text fs-10 primary-text">
                                      {item.unit_quantity} {item.unit_type_name}
                                    </div>
                                    <div className="moreDetails primary-text">
                                      <div className="accordion-item ">
                                        <h2 className="moreInfo fs-14" id={"headingSubOne"}>
                                          <button className="moreInfobtn ff-poppins-regular fs-10 px-0" type="button"
                                            onClick={() => navigate(`/singleProductDetails/${merchantId.vendor_id}/${item.product_id}`, { state: { productId: item.product_id, merchantId } })}  >
                                            More Details
                                            <BsChevronDown />
                                          </button>
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3 d-flex justify-content-center align-items-end primary-text">
                                    <div className="ff-poppins-regular fs-9 primary-text" >
                                      {
                                        (() => {
                                          const productAction = productActions?.all_products?.find(action => action.product_id === item.product_id);
                                          return productAction ? (
                                            productAction.added_count != 0 ? (
                                              <div>
                                                <div className="row align-items-center w-100 fs-14 gx-0">
                                                  <div className="col-4">
                                                    <FiMinusCircle
                                                      className="w-100 primary-text fontSizeCountIcon"
                                                      onClick={() => action_productcount(item, "remove")}
                                                    />
                                                  </div>
                                                  <div className="col-4 d-flex justify-content-center align-items-end primary-text">
                                                    {productAction.added_count}
                                                  </div>
                                                  <div className="col-4">
                                                    <LuPlusCircle
                                                      className={`w-100 primary-text fontSizeCountIcon ${card?.offer_type == "4" ? productAction.added_count == "1" ? "opacity_Cls rounded-circle" : "" : productAction.added_count == item.max_allowed_quantity ? "opacity_Cls rounded-circle" : ""}`}
                                                      onClick={() => {
                                                        setSelectItem(item);
                                                        if (productAction.added_count == item.max_allowed_quantity) {
                                                          toast("You have reached maximum order product", {
                                                            position: "bottom-center",
                                                            autoClose: 2000,
                                                            hideProgressBar: true,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: false,
                                                            progress: undefined,
                                                            theme: "light",
                                                            className: "text-danger toast-custom-style toast-style-1",
                                                          })
                                                        }
                                                        else if (item.addons == "" && productAction.added_count != item.max_allowed_quantity) {
                                                          action_productcount(item, "add");
                                                        } else if (card?.offer_type == "4") {
                                                          if (productAction.added_count == "1") {
                                                            toast("You have reached maximum order product", {
                                                              position: "bottom-center",
                                                              autoClose: 2000,
                                                              hideProgressBar: true,
                                                              closeOnClick: true,
                                                              pauseOnHover: true,
                                                              draggable: false,
                                                              progress: undefined,
                                                              theme: "light",
                                                              className: "text-danger toast-custom-style toast-style-1",
                                                            });
                                                          }
                                                        } else if (item.addons != "") {
                                                          productAction.added_count == item.max_allowed_quantity
                                                            ? toast("You have reached maximum order product", {
                                                              position: "bottom-center",
                                                              autoClose: 2000,
                                                              hideProgressBar: true,
                                                              closeOnClick: true,
                                                              pauseOnHover: true,
                                                              draggable: false,
                                                              progress: undefined,
                                                              theme: "light",
                                                              className: "text-danger toast-custom-style toast-style-1",
                                                            })
                                                            : openEditAddonModal(item.addons, item.added_addons, item.product_id, productAction.added_count);
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null
                                          ) : (
                                            <div className="col-4 selectBtnProductDiv">
                                              <button
                                                className={`selectBtnProduct primary-text fs-12 ff-poppins-semiBold ${loadingButtonId == item.product_id ? "loading" : ""}`}
                                                onClick={() => {

                                                  if (item.stock_status != 1 || item.stock_quantity > 0) {
                                                    item?.addons_status == true ? openAddonModal(item?.product_id, item) : handleClick(item.product_id, item);
                                                    setProductCost(item?.price);
                                                    setSelectItem(item);
                                                  }

                                                }}
                                              >
                                                <span>Select</span>
                                              </button>
                                            </div>
                                          );
                                        })()
                                      }
                                    </div>

                                  </div>
                                </div>
                              </div>
                            ))
                            :
                            <div className=" ReactLoading">
                              <p className="p-5 text-center secondary-text-1 ff-poppins-regular fs-14"> No Products  </p>
                            </div>
                        }
                      </div>
                    </div>

                  </div>



                </div>
              </div >
            </div>
            {isModalOpen && <div className="modal-backdrop fade show"></div>}

          </>
          :
          <div className="ReactLoading">
            <ReactLoading type={"spinningBubbles"} color={"#4E26A3"} />
          </div>
      }

    </>
  );
};

export default Products;


