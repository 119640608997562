import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../Components/PreOrderTicketCard/TicketCard.css";
import { FiArrowRight } from "react-icons/fi";

export default function TicketCard(Data) {

  const DEFAULT_Ticket_IMAGE_URL =
    "https://theme-assets.getbento.com/sensei/6941e9b.sensei/assets/images/catering-item-placeholder-704x520.png";
  const DEFAULT_IMAGE_URL =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScO99JkPvdfSyT3CbMrYsKBpRuXOACVR2cP9F6DBcP9B6nQ9oszX_18T-2oNv6Gyxwyhk&usqp=CAU";

  const { vendor_id } = useParams();

  const [data, setData] = useState(Data.Data);
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [ticketId, setTicketId] = useState(data?.ticket_id);
  const location = useLocation()
  const { select_date } = location.state || {};
 

  useEffect(() => {
    setData((prevData) => (prevData !== Data.Data ? Data.Data : prevData));
  }, [vendor_id, Data.Data]);
  return (
    <div className={`card-main-box-container p-2 mt-2 mb-2 `}>

      <div
        className={`preorder-card-main-box ${data?.card_colour == "transparent" && "ImageDesign"
          }`}
        style={{
          background:
            data.card_colour !== "transparent"
              ? data.card_colour
              : data?.card_pattern
                ? `url(${data.card_pattern})`
                : "rgb(231, 52, 96)",
        }}
      >
        <div className="conatiner-left">

          <img
            src={`${data.images || DEFAULT_Ticket_IMAGE_URL}`}
            alt="Preview"
            className="image" width={"100px"} height={"165px"}
            onError={(e) => e.target.src = DEFAULT_Ticket_IMAGE_URL}
          />

        </div>

        <div
          className="conatiner-right-Card"
          style={{
            background: data.card_colour
              ? data.card_colour
              : "rgb(231, 52, 96)",
          }}
        >

          <div className="content-top p-0 d-flex justify-content-between align-items-center">
            <span className="store-name p-0 fs-14 ff-poppins-semiBold text-white">
              {data.vendor_company_name}
            </span>
            <span></span>
          </div>
          <div className="content-bottom">
            <span className=" fs-14 ff-poppins-semiBold text-uppercase text-white ">
              {data.ticket_name || "TICKET NAME"}
            </span>
            <div className="gradient-line"
              style={{
                width: `${Math.min(data.ticket_name?.length * 20, 200) || 200
                  }px`,
                marginTop: 0,
                marginBottom: "10px",
              }}
            ></div>
            <p className="content ff-poppins-regular fs-10 text-white">
              {(
                data?.about ||
                "This ticket is for food, making preordering easy, convenient, budget..."
              ).slice(0, 70) + (data?.about?.length > 70 ? "..." : "")}
            </p>
            <span className="price fs-14 ff-poppins-semiBold text-white">
              ₹ {data.ticket_price || "00.0"}
            </span>
          </div>
          <div className="footer py-2">
            <span className="bottom-left fs-12 ff-poppins-regular text-white"></span>
            <button className="buyNow fs-12 ff-poppins-regular" onClick={() => navigate(`/single_tickt_detail/${data.vendor_id}/${data.ticket_id}`, { state: { select_date: data.selected_date } })}>
              Buy Now <FiArrowRight className="ms-1" />
            </button>
          </div>
        </div>

      </div>

    </div>
  );
}
