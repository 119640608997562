import p_nav_previous from '../../Assets/Images/p_nav_previous.svg';
import p_nav_edit from '../../Assets/Images/p_nav_edit.svg';
import p_dash_call from '../../Assets/Images/p_dash_call.svg';
import p_dash_text from '../../Assets/Images/p_dash_text.svg';
import p_dash_share from '../../Assets/Images/p_dash_share.svg';
import p_address_home from '../../Assets/Images/p_address_home.svg';
import p_address_others from "../../Assets/Images/p_address_others.svg";
import p_address_work from "../../Assets/Images/p_address_work.svg";
import { useEffect, useRef, useState } from 'react';
import { getMyLocations, getMyProfileDetails, getUserAllVehicles, removeMySavedLocation, userAllFamilyMembers, userAllPetsDetail } from '../../Services/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import defaultimg from "../../Assets/Images/defaultperson.jpg"
const UserProfile = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [profile, setProfileData] = useState('');
    const [locationsList, setLocationsList] = useState([]);
    const [membersList, setMembersList] = useState([]);
    const [petsList, setPetsList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const [copied, setCopied] = useState(false);
    const familyMembersRef = useRef(null);
    const myPetRef = useRef(null);
    const myVehiclesRef = useRef(null);
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [location_id, setLocation_id] = useState('')
    useEffect(() => {
        getMyProfileDetails().then((res) => {
            setProfileData(res.data?.user_details);
        });

        getMyLocations().then((res) => {
            setLocationsList(res.data?.saved_locations)
        });

        userAllFamilyMembers().then((res) => {
            setMembersList(res.data?.family_detail)
            console.log(res.data?.family_detail);
        });

        userAllPetsDetail().then((res) => {
            setPetsList(res.data?.pets_detail);
        });

        getUserAllVehicles().then((res) => {
            setVehicleList(res.data?.Vehicles_detail);
        });

        if (location.state && location.state.divRef && location.state.divRef == 'familyMembers') {
            myPetRef.current.scrollIntoView()
        }
        else if (location.state && location.state.divRef && location.state.divRef == 'myPets') {
            myPetRef.current.scrollIntoView()
        }
        else if (location.state && location.state.divRef && location.state.divRef == 'myVehicles') {
            myVehiclesRef.current.scrollIntoView()
        }


    }, [value]);

    const copyToClipboard = () => {
        const textToCopy = profile.user_referral_link;

        // Create a textarea element and set its value to the text to be copied
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);

        // Select the text in the textarea
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        // Update state to indicate that text has been copied
        setCopied(true);

        toast('Link copied!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: 'text-danger toast-style-1'
        });

        // Reset the copied state after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    function editLocation(item) {
        navigate("/map-view", {
            state: { formData: item, isFromFindLocation: false },
        });
    }

    function deleteSavedLocation() {
        let param = {
            "location_id": location_id
        }
        removeMySavedLocation(param).then((res) => {

            if (res.data?.status == '1') {
                getMyLocations().then((res) => {
                    setLocationsList(res.data?.saved_locations)
                    closeModal()
                });
            }
        });
    }
    const openModal = (id) => {
        setModalOpen(true);
        // setIsOpen(false)
        setLocation_id(id)
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-3 p-navbar">
                <div className="d-flex justify-content-between align-items-center ms-2 me-2">
                    <img src={p_nav_previous} width={'24px'} height={'16px'} role='button' onClick={() => navigate('/dashboard')} />
                    <div className='ff-balooPaaji2-bold fs-20 ms-2 ps-1'>User Profile</div>
                </div>
                <img src={p_nav_edit} width={'27px'} height={'27px'} role='button' onClick={() => navigate(`/edit-profile`)} />
            </div>

            <div className='userProfile scroller-style-y secondary-bg'>
                <div className='banner d-flex justify-content-center primary-bg pt-1'>
                    <img src={profile.user_img} width={'108px'} height={'108px'} className='mt-3 userImg' />
                </div>

                <div className='text-center userInfo1'>
                    <div className='ff-poppins-semiBold fs-20'>{profile.user_name}</div>
                    <div className='ff-poppins-regular fs-14'>UID:{profile.user_id}</div>
                </div>
                <div className='text-center userInfo2'>
                    <div className='ff-poppins-semiBold fs-14'>About</div>
                    <div className='ff-poppins-regular fs-14'>{profile.user_gender}</div>
                    <div className='ff-poppins-regular fs-14'>{profile.user_about}</div>
                </div>
                <div className='text-center userInfo3'>
                    <div className='ff-poppins-semiBold fs-16'>Referral ID:{profile.user_referral_id}</div>
                </div>

                <div className='d-flex justify-content-center mt-2 pt-1'>
                   
                    <div className='d-flex justify-content-center align-items-center contactDetails'
                        role='button' onClick={() => navigate('/messenger-list')}>
                        <img src={p_dash_text} width={'24px'} height={'24px'} />
                        <div className='ms-2 ff-poppins-semiBold fs-14 primary-text'>Text</div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center contactDetails border-0'
                        role='button' onClick={() => copyToClipboard()}>
                        <img src={p_dash_share} width={'24px'} height={'24px'} />
                        <div className='ms-2 ff-poppins-semiBold fs-14 primary-text'>Share</div>
                    </div>
                </div>

                <div className='userDetailsTitle ms-3 ps-1 me-3 pe-1 mb-2'>
                    <div className='d-flex justify-content-between'>
                        <div className='ff-poppins-semiBold fs-20'>Saved addresses</div>
                        <button
                            className='addButton rounded-pill ff-poppins-semiBold fs-14 white-bg'
                            role='button'
                            onClick={() => navigate("/map-view", {
                                state: {
                                    formData: {
                                        latlng: "",
                                        location_type: "",
                                        location_type_id: "",
                                        door_no: "",
                                        area: "",
                                        direction: "",
                                        latitude: "",
                                        longitude: "",
                                        location_id: ""
                                    },
                                    isFromFindLocation: false
                                },
                            })}
                        >
                            Add Address
                        </button>
                    </div>
                </div>
                <div className='userDetails'>

                    {
                        locationsList.length > 0
                            ?
                            locationsList.map(item => {
                                return (
                                    <div className='addreddCard d-flex white-bg mb-2'>
                                        {
                                            item.location_type_id == "1" &&
                                            <img src={p_address_home} className='mt-1' width={'20px'} height={'20px'} />
                                        }
                                        {
                                            item.location_type_id == "2" &&
                                            <img src={p_address_work} className='mt-1' width={'20px'} height={'20px'} />
                                        }
                                        {
                                            item.location_type_id == "3" &&
                                            <img src={p_address_others} className='mt-1' width={'20px'} height={'20px'} />
                                        }

                                        <div className='ms-3'>
                                            <div className='ff-poppins-semiBold fs-18'>{item.location_type}</div>
                                            <div className='ff-poppins-regular fs-15 addersClamped-text'>
                                                {item.door_no}, {item.area} {item.address}
                                            </div>
                                            <div className='d-flex'>
                                                <div className='ff-poppins-semiBold fs-18 primary-text editButton' role='button' onClick={() => editLocation(item)}>EDIT</div>
                                                <div className='ff-poppins-semiBold fs-18 primary-text' role='button' onClick={() => openModal(item.id)}>DELETE</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center'>
                                <div className='ff-poppins-regular fs-14'>No Address Added</div>
                            </div>
                    }
                </div>
                <div className='userDetailsTitle ms-3 ps-1 me-3 pe-1 mb-2 mt-3' ref={familyMembersRef}>
                    <div className='d-flex justify-content-between'>
                        <div className='ff-poppins-semiBold fs-20'>Family Members</div>
                        <Link to={`/add-family-member`}>
                            <button className='addButton rounded-pill ff-poppins-semiBold fs-14 white-bg'>Add Members</button>
                        </Link>
                    </div>
                </div>
                <div className='userDetails'>

                    {
                        membersList.length > 0 ?
                            membersList.map(item => {
                                return (
                                    <Link to={`/add-family-member`} state={{ person_id: item.person_id }} className='text-decoration-none'>
                                        <div className='othersProfileCard bg-white mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <img src={item.person_image == '' ? defaultimg : item.person_image} width={'65px'} height={'65px'}
                                                    onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }} />
                                                <div className='ms-2 ps-1'>
                                                    <div className='ff-poppins-bold fs-21 text-dark'>{item.name}</div>
                                                    <div className='ff-poppins-regular fs-15 secondary-text-1'>{item.relationship}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                            :
                            <div className='addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center'>
                                <div className='ff-poppins-regular fs-14'>No Family Member Available</div>
                            </div>
                    }


                </div>
                <div className='userDetailsTitle ms-3 ps-1 me-3 pe-1 mb-2 mt-3' ref={myPetRef} >
                    <div className='d-flex justify-content-between'>
                        <div className='ff-poppins-semiBold fs-20'>My Pets</div>
                        <Link to={`/pet-register`}>
                            <button className='addButton rounded-pill ff-poppins-semiBold fs-14 white-bg'>Add Pets</button>
                        </Link>
                    </div>
                </div>
                <div className='userDetails'>

                    {
                        petsList.length > 0
                            ?

                            petsList.map(item => {
                                return (
                                    <Link to={`/pet-register`} state={{ pet_id: item.pet_id }} className='text-decoration-none'>
                                        <div className='othersProfileCard bg-white mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <img src={item.pet_image} width={'65px'} height={'65px'} />

                                                <div className='ms-2 ps-1'>
                                                    <div className='ff-poppins-bold fs-21 text-dark'>{item.name}</div>
                                                    <div className='ff-poppins-regular fs-15 secondary-text-1'>{item.type}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })

                            :
                            <div className='addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center'>
                                <div className='ff-poppins-regular fs-14'>No Pet List Available</div>
                            </div>
                    }

                </div>
                <div className='userDetailsTitle ms-3 ps-1 me-3 pe-1 mb-2 mt-3' ref={myVehiclesRef}>
                    <div className='d-flex justify-content-between'>
                        <div className='ff-poppins-semiBold fs-20'>My Vehicles</div>
                        <Link to={`/vehicle-register`}>
                            <button className='addButton rounded-pill ff-poppins-semiBold fs-14 white-bg'>Add Vehicle</button>
                        </Link>
                    </div>
                </div>
                <div className='userDetails'>
                    {
                        vehicleList.length > 0
                            ?

                            vehicleList.map(item => {
                                return (
                                    <Link to={`/vehicle-register`} state={{ vehicle_id: item.id }} className='text-decoration-none'>
                                        <div className='othersProfileCard bg-white mb-2'>
                                            <div className='d-flex align-items-center'>
                                                <img src={item.image} width={'65px'} height={'65px'} />

                                                <div className='ms-2 ps-1'>
                                                    <div className='ff-poppins-bold fs-21 text-dark'>{item.brand}</div>
                                                    <div className='ff-poppins-regular fs-15 secondary-text-1'>{item.type_name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })

                            :
                            <div className='addreddCard d-flex secondary-bg-1 mb-2 p-5 d-flex justify-content-center'>
                                <div className='ff-poppins-regular fs-14'>No Vehicle List Available</div>
                            </div>
                    }

                </div>
                {
                    modalOpen &&
                    <div class={`modal   d-flex align-items-center`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-body ps-5 pe-5 pt-4 pb-4">
                                    <div className='text-center'>Are you sure you want to delete this Address</div>

                                    <div className='d-flex justify-content-between mt-3'>
                                        <button type="button" class="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                                        <button type="button" class="btn btn-danger" onClick={() => deleteSavedLocation()} data-bs-dismiss="modal">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        </>
    )
}

export default UserProfile