import axios, { AxiosError, AxiosHeaders } from "axios";

// Dev
// const axiosInstance = axios.create({
//     baseURL: 'https://dev.pingle.in/dev_Ragul_test/',
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

//  AWS Dev 
// const axiosInstance = axios.create({
//     baseURL: 'https://dev-aws.pingle.in/api/',
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Test API
// const axiosInstance = axios.create({
//     baseURL: 'https://pingle.in/test/api/',
//     headers: { 
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Pre Production API 
// const axiosInstance = axios.create({
//     baseURL: 'https://pingle.in/pre-production/api/',
//     headers: {
//         "Access-Control-Allow-Origin": "*"
//     },
// });

// Production API 
const axiosInstance = axios.create({
    baseURL: 'https://pingle.in/api/',
    headers: {
        "Access-Control-Allow-Origin": "*"
    },
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            if (config.headers)
                (config.headers).set("Authorization-Token", `${token}`);
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof AxiosError && error.response?.status === 401) {
            // useAuthStore.setState({ signedInAs: undefined });
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;

// Razer pay live key
// rzp_live_1wESdx2BEv08Ox

// Indra key = test && pre-production
// rzp_test_OgleFD0j6keHpA  

// bala key "dev/api_v3" = dev
// rzp_test_fGhaglbOrBdNAc = old 

// New key = dev
// rzp_test_YFJUZ2uWAhEaeJ 

export const Razorpay_Key = "rzp_live_1wESdx2BEv08Ox";


 