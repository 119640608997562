import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import axiosInstance from "../../Services/axios";
import OfferCard from "../Offers/offercard";
import "./LoyaltyOffer.css";
import pingleUserIcon from "../../Assets/Images/Pingle _UserCoin 1.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PinglePoints = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [redeemlist, setRedeemlist] = useState([])
  useEffect(() => {
    getproductDetails();
    getHistroy()
  }, []);
  const getproductDetails = () => {
    try {
      axiosInstance.post("/user/loyalty_points_detail").then((res) => {
        let data = res.data;
        setLoyaltyData(data);
      });
    } catch (error) {
      alert("Try again Later");
    }
  };

  const handleRedeemButtonClick = (vendorId) => {
    navigate("/merchant-loyalty", { state: { vendorId } });
  };

  const getHistroy = () => {
    try {
      axiosInstance.post('/user/get_referral_loyalty_offer_redeem_history')
        .then((res) => {
          setRedeemlist(res.data)
        })
    }
    catch {

    }
  }
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white " >
        <div className="d-flex  justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">
            Pingle Points
          </div>
        </div>
      </div>

      <div className="p-2 scroller-style-y">
        <div>
          <img src={pingleUserIcon} className="pingleUserIcon" alt="PLIcon" />
        </div>
        {/* navigate(`/review-and-pay/${item.book_vendor_id}`) */}
        <div className="primary-bg-1 d-flex flex-column align-items-center justify-content-center py-4 pingleCoinBox" onClick={() => navigate('/PinglePointsHistory')}>
          <p className="m-0 fs-40 ff-poppins-bold primary-text">
            {loyaltyData?.total_referal_points}
          </p>
          <p className="m-0 fs-12 primary-text pb-4" >- Pingle Coins -</p>
        </div>
        <div className="white-bg p-3">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Link to={`/LoyaltyOffer`} className="margin-25">
              <button className="text-white ff-poppins-regular primary-bg border-0 p-1 px-3 py-1 rounded-pill mb-3">
                Collect Offer <MdKeyboardArrowRight className="fs-24 p-1" />
              </button>
            </Link>
            <p className="m-0 fs-12 primary-text ff-poppins-regular mb-2 mt-2">
              ELIGIBLE FOR {loyaltyData?.referal_eligble_offer_count} OFFERS
            </p>
            <p className="m-0 fs-14 primary-text ff-poppins-semiBold mb-2" onClick={() => navigate('/redeemedlist')}>
              {redeemlist.redeem_count}  ITEMS REDEEMED
            </p>
            {
              redeemlist.redeem_detail?.map((list) => {

                return (
                  <div className="d-flex m-3 col-12 rounded" onClick={() => navigate('/RedeemedDetails', { state: { purchaseid: list.purchase_id } })}>
                    <div className="col-4">
                      <div className="">
                        <img src={list.offer_detail.offer_images[0]} className="earlyBirdCardImage" alt="PLIcon" />

                        {list.deleverystatus == '2' ?
                          <button className="loyaltyStatusBtn fs-16 ff-poppins-regular" style={{ backgroundColor: '#f7b924' }}>
                            Shipped
                          </button> :
                          <button className="loyaltyStatusBtn fs-16 ff-poppins-regular" style={{ backgroundColor: '#16aaff' }} >
                            Processing
                          </button>
                        }

                      </div>
                    </div>
                    <div className="col-8 d-flex justify-content-center flex-column align-items-center">
                      <div className="w-100 px-2">
                        <p className="m-0 ff-poppins-bold fs-14 elipseLoyalty">
                          {list.offer_detail.offer_name}
                        </p>
                        <p className="m-0 ff-poppins-regular secondary-text fs-12  singleline-text">
                          {list.offer_detail.offfer_description}
                        </p>
                        <p className="m-0 ff-poppins-regular fs-12 elipseLoyalty"> {list.delivery_status == "3" ? "Delivered" : "Delivery"} on
                          <span> {new Date(list.delivery_date.substring(0, 10)).toDateString().substring(4, 20)}  </span>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })
            }

            <p className="m-0 fs-12 primary-text ff-poppins-semiBold mb-3">
              Earned pingle points history
            </p>
          </div>
        </div>
        <div className="white-bg p-2">
          {loyaltyData?.vendor_loyalty &&
            loyaltyData?.vendor_loyalty.map((loyalty, index) => (
              <div className="px-2 my-2" key={index} >
                <div className="row primary-bg-1 p-2">
                  <div className="d-flex col-5" onClick={() => navigate('/PinglePointsHistory', { state: { vendorId: loyalty.vendor_id } })}
                  >
                    <div className="col-4 px-0 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src={pingleUserIcon}
                        className="pingleUserIconEarned"
                        alt="PLIcon"
                      />
                    </div>
                    <div className="col-8 ms-2 px-0 d-flex flex-column">
                      <p className="m-0 fs-24 ff-poppins-bold primary-text">
                        {loyalty?.vendor_point}
                      </p>
                      <p className="m-0 ff-poppins-regular fs-12">
                        {loyalty?.vendor_name}
                      </p>
                    </div>
                  </div>

                  <div className="col-7 d-flex justify-content-end px-0 align-items-center">
                    <div className="">
                      <p className="m-0 fs-12 mb-2 ff-poppins-regular">
                        Eligible for {loyalty?.vendor_eligble_offers_count} offers
                      </p>
                      {/* <Link to={`/merchant-loyalty`}> */}
                      <div onClick={() => navigate(`/merchant-loyalty/${loyalty.vendor_id}`)}
                        className="text-white w-100 ff-poppins-regular d-flex justify-content-between primary-bg border-0 p-1 fs-12 rounded-pill"
                        style={{ display: "flex", justifyContent: "spaceEvenly", alignItems: "center", }}  >
                        <span className="ms-2 ">Redeem</span>  <span> <MdKeyboardArrowRight className="fs-20" /></span>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PinglePoints;
