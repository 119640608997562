import React, { useEffect, useState } from "react";
import p_nav_previous from "../../Assets/Images/p_nav_previous.svg";
import { useNavigate, useLocation, Link } from "react-router-dom"; // Import useLocation here
import axiosInstance from "../../Services/axios";
import "./LoyaltyOffer.css";
import Lottie from "lottie-react";
import empotyLottie from "../../Assets/lottieFiles/emptybox.json"
import pingleUserIcon from "../../Assets/Images/Pingle _UserCoin 1.svg";
// Pingle _UserCoin 1.svg"
import placeholderImage from "../../Assets/Images/default-placeholder.png";
import { toast } from "react-toastify";

const LoyaltyOffer = () => {
  const navigate = useNavigate();
  const [loyaltyData, setLoyaltyData] = useState({
    referal_eligble_offer_count: 0,
    referal_eligble_offers: [],
    referal_unlock_offers: [],
  });

  useEffect(() => {
    getLoyaltyOffers();
    localStorage.removeItem("loyaltyDetail");
  }, []);

  const getLoyaltyOffers = () => {
    try {
      axiosInstance
        .post("user/my_referral_loyalty_offers_detail")
        .then((res) => {
          let data = res.data;
          console.log('data', data);
          setLoyaltyData(data);
        });
    } catch (error) {
      toast("Try again later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: 'text-danger toast-style-1'
      })
    }
  };
  const setLoyaltyDetail = (offerid) => {
    let loyaltyDetail = { 'offerId': offerid }
    localStorage.setItem('loyaltyDetail', JSON.stringify(loyaltyDetail));
    // navigate('/singleLoyaltydetails', { state: { flowparent: 'pingleloyalty' } })
    navigate('/SingleRefrelPointsdetail', { state: { flowparent: 'pingleloyalty' } });

  }
  return (
    <div className="scroller-style-y">
      <div className="d-flex justify-content-between align-items-center p-3 p-navbar bg-white">
        <div className="d-flex justify-content-between align-items-center ms-2 me-2">
          <img
            src={p_nav_previous}
            width={"24px"}
            height={"16px"}
            alt="Previous Button"
            role="button"
            onClick={() => navigate(-1)}
          />
          <div className="ff-balooPaaji2-bold fs-20 ms-2 ps-1">Offer</div>
        </div>
      </div>

      <div className="p-2">
        <div>
          <p className="ff-poppins-bold fs-24 secondary-text m-0">Pingle</p>
          <p className="ff-poppins-regular fs-16 secondary-text-1">
            Loyalty Offers
          </p>
        </div>
        <div>
          {
            loyaltyData.referal_eligble_offers.length > 0 || loyaltyData.referal_unlock_offers.length > 0 ?
              <>
                {
                  loyaltyData.referal_eligble_offers.length > 0 ?
                    <div className="pingleOfferHero" id="availbleofferid">
                      <div className="pinglerOfferItemsCardsDiv">
                        <div className="fw-bold pb-3 fs-5">
                          {loyaltyData.referal_eligble_offer_count} Offers for You!
                        </div>
                        <div className="row gx-0 px-0">
                          {loyaltyData.referal_eligble_offers.map((offers, index) => (
                            <div className="col-6 px-1 " key={index}>
                              <div className="col-12 d-flex flex-column mx-1 pingleOfferCardBox">
                                <div className="col-6 px-0 w-100">
                                  <img
                                    src={
                                      offers.offer_images.length > 0
                                        ? offers.offer_images[0]
                                        : placeholderImage
                                    }
                                    onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }}
                                    alt={
                                      offers.offer_images.length > 0
                                        ? "Offer Image"
                                        : "No Offer Image"
                                    }
                                    className="pingleOfferImage"
                                  />
                                </div>

                                <div className="col-6 px-0 mainDivPingleOfferCard">
                                  <div className="pingleOfferPointsDiv d-flex justify-content-around primary-bg text-light p-2 ms-2 me-2">
                                    <img src={pingleUserIcon} className="PingleLoyaltyIcon me-2" alt="PLIcon" />
                                    <p className="m-0 ms-1 ff-poppins-semiBold fs-12 ms-1">{offers.offer_price}</p>
                                  </div>
                                  <p
                                  className="mt-3 mb-1 ff-poppins-semiBold fs-12"
                                  style={{
                                    textAlign: "center",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    width: "100%", // Adjust the width as needed
                                  }}
                                  title={offers.offer_name} // Shows the full text on hover
                                >
                                 {offers.offer_name} 
                                  </p>
                                  <p
                                    className="pingleOfferExplainText py-2"
                                    style={{
                                      fontFamily: "Poppins-Light",
                                      fontSize: "11px",
                                      textAlign: "center",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: "vertical",
                                      overflow: "hidden",
                                      height: "60px",
                                    }}
                                  >
                                    {offers.offfer_description}
                                  </p>

                                  <button className="pingleOfferRedeemBtnGreen primary-bg ff-poppins-semiBold fs-12" onClick={() => setLoyaltyDetail(offers.offer_id)}>
                                    REDEEM
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    : ""
                }
                {loyaltyData.referal_unlock_offers.length > 0 ?
                  <div className="pingleOfferHero">
                    <div className="pinglerOfferItemsCardsDiv">
                      <div className="fw-bold pb-3 fs-5">
                        {loyaltyData.referal_unlock_offers.length} Items to Unlock
                      </div>
                      <div className="row gx-0 px-0">
                        {loyaltyData.referal_unlock_offers?.map((offers, index) => (
                          <div className="col-6 container-fluid" key={index}>
                            <div className="col-12 d-flex flex-column mx-1 pingleOfferCardBox">
                              <div className="col-6 px-0 w-100">
                                <img src={
                                  offers.offer_images.length > 0
                                    ? offers.offer_images[0]
                                    : placeholderImage
                                }
                                  onError={(event) => { event.target.onerror = null; event.target.src = 'https://pingle.s3.ap-south-1.amazonaws.com/uploads/vendor/profile/user.jpg' }}
                                  // alt="Offer Image"
                                  className="pingleOfferImage"
                                />
                              </div>
                              <div className="col-6 px-0 mainDivPingleOfferCard">
                                <div className="pingleOfferPointsDiv d-flex justify-content-around primary-bg text-light p-1 ms-1 me-1">
                                  <img src={pingleUserIcon} className="PingleLoyaltyIcon ms-1 me-2" alt="PLIcon" />
                                  <p className="m-0 ms-1 ff-poppins-semiBold fs-12 ms-1 me-1">{offers.offer_price}</p>
                                </div>
                                <p
                                  className="mt-3 mb-1 ff-poppins-semiBold fs-12"
                                  style={{
                                    textAlign: "center",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    width: "100%", // Adjust the width as needed
                                  }}
                                  title={offers.offer_name} // Shows the full text on hover
                                >
                                  {offers.offer_name}
                                </p>

                                <p className="pingleOfferExplainText py-2"
                                  style={{ fontFamily: "Poppins-Light", fontSize: "11px", textAlign: "center", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", height: "60px", }}  >
                                  {offers.offfer_description}
                                </p>
                                <button className="pingleOfferRedeemBtn">REDEEM</button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  : ""
                }
              </>
              :
              <div>
                <Lottie animationData={empotyLottie} />
              </div>

          }
        </div>
      </div>
    </div>
  );
};

export default LoyaltyOffer;
